import {
  CHANGE_CURRENT_QUESTION,
  CHANGE_CURRENT_SECTION,
  CREATE_FORM,
  SEND_EMAIL,
  UPDATE_ANSWER,
  UPDATE_BUTTON_STATE,
  UPDATE_CURRENT_FORM,
} from "./actionTypes"

export const createForm = (defaultFormType) => {
  return {
    type: CREATE_FORM,
    payload: defaultFormType,
  }
}

export const updateFormData = (data) => {
  return {
    type: UPDATE_CURRENT_FORM,
    payload: data,
  }
}

export const updateCurrentQuestion = (data) => {
  return {
    type: CHANGE_CURRENT_QUESTION,
    payload: data,
  }
}

export const updateCurrentSection = (data) => {
  return {
    type: CHANGE_CURRENT_SECTION,
    payload: data,
  }
}

export const sendEmail = () => {
  return {
    type: SEND_EMAIL,
  }
}

export const updateAnswer = (data) => {
  return {
    type: UPDATE_ANSWER,
    payload: data
  }
}

export const updateButtonState = (status) => {
  return {
    type: UPDATE_BUTTON_STATE,
    payload: status
  }
}

// export const saveStudyForm = (data) => {
//     return {
//         type: SAVE_STUDY_FORM,
//         payload: data
//     }
// }

// export const changeCurrentEditableForm = (data) => {
//     return {
//         type: CHANGE_CURRENT_EDITABLE_FORM,
//         payload: data
//     }
// }

// export const addQuestion = (data) => {
//     return {
//         type: ADD_QUESTION,
//         payload: data
//     }
// }

// export const removeQuestion = (secIndex, queIndex) => {
//     return {
//         type: REMOVE_QUESTION,
//         payload: {
//             secIndex: secIndex,
//             queIndex: queIndex
//         }
//     }
// }

// export const updateQuestion = (data) => {
//     return {
//         type: UPDATE_FIELDS_IN_CURRENT_QUESTION,
//         payload: data
//     }
// }
