import { SET_INITIAL_CHAT, UPDATE_CHAT } from "./actionTypes"

export const setInitialChat = (data) => {
  return {
    type: SET_INITIAL_CHAT,
    payload: data,
  }
}

export const updateChat = (data) => {
  return {
    type: UPDATE_CHAT,
    payload: data,
  }
}
