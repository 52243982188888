import React, { useEffect, useState } from "react"
import "./scss/style.scss"
import "./css/animate.css"
import "./css/animate.css"
import "./css/bootstrap.min-min.css"
// import "./css/bootstrap.min.css"
import "./css/default.css"
import "./css/LineIcons-min.css"
import "./css/LineIcons.css"
import "./css/slick-min.css"
import "./css/slick.css"
import "./css/magnific-popup.css"
import "./css/magnific-popup-min.css"
// import hureo from "../../assets/images/hureo.png"
// import hureoLandingPage from "../../assets/images/hureoLandingPage.png"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "antd"
import slider1 from "./images/slider-1.jpg"
// import slider2 from "./images/slider-2.jpg"
// import slider3 from "./images/slider-3.jpg"
import about2 from "./images/about-2.png"
import about3 from "./images/about-3.png"
import about4 from "./images/about-4.png"
// import author1 from "./images/author-1.jpg"
// import author2 from "./images/author-2.jpg"
// import author3 from "./images/author-3.jpg"
// import author4 from "./images/author-4.jpg"
import blog1 from "./images/blog-1.jpg"
import blog2 from "./images/blog-2.jpg"
// import clientLogo01 from "./images/client_logo_01.png"
// import clientLogo02 from "./images/client_logo_02.png"
// import clientLogo03 from "./images/client_logo_03.png"
// import clientLogo04 from "./images/client_logo_04.png"
// import clientLogo05 from "./images/client_logo_05.png"
// import clientLogo06 from "./images/client_logo_06.png"
// import clientLogo07 from "./images/client_logo_07.png"
// import clientLogo08 from "./images/client_logo_08.png"
// import favicon from "./images/favicon.png"
// import flower from "./images/flower.svg"
// import header5 from "./images/header-5.jpg"
// import headershape from "./images/header-shape.svg"
// import icon1 from "./images/icon-1.png"
// import icon2 from "./images/icon-2.png"
// import icon3 from "./images/icon-3.png"
// import icon4 from "./images/icon-4.png"
// import logo2 from "./images/logo-2.svg"
// import logoalt from "./images/logo-alt.png"
// import logopng from "./images/logo.png"
// import logosvg from "./images/logo.svg"
// import portfolio1 from "./images/portfolio-1.png"
// import portfolio2 from "./images/portfolio-2.png"
// import portfolio3 from "./images/portfolio-3.png"
// import portfolio4 from "./images/portfolio-4.png"
// import portfolio5 from "./images/portfolio-5.png"
// import portfolio6 from "./images/portfolio-6.png"
// import services from "./images/services.png"
// import wman from "./images/wman.svg"
import { getFunctions, httpsCallable } from "firebase/functions"
import { displayNotification } from "utils/utility"
import { FormFeedback, Input, Label, Spinner } from "reactstrap"
import * as Yup from "yup"

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name must be at most 100 characters long"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  location: Yup.string()
    .required("Location is required")
    .max(100, "Location must be at most 100 characters long"),
  role: Yup.string().max(50, "Role must be at most 50 characters long"),
  company: Yup.string()
    .required("Company is required")
    .max(100, "Company name must be at most 100 characters long"),
  message: Yup.string()
    .required("Message is required")
    .max(1000, "Message must be at most 1000 characters long"),
})

import { useFormik } from "formik"

const FormComponent = ({ submitForm, isLoading }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      location: "",
      role: "",
      company: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitForm(values)
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  const handleInput = (e) => {
    formik.handleChange(e)
  }

  return (
    <form id="contact-form" onSubmit={handleSubmit}>
      {/* Your form inputs */}
      {/* <div className="form-input mt-15">
        <label className="d-flex">Name</label>
        <div className="input-items default">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={formik.values.name}
            onChange={handleInput}
          />
          <i className="lni-user"></i>
        </div>
        {formik.errors.name && formik.touched.name && (
          <p className="error">{formik.errors.name}</p>
        )}
      </div> */}
      <div className="mb-3">
        <div className="form-input mt-15">
          <div className="input-items default">
            <Input
              name="name"
              placeholder="Name"
              type="text"
              onChange={handleInput}
              value={formik.values.name || ""}
              invalid={formik.touched.name && formik.errors.name ? true : false}
            />
            <i className="lni-user"></i>
            {formik.touched.name && formik.errors.name ? (
              <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-input mt-15">
          <div className="input-items default">
            <Input
              name="email"
              placeholder="Email"
              type="text"
              onChange={handleInput}
              value={formik.values.email || ""}
              invalid={
                formik.touched.email && formik.errors.email ? true : false
              }
            />
            <i className="lni-envelope"></i>
            {formik.touched.email && formik.errors.email ? (
              <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-input mt-15">
          <div className="input-items default">
            <Input
              name="company"
              placeholder="Company Name"
              type="text"
              onChange={handleInput}
              value={formik.values.company || ""}
              invalid={
                formik.touched.company && formik.errors.company ? true : false
              }
            />
            <i className="fas fa-building"></i>
            {formik.touched.company && formik.errors.company ? (
              <FormFeedback type="invalid">
                {formik.errors.company}
              </FormFeedback>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-input mt-15">
          <div className="input-items default">
            <Input
              name="role"
              placeholder="Role"
              type="text"
              onChange={handleInput}
              value={formik.values.role || ""}
              invalid={formik.touched.role && formik.errors.role ? true : false}
            />
            <i className="bx bx-id-card"></i>
            {formik.touched.role && formik.errors.role ? (
              <FormFeedback type="invalid">{formik.errors.role}</FormFeedback>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-input mt-15">
          <div className="input-items default">
            <Input
              name="location"
              placeholder="Location"
              type="text"
              onChange={handleInput}
              value={formik.values.location || ""}
              invalid={
                formik.touched.location && formik.errors.location ? true : false
              }
            />
            <i className="dripicons-location"></i>
            {formik.touched.location && formik.errors.location ? (
              <FormFeedback type="invalid">
                {formik.errors.location}
              </FormFeedback>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-input mt-15">
          <div className="input-items default">
            <Input
              name="message"
              placeholder="Message"
              type="textarea"
              onChange={handleInput}
              value={formik.values.message || ""}
              invalid={
                formik.touched.message && formik.errors.message ? true : false
              }
            />
            <i className="lni-pencil-alt"></i>
            {formik.touched.message && formik.errors.message ? (
              <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
            ) : null}
          </div>
        </div>
      </div>
      {/* Repeat similar structure for other form fields */}

      <div className="form-input rounded-buttons mt-20 d-flex justify-content-center">
        <button
          type="submit"
          className="main-btn rounded-three d-flex justify-content-center  align-items-center "
          onClick={formik.handleSubmit}
        >
          {isLoading ? (
            <>
              <Spinner size="sm" color="light" className="mr-2" /> Submitting...
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  )
}

const LandingPageHTML = ({ handleNavigate }) => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    location: "",
    role: "",
    company: "",
    message: "",
  })

  const handleInput = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const navigate = useNavigate()

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true)
      const functions = getFunctions()
      const enQuiry = httpsCallable(functions, "enQuiry")
      const response = await enQuiry(values)
      if (response && response.data.status === "success") {
        displayNotification("success", response.data.message)
      } else if (response && response.data.status === "info") {
        displayNotification("info", response.data.message)
      } else {
        displayNotification("error", response.data.message)
      }
      setIsLoading(false)
    } catch (error) {
      console.error("Error sending enquiry invite", error)
      displayNotification("error", error?.message)
      setIsLoading(false)
    }
    console.log("formData", formData)
  }

  const handleInputChange = (event) => {
    setEmail(event.target.value)
  }

  const handleEmailsSubmit = async (event) => {
    event.preventDefault()
    try {
      const functions = getFunctions()
      const enQuiry = httpsCallable(functions, "sendwaitlistInvite")
      const data = {
        email: email,
      }
      setIsLoading(true)
      const response = await enQuiry(data)
      console.log("response", response)
      if (response && response.data.status === "success") {
        displayNotification("success", response.data.message)
      } else if (response && response.data.status === "info") {
        displayNotification("info", response.data.message)
      } else {
        displayNotification("error", response.data.message)
      }
      setEmail([])
      setIsLoading(false)
    } catch (error) {
      console.error("Error sending waitlist invite", error)
      displayNotification("error", error?.message)
      setIsLoading(false)
    }
  }
  const handleScrollToSupportCenter = () => {
    window.scrollTo({
      top: 200, // Scroll to 200 pixels from the top
      behavior: "smooth", // Smooth scrolling
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar-area")
      if (navbar) {
        if (window.scrollY > 0) {
          navbar.classList.add("scrolled")
        } else {
          navbar.classList.remove("scrolled")
        }
      }
    }

    window.addEventListener("scroll", handleScroll)

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <div className="landing-page">
        <head>
          <meta charset="utf-8" />
          <meta name="description" content="" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />

          <title>Smart - Multi-purpose Landing Page Template</title>

          <link
            rel="shortcut icon"
            href="assets/images/favicon.png"
            type="image/png"
          />

          <link rel="stylesheet" href="assets/css/bootstrap.min.css" />

          <link rel="stylesheet" href="assets/css/LineIcons.css" />

          <link rel="stylesheet" href="assets/css/magnific-popup.css" />

          {/* <link rel="stylesheet" href="assets/css/slick.css"> */}

          <link rel="stylesheet" href="assets/css/animate.css" />

          <link rel="stylesheet" href="assets/css/default.css" />

          <link rel="stylesheet" href="assets/css/style.css" />
        </head>
      </div>

      <body className="landing-page">
        {/* <div className="preloader">
          <div className="loader">
            <div className="ytp-spinner">
              <div className="ytp-spinner-container">
                <div className="ytp-spinner-rotator">
                  <div className="ytp-spinner-left">
                    <div className="ytp-spinner-circle"></div>
                  </div>
                  <div className="ytp-spinner-right">
                    <div className="ytp-spinner-circle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section className="header-area">
          <div className="navbar-area">
            <div className="container" style={{ maxWidth: "none" }}>
              <div className="row">
                <div className="col-lg-12">
                  <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand ml-4" href="#">
                      {/* <img src={hureo} alt="Logo" /> */}
                      <h2 className="text " style={{ color: "white" }}>
                        Hureo.ai
                      </h2>
                    </a>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarEight"
                      aria-controls="navbarEight"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                    </button>

                    <div
                      className="collapse navbar-collapse sub-menu-bar "
                      id="navbarEight"
                    >
                      <ul className="navbar-nav ml-auto">
                        {/* <li className="nav-item active">
                          <a className="page-scroll" href="#home">
                            HOME
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#about">
                            ABOUT
                          </a>
                        </li> */}
                        {/* <li className="nav-item">
                          <a className="page-scroll" href="#services">
                            SERVICES
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#pricing">
                            PRICING
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#blog">
                            BLOG
                          </a>
                        </li> */}
                        {/* <li className="nav-item">
                          <a className="page-scroll" href="#contact">
                            CONTACT
                          </a>
                        </li> */}
                        <li className="nav-item ">
                          <ul className="carousel-btn rounded-buttons">
                            <li>
                              {!localStorage.getItem("authUser") ? (
                                <>
                                  <Button
                                    className="main-btn rounded-two"
                                    // href="/login"
                                    onClick={() => handleNavigate("/login")}
                                  >
                                    Login
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex justify-center align-items-center mt-3">
                                    <Button
                                      onClick={() =>
                                        handleNavigate("/dashboard")
                                      }
                                      className="main-btn rounded-one  d-flex justify-center align-items-center "
                                      // href="/dashboard"
                                    >
                                      Visit Dashboard
                                    </Button>
                                  </div>
                                </>
                              )}
                            </li>
                          </ul>
                        </li>{" "}
                        <li className="nav-item">
                          <ul className="carousel-btn rounded-buttons">
                            <li>
                              <div className="d-flex justify-center align-items-center">
                                {!localStorage.getItem("authUser") ? (
                                  // <Button
                                  //   className="main-btn rounded-one mx-2"
                                  //   onClick={() => handleNavigate("/register")}
                                  // >
                                  //   Signup
                                  // </Button>
                                  <></>
                                ) : (
                                  <>
                                    <div
                                      className="d-flex justify-center align-items-center mt-3"
                                      // style={{ marginTop: "2px" }}
                                    >
                                      {/* <Button
                                        className="main-btn rounded-three mx-2 d-flex justify-center align-items-center"
                                        onClick={() =>
                                          handleNavigate("/register")
                                        }
                                      >
                                        Signup
                                      </Button> */}
                                      <Button
                                        className="main-btn rounded-two mx-2 d-flex justify-center align-items-center"
                                        // href="/logout"
                                        onClick={() =>
                                          handleNavigate("/logout")
                                        }
                                      >
                                        Logout
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="navbar-btn mt-15 d-none d-lg-inline-block">
                      <a className="menu-bar" href="#side-menu-right">
                        <i className="lni-menu"></i>
                      </a>
                    </div> */}
                  </nav>
                  {/* <!-- navbar --> */}
                </div>
              </div>
              {/* <!-- row --> */}
            </div>
            {/* <!-- container --> */}
          </div>
          {/* <!-- navbar area --> */}

          <div id="home" className="slider-area slider-3">
            <div className="bd-example">
              <div
                id="carouselOne"
                className="carousel slide"
                data-ride="carousel"
              >
                {/* <ol className="carousel-indicators">
                  <li
                    data-target="#carouselOne"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#carouselOne" data-slide-to="1"></li>
                  <li data-target="#carouselOne" data-slide-to="2"></li>
                </ol> */}

                <div className="carousel-inner">
                  <div
                    className="carousel-item bg_cover active background-image"
                    style={{
                      backgroundImage: `url(${slider1})`,
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="overlay"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1,
                      }}
                    ></div>
                    <div className="carousel-caption w-100">
                      {/* <img src={hureoLandingPage}  className="w-100"/> */}
                      <div className="">
                        <div className="row d-flex  justify-content-center">
                          <div className="col-xl-6 col-lg-7 col-sm-10">
                            <div
                            // style={{
                            //   marginTop: "250px",
                            //   marginLeft: "139px",
                            // }}
                            >
                              <div className="d-flex justify-content-center  align-item-center ">
                                <h3
                                  style={{
                                    width: "100%",
                                    color: "white",
                                    // height: "164px",
                                    fontSize: "42px",
                                    lineHeight: "56px",
                                    fontWeight: "700px",
                                  }}
                                >
                                  Let's build technology with Empathy
                                </h3>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                {" "}
                                <form action="#">
                                  <input
                                    className=" px-4 rounded-one  "
                                    style={{
                                      width: "75%",
                                      height: "50px",
                                      border: "none",
                                      // borderColor: "none",
                                      borderRadius: "50px",
                                      cursor: "auto",
                                      fontSize: "medium",
                                    }}
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleInputChange}
                                  />
                                  <div className="action-btn rounded-buttons">
                                    <button
                                      className="main-btn rounded-two "
                                      style={{
                                        // width: "75%",
                                        marginTop: "20px",
                                        height: "50px",
                                        marginLeft: "-5px",
                                        borderRadius: "50px",
                                      }}
                                      onClick={(value) =>
                                        handleEmailsSubmit(value)
                                      }
                                    >
                                      {isLoading ? (
                                        <>
                                          <Spinner
                                            size="sm"
                                            color="light"
                                            className="mr-2"
                                          />{" "}
                                          Submitting...
                                        </>
                                      ) : (
                                        "Join waiting list"
                                      )}
                                    </button>
                                  </div>
                                </form>
                              </div>
                              {/* <div className="carousel-caption ">
                                <div className="d-flex justify-content-center ">
                                  <div className="col-lg-6">
                                    <div className="call-action-form "></div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <!-- row --> */}
                      </div>
                      {/* <!-- container --> */}
                    </div>
                    {/* <!-- carousel caption --> */}
                  </div>
                  {/* <!-- carousel-item --> */}

                  {/* <div
                    className="carousel-item bg_cover"
                    style="background-image: url(assets/images/slider-2.jpg)"
                    style={{backgroundImage: 'url("assets/images/slider-2.jpg")'}}
                    style={{backgroundImage: slider2}}
                    style={{ backgroundImage: `url(${slider2})` }}
                  >
                    <div className="carousel-caption">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-xl-6 col-lg-7 col-sm-10">
                            <h2 className="carousel-title">
                              Based on Latest Bootstrap & HTML5
                            </h2>
                            <ul className="carousel-btn rounded-buttons">
                              <li>
                                <a className="main-btn rounded-three" href="#">
                                  GET STARTED
                                </a>
                              </li>
                              <li>
                                <a className="main-btn rounded-one" href="#">
                                  DOWNLOAD
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <!-- row -->
                      </div>
                      <!-- container -->
                    </div>
                    <!-- carousel caption -->
                  </div>
                  <!-- carousel-item --> */}

                  {/* <div
                    className="carousel-item bg_cover"
                    style="background-image: url(assets/images/slider-3.jpg)"
                    style={{backgroundImage: 'url("assets/images/slider-3.jpg")'}}
                    style={{backgroundImage: slider3}}
                    style={{ backgroundImage: `url(${slider3})` }}
                  >
                    <div className="carousel-caption">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-xl-6 col-lg-7 col-sm-10">
                            <h2 className="carousel-title">
                              Multi-purpose Landing Page Template
                            </h2>
                            <ul className="carousel-btn rounded-buttons">
                              <li>
                                <a className="main-btn rounded-three" href="#">
                                  GET STARTED
                                </a>
                              </li>
                              <li>
                                <a className="main-btn rounded-one" href="#">
                                  DOWNLOAD
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <!-- row -->
                      </div>
                      <!-- container -->
                    </div>
                    <!-- carousel caption -->
                  </div>
                  <!-- carousel-item -->
                <!-- carousel-inner --> */}
                </div>

                {/* <a
                  className="carousel-control-prev"
                  href="#carouselOne"
                  role="button"
                  data-slide="prev"
                >
                  <i className="lni-arrow-left-circle"></i>
                </a>

                <a
                  className="carousel-control-next"
                  href="#carouselOne"
                  role="button"
                  data-slide="next"
                >
                  <i className="lni-arrow-right-circle"></i>
                </a> */}
              </div>
              {/* <!-- carousel --> */}
            </div>
            {/* <!-- bd-example --> */}
          </div>
        </section>

        {/* <!--====== HEADER PART ENDS ======--> */}

        {/* <!--====== SAIDEBAR PART START ======--> */}

        {/* <div className="sidebar-right">
          <div className="sidebar-close">
            <a className="close" href="#close">
              <i className="lni-close"></i>
            </a>
          </div>
          <div className="sidebar-content">
            <div className="sidebar-logo text-center">
              <a href="#">
                <img src={hureoLandingPage} alt="Logo" /></a>
            </div>
            <!-- logo -->
            <div className="sidebar-menu">
              <ul>
                <li>
                  <a href="#">ABOUT</a>
                </li>
                <li>
                  <a href="#">SERVICES</a>
                </li>
                <li>
                  <a href="#">RESOURCES</a>
                </li>
                <li>
                  <a href="#">CONTACT</a>
                </li>
              </ul>
            </div>
            <!-- menu -->
            <div className="sidebar-social d-flex align-items-center justify-content-center">
              <span>FOLLOW US</span>
              <ul>
                <li>
                  <a href="#">
                    <i className="lni-twitter-original"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="lni-facebook-filled"></i>
                  </a>
                </li>
              </ul>
            </div>
            <!-- sidebar social -->
          </div>
          <!-- content -->
        </div>
        <div className="overlay-right"></div>

        <!--====== SAIDEBAR PART ENDS ======-->

        <!--====== ABOUT PART START ======--> */}
        <section
          id="about"
          className="about-area-3"
          style={{ backgroundColor: "white" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="about-content  wow fadeInRight align-items-center"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                >
                  <h5 className="sub-title"></h5>
                  <h3 className="about-title">About the product</h3>
                </div>
                <p className="text">
                  Welcome to our revolutionary SaaS platform designed to
                  transform the way you interact with your customers and gather
                  crucial insights for your product and marketing campigns. At
                  our core, we prioritize simplicity and effectiveness, ensuring
                  that our platform empowers you to make informed decisions with
                  ease.
                </p>
                {/* <!-- about content --> */}
              </div>
              <div className="col-lg-6">
                <div
                  className="image mt-50 wow fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                  style={{ border: "none" }}
                >
                  <img src={about2} alt="about" />
                </div>
                {/* <!-- about image --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </section>
        <section id="about" className="about-area-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="image mt-50 wow fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                  style={{ border: "none" }}
                >
                  <img src={about3} alt="about" />
                </div>
                {/* <!-- about image --> */}
              </div>
              <div className="col-lg-6">
                <div
                  className="about-content  wow fadeInRight align-items-center"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                >
                  <h5 className="sub-title"></h5>
                  <h3 className="about-title">What the tool does?</h3>
                </div>
                <p className="text">
                  Our platform offers a straightforward yet powerful 3-step
                  process to streamline your customer engagement strategy:
                  <ul className="landing-page-list">
                    <li>
                      {" "}
                      <b>Define the Problem:</b> Identify the key areas where
                      you seek insights, whether it's product development,
                      design enhancements, or optimizing marketing campaigns.
                    </li>
                    <li>
                      <b>Engage with Your Customers:</b> Seamlessly connect with
                      your customers directly through our integrated platform.
                      No more cumbersome processes or unnecessary steps – just
                      direct and meaningful interaction.
                    </li>{" "}
                    <li>
                      <b>Gain Valuable Insights:</b> Through these interactions,
                      unlock valuable insights that drive informed
                      decision-making for your business. Understand your
                      customers' needs, preferences, and pain points like never
                      before.
                    </li>
                  </ul>
                </p>
                {/* <!-- about content --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </section>
        <section
          id="about"
          className="about-area-3"
          style={{ backgroundColor: "white" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="about-content wow fadeInRight align-items-center"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                >
                  <h5 className="sub-title"></h5>
                  <h3 className="about-title">Who can benefit?</h3>
                </div>
                <p className="text">
                  Our platform caters to a wide range of professionals and
                  teams, including:
                  <ul className="landing-page-list">
                    <li>
                      <b>Product Managers:</b> Enhance your product development
                      process by gathering direct feedback from your target
                      audience.
                    </li>
                    <li>
                      <b>Designers:</b> Gain insights into user preferences and
                      behavior to create more intuitive and user-friendly
                      designs.
                    </li>
                    <li>
                      <b>Marketing Teams:</b> Optimize your marketing campaigns
                      by understanding customer sentiments and preferences in
                      real-time.
                    </li>
                    <li>
                      <b>Customer Support Teams:</b> Address customer concerns
                      and issues promptly by engaging directly with your user
                      base.
                    </li>
                  </ul>
                  <br />
                  Join us today and revolutionize your approach to customer
                  feedback and engagement. Say hello to a more efficient and
                  insightful way of connecting with your customers.
                </p>
                {/* <!-- about content --> */}
              </div>
              <div className="col-lg-6">
                <div
                  className="image mt-50 wow fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                  style={{ border: "none" }}
                >
                  <img src={about4} alt="about" />
                </div>
                {/* <!-- about image --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </section>
        {/* <section id="about" className="about-area-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                className="image mt-50 wow fadeInLeft"
                data-wow-duration="1.5s"
                data-wow-delay="0.6s"
                data-wow-offset="50"
                >
                  <img src={about3} alt="about" />
                </div>
                <!-- about image -->
              </div>
              <div className="col-lg-6">
                <div
                  className="about-content  wow fadeInRight align-items-center"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                >
                  <h5 className="sub-title"></h5>
                  <h3 className="about-title">Join our community</h3>
                  <p className="text mb-5">
                    At Hureo - connecting scattered dots , we have expertise in
                    a wide range of software development areas, including web
                    development, mobile app development, and custom software
                    development. We use the latest technologies and tools to
                    ensure that our clients receive cutting-edge solutions that
                    drive their business forward. We're on a mission to
                    revolutionize the way companies approach product
                    development. Join our community today and be part of the
                    movement towards building products with empathy at their
                    core. Sign up now to stay updated on our latest developments
                    and be the first to experience the power of empathy-driven
                    innovation.
                  </p>
                </div>
                <!-- about content -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- container -->
        </section> */}

        {/* <section id="about" className="about-area-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="image mt-50 wow fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                >
                  <img src={about3} alt="about" />
                </div>
                <!-- about image -->
              </div>
              <div className="col-lg-18">
                <div
                  className="about-content mt-45 wow fadeInRight align-items-center justify-content-center"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  data-wow-offset="50"
                >
                  <h5 className="sub-title"></h5>
                  <h4 className="about-title text-center">About the product</h4>
                  <p className="text text-center">
                    Hureo. AI is a platform designed to harness the power of
                    empathy in building products that resonate with users. Our
                    mission is simple: to empower companies to connect with
                    their users on a deeper level, crafting solutions that truly
                    meet their needs.
                  </p>
                </div>
                <!-- about content -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- container -->
        </section> */}

        {/* <!--====== ABOUT PART ENDS ======--> */}

        {/* <!--====== ABOUT PART START ======--> */}

        {/* <section id="testimonial" className="testimonial-area testimonial-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center pb-20">
                  <h3 className="title"></h3>
                  <p className="text">
                    Stop wasting time and money designing and managing a website
                    that doesn’t get results. Happiness guaranteed!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 order-lg-first">
              <div className="col-lg-12 order-lg-first">
                <div
                  className="about-content  wow fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                >
                  <h5 className="sub-title"> Our Mission</h5>
                  <h3 className="sub-title">What the tool does?</h3>
                  <p className="text mb-3">
                    At Hureo - connecting scattered dots , our mission is to
                    provide top-quality software solutions to businesses of all
                    sizes. We strive to exceed our clients' expectations and
                    deliver innovative solutions that drive growth and success.
                    Our platform offers a seamless 3-step guided process,
                    blending human insight with AI capabilities. It provides
                    actionable guidance on what actions to take, offers support
                    on execution, and generates invaluable insights for product
                    management, UX, and marketing teams. With Hureo.AI,
                    companies can navigate the complex landscape of user
                    feedback and turn it into actionable strategies for success.
                  </p>{" "}
                  <h5 className="sub-title"> Our Team</h5>
                  <h3 className="sub-title ">Who can benefit?</h3>
                  <p className="text mb-3">
                    Our team is comprised of experienced software developers,
                    designers, and project managers who are dedicated to
                    delivering exceptional results. We work collaboratively with
                    our clients to ensure that their unique needs are met and
                    that they receive the best possible service. Our platform
                    caters to product managers, marketing teams, UX teams, and
                    anyone looking to engage with their customers to create
                    products or marketing campaigns that truly resonate.
                  </p>{" "}
                  <h5 className="sub-title"> Our Expertise</h5>
                  <h3 className="sub-title">Join our community</h3>
                  <p className="text">
                    At Hureo - connecting scattered dots , we have expertise in
                    a wide range of software development areas, including web
                    development, mobile app development, and custom software
                    development. We use the latest technologies and tools to
                    ensure that our clients receive cutting-edge solutions that
                    drive their business forward. We're on a mission to
                    revolutionize the way companies approach product
                    development. Join our community today and be part of the
                    movement towards building products with empathy at their
                    core. Sign up now to stay updated on our latest developments
                    and be the first to experience the power of empathy-driven
                    innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <!--====== ABOUT PART ENDS ======--> */}

        {/* <!--====== SERVICES PART START ======--> */}

        {/* <section id="services" className="services-area ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center pb-20">
                  <h3 className="title">Our Services</h3>
                  <p className="text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum
                    vitae aspernatur obcaecati. Earum sequi reiciendis,
                    dignissimos eaque explicabo rerum nemo minima, dolorem,
                    corporis ipsa nihil.
                  </p>
                </div>
                <!-- section title -->
              </div>
            </div>
            <!-- row -->
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-services mt-30 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.2s"
                >
                  <div className="services-icon">
                    <i className="lni-rocket"></i>
                  </div>
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="#">Best Creative Solution</a>
                    </h5>
                    <p className="text">
                      We use product UI, UX Motion to the interaction design to
                      craft products that makes better solutions.
                    </p>
                  </div>
                </div>
                <!-- single services -->
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-services mt-30 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.4s"
                >
                  <div className="services-icon">
                    <i className="lni-layers"></i>
                  </div>
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="#">Branding Support</a>
                    </h5>
                    <p className="text">
                      We use product UI, UX Motion to the interaction design to
                      craft products that makes better solutions.
                    </p>
                  </div>
                </div>
                <!-- single services -->
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-services mt-30 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.7s"
                >
                  <div className="services-icon">
                    <i className="lni-offer"></i>
                  </div>
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="#">Bussiness Consultancy</a>
                    </h5>
                    <p className="text">
                      We use product UI, UX Motion to the interaction design to
                      craft products that makes better solutions.
                    </p>
                  </div>
                </div>
                <!-- single services -->
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-services mt-30  fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.2s"
                >
                  <div className="services-icon">
                    <i className="lni-bullhorn"></i>
                  </div>
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="#">Startup Bussiness</a>
                    </h5>
                    <p className="text">
                      We use product UI, UX Motion to the interaction design to
                      craft products that makes better solutions.
                    </p>
                  </div>
                </div>
                <!-- single services -->
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-services mt-30 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.4s"
                >
                  <div className="services-icon">
                    <i className="lni-archive"></i>
                  </div>
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="#">Good Service Guarantee</a>
                    </h5>
                    <p className="text">
                      We use product UI, UX Motion to the interaction design to
                      craft products that makes better solutions.
                    </p>
                  </div>
                </div>
                <!-- single services -->
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="single-services mt-30 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.7s"
                >
                  <div className="services-icon">
                    <i className="lni-briefcase"></i>
                  </div>
                  <div className="services-content">
                    <h5 className="services-title">
                      <a href="#">Bussiness Consultancy</a>
                    </h5>
                    <p className="text">
                      We use product UI, UX Motion to the interaction design to
                      craft products that makes better solutions.
                    </p>
                  </div>
                </div>
                <!-- single services -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- container -->
        </section> */}

        {/* <!--====== SERVICES PART ENDS ======--> */}

        {/* <!--====== PRINICNG STYLE EIGHT START ======--> */}

        {/* <section id="pricing" className="pricing-area pricing-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center pb-20">
                  <h3 className="title">Pricing Plan</h3>
                  <p className="text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum vitae aspernatur obcaecati. Earum sequi reiciendis, dignissimos eaque explicabo rerum nemo minima, dolorem, corporis ipsa nihil.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-7 col-sm-9">
                <div
                  className="pricing-style-two mt-40 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.2s"
                >
                  <div className="pricing-icon text-center">
                    <img src="assets/images/flower.svg" alt="" />
                  </div>
                  <div className="pricing-header text-center">
                    <h5 className="sub-title">Basic</h5>
                    <span className="price">$ 199</span>
                    <p className="year">per year</p>
                  </div>
                  <div className="pricing-list">
                    <ul>
                      <li>
                        <i className="lni-check-mark-circle"></i> Carefully
                        crafted components
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Amazing page
                        examples
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Super friendly
                        support team
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Awesome
                        Support
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-btn rounded-buttons text-center">
                    <a className="main-btn rounded-three" href="#">
                      GET STARTED
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-7 col-sm-9">
                <div
                  className="pricing-style-two mt-40 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
                >
                  <div className="pricing-icon text-center">
                    <img src="assets/images/flower.svg" alt="" />
                  </div>
                  <div className="pricing-header text-center">
                    <h5 className="sub-title">Pro</h5>
                    <span className="price">$ 399</span>
                    <p className="year">per year</p>
                  </div>
                  <div className="pricing-list">
                    <ul>
                      <li>
                        <i className="lni-check-mark-circle"></i> Carefully
                        crafted components
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Amazing page
                        examples
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Super friendly
                        support team
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Awesome
                        Support
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-btn rounded-buttons text-center">
                    <a className="main-btn rounded-three" href="#">
                      GET STARTED
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-7 col-sm-9">
                <div
                  className="pricing-style-two mt-40 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.8s"
                >
                  <div className="pricing-icon text-center">
                    <img src="assets/images/flower.svg" alt="" />
                  </div>
                  <div className="pricing-header text-center">
                    <h5 className="sub-title">Enterprise</h5>
                    <span className="price">$ 699</span>
                    <p className="year">per year</p>
                  </div>
                  <div className="pricing-list">
                    <ul>
                      <li>
                        <i className="lni-check-mark-circle"></i> Carefully
                        crafted components
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Amazing page
                        examples
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Super friendly
                        support team
                      </li>
                      <li>
                        <i className="lni-check-mark-circle"></i> Awesome
                        Support
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-btn rounded-buttons text-center">
                    <a className="main-btn rounded-three" href="#">
                      GET STARTED
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <!--====== PRINICNG STYLE EIGHT ENDS ======--> */}

        {/* <!--====== CALL TO ACTION TWO PART START ======--> */}

        <section id="call-action" className="call-action-area call-action-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="call-action-content mt-45">
                  <h3 className="action-title">
                    Join our excusive waiting list!
                  </h3>
                  <p className="text ">
                    As we diligently craft and refine our product, we're
                    selectively launching to a limited number of customers to
                    ensure an exceptional user experience. If you're eager to be
                    part of our journey and contribute to shaping our platform,
                    reach out to us today! As soon as we have space available,
                    we'll gladly welcome you on board.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="call-action-form mt-50">
                  <form action="#">
                    <input
                      style={{ height: "50px", borderRadius: "50px" }}
                      value={email}
                      onChange={handleInputChange}
                      placeholder="Enter your email"
                    />
                    <div className="action-btn rounded-buttons  ">
                      <button
                        className="main-btn rounded-two mb-1 d-flex  align-items-center  "
                        onClick={(value) => handleEmailsSubmit(value)}
                      >
                        {isLoading ? (
                          <>
                            <Spinner size="sm" color="light" className="mr-2" />{" "}
                            Loading...
                          </>
                        ) : (
                          "Join"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>{" "}
        </section>

        {/* <!--====== CALL TO ACTION TWO PART ENDS ======--> */}

        {/* <!--====== TESTIMONIAL THREE PART START ======--> */}

        {/* <section id="testimonial" className="testimonial-area testimonial-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center pb-20">
                  <h3 className="title">Testimonial</h3>
                  <p className="text">
                    Stop wasting time and money designing and managing a website
                    that doesn’t get results. Happiness guaranteed!
                  </p>
                </div>
                <!-- section title -->
              </div>
            </div>
            <!-- row -->

            <div className="row">
              <div className="col-lg-12">
                <div className="row testimonial-active-2">
                  <div className="col-lg-4">
                    <div className="single-testimonial mt-30 mb-30 text-center">
                      <div className="testimonial-image">
                        <img src={author3} alt="Author" />
                      </div>
                      <div className="testimonial-content">
                        <p className="text">
                          Stop wasting time and money designing and managing a
                          website that doesn’t get results. Happiness
                          guaranteed! Stop wasting time and money designing and
                          managing a website that doesn’t get results. Happiness
                          guaranteed!
                        </p>
                        <h6 className="author-name">Isabela Moreira</h6>
                        <span className="sub-title">CEO, GrayGrids</span>
                      </div>
                    </div>
                    <!-- single testimonial -->
                  </div>
                  <div className="col-lg-4">
                    <div className="single-testimonial mt-30 mb-30 text-center">
                      <div className="testimonial-image">
                        <img src={author1} alt="Author" />
                      </div>
                      <div className="testimonial-content">
                        <p className="text">
                          Stop wasting time and money designing and managing a
                          website that doesn’t get results. Happiness
                          guaranteed! Stop wasting time and money designing and
                          managing a website that doesn’t get results. Happiness
                          guaranteed!
                        </p>
                        <h6 className="author-name">Fiona</h6>
                        <span className="sub-title">Lead Designer, UIdeck</span>
                      </div>
                    </div>
                    <!-- single testimonial -->
                  </div>
                  <div className="col-lg-4">
                    <div className="single-testimonial mt-30 mb-30 text-center">
                      <div className="testimonial-image">
                        <img src={author2} alt="Author" />
                      </div>
                      <div className="testimonial-content">
                        <p className="text">
                          Stop wasting time and money designing and managing a
                          website that doesn’t get results. Happiness
                          guaranteed! Stop wasting time and money designing and
                          managing a website that doesn’t get results. Happiness
                          guaranteed!
                        </p>
                        <h6 className="author-name">Elon Musk</h6>
                        <span className="sub-title">CEO, SpaceX</span>
                      </div>
                    </div>
                    <!-- single testimonial -->
                  </div>
                </div>
                <!-- row -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- container -->
        </section> */}

        {/* <!--====== TESTIMONIAL THREE PART ENDS ======--> */}

        {/* <!--====== CLIENT LOGO PART START ======--> */}

        {/* <section id="client" className="client-logo-area client-logo-2">
          <div className="container">
            <div className="row client-active">
              <div className="col-lg-3">
                <div className="single-client text-center">
                  <img src={clientLogo01} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-client text-center">
                  <img src={clientLogo02} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-client text-center">
                  <img src={clientLogo03} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-client text-center">
                  <img src={clientLogo04} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-client text-center">
                  <img src={clientLogo05} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-client text-center">
                  <img src={clientLogo06} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-client text-center">
                  <img src={clientLogo07} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-client text-center">
                  <img src={clientLogo08} alt="Logo" />
                </div>
              </div>
            </div>
           
          </div>
         
        </section> */}

        {/* <!--====== CLIENT LOGO PART ENDS ======--> */}

        {/* <!--====== BLOG PART START ======--> */}

        {/* <section id="blog" className="blog-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center pb-20">
                  <h3 className="title">Our Blog</h3>
                  <p className="text">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Veritatis autem, sed dolor asperiores ab ex aliquam libero
                    repellat saepe porro. Sapiente quaerat sequi nam molestiae
                    reprehenderit facere maiores modi natus?
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-7 col-sm-9">
                <div
                  className="single-blog mt-50 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog-image">
                    <img src={blog1} alt="Blog" />
                  </div>
                  <div className="blog-content">
                    <h4 className="blog-title">
                      <a href="#">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Reiciendis esse repudiandae rem sequi velit doloribus.
                      </a>
                    </h4>
                    <span>
                      <i className="lni-calendar"></i> Mar 23, 2022
                    </span>
                    <span>
                      <i className="lni-comment-alt"></i> 24Commnet
                    </span>
                    <p className="text">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Laborum officiis corrupti eius distinctio provident,
                      nostrum cum cupiditate? Ipsa sapiente blanditiis sed, ea
                      dolores hic labore. Enim omnis sint incidunt doloribus.
                    </p>
                    <a className="more" href="#">
                      READ MORE
                    </a>
                  </div>
                </div>
                <!-- single blog -->
              </div>
              <div className="col-lg-4 col-md-7 col-sm-9">
                <div
                  className="single-blog mt-50 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
                >
                  <div className="blog-image">
                    <img src={blog1} alt="Blog" />
                  </div>
                  <div className="blog-content">
                    <h4 className="blog-title">
                      <a href="#">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Obcaecati, itaque?
                      </a>
                    </h4>
                    <span>
                      <i className="lni-calendar"></i> Mar 23, 2022
                    </span>
                    <span>
                      <i className="lni-comment-alt"></i> 24Commnet
                    </span>
                    <p className="text">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Vitae reiciendis, esse autem accusamus, excepturi
                      consequuntur eius cum, et soluta libero optio minima
                      maiores eligendi. Ad non rem possimus quisquam aliquam.
                    </p>
                    <a className="more" href="#">
                      READ MORE
                    </a>
                  </div>
                </div>
                <!-- single blog -->
              </div>
              <div className="col-lg-4 col-md-7 col-sm-9">
                <div
                  className="single-blog mt-50 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.8s"
                >
                  <div className="blog-image">
                    <img src={blog1} alt="Blog" />
                  </div>
                  <div className="blog-content">
                    <h4 className="blog-title">
                      <a href="#">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Repellat, iste.
                      </a>
                    </h4>
                    <span>
                      <i className="lni-calendar"></i> Mar 23, 2022
                    </span>
                    <span>
                      <i className="lni-comment-alt"></i> 24Commnet
                    </span>
                    <p className="text">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dignissimos, consectetur eum, doloribus id rerum sed odit
                      fuga corporis quas numquam iure amet reprehenderit itaque
                      facilis!
                    </p>
                    <a className="more" href="#">
                      READ MORE
                    </a>
                  </div>
                </div>
                <!-- single blog -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- container -->
        </section> */}

        {/* <!--====== BLOG PART ENDS ======--> */}

        {/* <!--====== CONTACT TWO PART START ======--> */}
        <section id="blog" className="blog-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center pb-20">
                  <h3 className="title">Our Blog</h3>
                  <p className="text">
                    Stop wasting time and money designing and managing a website
                    that doesn’t get results. Happiness guaranteed!
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-7 col-sm-9">
                <div
                  className="single-blog mt-50 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.2s"
                >
                  <div className="blog-image">
                    {/* <img src="assets/images/blog-1.jpg" alt="Blog"/> */}
                  </div>
                  <div className="blog-content">
                    {/* <h4 className="blog-title"><a href="#">Budget-Friendly Design Ideas to Transform Your Office</a></h4>
                            <span><i className="lni-calendar"></i> Mar 23, 2022</span>
                            <span><i className="lni-comment-alt"></i> 24Commnet</span>
                            <p className="text">Create a workspace your team will love with these money-saving interior design tips. Learn how People and Culture leaders… align their workspace and company values.</p>
                            <a className="more" href="#">READ MORE</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 col-sm-9">
                <div
                  className="single-blog mt-50 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
                >
                  <div className="blog-image">
                    <img src={blog1} alt="Blog" />
                  </div>
                  <div className="blog-content">
                    <h4 className="blog-title">
                      <a>Join our community</a>
                    </h4>
                    <span>
                      <i className="lni-calendar"></i> Apr 4, 2024
                    </span>
                    {/* <span>
                      <i className="lni-comment-alt"></i> 24Comment
                    </span> */}
                    <p className="text">
                      At Hureo - connecting scattered dots , we have expertise
                      in a wide range of software development areas, including
                      web development, mobile app development, and custom
                      software development. We use the latest technologies and
                      tools to ensure that our clients receive cutting-edge
                      solutions that drive their business forward. We're on a
                      mission to revolutionize the way companies approach
                      product development. Join our community today and be part
                      of the movement towards building products with empathy at
                      their core. Sign up now to stay updated on our latest
                      developments and be the first to experience the power of
                      empathy-driven innovation.
                    </p>
                    {/* <a className="more" onClick={() => navigate("/blog")}>
                      READ MORE
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 col-sm-9">
                <div
                  className="single-blog mt-50 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
                >
                  <div className="blog-image">
                    <img src={blog2} alt="Blog" />
                  </div>
                  <div className="blog-content">
                    <h4 className="blog-title">
                      <a onClick={() => navigate("/blog")}>
                        Efficient and Budget-Friendly User Feedback Solutions
                        for Product Development
                      </a>
                    </h4>
                    <span>
                      <i className="lni-calendar"></i> Apr 5, 2024
                    </span>
                    {/* <span>
                      <i className="lni-comment-alt"></i> 24Comment
                    </span> */}
                    <p className="text">
                      At Hureo, we've witnessed firsthand the challenges that
                      companies face when trying to engage with their users
                      effectively. While we've helped numerous organizations
                      connect with their customers and make informed decisions
                      about their products, we've also encountered many startups
                      that simply couldn't afford the necessary resources for
                      regular user feedback.
                    </p>
                    <a className="more" onClick={() => navigate("/blog")}>
                      READ MORE
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-7 col-sm-9">
                <div
                  className="single-blog mt-50 wow fadeIn"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.8s"
                >
                  <div className="blog-image">
                    {/* <img src="assets/images/blog-1.jpg" alt="Blog" /> */}
                  </div>
                  <div className="blog-content">
                    {/* <h4 className="blog-title">
                      <a href="#">
                        Budget-Friendly Design Ideas to Transform Your Office
                      </a>
                    </h4>
                    <span>
                      <i className="lni-calendar"></i> Mar 23, 2022
                    </span>
                    <span>
                      <i className="lni-comment-alt"></i> 24Commnet
                    </span>
                    <p className="text">
                      Create a workspace your team will love with these
                      money-saving interior design tips. Learn how People and
                      Culture leaders… align their workspace and company values.
                    </p>
                    <a className="more" href="#">
                      READ MORE
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="contact-area">
          <div className="container contact-one">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-title text-center pb-20">
                  <h3 className="title">Get in touch!</h3>
                  <p className="text">
                    Any question or remarks? just write us a message!
                  </p>
                  <div className="d-flex">
                    <div className="col-lg-6 mx-auto">
                      <div
                        className="contact-form form-style-one mt-35 wow fadeIn"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.5s"
                      >
                        <FormComponent
                          submitForm={handleSubmit}
                          isLoading={isLoading}
                        />
                      </div>
                      {/* <!-- contact form --> */}
                    </div>
                  </div>
                </div>
                {/* <!-- section title --> */}
              </div>
            </div>
            {/* <!-- row --> */}
            <div className=" d-flex justify-center align-items-center"></div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </section>

        {/* <!--====== CONTACT TWO PART ENDS ======--> */}

        {/* <!--====== FOOTER FOUR PART START ======--> */}

        <footer id="footer" className="footer-area footer-dark">
          <div className="footer-widget">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  {/* <div className="footer-link">
                    <h6 className="footer-title">Company</h6>
                    <ul>
                      <li>
                        <a href="#">About</a>
                      </li>
                      <li>
                        <a href="#">Contact</a>
                      </li>
                      <li>
                        <a href="#">Profile</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                {/* <div className="col-lg-3 col-sm-6">
                  <div className="footer-link">
                    <h6 className="footer-title">Solutions</h6>
                    <ul>
                      <li>
                        <a href="#">Facilities Services</a>
                      </li>
                      <li>
                        <a href="#">Workplace Staffing</a>
                      </li>
                      <li>
                        <a href="#">Project Management</a>
                      </li>
                    </ul>
                  </div>
                
                </div> */}
                <div className="col-lg-3 col-sm-6">
                  {/* <div className="footer-link">
                    <h6 className="footer-title">Product & Services</h6>
                    <ul>
                      <li>
                        <a href="#">Products</a>
                      </li>
                      <li>
                        <a href="#">Business</a>
                      </li>
                      <li>
                        <a href="#">Developer</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="col-lg-12 col-sm-12">
                  <div className="footer-link">
                    <h6 className="footer-title">Help & Suuport</h6>
                    <ul>
                      <li>
                        <Link
                          // href="contact-us"
                          className="page-scroll"
                          onClick={handleScrollToSupportCenter}
                        >
                          Support Center
                        </Link>
                      </li>
                      {/* <li>
                        <a href="#">FAQ</a>
                      </li> */}
                      <li>
                        <a onClick={() => handleNavigate("/privacy-policy")}>
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a
                          // href="https://firebasestorage.googleapis.com/v0/b/hureox-4989b.appspot.com/o/links%2FTerms%20and%20conditions.pdf?alt=media&token=02822070-45e2-469c-8cf4-8300804a185b"
                          // target="_blank"
                          // rel="noreferrer"
                          onClick={() => handleNavigate("/terms-and-condition")}
                        >
                          Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          // href="https://firebasestorage.googleapis.com/v0/b/hureox-4989b.appspot.com/o/links%2FTerms%20and%20conditions.pdf?alt=media&token=02822070-45e2-469c-8cf4-8300804a185b"
                          // target="_blank"
                          // rel="noreferrer"
                          onClick={() => handleNavigate("/documentation")}
                        >
                          Documentation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-copyright">
            <div className="container">
              <div className="row align-items-center">
                {/* <div className="col-lg-5">
                  <div className="copyright text-center text-lg-left mt-10">
                    <p className="text">
                      Crafted by{" "}
                      <a
                        rel="nofollow"
                        href="https://uideck.con"
                      >
                        UIdeck
                      </a>{" "}
                      and UI Elements from{" "}
                      <a
                        rel="nofollow"
                        href="https://ayroui.com"
                      >
                        Ayro UI
                      </a>
                    </p>
                  </div>
                
                </div>
                <div className="col-lg-2">
                  <div className="footer-logo text-center mt-10">
                    <a href="index.html">
                      <img src="assets/images/logo-2.svg" alt="Logo" />
                    </a>
                  </div>
                 
                </div> */}
                {/* <div className="col-lg-5">
                  <ul className="social text-center text-lg-right mt-10">
                    <li>
                      <a href="https://facebook.com/uideckHQ">
                        <i className="lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/uideckHQ">
                        <i className="lni-twitter-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/uideckHQ">
                        <i className="lni-instagram-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-linkedin-original"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </footer>

        {/* <!--====== FOOTER FOUR PART ENDS ======--> */}

        {/* <!--====== BACK TOP TOP PART START ======--> */}

        <a href="#" className="back-to-top">
          <i className="lni-chevron-up"></i>
        </a>

        {/* <!--====== BACK TOP TOP PART ENDS ======-->   */}

        {/* <!--====== PART START ======--> */}

        {/* <!--====== PART ENDS ======--> */}

        {/* <!--====== jquery js ======--> */}
        <script src="assets/js/vendor/modernizr-3.6.0.min.js"></script>
        <script src="assets/js/vendor/jquery-1.12.4.min.js"></script>

        {/* <!--====== Bootstrap js ======--> */}
        <script src="assets/js/bootstrap.min.js"></script>
        <script src="assets/js/popper.min.js"></script>

        {/* <!--====== Slick js ======--> */}
        <script src="assets/js/slick.min.js"></script>

        {/* <!--====== Isotope js ======--> */}
        <script src="assets/js/isotope.pkgd.min.js"></script>

        {/* <!--====== Images Loaded js ======--> */}
        <script src="assets/js/imagesloaded.pkgd.min.js"></script>

        {/* <!--====== Magnific Popup js ======--> */}
        <script src="assets/js/jquery.magnific-popup.min.js"></script>

        {/* <!--====== Scrolling js ======--> */}
        <script src="assets/js/scrolling-nav.js"></script>
        <script src="assets/js/jquery.easing.min.js"></script>

        {/* <!--====== Ajax Contact js ======--> */}
        <script src="assets/js/ajax-contact.js"></script>

        {/* <!--====== wow js ======--> */}
        <script src="assets/js/wow.min.js"></script>

        {/* <!--====== Main js ======--> */}
        <script src="assets/js/main.js"></script>
      </body>
    </>
  )
}

export default LandingPageHTML
