import React, { useContext, useState } from "react"
import {
  Row,
  Col,
  Card,
  Input,
  Label,
  TabPane,
  Form,
  FormGroup,
  TabContent,
  NavLink,
  NavItem,
  CardBody,
  Spinner,
} from "reactstrap"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import "../StudyForm"
import { displayNotification, generateVariables } from "../../utils/utility"
import { Link } from "react-router-dom"
import Redirector from "helpers/Redirector"
import classnames from "classnames"
import { Checkbox, Radio } from "antd"
import {
  STUDY_TYPE_FEATURE,
  AWARENESS_OPTIONS,
  DURATION_OF_STUDY,
  PARTICIPANT_COUNT,
  PRODUCT_DOMAIN,
  PRODUCT_KIND,
  PRODUCT_TYPE,
  USER_METRICS,
} from "constants/studyForm"
import { UserDataContext } from "../../routes/route"
import FirebaseService from "services/firebaseServices"
import classNames from "classnames"

const FeatureForm = () => {
  document.title = "Feature Form"
  const [studyId, setStudyId] = useState()
  const [params, setParams] = useState(false)
  const [loader, setLoader] = useState(false)
  const usersData = useContext(UserDataContext)
  const [apiCalled, setApiCalled] = useState(false)
  const [redirectPath, setRedirectPath] = useState()
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [showProductDomainQuestion, setShowProductDomainQuestion] =
    useState(false)
  const [otherCategoryValue, setOtherCategoryValue] = useState("")
  const [showOtherInput, setShowOtherInput] = useState(false)
  const [answer, setAnswer] = useState({
    studyType: "Feature Testing",
    studyName: "",
    productName: "",
    productLink: "",
    metrics: [],
    studyDuration: 20,
    feature1: "",
    feature2: "",
    feature3: "",
    productType: [],
    productCategory: "",
    productDomain: "",
    participantProductAwareness: [],
    participantCount: 6,
  })
  console.log("answer --->", answer)

  const selectFormState = (state) => state.studyForm
  const selectStudyFormProperties = createSelector(
    selectFormState,
    (formData) => ({
      currentForm: formData.form,
      currentQue: formData.currentQuestion,
      currentSec: formData.currentSection,
      isSubmittable: formData.isSubmittable,
      studyType: formData.studyType,
    }),
  )
  const { studyType } = useSelector(selectStudyFormProperties)

  const validateFirstSection = () => {
    return answer.studyName.length > 0 && answer.studyDuration
  }
  const validateSecondSection = () => {
    if (answer.productType === "B2C" && showProductDomainQuestion) {
      // If product type is B2C and product domain question is shown
      // Check if product domain is provided
      return answer.productDomain !== "" && answer.productDomain !== "Other"
    } else {
      // Otherwise, perform regular validation
      return (
        answer.productType.length > 0 &&
        answer.productCategory.length > 0 &&
        answer.feature1.length > 0
      )
    }
  }
  const validateThirdSection = () => {
    return (
      answer.participantProductAwareness.length > 0 && answer.participantCount
    )
  }

  const handleSubmit = async () => {
    try {
      let fieldValues = answer
      if (!fieldValues.studyType) {
        fieldValues = { ...fieldValues, studyType: studyType }
      }
      setLoader(true)
      const user = usersData
      const payload = {
        userId: user.uid,
        form: [],
        userName: user.displayName ?? "",
        companyName: user.companyName ?? "",
        templateVariables: generateVariables(answer),
        fieldValues: fieldValues,
      }
      const response = await FirebaseService.createStudy(payload)
      const { success, message, data } = response
      if (success) {
        const studyId = data.formId
        displayNotification("success", message ?? "Study created successfully")
        setStudyId(studyId)
        setRedirectPath(`/study/${studyId}/script`)
        setParams({ ...params, state: { docId: studyId } })
        setLoader(false)
        setApiCalled(true)
      } else {
        displayNotification("error", message ?? "Failed to create study")
        setRedirectPath(`/dashboard`)
        setApiCalled(true)
      }
    } catch (error) {
      displayNotification("error", "Something went wrong")
      setRedirectPath(`/dashboard`)
      setApiCalled(true)
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  return (
    <>
      <Row>
        <div className="page-content">
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4"></h4>
                <div className="vertical-wizard wizard clearfix vertical">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classNames({
                          current: activeTabVartical === 1,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTabVartical === 1,
                          })}
                          onClick={() => {
                            toggleTabVertical(1)
                          }}
                          disabled={!(passedStepsVertical || []).includes(1)}
                        >
                          <span className="number">1</span> Study Information
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 2,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTabVartical === 2,
                          })}
                          onClick={() => {
                            toggleTabVertical(2)
                          }}
                          disabled={!(passedStepsVertical || []).includes(2)}
                        >
                          <span className="number">2</span>{" "}
                          <span>About Product</span>
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTabVartical === 3,
                        })}
                      >
                        <NavLink
                          className={
                            (classnames({
                              active: activeTabVartical === 3,
                            }),
                            "done")
                          }
                          onClick={() => {
                            toggleTabVertical(3)
                          }}
                          disabled={!(passedStepsVertical || []).includes(3)}
                        >
                          <span className="number">3</span> Participant Profile
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <Form>
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Row>
                            <Col lg="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-firstname-input12">
                                  Study Type{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>
                                <select
                                  defaultValue={
                                    "Usability Testing - Feature testing"
                                  }
                                  className="form-select"
                                  id="basicpill-firstname-input12"
                                  onChange={(e) =>
                                    setAnswer({
                                      ...answer,
                                      studyType: e.target.value,
                                    })
                                  }
                                >
                                  {STUDY_TYPE_FEATURE.map((opt) => {
                                    return (
                                      <>
                                        <option value={opt?.value ?? "NA"}>
                                          {opt?.label ?? "na"}
                                        </option>
                                      </>
                                    )
                                  })}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="8">
                              <FormGroup className="mb-3">
                                {/* <div className="position-relative"> */}
                                <Label htmlFor="basicpill-firstname-input12">
                                  Study Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-firstname-input12"
                                  placeholder="Enter study name"
                                  onChange={(e) =>
                                    setAnswer({
                                      ...answer,
                                      studyName: e.target.value,
                                    })
                                  }
                                  // valid={false}
                                  // invalid={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-lastname-input22">
                                  Product Name{" "}
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-lastname-input22"
                                  placeholder="Enter Product Name"
                                  onChange={(e) =>
                                    setAnswer({
                                      ...answer,
                                      productName: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-phoneno-input32">
                                  Product Link
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-phoneno-input32"
                                  placeholder="Enter Product Link"
                                  onChange={(e) =>
                                    setAnswer({
                                      ...answer,
                                      productLink: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-phoneno-input32">
                                  User Metrics{" "}
                                </Label>
                                <div className="checkboxes row mb-2 p-2">
                                  {USER_METRICS.map((option) => {
                                    return (
                                      <>
                                        <div className="col-md-6 mb-2">
                                          <div>
                                            <Checkbox
                                              className="checkbox-option mb-2"
                                              style={{ fontWeight: "400" }}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setAnswer((answer) => ({
                                                    ...answer,
                                                    metrics: [
                                                      ...answer.metrics,
                                                      option.value,
                                                    ],
                                                  }))
                                                } else {
                                                  setAnswer((answer) => ({
                                                    ...answer,
                                                    metrics:
                                                      answer.metrics.filter(
                                                        (metric) =>
                                                          metric !==
                                                          option.value,
                                                      ),
                                                  }))
                                                }
                                              }}
                                            >
                                              {option.label}
                                            </Checkbox>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col lg="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="basicpill-firstname-input12">
                                  Duration Of Study{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>
                                <select
                                  className="form-select"
                                  id="basicpill-firstname-input12"
                                  onChange={(e) =>
                                    setAnswer({
                                      ...answer,
                                      durationOfStudy: e.target.value,
                                    })
                                  }
                                >
                                  {DURATION_OF_STUDY.map((opt) => {
                                    return (
                                      <>
                                        <option value={opt?.value ?? "NA"}>
                                          {opt?.label ?? "na"}
                                        </option>
                                      </>
                                    )
                                  })}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Row>
                              <Col lg="8">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-firstname-input12">
                                    Feature 1{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input12"
                                    placeholder="Add feature"
                                    onChange={(e) =>
                                      setAnswer({
                                        ...answer,
                                        feature1: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="8">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-firstname-input12">
                                    Feature 2
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input12"
                                    placeholder="Add feature"
                                    onChange={(e) =>
                                      setAnswer({
                                        ...answer,
                                        feature2: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="8">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-firstname-input12">
                                    Feature 3
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input12"
                                    placeholder="Add feature"
                                    onChange={(e) =>
                                      setAnswer({
                                        ...answer,
                                        feature3: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="8">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-pancard-input52">
                                    What kind of product it is?{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <br />
                                  <Row>
                                    {PRODUCT_KIND.map((option, index) => {
                                      return (
                                        <>
                                          <Col
                                            md="12"
                                            style={{ lineHeight: 3 }}
                                          >
                                            <Checkbox
                                              className="checkbox-option mb-2"
                                              style={{ fontWeight: "400" }}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  // If "Other" option is checked, show the input box
                                                  if (
                                                    option.value === "other"
                                                  ) {
                                                    setShowOtherInput(true)
                                                  }
                                                  setAnswer((answer) => ({
                                                    ...answer,
                                                    productCategory: [
                                                      ...answer.productCategory,
                                                      option.value,
                                                    ],
                                                  }))
                                                } else {
                                                  // If "Other" option is unchecked, check if it's the "Other" checkbox
                                                  if (
                                                    option.value === "other"
                                                  ) {
                                                    setShowOtherInput(false) // Hide the input box
                                                    setOtherCategoryValue("") // Reset the input field value
                                                  }
                                                  // Update productCategory array for other checkboxes
                                                  setAnswer((answer) => ({
                                                    ...answer,
                                                    productCategory:
                                                      answer.productCategory.filter(
                                                        (category) =>
                                                          category !==
                                                          option.value,
                                                      ),
                                                  }))
                                                }
                                              }}
                                            >
                                              {option.label ?? "NA"}
                                            </Checkbox>
                                          </Col>
                                        </>
                                      )
                                    })}
                                  </Row>
                                </FormGroup>
                              </Col>
                              {showOtherInput && (
                                <Col lg="8">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="other-input">
                                      {/* Place the comment inside braces */}
                                      Add Category
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <br />
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="other-input"
                                      value={otherCategoryValue}
                                      onChange={(e) => {
                                        const newCategoryValue = e.target.value
                                        setOtherCategoryValue(newCategoryValue) // Update the input value state
                                      }}
                                      onBlur={() => {
                                        // Check if the otherCategoryValue is not already present in productCategory
                                        if (
                                          !answer.productCategory.includes(
                                            otherCategoryValue,
                                          )
                                        ) {
                                          // Append the otherCategoryValue to the productCategory array
                                          setAnswer((prevAnswer) => ({
                                            ...prevAnswer,
                                            productCategory: [
                                              ...prevAnswer.productCategory,
                                              otherCategoryValue,
                                            ],
                                          }))
                                        }
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              )}

                              <Col lg="8">
                                {/* Product Type Dropdown */}
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-vatno-input62">
                                    What is the product type?{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <br />
                                  <Row>
                                    {PRODUCT_TYPE.map((option, index) => (
                                      <Col
                                        md="12"
                                        style={{ lineHeight: 3 }}
                                        key={index}
                                      >
                                        <Radio
                                          onChange={(e) => {
                                            const productTypeValue =
                                              e.target.value
                                            setAnswer({
                                              ...answer,
                                              productType: productTypeValue,
                                              // Clear product domain if B2B is selected
                                              productDomain:
                                                productTypeValue === "B2B"
                                                  ? ""
                                                  : answer.productDomain,
                                            })
                                            setShowProductDomainQuestion(
                                              productTypeValue === "B2C" &&
                                                answer.productDomain ===
                                                  "Other",
                                            )
                                          }}
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "normal",
                                            fontFamily: "poppins",
                                          }}
                                          checked={
                                            answer.productType === option.value
                                          }
                                          value={option.value}
                                        >
                                          {option.label}
                                        </Radio>
                                      </Col>
                                    ))}
                                  </Row>
                                </FormGroup>
                              </Col>

                              {/* Product Domain Dropdown */}
                              {answer.productType === "B2C" && (
                                <Col lg="8">
                                  <FormGroup className="mb-3">
                                    <Label htmlFor="basicpill-vatno-input62">
                                      What is the product domain?{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <br />
                                    <select
                                      className="form-select"
                                      id="basicpill-firstname-input12"
                                      onChange={(e) => {
                                        setShowProductDomainQuestion(
                                          e.target.value === "Other",
                                        )
                                        // Set productDomain to empty string if B2C is selected and Other is not chosen
                                        setAnswer({
                                          ...answer,
                                          productDomain:
                                            e.target.value === "Other"
                                              ? answer.productDomain
                                              : e.target.value,
                                        })
                                      }}
                                    >
                                      {PRODUCT_DOMAIN.map((opt, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={opt?.value ?? "NA"}
                                          >
                                            {opt?.label ?? "na"}
                                          </option>
                                        )
                                      })}
                                    </select>
                                  </FormGroup>
                                </Col>
                              )}

                              {/* Other Domain Input */}
                              {answer.productType === "B2C" &&
                                showProductDomainQuestion && (
                                  <Col lg="8">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="other-domain-input">
                                        Add Domain{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <br />
                                      <input
                                        type="text"
                                        className="form-control border-none"
                                        id="other-domain-input"
                                        value={answer.productDomain}
                                        onChange={(e) =>
                                          setAnswer({
                                            ...answer,
                                            productDomain: e.target.value,
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                            </Row>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Row>
                              <Col lg="8">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="basicpill-namecard-input112">
                                    Are participants aware of the product?{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <div className="checkboxes row mb-2 p-2">
                                    {AWARENESS_OPTIONS.map((option) => {
                                      return (
                                        <>
                                          <div className="col-md-6 mb-2">
                                            <div>
                                              <Checkbox
                                                className="checkbox-option mb-2"
                                                style={{ fontWeight: "400" }}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setAnswer((answer) => ({
                                                      ...answer,
                                                      participantProductAwareness:
                                                        [
                                                          ...answer.participantProductAwareness,
                                                          option.value,
                                                        ],
                                                    }))
                                                  } else {
                                                    setAnswer((answer) => ({
                                                      ...answer,
                                                      participantProductAwareness:
                                                        answer.participantProductAwareness.filter(
                                                          (aware) =>
                                                            aware !==
                                                            option.value,
                                                        ),
                                                    }))
                                                  }
                                                }}
                                              >
                                                {option.label}
                                              </Checkbox>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                  </div>
                                </FormGroup>
                              </Col>

                              <Col lg="8">
                                <FormGroup className="mb-3">
                                  <Label>
                                    How many participants are planned to
                                    participate in the study?{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Label>
                                  <select
                                    className="form-select"
                                    id="basicpill-firstname-input12"
                                    onChange={(e) =>
                                      setAnswer({
                                        ...answer,
                                        participantCount: e.target.value,
                                      })
                                    }
                                  >
                                    {PARTICIPANT_COUNT.map((opt) => {
                                      return (
                                        <>
                                          <option value={opt?.value ?? "NA"}>
                                            {opt?.label ?? "na"}
                                          </option>
                                        </>
                                      )
                                    })}
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Form>
                  </div>
                  <div className="actions clearfix">
                    <ul>
                      {activeTabVartical !== 1 && (
                        <li
                          className={
                            activeTabVartical === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                      )}
                      {activeTabVartical === 1 && (
                        <>
                          {!validateFirstSection() && (
                            <li className="next disabled">
                              <Link to="#">Next</Link>
                            </li>
                          )}
                          {validateFirstSection() && (
                            <li>
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTabVertical(activeTabVartical + 1)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          )}
                        </>
                      )}
                      {activeTabVartical === 2 && (
                        <>
                          {!validateSecondSection() && (
                            <li className="next disabled">
                              <Link to="#">Next</Link>
                            </li>
                          )}
                          {validateSecondSection() && (
                            <li>
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTabVertical(activeTabVartical + 1)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          )}
                        </>
                      )}
                      {activeTabVartical === 3 && (
                        <>
                          {!validateThirdSection() && (
                            <li className="next disabled">
                              <Link to="#">Create Study</Link>
                            </li>
                          )}
                          {validateThirdSection() && (
                            <li>
                              <Link
                                to="#"
                                onClick={() => {
                                  handleSubmit()
                                }}
                              >
                                {loader ? (
                                  <>
                                    <Spinner
                                      size="sm"
                                      color="light"
                                      className="mr-2"
                                    />
                                    Loading...
                                  </>
                                ) : (
                                  "Create Study"
                                )}
                              </Link>
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Row>
      {apiCalled && redirectPath && (
        <Redirector path={redirectPath} params={params} replace={true} />
      )}
    </>
  )
}

export default FeatureForm
