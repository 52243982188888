import { SAVE_STUDY_DETAILS } from "./actionTypes"

const INIT_STATE = {
    studyId: "",
    studyDetails: null,
    pastMeetings: [],
    ongoingMeetings: [],
    upcomingMeetings: [],
    isFetching: false 
}

const CurrentStudy = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_STUDY_DETAILS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default CurrentStudy
