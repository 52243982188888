import React from 'react'
import { Button } from 'reactstrap'
import { getFunctions, httpsCallable } from "firebase/functions"

const test = () => {
  const fbDeleteMeeting = async (meetingId) => {
    try {
      const functions = getFunctions()
      const duplicateStudies = httpsCallable(functions, "duplicateStudies")
      const data ={
        formId : "GlWeWZpMRzMY9Fz2tUk0"
      }
      const meetResponse = await duplicateStudies(data)
      console.log('meetResponse: ', meetResponse);
      
    } catch (error) {
      console.log("fbGetMeetings ERROR : ", error.message)
      await fbGetMeetings()
    }
  }

  return (
    <div  className='page-content'>
      <Button onClick={fbDeleteMeeting}>hello</Button>
    </div>
  )
}

export default test