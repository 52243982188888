import humanskull from "../assets/images/humanskull.svg"
import message from "../assets/images/message.svg"
import usability from "../assets/images/usability.svg"
import user from "../assets/images/user.svg"

export const SEND_EMAIL_HIRE_UX_RESEARCHER = "SEND_EMAIL_HIRE_UX_RESEARCHER"
export const HIRE_UX_RESEARCHER = "Hire a UX Researcher"
export const USER_INTERVIEW = "User Interview"
export const UT_ONBOARDING = "Onboarding Testing"
export const UT_FEATURE_TESTING = "Feature Testing"

export const ORANGE = "orange"
export const GREEN = "green"
export const PURPLE = "purple"
export const YELLOW = "yellow"
export const LIGHT_BLUE = "lightblue"
export const OTHER = "other"

export const DASHBOARD_DATA = [
  {
    title: HIRE_UX_RESEARCHER,
    body: "Schedule a 30/60-minute call with a skilled user researcher to plan the study",
    img: humanskull,
    route: null,
  },
  {
    title: USER_INTERVIEW,
    body: "User Satisfaction",
    img: message,
    route: null,
  },
  {
    title: UT_ONBOARDING,
    body: "Usability testing",
    img: usability,
    route: null,
  },
  {
    title: UT_FEATURE_TESTING,
    body: "Usability testing",
    img: user,
    route: null,
  },
]
