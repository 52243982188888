import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Row, Col, Button, Spinner } from "reactstrap"
import BubbleChart from "../../components/Graphs/BubbleChart.js"
import { Link, useNavigate, useParams } from "react-router-dom"
import { CSVLink } from "react-csv"
import { displayNotification, transformDataForExport } from "utils/utility"
import { transformBubbleData } from "utils/utility"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { getFunctions, httpsCallable } from "firebase/functions"
import { collection, getDocs, getFirestore, limit, query, where } from "firebase/firestore"
import { point } from "leaflet"

const GeneratedReport = () => {
  const navigate = useNavigate()
  const [studyDetails, setStudyDetails] = useState({})
  const { studyId, meetingId } = useParams()
  const [updatedtranscript, setUpdatedTranscript] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const selectFormTemplateState = (state) => state.TemplateForm
  const selectFormTemplateProperties = createSelector(
    selectFormTemplateState,
    (tempData) => ({
      studyName: tempData.studyName,
      studyType: tempData.studyType,
    }),
  )
  const AuthState = (state) => state.AuthState
  const AuthStateProps = createSelector(AuthState, (data) => ({
    authUserData: data.authUserData,
  }))
  const { authUserData } = useSelector(AuthStateProps)
  const userId = authUserData.uid
  const getTranscript = async () => {
    try {
      console.log("user transcript: ", userId)
      
      if (!userId) {
        return
      }
      const db = getFirestore()
      const transcriptsCollectionRef = collection(
        db,
        "studies",
        studyId,
        "transcripts",
      )
      const snapShot = query(
        transcriptsCollectionRef,
        where("userId", "==", userId),
        limit(1),
      )
      const querySnap = await getDocs(snapShot)
      console.log("querySnap: ", querySnap.empty)
      if (querySnap.empty) {
        displayNotification("error", "Transcript not found!")
        return
      }
      querySnap.forEach(async (doc) => {
        const transcript = doc.data()?.transcriptJson
        console.log('transcript: ', transcript);
        if (!transcript) {
          displayNotification("error", "Transcript not found!")
          return
        }
        setIsLoading(true)
        setUpdatedTranscript(transcript)
        handleReport()
      })
    } catch (error) {
      console.log("ERROR : ", error)
      displayNotification(
        "error",
        error?.message ?? "Failed to load transcript",
      )
    }
  }

  const handleReport = async() => {
    console.log("first")
    try {
      const ReportURL = "http://ec2-13-201-42-107.ap-south-1.compute.amazonaws.com:5000/process-conversations"
      console.log('ReportURL: ', ReportURL);
      const requestData = updatedtranscript
      console.log('requestData: ', requestData);

        const response = await axios.post(ReportURL, requestData);
        console.log(response.data); 
        setIsLoading(false)
    } catch (error) {
      
    }
  }
  const keySentimentsData = [
    // {
    //   category_level_1: "Unknown",
    //   type: "Issue",
    //   category_level_2: "Swigen Stomach Works",
    //   user: "user1",
    //   category_level_3: false,
    //   transcript_chunk:
    //     "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
    //   no_of_instances: 1,
    // },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Network Failures",
      user: "user1",
      category_level_3: "swiggy instamart",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Network Failures",
      user: "user1",
      category_level_3: "EatSure",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Network Failures",
      user: "user1",
      category_level_3: "McDonald",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Network Failures",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Issue",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "swiggy instamart",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Issue",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Issue",
      category_level_2: "Platform Research",
      user: "user1",
      category_level_3: "Google Pay",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Issue",
      category_level_2: "Platform Research",
      user: "user1",
      category_level_3: "Paypal",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Issue",
      category_level_2: "Platform Research",
      user: "user1",
      category_level_3: "Mobikwik",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "ZOMATO",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "App Crashes",
      user: "user1",
      category_level_3: "TOYOTA",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "App Crashes",
      user: "user1",
      category_level_3: "MAHINDRA",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "App Crashes",
      user: "user1",
      category_level_3: "MARUTI",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Inaccurate Order Fulfillment",
      user: "user1",
      category_level_3: "TVS",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Inaccurate Order Fulfillment",
      user: "user1",
      category_level_3: "BAJAJ",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Accurate Order Fulfillment",
      user: "user1",
      category_level_3: "Honda",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Accurate Order Fulfillment",
      user: "user1",
      category_level_3: "YAMAHA",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Accurate Order Fulfillment",
      user: "user1",
      category_level_3: "SUZUKI",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Time",
      type: "Issue",
      category_level_2: "Order Delays",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Time",
      type: "Issue",
      category_level_2: "Order OnTime",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Time",
      type: "Issue",
      category_level_2: "Order Before Time",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Wide Restaurant Selection",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "Order Tracking Feature",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "User-friendly Interface",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Quick Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Unknown",
      type: "Good Feature",
      category_level_2: "",
      user: "user1",
      category_level_3: "",
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Variety Of Food Options Available",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience Of Ordering Through The App",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Quality",
      type: "Good Feature",
      category_level_2: "Improved Quality Compared To Local Stores",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
  ]
  const keyChallengeData = [
    // {
    //   category_level_1: "Unknown",
    //   type: "Issue",
    //   category_level_2: "Swigen Stomach Works",
    //   user: "user1",
    //   category_level_3: false,
    //   transcript_chunk:
    //     "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
    //   no_of_instances: 1,
    // },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "Paytm",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "swiggy instamart Works",
      user: "user1",
      category_level_3: "swiggy instamart ",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "swiggy instamart Works",
      user: "user1",
      category_level_3: "Zomato ",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "swiggy instamart Works",
      user: "user1",
      category_level_3: "Paytm ",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "swiggy instamart",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "PhonePe",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "PhonePe",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "Mobikwike",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "WhatsApp",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "Cred",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "swiggy instamart",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Success",
      user: "user1",
      category_level_3: "swiggy instamart",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Success",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Success",
      user: "user1",
      category_level_3: "Paytm",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Network Failures",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "App Crashes",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Inaccurate Order Fulfillment",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Time",
      type: "Issue",
      category_level_2: "Order Delays",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Wide Restaurant Selection",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "Order Tracking Feature",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "User-friendly Interface",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Quick Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Slow Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Medium Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    // {
    //   category_level_1: "Unknown",
    //   type: "Good Feature",
    //   category_level_2: "",
    //   user: "user1",
    //   category_level_3: "",
    //   transcript_chunk:
    //     "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
    //   no_of_instances: 1,
    // },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Variety Of Food Options Available",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience for App",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience for Laptop",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience for Mobile",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Quality",
      type: "Good Feature",
      category_level_2: "Improved Quality Compared To Local Stores",
      user: "user1",
      category_level_3: "TATA",
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Quality",
      type: "Good Feature",
      category_level_2: "Improved Quality Compared To Local Stores",
      user: "user1",
      category_level_3: "MARUTI",
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
  ]
  const { studyType, studyName } = useSelector(selectFormTemplateProperties)
  const data = [
    {
      category_level_1: "Unknown",
      type: "Issue",
      category_level_2: "Swigen Stomach Works",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Network Failures",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "App Crashes",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Inaccurate Order Fulfillment",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Time",
      type: "Issue",
      category_level_2: "Order Delays",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Wide Restaurant Selection",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "Order Tracking Feature",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "User-friendly Interface",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Quick Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Unknown",
      type: "Good Feature",
      category_level_2: "",
      user: "user1",
      category_level_3: "",
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Variety Of Food Options Available",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience Of Ordering Through The App",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Quality",
      type: "Good Feature",
      category_level_2: "Improved Quality Compared To Local Stores",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
  ]

  const headers = [
    { label: "Category", key: "Category" },
    { label: "Issue/Feature (1)", key: "Issue/Feature (1)" },
    { label: "Issue/Feature (2)", key: "Issue/Feature (2)" },
    { label: "SUM of No of Instances", key: "SUM of No of Instances" },
  ]

  const data2 = [
    {
      category_level_1: "Unknown",
      type: "Issue",
      category_level_2: "Swigen Stomach Works",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: " stomach",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "App Crashes",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Inaccurate Order Fulfillment",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Time",
      type: "Issue",
      category_level_2: "Order Delays",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Wide Restaurant Selection",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "Order Tracking Feature",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "User-friendly Interface",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Quick Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Unknown",
      type: "Good Feature",
      category_level_2: "",
      user: "user1",
      category_level_3: "",
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Variety Of Food Options Available",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience Of Ordering Through The App",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Quality",
      type: "Good Feature",
      category_level_2: "Improved Quality Compared To Local Stores",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
  ]
  const data1 = transformDataForExport(data2)
  // const data1 = [
  //   {
  //     Category: "node.category_level_1",
  //     "Issue/Feature (1)": 'node.category_level_2 ? node.category_level_2 : ""',
  //     "Issue/Feature (2)": 'node.category_level_3 ? node.category_level_3 : ""',
  //     "SUM of No of Instances": "node.no_of_instances",
  //   },
  // ]
  // console.log("transformDataForExport=========================", data1)

  const handleNavigate = () => {
    const currentURL = window.location.pathname
    navigate(currentURL + "/keychallenges")
  }
  const handleNavigateSentiments = () => {
    const currentURL = window.location.pathname
    navigate(currentURL + "/keysentiments")
  }
  const handleNavigateBack = () => {
    navigate(-1)
  }
  const fetchStudyDetails = async () => {
    setIsLoading(true)
    try {
      const functions = getFunctions()
      const getStudyDetails = httpsCallable(functions, "getStudyDetails")
      const { data } = await getStudyDetails({ formId: studyId })

      if (!data) {
        return
      }
      if (!data.success) {
        return
      }
      setStudyDetails(data.data)

      // setIsLoading(false)
    } catch (error) {
      // displayNotification("error", error.message ?? "Failed to fetch study details")
      // setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchStudyDetails()
    getTranscript()
    
  }, [])

  const transformedData = transformBubbleData(keyChallengeData, "Good Feature")
  const transformedDataSentiments = transformBubbleData(
    keySentimentsData,
    "Issue",
  )

  return (
    <div className="page-content">
   {isLoading ? ( // If isLoading is true, show the loading spinner
   <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>

     <Spinner color="primary" />
   </div>
  ) : (
    <>
      <Row>
        <Col>
          <Link
            onClick={handleNavigateBack}
            className="mb-1 d-sm-flex align-items-center justify-content-between "
          >
            <h4 className="mb-sm-0 text-black p-2 font-size-18">
              {studyDetails?.studyName ?? ""} : {studyDetails?.studyType ?? ""}
            </h4>
          </Link>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            color="primary"
            outline
            className=" d-flex justify-content-end"
          >
            <CSVLink data={data1} headers={headers}>
              <h5>Export Insights ↓</h5>
            </CSVLink>
          </Button>
        </Col>
      </Row>
      <hr style={{ height: "1px", backgroundColor: "#E0E0E0" }}></hr>

      <div>
        <h1
          className="mb-5 mt-3"
          style={{
            fontSize: "26px",
            fontWeight: "500",
            lineHeight: "38px",
            textAlign: "left",
            color: "#173261",
          }}
        >
          Insights
        </h1>
      </div>

      <Row className="d-flex justify-content-center">
        <Col xl="4" lg="4" md="4" sm="4">
          <Card
            style={{
              // width: "450px",
              height: "50vh",
              borderRadius: "8px",
              backgroundColor: "#e2e6ef",
              cursor: "pointer",
              // marginLeft: "-20px",
              // backgroundColor: "lightblue",
            }}
            onClick={handleNavigate}
          >
            <CardTitle
              className="pl-2 d-flex align-items-center "
              style={{
                backgroundColor: "#e2e6ef",
                height: "50px",
                borderRadius: "8px",
              }}
            >
              <p className="mt-4 text-muted " style={{ fontSize: "18px" }}>
                Key Challenges
              </p>
            </CardTitle>
            <CardBody
              className="d-flex justify-content-center mb-1"
              style={{
                borderRadius: "6px",
                // width: "445px",
                backgroundColor: "white",
                marginLeft: "5px",
                marginBottom: "0px",
                height: "70px",
                padding: "5px",
                marginRight: "5px",
              }}
            >
              <span
                className="d-flex justify-content-center "
                style={{ pointerEvents: "none" }}
              >
                {/* <img
                      src={bubble}
                      style={{height:"150px"}}
                      /> */}
                <BubbleChart
                  data={transformedData}
                  switchColor={true}
                  fontSize={18}
                  switchborder={false}
                />
              </span>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="4" md="4" sm="4">
          <Card
            style={{
              // width: "450px",
              height: "50vh",
              borderRadius: "8px",
              padding: "opx",
              backgroundColor: "#e2e6ef",
              cursor: "pointer",
              // marginLeft: "-20px",
              // backgroundColor: "lightblue",
            }}
            onClick={handleNavigateSentiments}
          >
            <CardTitle
              className="pl-2 d-flex align-items-center"
              style={{
                backgroundColor: "#e2e6ef",
                height: "50px",
                borderRadius: "8px",
              }}
            >
              <p className="mt-4 text-muted   " style={{ fontSize: "16px" }}>
                Key Sentiments
              </p>
            </CardTitle>
            <CardBody
              className="d-flex justify-content-center mb-1"
              style={{
                borderRadius: "6px",
                // width: "445px",
                backgroundColor: "white",
                marginLeft: "5px",
                marginBottom: "0px",
                height: "70px",
                padding: "5px",
                marginRight: "5px",
              }}
            >
              <span
                className="d-flex justify-content-center"
                style={{ pointerEvents: "none" }}
              >
                {/* <img
      src={bubble}
      style={{height:"150px"}}
      /> */}
                <BubbleChart
                  data={transformedDataSentiments}
                  switchColor={false}
                  fontSize={18}
                  switchborder={false}
                />
              </span>
            </CardBody>
          </Card>
        </Col>
        {/* <Col xl="4" sm="6">
          <Card
            style={{ height: "415px", width: "435px" }}
            
          >
            <CardBody>
              <div>
                <div>
                  <div className="d-flex justify-content-center ">
                    <img
                      src={nodes}
                      style={{ height: "200px", width: "400px" }}
                    />
                  </div>
                </div>
              
              </div>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
      <Row className="d-flex justify-content-center ">
        <Col xl="4  " lg="4" md="4" sm="4">
          <div>
            <div className="mt-4 ">
              <Card
                style={{
                  // width: "450px",
                  height: "47vh",
                  borderRadius: "8px",
                  backgroundColor: "#e2e6ef",
                  // marginLeft: "-20px",
                  // backgroundColor: "lightblue",
                }}
              >
                <CardTitle
                  className="pl-2 d-flex align-items-center"
                  style={{
                    backgroundColor: "#e2e6ef",
                    height: "50px",
                    borderRadius: "8px",
                  }}
                >
                  <p className="mt-4 text-muted " style={{ fontSize: "16px" }}>
                    Why users were using the product?
                  </p>
                </CardTitle>
                <CardBody
                  className="d-flex justify-content-center "
                  style={{
                    borderRadius: "6px",
                    // width: "445px",
                    backgroundColor: "white",
                    marginLeft: "2px",
                    marginBottom: "5px",
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                >
                  <div>
                    <p className="font-size-16">
                      <strong>Convenience:</strong> The app make it easy to
                      order food, groceries, or other items from the comfort of
                      their homes, reducing the need to travel or wait in lines.
                    </p>
                    <p className="font-size-16">
                      <strong>Choice:</strong> Users appreciate having a variety
                      of options to choose from, whether it's different cuisines
                      from restaurants or a wide range of products from stores.
                    </p>
                    <p className="font-size-16">
                      <strong>Speed:</strong> Fast and efficient delivery. Users
                      like to receive their orders quickly, and they appreciate
                      accurate delivery time estimates.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>

        <Col xl="4" lg="4" md="4" sm="4">
          <div>
            <div className="mt-4 ">
              <Card
                style={{
                  // width: "450px",
                  height: "47vh",
                  borderRadius: "8px",
                  backgroundColor: "#e2e6ef",
                  // marginLeft: "-20px",
                  // backgroundColor: "lightblue",
                }}
              >
                <CardTitle
                  className="pl-2 d-flex align-items-center"
                  style={{
                    backgroundColor: "#e2e6ef",
                    height: "50px",
                    borderRadius: "8px",
                  }}
                >
                  <p className="mt-4 text-muted " style={{ fontSize: "16px" }}>
                    Why user uninstall the product?
                  </p>
                </CardTitle>
                <CardBody
                  className="d-flex justify-content-center "
                  style={{
                    borderRadius: "6px",
                    // width: "445px",
                    marginRight: "5px",
                    marginLeft: "5px",
                    backgroundColor: "white",
                  
                    marginBottom: "5px",
                  }}
                >
                  <div>
                    <p className="font-size-16">
                      <strong>Poor User Experience:</strong> the app is
                      difficult to navigate, slow, or frequently crashes, users
                      get frustrated and uninstall it.
                    </p>
                    <p className="font-size-16">
                      <strong>High Fees:</strong> Users were put off by high
                      delivery fees, service charges, or inflated menu prices,
                      and if they found better deals elsewhere. ...
                    </p>
                    <p className="font-size-16">
                      <strong>Speed:</strong> Fast and efficient delivery. Users
                      like to receive their orders quickly
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>

        </>
  )}
    </div>
  )
}

export default GeneratedReport
