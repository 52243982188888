import React, { useEffect } from "react"
import "./documentation.scss"
import { Card, CardBody, Col, Row } from "reactstrap"

function Documentation() {
  return (
    <Card style={{ marginTop: "80px" }}>
      <Row>
        <Col className="col-lg-2">
          <div
            style={{
              // flex: " 0 0 200px",
              backgroundColor: "transparent",
              padding: "20px",
              // height: "100% !important",
              // width: "250px",
              display: "flow-root !important",
              position: "fixed",
              top: "4 !important",
              zIndex: "10 !important",
            }}
          >
            <div className="d-none d-xl-block">
              <div className="right-side-nav">
                <ul className="nav nav-pills flex-column">
                  <li className="nav-item">
                    <a href="#Guides" className="nav-link">
                      Guides
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#Using the app" className="nav-link">
                      Using the app
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#Removing the app" className="nav-link">
                      Removing the app
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col className="col-lg-10">
          <Card className="card">
            <CardBody className="card-body">
              <div className="d-xl-flex">
                <div className="w-100">
                  <div className="p-lg-1">
                    <div id="intro" className="mb-5">
                      <h4 className="mt-0 mb-4">Guides</h4>
                      <p
                        className="alert alert-warning"
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        This documentation aims to provide comprehensive
                        information about Hureo.AI.
                      </p>
                      <p
                        className="alert alert-success"
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        This user guide is designed to get you create and
                        interact in your study using Zoom meeting sdk quickly
                        and easily. Please note your Zoom Admin or IT personnel
                        will have to enable Marketplace Apps and approve the
                        Hureo.AI App for your organization before you can
                        install it. Once installed there are four main areas on
                        the dashboard. We'll go through the install process
                        first then discuss the dashboard and its options.
                      </p>
                      <h4 className="mt-0 mb-4">Adding the app</h4>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        1. Firstly, user need to visit the url of our website{" "}
                        <strong>
                          <a
                            href="https://hureo.ai"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            hureo.ai
                          </a>
                        </strong>
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%202024-02-22%20123939.png?alt=media&token=57259d4f-5c1a-401e-88f8-7f187681d10c"
                        alt="Landing Page"
                        className="image"
                      />
                      <br></br>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        2. Then user need to register or login to the app and
                        the user will be redirected to the dashboard of the app.
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(139).png?alt=media&token=bc314e59-18d7-402f-8dd6-550d7abbb45e"
                        alt="Login Page"
                        className="image"
                      />
                      <br></br>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        3. After logging in, user will be redirected to the
                        dashboard of our website, where user can create a study
                        and can connect to their Zoom account as well and will
                        recevie the notification for successful Zoom
                        authorization and when clicked on the "Back to
                        Dashboard" button will be redirected to the dashboard.
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%202024-03-20%20115708.png?alt=media&token=9798f411-239c-40cd-b4ea-445c381629d9"
                        alt="Dashboard Page"
                        className="image"
                      />
                      <br />
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(154).png?alt=media&token=887bc9f8-c7da-4f52-8fcf-484c7e9ae49c"
                        alt="Zoom authorization"
                        className="image"
                      />
                      <br />
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(183).png?alt=media&token=2da6943a-8145-49cd-b15c-6e09a7f0632d"
                        alt="Zoom authorization"
                        className="image"
                      />
                      <br></br>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        4. Users can create a study by filling the form,
                        determining which type of study which they want to
                        conduct.
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(143).png?alt=media&token=cf2827c6-90e6-4e0f-b3bc-a69d0a12717e"
                        alt="Study Form 1"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(144).png?alt=media&token=b1844d2e-6d31-4929-b90d-fc877a83e180"
                        alt="Study Form 2"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(145).png?alt=media&token=e0089098-00d6-4451-aa93-6b54de4c9ad2"
                        alt="Study Form 3"
                        className="image"
                      />
                      <br></br>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        5. After clicking on the " Create Study " button users
                        will be navigated to the template page which consist of
                        6/7 sections conforming to the study type selected in
                        the form, where they can edit the template according to
                        their requirements and save it as well. After saving the
                        user will land on the dashboard page,where they can view
                        their created studies.
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(146).png?alt=media&token=8873a8ef-d567-4bf5-8391-a39c3b0ccb77"
                        alt="Template Page"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(155).png?alt=media&token=90c3c072-cba8-4ee4-975e-96149e2fdae9"
                        alt="Template Page"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(181).png?alt=media&token=3b0ce4f8-97aa-44b2-b093-a7e10db9e169"
                        alt="Template Page"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(182).png?alt=media&token=e1854049-d45f-4120-a8cb-36dcd47998e9"
                        alt="Template Page"
                        className="image"
                      />
                      <br></br>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        6. When the users click on the studies which have been
                        created, study overview page will open up which will
                        consist the information regarding the study, the
                        template which the user can revisit and edit if it's
                        required and can see a button called "Connect to Zoom
                        account".
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(156).png?alt=media&token=5e939c2c-1fc8-481f-85ca-7e512f674f71"
                        alt="Study Overview"
                        className="image"
                      />

                      <br></br>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        7. Once the user clicks on the "Connect to your Zoom
                        account" button then it will open the Zoom authorization
                        window, on users approval their Zoom account will add
                        our "Hureo.AI" app into users Zoom account and will
                        recevie the notification for successful Zoom
                        authorization and when clicked on the "Back to
                        Dashboard" button will be redirected to the dashboard.
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(153).png?alt=media&token=198df242-45f7-4919-bd81-a1fffc35c4d9"
                        alt="Connect Zoom"
                        className="image"
                      />
                      <br />
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(154).png?alt=media&token=887bc9f8-c7da-4f52-8fcf-484c7e9ae49c"
                        alt="Zoom authorization"
                        className="image"
                      />
                      <br />
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(183).png?alt=media&token=2da6943a-8145-49cd-b15c-6e09a7f0632d"
                        alt="Zoom authorization"
                        className="image"
                      />
                      <br></br>
                    </div>
                    <div className="mb-5" id="how-to-use">
                      <h4 className="mt-0 mb-4">Using the app</h4>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        1. So now our app is added in the users Zoom account,
                        they can schedule meetings using Zoom using our
                        app.While the meeting is going on, user can view the
                        template in the form of chatbot script for asking
                        questions,can answer the questions, take notes &
                        interacting with participants.
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(147).png?alt=media&token=22e25ec2-d06d-4ad7-b598-2134d5cc7ee4"
                        alt="Join Meeting"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(148).png?alt=media&token=39a4a651-8cec-4f8e-805d-badd3fedfbf4"
                        alt="Waiting room"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(149).png?alt=media&token=794567d6-7943-4282-8a0c-fd176c9ac3ea"
                        alt="Meeting page"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(184)%20(1).png?alt=media&token=d84033f6-53bd-4988-9416-3a710fde50c6"
                        alt="Meeting page"
                        className="image"
                      />
                      <br></br>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        2. After the meeting session is finished,the user can
                        view the recordings and transcripts in the past meetings
                        section on the study overview page.Also the user can
                        edit the transcript with the recording playing in sync.
                      </p>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(150).png?alt=media&token=91f26ad7-98c5-40cb-96d7-f91c35f47d9b"
                        alt="Past meeting page"
                        className="image"
                      />
                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(1).png?alt=media&token=cd558b24-5b0d-4ebd-b6bd-aa2ec7706a7b"
                        alt="Recordings page"
                        className="image"
                      />

                      <br></br>
                    </div>
                    <div className="mb-5" id="dependencies">
                      <h4 className="mt-0 mb-4">Removing the app</h4>
                      <p
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                      >
                        1. In the profile page of the website, there's an option
                        to disconnect/deauthorize the Zoom app. When the user
                        clicks on the disconnect Zoom button, their Zoom account
                        credentials will be erased from the database and the app
                        will be removed from their Zoom account as well.After
                        deauthorizing the Zoom account, user cannot access the
                        Zoom functionalities.For accessing them the user needs
                        to authorize their Zoom account again.
                      </p>

                      <br></br>
                      <img
                        style={{ maxWidth: "1100px", margin: "25px 0 25px 0" }}
                        src="https://firebasestorage.googleapis.com/v0/b/react-native-5e04d.appspot.com/o/Screenshot%20(21)%20(1)%20(1).png?alt=media&token=f9c1ccd3-043c-4e3f-bb39-cf0020a3f9d8"
                        alt="Profile Page"
                        className="image"
                      />

                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Card>
  )
}

export default Documentation
