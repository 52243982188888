import React, { createRef, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Input, FormFeedback } from "reactstrap"
import { debounce, isEqual, update } from "lodash"
import { LoadingOutlined } from "@ant-design/icons"
import $ from "jquery"
import "./form.scss"
import { saveStudyDetails } from "store/study/action"
import { displayNotification } from "utils/utility"
window.jQuery = $
window.$ = $
require("jquery-ui-sortable")
require("formBuilder")

const FormBuilder = ({
  section,
  secIdx,
  script,
  name,
  title,
  setFormData,
  setIsEdited,
  handleSaving,
  updateStepsFormData,
  setcurrentTitle,
  studyId,
  scriptId,
  autoSaveTimer,
  APICall,
  SavingState,
  isSaving,
  studyDetails,
}) => {
  const fb = createRef()
  const dispatch = useDispatch()
  const [formBuilder, setFormBuilder] = useState(null)
  const AUTOSAVE_TIME = process.env.REACT_APP_AUTOSAVE_TIME_IN_MS
  const AUTOSAVE_TIME_COUNT = Number(AUTOSAVE_TIME)

  const handleEdit = () => {
    console.log("handle edit triggered")
    // setIsEdited(true)
  }

  const AutoSaveDisplay = ({ saving }) => {
    let display
    switch (saving) {
      case SavingState.SAVING:
        display = (
          <span
            className="d-flex justify-content-end"
            style={{ color: "#ff0000" }}
          >
            {" "}
            <span>
              <LoadingOutlined className="mr-1" />
            </span>{" "}
            Saving...
          </span>
        )
        break
      case SavingState.NOT_SAVED:
        display = (
          <span
            className="d-flex justify-content-end"
            style={{ color: "#ff0000" }}
          >
            Not Saved!
          </span>
        )
        break
      case SavingState.SAVED:
        display = (
          <span
            className="d-flex justify-content-end"
            style={{ color: "#26dc15" }}
          >
            Saved!
          </span>
        )
        break
      default:
        display = <span className="d-flex justify-content-end">None</span>
        break
    }
    return <>{display}</>
  }

  useEffect(() => {
    // console.log("UE 1 : title")
    setcurrentTitle(title)
  }, [title])

  useEffect(() => {
    // console.log("UE 2")
    if (!formBuilder?.formData) {
      setFormBuilder(
        $(fb.current).formBuilder({
          disabledActionButtons: ["data", "clear", "save"],
          editOnAdd: true,
          controlOrder: [
            "text",
            "textarea",
            "paragraph",
            "checkbox-group",
            "radio-group",
            "select",
          ],
          formData: section ?? [],
          disabledAttrs: [
            "value",
            "placeholder",
            "access",
            "className",
            "name",
            "toggle",
            "description",
            "inline",
            "other",
            "rows",
            "max",
            "subtype",
            "maxlength",
          ],
          disableFields: [
            "autocomplete",
            "datefield",
            "fileupload",
            "button",
            "date",
            "file",
            "header",
            "hidden",
            "number",
          ],
          // onOpenFieldEdit: handleEdit,
        }),
      )
    }
  }, [])

  const updateData = (newScript) => {
    // console.log("1. creating new study details")
    const newStudyDetails = { ...studyDetails, templateForm: newScript }
    const areTheyEqual = isEqual(studyDetails, newStudyDetails)
    // console.log("2. check if prev study and cur study are equal", areTheyEqual)
    if (!areTheyEqual) {
      // console.log("3. dispatching action")
      dispatch(saveStudyDetails({ studyDetails: newStudyDetails }))
      if (autoSaveTimer.current) {
        clearTimeout(autoSaveTimer.current)
      }
      handleSaving(SavingState.SAVING)

      autoSaveTimer.current = setTimeout(() => {
        APICall(newScript)
        clearTimeout(autoSaveTimer.current)
      }, AUTOSAVE_TIME_COUNT)
    }
  }

  useEffect(() => {
    let curData
    let isSameAsPrev = true
    if (formBuilder) {
      try {
        try {
          curData = JSON.parse(formBuilder?.actions?.getData("json"))
        } catch (error) {
          console.log('FORMBUILDER ERROR when trying to parse the JSON initially: ', error);
          return
        }
        isSameAsPrev = isEqual(curData, section)
        if (!isSameAsPrev && curData) {
          const newScript = [...script]
          newScript[secIdx] = {
            name: name ?? "NA",
            title: title ?? "NA",
            root: curData ?? [],
          }
          updateData(newScript)
        }
      } catch (error) {
        displayNotification("error", "Failed to save changes")
        console.log("Error when form builder data is changed : ", error)
      }
    }
  }, [formBuilder?.formData])

  const handleTitleChange = (e) => {
    let curData
    const newTitle = e.target.value

    if (formBuilder) {
      try {
        curData = JSON.parse(formBuilder?.actions?.getData("json"))
        if (curData) {
          const newScript = [...script]
          const updatedSection = {
            title: newTitle ?? "NA",
            name: newTitle ?? "NA",
            root: curData ?? [],
          }
          newScript[secIdx] = updatedSection
          if (newTitle !== "") {
            updateData(newScript)
          } else {
            const newStudyDetails = { ...studyDetails, templateForm: newScript }
            const areTheyEqual = isEqual(studyDetails, newStudyDetails)
            if (!areTheyEqual) {
              dispatch(saveStudyDetails({ studyDetails: newStudyDetails }))
              setcurrentTitle(newTitle)
            }
          }
        }
      } catch (error) {
        displayNotification("error", "Failed to save changes")
        console.log("Error when form builder section title is changed : ", error)
      }
    }

  }

  return (
    <>
      <div className="section-spacing form-builder">
        <div className="form-builder-autosave-notification mb-3">
          <AutoSaveDisplay saving={isSaving} />
        </div>
        <Input
          type="text"
          value={title ?? ""}
          onChange={handleTitleChange}
          className={`form-builder-Input ${
            title && title.length === 0 ? "empty-title" : ""
          }`}
          invalid={title && title.length === 0 ? true : false}
        />
        {title && title.length === 0 && (
          <FormFeedback type="invalid">{"Please enter the title"}</FormFeedback>
        )}
        <div className="cb-wrap pull-right" ref={fb} />
        <div>
          <div className="btn-toolbar"></div>
        </div>
      </div>
    </>
  )
}

export default FormBuilder
