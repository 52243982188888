import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import BubbleChart from "../../components/Graphs/BubbleChart.js";
import { transformBubbleData } from "utils/utility"
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const KeyChallenges = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [studyDetails, setStudyDetails] = useState({})

  // const CurrentStudyState = (state) => state.CurrentStudy
  // const CurrentStudyProps = createSelector(CurrentStudyState, (data) => ({
  //   studyId: data.studyId,
  //   pastMeetings: data.pastMeetings,
  //   ongoingMeetings: data.ongoingMeetings,
  //   upcomingMeetings: data.upcomingMeetings,
  //   studyDetails: data.studyDetails,
  // }))
  // const { studyDetails,} = useSelector(CurrentStudyProps)
  // console.log('studyDetails: ', studyDetails);


  const path = window.location.pathname;
  const studyId = path.split('/').slice(-3)[0]; // Assuming the studyId is the second-to-last segment in the path
  // const navigate = useNavigate()
  const data = [
    // {
    //   category_level_1: "Unknown",
    //   type: "Issue",
    //   category_level_2: "Swigen Stomach Works",
    //   user: "user1",
    //   category_level_3: false,
    //   transcript_chunk:
    //     "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
    //   no_of_instances: 1,
    // },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "Platform-related Research",
      user: "user1",
      category_level_3: "Paytm",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "swiggy instamart Works",
      user: "user1",
      category_level_3: "swiggy instamart ",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "swiggy instamart Works",
      user: "user1",
      category_level_3: "Zomato ",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Behavior",
      type: "Good Feature",
      category_level_2: "swiggy instamart Works",
      user: "user1",
      category_level_3: "Paytm ",
      transcript_chunk:
        "Unknown: Morning. Arunima: Yeah, I've started the recording. So you have any questions for me right now. Unknown: No, nothing. As of now. I'm just waiting to understand exactly. So basically, all are doing research on. Jismitha Poojary: Are you doing research on how Swigen stomach works Jismitha Poojary: for me? Or is it about the platform that you all want to know?",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "swiggy instamart",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Failures",
      user: "user1",
      category_level_3: "PhonePe",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "PhonePe",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "Mobikwike",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "WhatsApp",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "Cred",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "swiggy instamart",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Term & Condition",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Success",
      user: "user1",
      category_level_3: "swiggy instamart",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Success",
      user: "user1",
      category_level_3: "Zomato",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Good Feature",
      category_level_2: "Payment Success",
      user: "user1",
      category_level_3: "Paytm",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "Network Failures",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Technical Issues",
      type: "Issue",
      category_level_2: "App Crashes",
      user: "user1",
      category_level_3: "wigen stomach",
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Order Accuracy",
      type: "Issue",
      category_level_2: "Inaccurate Order Fulfillment",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Time",
      type: "Issue",
      category_level_2: "Order Delays",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Wide Restaurant Selection",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "Order Tracking Feature",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Interface",
      type: "Good Feature",
      category_level_2: "User-friendly Interface",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Quick Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Slow Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    {
      category_level_1: "Delivery Strategies",
      type: "Good Feature",
      category_level_2: "Medium Delivery",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Okay? So by swiggy, you mean like swiggie instamod, right? Jismitha Poojary: But I Jismitha Poojary: I use the normal swiggy app for food order also. And as well for for your daily routes and everything. Arunima: Hmm, hmm. Understood. Understood. Arunima: okay, so just to understand a little bit more about swigen stamat.",
      no_of_instances: 1,
    },
    // {
    //   category_level_1: "Unknown",
    //   type: "Good Feature",
    //   category_level_2: "",
    //   user: "user1",
    //   category_level_3: "",
    //   transcript_chunk:
    //     "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
    //   no_of_instances: 1,
    // },
    {
      category_level_1: "Product Range",
      type: "Good Feature",
      category_level_2: "Variety Of Food Options Available",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience for App",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience for Laptop",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "User Experience",
      type: "Good Feature",
      category_level_2: "Convenience for Mobile",
      user: "user1",
      category_level_3: false,
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Quality",
      type: "Good Feature",
      category_level_2: "Improved Quality Compared To Local Stores",
      user: "user1",
      category_level_3: "TATA",
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
    {
      category_level_1: "Product Quality",
      type: "Good Feature",
      category_level_2: "Improved Quality Compared To Local Stores",
      user: "user1",
      category_level_3: "MARUTI",
      transcript_chunk:
        "Arunima: Can you tell me like if you kind of remember it? You kind of remember like, why did you kind of install it? Or why, you kind of started using in our area. The Goto shopping mart for me was Hyper city which was in the same locality. So like in Google, we had a huge hyper city store Jismitha Poojary: a bit shut down. And later on, what happened was, I was not very happy with the quality that I was getting around Jismitha Poojary: in this locality. So II usually try and avoid Dmart Jismitha Poojary: and from from the big you know, standardized. food brand perspective. II we do not have many over here around that. Jismitha Poojary: So that is when we I installed swiggy. App. Actually I earlier I had installed it only for the full app part. And then, when Instagram Mark came around",
      no_of_instances: 1,
    },
  ]
// const handleNavigateBack = () => {
//   navigate(-1)
// }
const handleNavigateBack = () => {

  window.history.back();
};
const fetchStudyDetails = async () => {
  setIsLoading(true)
  try {
    const functions = getFunctions()
    const getStudyDetails = httpsCallable(functions, "getStudyDetails")
    const { data } = await getStudyDetails({ formId: studyId })

    if (!data) {
      return
    }
    if (!data.success) {
      return
    }
    setStudyDetails(data.data)

    setIsLoading(false)
  } catch (error) {
    // displayNotification("error", error.message ?? "Failed to fetch study details")
    setIsLoading(false)
  }
}
useEffect(() => {
  fetchStudyDetails()
}, [])
  const transformedData = transformBubbleData(data, "Good Feature")

  return (
    <div className="page-content">
        <Link
            onClick={handleNavigateBack}
            className="mb-1 d-sm-flex align-items-center justify-content-between "
          >
            <h4 className="mb-sm-0 text-black p-2 font-size-18">
              {studyDetails?.studyName ?? ""} : {studyDetails?.studyType ?? ""}
            </h4>
          </Link>
        {/* <Button
        color="primary"
        style={{height:"40px"}}
        outline
  onClick={handleNavigateBack}
  className="mb-1 d-sm-flex align-items-center justify-content-between "
>
  <h4 className="mb-sm-0 text-black p-2 font-size-18" style={{ color: '#214075' }}>
    Back
  </h4>
</Button> */}
      <div className="d-flex justify-content-evenly">
        <div>
          <BubbleChart data={transformedData} switchColor={true} fontSize={20} switchborder={true} switchOutline={true}/>
        </div>
        {/* <div>
          <BubbleChart data={data} switchColor={false} />
        
        
        </div> */}
      </div>
    </div>
  )
}

export default KeyChallenges;