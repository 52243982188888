import { getFunctions, httpsCallable } from "firebase/functions"
import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import CreatableSelect from "react-select/creatable"
import { Select, Input } from "antd"
import {
  Button,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import { displayNotification } from "utils/utility"
import EditSchedulerForm from "./EditSchedulerForm"
import { UserDataContext } from "../../routes/route"

const MeetTable = ({
  list,
  id,
  handleDelete,
  recordingList,
  studyDetails,
  transcriptList,
  fbGetMeetings,
  handleResponse,
}) => {
  const usersData = useContext(UserDataContext)
  const [isLoading, setIsLoading] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [participants, setParticipants] = useState([])
  const [message, setMessage] = useState("")
  const [currMeet, setCurrMeet] = useState("")
  const [editModal, setEditModal] = useState(false)
  const [editedMeet, setEditedMeet] = useState(null)
  const createOption = (label) => ({ label, value: label })
  const navigate = useNavigate()
  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
        setParticipants((prev) => [...prev, createOption(inputValue)])
        setInputValue("")
        event.preventDefault()
        break
      case "Tab":
        setParticipants((prev) => [...prev, createOption(inputValue)])
        setInputValue("")
        event.preventDefault()
        break
      default:
        break
    }
  }

  const handleSubmit = async (meet) => {
    let hostName = "Test user"
    if (usersData) {
      const obj = usersData
      hostName = obj.displayName
    }
    let timezone = currMeet.start_time.split("T")[1]
    let time = timezone.slice(0, -1) + " (UTC)"

    const emailInvite = {
      emailList: participants,
      joinUrl: currMeet.join_url,
      hostName: hostName, // changeit later
      agenda: currMeet.agenda,
      topic: currMeet.topic,
      date: currMeet.start_time.split("T")[0],
      time: time,
      message: message,
    }
    setParticipants([])
    sendEmailInvite(emailInvite)
    setIsLoading(false)
  }
  const sendEmailInvite = async (data) => {
    setIsLoading(true)
    const functions = getFunctions()
    const sendInvite = httpsCallable(functions, "sendInvite")
    await sendInvite(data)
    displayNotification("success", "Invite sent successfully")
    setSubscribemodal(false)
  }

  const formatDate = (isoString) => {
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }
    const date = new Date(isoString)
    return date.toLocaleDateString("en-US", options).replace(/\//g, "-")
  }

  const handleModal = (meet) => {
    setSubscribemodal(true)
    setCurrMeet(meet)
  }

  const formatTime = (time) => {
    const dateObject = new Date(time)
    const options = {
      // year: "numeric",
      // month: "short",
      // day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }
    const formattedString = dateObject.toLocaleString("en-US", options)
    return formattedString
  }
  const openEditModal = (meet) => {
    setEditedMeet(meet)
    setEditModal(true)
  }

  const handleNavigation = (id) => {
    const currentPath = window.location.pathname
    navigate(currentPath + `/${id}` + "/recording")
  }
  const handleFormNavigation = (id) => {
    const currentPath = window.location.pathname
    navigate(currentPath + `/${id}` + "/form-response")
  }

  return (
    <>
      <div className="mt-0 ml-0">
        <div
          className="pl-0 table-responsive col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 "
          style={{ minHeight: "200px" }}
        >
          <Table className="table align-middle table-nowrap table-hover mb-0">
            <thead>
              <tr>
                <th
                  className="pr-0 pl-0 px-1"
                  scope="col"
                  style={{ fontFamily: "poppins" }}
                >
                  Topic
                </th>
                <th
                  className="pr-0 pl-0 px-1"
                  scope="col"
                  style={{ fontFamily: "poppins" }}
                >
                  Date{" "}
                </th>
                <th
                  className="pr-0 pl-0 px-1"
                  scope="col"
                  style={{ fontFamily: "poppins" }}
                >
                  Time{" "}
                </th>
                {id === 3 && (
                  <th
                    className="pr-0 pl-0 px-1"
                    scope="col"
                    style={{ fontFamily: "poppins" }}
                  ></th>
                )}

                {id === 3 && (
                  <th
                    className="pr-0 pl-0 px-1"
                    scope="col"
                    style={{ fontFamily: "poppins" }}
                  >
                    Recording
                  </th>
                )}
                {id === 3 && (
                  <th
                    className="pr-0 pl-0 px-1"
                    scope="col"
                    style={{ fontFamily: "poppins" }}
                  >
                    Notes
                  </th>
                )}
                {id !== 3 && (
                  <th
                    className="pr-0 pl-0 px-1 d-flex justify-content-center "
                    scope="col"
                    style={{ fontFamily: "poppins" }}
                  >
                    Meeting link
                  </th>
                )}
                <th
                  className="pr-0 pl-0 px-1"
                  scope="col"
                  style={{ fontFamily: "poppins" }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            {list &&
              list.map((meet, index) => {
                let recordingUrl = ""
                let transcriptUrl = ""
                if (Array.isArray(recordingList)) {
                  recordingList.forEach((recording) => {
                    if (
                      recording.meetingId === meet.id &&
                      recording.hasOwnProperty("recording_url")
                    ) {
                      recordingUrl = recording.recording_url
                    }
                  })
                }
                if (Array.isArray(transcriptList)) {
                  transcriptList.forEach((transcript) => {
                    if (
                      transcript.meetingId === meet.id &&
                      transcript.hasOwnProperty("transcript_url")
                    ) {
                      transcriptUrl = transcript.transcript_url
                    }
                  })
                }
                return (
                  <>
                    <tbody>
                      <tr>
                        <td
                          className="pr-0 pl-0 font-size-13 pr-2 "
                          style={{ fontFamily: "poppins" }}
                        >
                          {meet.topic}
                        </td>
                        <td
                          className="pr-0 pl-0 font-size-13 pb-0"
                          style={{ fontFamily: "poppins" }}
                        >
                          {formatDate(meet.start_time)}
                        </td>
                        <td
                          className="pr-0 pl-0 font-size-13 pb-0 "
                          style={{ fontFamily: "poppins" }}
                        >
                          {formatTime(meet.start_time)}
                        </td>

                        <td
                          className="pr-0 pl-0 font-size-13 pb-0"
                          style={{ fontFamily: "poppins" }}
                        >
                          {/* {id === 3 && recordingUrl !== "" && (
                            <Link
                              className="bg-transparent font-size-13"
                              to={recordingUrl}
                              target="_blank"
                            >
                              <p
                              className="font-size-13"
                                style={{ color: "blue", fontFamily: "poppins" }}
                              >
                                View
                              </p>
                            </Link>
                          )} */}
                          {/* {id === 3 && recordingUrl === "" && (
                            <p
                              className="bg-transparent pr-0 pl-0 font-size-13 mb-0 pb-0"
                              style={{ fontFamily: "poppins" }}
                            >
                              Unrecorded
                            </p>
                          )} */}
                          {id !== 3 && (
                            <Link
                              className="bg-transparent pr-0 pl-0 font-size-13 "
                              to={window.location.href + `/meet/${meet.id}`}
                              target="_blank"
                            >
                              <p
                                className="font-size-13 pb-0 pl-30"
                                style={{ color: "blue" }}
                              >
                                Join
                              </p>
                            </Link>
                          )}
                        </td>
                        {id === 3 && (
                          <td
                            className="pr-0 pl-0 font-size-13 pb-0"
                            style={{ fontFamily: "poppins" }}
                          >
                            {recordingUrl !== "" && (
                              <div
                                className="bg-transparent font-size-13"
                                onClick={() => handleNavigation(meet.id)}
                                target="_blank"
                              >
                                <p
                                  className="font-size-13 d-flex justify-content-center"
                                  style={{
                                    color: "blue",
                                    fontFamily: "poppins",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="bx bx-video font-size-20"></i>
                                </p>
                              </div>
                            )}
                            {recordingUrl === "" && (
                              <p
                                className="bg-transparent pr-0 pl-0 d-flex justify-content-center font-size-13 mb-0 pb-0"
                                style={{ fontFamily: "poppins" }}
                              >
                                <i className="bx bx-video-off  font-size-20"></i>
                              </p>
                            )}
                          </td>
                        )}
                        {id === 3 && (
                          <td
                            className="pr-0 pl-0 font-size-13 pb-0"
                            style={{ fontFamily: "poppins" }}
                          >
                            <div
                              className="bg-transparent font-size-13"
                              onClick={() => handleFormNavigation(meet.id)}
                              target="_blank"
                            >
                              <p
                                className="font-size-13 d-flex justify-content-center"
                                style={{
                                  color: "blue",
                                  fontFamily: "poppins",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="bx bx-book font-size-20"></i>
                              </p>
                            </div>
                          </td>
                        )}

                        {/* notes */}

                        <td
                          className="pr-0 pl-0 pb-0"
                          style={{ fontFamily: "poppins" }}
                        >
                          <UncontrolledDropdown className="p-0 w-25  mx-3 bg-transparent ">
                            <DropdownToggle
                              tag="a"
                              className="font-size-16 text-muted"
                              role="button"
                            >
                              <i className="mdi mdi-dots-horizontal"></i>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end  ">
                              <DropdownItem
                                onClick={() => handleDelete(meet.id)}
                              >
                                Delete
                              </DropdownItem>
                              {id !== 3 && (
                                <DropdownItem onClick={() => handleModal(meet)}>
                                  Invite
                                </DropdownItem>
                              )}{" "}
                              {id !== 3 && (
                                <DropdownItem
                                  onClick={() => openEditModal(meet)}
                                >
                                  Edit
                                </DropdownItem>
                              )}{" "}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    </tbody>
                  </>
                )
              })}
          </Table>
        </div>
      </div>

      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal)
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center ">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary mb-4"> Invite For meet!</h4>
                {/* <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p> */}
                <Select
                  mode="tags"
                  style={{ width: "80%" }}
                  className="text-start"
                  placeholder="Enter email address"
                  value={participants}
                  onChange={(value) => setParticipants(value)}
                  onKeyDown={handleKeyDown}
                ></Select>
                <br /> <br />
                {/* Input field for typing a message */}
                <Input
                  style={{ width: "80%" }}
                  placeholder="Type your message"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <br /> <br />
              </div>
              <div className="">
                <Button
                  color="primary "
                  type="button"
                  id="button-addon2"
                  onClick={() => handleSubmit(currMeet)}
                >
                  {isLoading ? (
                    <>
                      <Spinner size="sm" color="light" className="mr-2" />{" "}
                      Loading...
                    </>
                  ) : (
                    "Invite"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        role="dialog"
        autoFocus={true}
        centered
        className="meet-modal"
        data-toggle="modal"
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => setEditModal(!editModal)}
          ></ModalHeader>
        </div>
        <Row>
          <Col>
            <CardTitle className="h4">
              {" "}
              <h4 className="text-primary text-center mb-4">
                Edit your meeting
              </h4>
            </CardTitle>

            <EditSchedulerForm
              studyDetails={studyDetails}
              editedMeet={editedMeet}
              setEditModal={setEditModal}
              fbGetMeetings={fbGetMeetings}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default MeetTable
