import React, { useContext, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  Label,
  Input,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { Button, Select } from "antd"
import {
  GREEN,
  HIRE_UX_RESEARCHER,
  LIGHT_BLUE,
  ORANGE,
  PURPLE,
  USER_INTERVIEW,
  UT_FEATURE_TESTING,
  UT_ONBOARDING,
  YELLOW,
} from "constants/dashboard"
import "./paststudies.scss"
import "./dashCard.scss"
import { getFunctions, httpsCallable } from "firebase/functions"
import { useDispatch } from "react-redux"
// import Select from "react-select"
import { createForm } from "store/actions"
import { displayNotification } from "utils/utility"
import { UserDataContext } from "../../routes/route"

const functions = getFunctions()
const contactUsOnEmail = httpsCallable(functions, "contactUsOnEmail")

const { TextArea } = Input

const DashCard = ({ title, body, img, route }) => {
  const dispatch = useDispatch()
  const usersData = useContext(UserDataContext)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [selectedMulti3, setselectedMulti3] = useState(null)

  function handleMulti3(selectedMulti3) {
    setselectedMulti3(selectedMulti3)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : "white", // Change background color here
      color: state.isSelected ? "white" : "black", // Change text color accordingly
    }),
  }

  const optionGroup2 = [
    { label: "User Interview", value: "User Interview" },
    {
      label: "Usability Testing - Onboarding",
      value: "Usability Testing - Onboarding",
    },
    {
      label: "Usability Testing - Feature Testing",
      value: "Usability Testing - Feature Testing",
    },
  ]
  const sendMessage = async (message) => {
    try {
      const user = usersData
      const email = user.email
      const userId = user.uid
      const data = {
        userId: userId,
        email: email,
        message: message,
        createdAt: Date.now(),
      }

      contactUsOnEmail(data).then((result) => {
        const data = result.data
        if (result.data.success) {
          displayNotification("success", result.data.message)
        }
      })
    } catch (error) {
      displayNotification("error", error.message)
      console.log("error -", error)
    }
  }

  const handleModal = () => {
    addHandler()
    if (title === HIRE_UX_RESEARCHER) {
      setSubscribemodal(true)
    }
  }
  const handleCancel = () => {
    console.log("sdhisuh")
    setSubscribemodal(false)
  }
  const handleSend = () => {
    setSubscribemodal(false)
    if (message) {
      sendMessage(message)
    }
  }
  const navigate = useNavigate()
  const addHandler = () => {
    let route = ""
    switch (title) {
      case USER_INTERVIEW:
        route = "/user-interview"
        break
      case UT_ONBOARDING:
        route = "/onboarding"
        break
      case UT_FEATURE_TESTING:
        route = "/feature"
        break
      case HIRE_UX_RESEARCHER:
        route = ""
        break
      default:
        route = "/default"
        break
    }

    if (route) {
      dispatch(createForm(title))
      navigate(route)
    }
  }

  const getColor = () => {
    switch (title) {
      case HIRE_UX_RESEARCHER:
        return ORANGE
      case USER_INTERVIEW:
        return YELLOW
      case UT_ONBOARDING:
        return GREEN
      case UT_FEATURE_TESTING:
        return PURPLE
      default:
        return LIGHT_BLUE
    }
  }

  const handleChange = (e) => {
    console.log(e)
  }

  return (
    <>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <div onClick={handleModal}>
          <Card className="study-containers">
            <CardBody className="studies-card ">
              <div className={"favorite-icon-" + getColor()}>
                <div className={"img-content-" + getColor()}>
                  <img src={img}></img>
                </div>
              </div>
              <div className="studies-card-content">
                <h5 className="study-title mb-2">
                  <medium className="study-primary-text">{title}</medium>
                </h5>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <p className="text-muted study-body-text lh-base">{body}</p>
                  </li>{" "}
                </ul>
              </div>
            </CardBody>
          </Card>
        </div>
      </Col>

      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal)
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body mb-5">
          <div className="text-center mb-4">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary mb-2">
                  What type of user research service are you interested in?
                </h4>
                <div className="mb-4 templating-select select1-container">
                  <Select
                    value={selectedMulti3}
                    onChange={handleMulti3}
                    placeholder="Study Type"
                    options={optionGroup2}
                    className="select2-selection"
                    styles={customStyles}
                  />
                </div>

                <span className="input-group rounded bg-light">
                  <Input
                    type="email"
                    className="form-control bg-transparent "
                    placeholder="Enter Email address"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Button
                    className="input-button "
                    color="primary"
                    type="button"
                    id="button-addon2"
                    onClick={handleSend}
                  >
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DashCard
