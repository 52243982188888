import React from "react"
import { DatePicker, Form, Input, Select } from "antd"
import { useFormik } from "formik"
import { Button, Col, Row, Spinner } from "reactstrap"
import * as Yup from "yup"
import {
  AM_PM_OPTION,
  SCHEDULER_FORM_INVITATION_TIME_OPTIONS,
  SCHEDULER_FORM_TIME_OPTIONS,
  TIMEZONE_OPTIONS,
} from "constants/zoom"

const SchedulerForm = ({
  handleScheduleMeet,
  studyDetails,
  isApiInProgress,
}) => {
  const timeFormat = "HH:mm"

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("Topic is required"),
    duration: Yup.string(),
    date: Yup.date().required("Please select a Time and Date"),
    time: Yup.string().required("*"),
    period: Yup.string().required("Please select AM or PM"),
    timezone: Yup.string().required("Please select Timezone"),
    invite: Yup.array().of(Yup.string()),
  })
  const formik = useFormik({
    initialValues: {
      topic: studyDetails?.studyName ?? null,
      duration: studyDetails?.studyDuration ?? null,
      date: null,
      time: null,
      period: null,
      invite: [],
      timezone: "Asia/Kolkata",
    },
    validationSchema: validationSchema,
    onSubmit: (val) => {
      const formValuesCopy = { ...val }
      handleScheduleMeet(formValuesCopy)
    },
  })

  return (
    <div>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="horizontal"
        style={{
          maxWidth: 600,
        }}
        onSubmit={formik.handleSubmit}
      >
        <Form.Item label="Topic">
          <Input
            name="topic"
            // placeholder='Enter Topic'
            // autoComplete={studyDetails.studyName}
            placeholder={studyDetails?.studyName ?? ""}
            value={formik.values.topic ?? studyDetails?.studyDetails?.studyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.topic && formik.errors.topic ? (
            <div style={{ color: "red" }}>{formik.errors.topic}</div>
          ) : null}
        </Form.Item>

        <div className="when-div d-flex  justify-content-center ">
          <div className=" when-text mx-4 mt-1  color-black">When :</div>
          <Row className="when-row d-flex justify-content-evenly   ">
            <Col className="modal-when-col mr-1">
              <Form.Item label="">
                <DatePicker
                  className="form-control"
                  name="date"
                  value={formik.values.date}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.date && formik.errors.date ? (
                  <div style={{ color: "red", width: "220px" }}>
                    {formik.errors.date}
                  </div>
                ) : null}
              </Form.Item>
            </Col>

            <Col className="modal-when-col mr-1 p-0 ">
              <Form.Item label="">
                <div className="ant-select-1">
                  <Select
                    placeholder="Time"
                    name="time"
                    options={SCHEDULER_FORM_INVITATION_TIME_OPTIONS}
                    value={formik.values.time}
                    onChange={(value) => formik.setFieldValue("time", value)}
                    onBlur={formik.handleBlur}
                    format={timeFormat}
                  />
                </div>
                {formik.touched.time && formik.errors.time ? (
                  <div style={{ color: "red" }}>{formik.errors.time}</div>
                ) : null}
              </Form.Item>
            </Col>
            <Col className="modal-when-col ">
              <Form.Item label="">
                <div className="ant-select-1  ">
                  <Select
                    placeholder="AM/PM"
                    name="period"
                    options={AM_PM_OPTION}
                    value={formik.values.period}
                    onChange={(value) => formik.setFieldValue("period", value)}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.period && formik.errors.period ? (
                  <div style={{ color: "red", width: "220px" }}>
                    {formik.errors.period}
                  </div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Form.Item label="Timezone">
          <Select
            name="timezone"
            defaultValue={TIMEZONE_OPTIONS[0]}
            options={TIMEZONE_OPTIONS} // Replace with your array of timezone options
            value={formik.values.timezone}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onChange={(value) => formik.setFieldValue("timezone", value)}
            onBlur={formik.handleBlur}
          />
          {formik.touched.timezone && formik.errors.timezone ? (
            <div style={{ color: "red" }}>{formik.errors.timezone}</div>
          ) : null}
        </Form.Item>
        <Form.Item label="Duration">
          <Select
            name="duration"
            value={formik.values.duration}
            // value={
            //   formik.values.duration ?? studyDetails.studyDuration
            // }
            placeholder="Select Duration"
            onChange={(value) => formik.setFieldValue("duration", value)}
            onBlur={formik.handleBlur}
            options={SCHEDULER_FORM_TIME_OPTIONS}
          ></Select>
        </Form.Item>

        <Form.Item label="Invite">
          <Select
            name="invite"
            mode="tags"
            placeholder="Enter participant's email address"
            value={formik.values.invite}
            onChange={(value) => formik.setFieldValue("invite", value)}
            onBlur={formik.handleBlur}
          ></Select>
          {formik.touched.invite && formik.errors.invite ? (
            <div style={{ color: "red" }}>{formik.errors.invite}</div>
          ) : null}
        </Form.Item>

        <Form.Item className="d-flex justify-content-center ">
          <Button
            type="primary"
            htmlType="submit"
            color="primary"
            style={{ whiteSpace: "nowrap" }}
            // disabled={isLoading || isApiInProgress}
            onClick={formik.submitForm}
          >
            {isApiInProgress ? (
              <>
                <Spinner size="sm" color="light" className="mr-2" />
                Scheduling...
              </>
            ) : (
              "Schedule Meeting"
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SchedulerForm
