import { $getRoot, $getSelection } from "lexical"
import React, { useEffect, useCallback       } from "react"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
// import lexicalEditorConfig from "../../../../config/lexicalEditorConfig"
// import LexicalEditorTopBar from "../LexicalEditorTopBar"
import TreeViewPlugin from "../CustomPlugins/TreeViewPlugin"
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import ImagesPlugin from "../CustomPlugins/ImagePlugin"
import FloatingTextFormatToolbarPlugin from "../CustomPlugins/FloatingTextFormatPlugin"
import { Divider } from "antd"
// import { MuiContentEditable } from "./styles"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalEditorTopBar from "../LexicalEditorTopBar"
import "../LexicalEditorTopBar/topbar.scss"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { TableNode, TableCellNode, TableRowNode } from "@lexical/table"
import { ListNode, ListItemNode } from "@lexical/list"
import { ImageNode } from "../CustomNodes/ImageNode"
import lexicalEditorTheme from "../theme/lexicalEditorTheme"
import { updateTemplate } from "store/template/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import ListMaxIndentLevelPlugin from "../CustomPlugins/ListMaxIndentLevelPlugin"
import { Dropdown,DropdownItem } from "reactstrap"

const generateConfig = (initialData, isEditable) => {
  const lexicalEditorConfig = {
    editable: isEditable,
    namespace: "MyEditor",
    theme: lexicalEditorTheme,
      onError(error) {
        throw error
      },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
    ],
    editorState: initialData,
  }
  return lexicalEditorConfig
}

function LexicalEditorWrapper({ initialData, sectionIndex, template, isEditable, showTopBar,editor,
  value,
  style,
  disabled = false, }) {
  // When the editor changes, you can get notified via the
  // LexicalOnChangePlugin!
  const onChange = (editorState, sectionIndex, template) => {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      const root = $getRoot()
      const selection = $getSelection()
    })
    const json = editorState.toJSON()
    const jsonstring = JSON.stringify(json)
    updateCurrentTemplate(jsonstring, sectionIndex, template)
  }

  const updateCurrentTemplate = (jsonstring, sectionIndex, template) => {
    const currentSection = template[sectionIndex]
    const updatedSection = { ...currentSection, editorContent: JSON.parse(jsonstring) }
    template[sectionIndex] = updatedSection
    dispatch(
      updateTemplate({
        template: template,
        currentSectionIndex: sectionIndex,
      }),
    )
  }
  const handleClick = useCallback(
    (option) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($INTERNAL_isPointSelection(selection)) {
          $patchStyleText(selection, {
            [style]: option,
          });
        }
      });
    },
    [editor, style],
  );

  const buttonAriaLabel =
    style === 'font-family'
      ? 'Formatting options for font family'
      : 'Formatting options for font size';

  const dispatch = useDispatch()
  return (
    <div>
      <LexicalComposer initialConfig={generateConfig(initialData, isEditable)}>
        {showTopBar && 
        <>
        <div className="lexical-top-bar">
      
    
          <LexicalEditorTopBar />
          
        </div>
        <div className="divider">
        <Divider />
        </div>
        </>
        }
        <div
          className="template-content"
          style={{ position: "relative", background: "white" }}
        >
          <RichTextPlugin
            contentEditable={<ContentEditable />}
            placeholder={<div>Enter some text...</div>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin
            onChange={(editorState) =>
              onChange(editorState, sectionIndex, template)
            }
          />
          <HistoryPlugin />
          {/* <TreeViewPlugin /> */}
          <ListPlugin />
          <LinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={3}/>
          <TabIndentationPlugin />
          {/* <ImagesPlugin captionsEnabled={false} /> */}
          <FloatingTextFormatToolbarPlugin />
          <MyCustomAutoFocusPlugin/>
        </div>
      </LexicalComposer>
    </div>
  )
}

function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus()
    // const stringifiedEditorState = JSON.stringify(
    //   editor.getEditorState().toJSON(),
    // );
    // console.log("json str - : ", stringifiedEditorState)
    // const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
    
    // const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent())
    // console.log("editorstate text string : ", editorStateTextString)
  }, [editor])

  return null
}

export default LexicalEditorWrapper
// export const FORMAT_ELEMENT_COMMAND = createCommand('FORMAT_ELEMENT_COMMAND');
// export function createCommand<T>(type?: string): LexicalCommand<T> {
//   return __DEV__ ? { type } : {};
// }
