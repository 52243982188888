import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap"
import "./paststudies.scss"
import { getFunctions, httpsCallable } from "firebase/functions"
import moment from "moment"
import messages from "../../assets/images/message.svg"
import user from "../../assets/images/user.svg"
import usability from "../../assets/images/usability.svg"
import other from "../../assets/images/other.svg"
import {
  GREEN,
  PURPLE,
  USER_INTERVIEW,
  UT_FEATURE_TESTING,
  UT_ONBOARDING,
  YELLOW,
  OTHER,
} from "constants/dashboard"
import { useDispatch } from "react-redux"
import { saveStudyDetails } from "store/study/action"
import FirebaseService from "services/firebaseServices"
import { message } from "antd"

const PastStudiesCard = ({
  data,
  handleClick,
  updateList,
  deleteStudy,
  duplicateStudy,
  renameStudy,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [duplicateName, setDuplicateName] = useState("")
  const [duplicateStudyId, setDuplicateStudyId] = useState("")
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false)
  const [renameStudyName, setRenameStudyName] = useState("")
  const [UpdatedData, setUpdatedData] = useState("")
  const [fieldValues, setFieldValues] = useState("")
  const [renameStudyId, setRenameStudyId] = useState("")
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [studyToDelete, setStudyToDelete] = useState(null)

  const { _seconds } = data?.updatedAt || {} // Destructure seconds from Firebase timestamp object
  let formattedDate = ""
  if (_seconds) {
    formattedDate = moment.unix(_seconds).format("DD/MM/YYYY HH:mm")
  }
  const handleDelete = async (id) => {
    deleteStudy(id)
  }

  const handleDeleteClick = (id) => {
    setStudyToDelete(id)
    setIsDeleteModalOpen(true)
  }

  const handleConfirmDelete = () => {
    if (studyToDelete) {
      deleteStudy(studyToDelete)
      setStudyToDelete(null)
      setIsDeleteModalOpen(false)
      // Display success message
      message.success("deleting...")
    }
  }

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen)
  }

  const handleDuplicate = async (id) => {
    setDuplicateStudyId(id)
    setIsModalOpen(true)
  }

  const image = () => {
    if (data.studyType === USER_INTERVIEW) {
      return messages
    } else if (data.studyType === UT_ONBOARDING) {
      return usability
    } else if (data.studyType === UT_FEATURE_TESTING) {
      return user
    } else {
      return other
    }
  }
  const handleRename = async (data) => {
    fetchStudyDetails(data.docId)
    setRenameStudyId(data.docId)
    setRenameStudyName(data.studyName)
    setUpdatedData(data)
    setIsRenameModalOpen(true)
  }
  const fetchStudyDetails = async (studyId) => {
    try {
      const response = await FirebaseService.fetchStudyDetails(studyId)
      setFieldValues(response.data.fieldValues)
      const { success, data } = response
      dispatch(saveStudyDetails({ studyId: studyId, studyDetails: data }))
      setIsLoading(false)
    } catch (error) {
      dispatch(saveStudyDetails({ studyId: studyId, data: null }))
      setIsLoading(false)
    }
  }
  const toggleRenameModal = () => {
    setIsRenameModalOpen(!isRenameModalOpen)
    setRenameStudyName("")
  }

  const handleRenameChange = (e) => {
    setRenameStudyName(e.target.value)
  }

  const handleConfirmRename = () => {
    const renamedData = { ...fieldValues, studyName: renameStudyName }
    renameStudy(renamedData, UpdatedData.docId)
    setIsRenameModalOpen(false)
  }
  const getSubType = () => {
    switch (data.studyType) {
      case USER_INTERVIEW:
        return "User Satisfaction"
      case UT_ONBOARDING:
        return "User Testing"
      case UT_FEATURE_TESTING:
        return "User Testing"
      default:
        return data.studyType
    }
  }

  const getColor = () => {
    switch (data.studyType) {
      case USER_INTERVIEW:
        return YELLOW
      case UT_ONBOARDING:
        return GREEN
      case UT_FEATURE_TESTING:
        return PURPLE
      default:
        return OTHER
    }
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    setDuplicateName("")
  }

  const handleChange = (e) => {
    setDuplicateName(e.target.value)
  }

  const handleConfirmDuplicate = () => {
    duplicateStudy(duplicateStudyId, duplicateName)
    setDuplicateStudyId("")
    setDuplicateName("")
    setIsModalOpen(false)
  }

  return (
    <>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <Card className="study-container1">
          <CardBody className="studies-card">
            <div
              className={"favorite-icon-" + getColor()}
              onClick={() => handleClick(data)}
            >
              <div className="mt-1 hstack gap-2">
                <span className="badge p-2 badge-text ms-auto rounded-1 badge-soft-success">
                  {data?.studyType ?? ""}
                </span>
              </div>
              <div className={"img-content-" + getColor()}>
                <img src={image()} />
              </div>
            </div>
            <div className="studies-card-content">
              <div className="d-flex justify-content-between">
                <h5 className="study-title mb-2">
                  <medium
                    className="study-primary-text"
                    onClick={() => handleClick(data)}
                  >
                    {data?.studyName ?? ""}
                  </medium>
                </h5>
                <div className="delete-button">
                  <UncontrolledDropdown className="mb-2">
                    <DropdownToggle className="font-size-16 text-muted" tag="a">
                      <i className="mdi mdi-dots-horizontal"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        className="delete"
                        onClick={() => handleDeleteClick(data.docId)}
                      >
                        {isLoading ? (
                          <>
                            <Spinner size="sm" color="light" className="mr-2" />{" "}
                            Loading...
                          </>
                        ) : (
                          <>
                            <div className="d-flex justify-content-evenly ">
                              <div>Delete </div>
                              <div className="delete-icon">
                                <i className="mdi mdi-delete"></i>
                              </div>
                            </div>
                          </>
                        )}
                      </DropdownItem>
                      <DropdownItem
                        color="primary"
                        className="delete"
                        onClick={() => handleDuplicate(data.docId)}
                      >
                        <>
                          <div className="d-flex justify-content-evenly  ">
                            <div>Duplicate </div>
                            <div className="duplicate-icon">
                              <i className="fas fa-copy"></i>
                            </div>
                          </div>
                        </>
                      </DropdownItem>
                      <DropdownItem
                        className="delete"
                        onClick={() => handleRename(data)}
                      >
                        <>
                          <div className="d-flex justify-content-evenly ">
                            <div>Rename </div>
                            <div className="duplicate-icon">
                              <i className="mdi mdi-file-document-edit-outline "></i>
                            </div>
                          </div>
                        </>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <ul
                className="list-inline mb-0"
                onClick={() => handleClick(data)}
              >
                <li
                  onClick={() => handleClick(data)}
                  className="list-inline-item text-muted study-body-text w-100"
                >
                  {getSubType()}
                </li>{" "}
                <li className="list-inline-item">
                  <p
                    className="text-muted study-body-text"
                    onClick={() => handleClick(data)}
                  >
                    {formattedDate}
                  </p>
                </li>{" "}
                <li className="list-inline-item">
                  <p
                    className="text-muted study-secondary-text"
                    onClick={() => handleClick(data)}
                  ></p>
                </li>{" "}
              </ul>
              <div className="mt-3 hstack gap-2"></div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Modal
        isOpen={isModalOpen}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={toggleModal}
      >
        <ModalBody>
          {/* <div className="modal-body mb-"> */}
          <div className="text-center mb-">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary mb-4 font-size-22">
                  Duplicate Study
                </h4>
                <div className="mb-4 templating-select select1-container">
                  <Input
                    type="text"
                    placeholder="Enter a name for the duplicated study"
                    value={duplicateName}
                    onChange={handleChange}
                  />
                  <div className="my-4">
                    <Button
                      type="primary"
                      className="mx-2"
                      color="primary"
                      onClick={handleConfirmDuplicate}
                    >
                      Duplicate
                    </Button>
                    <Button
                      color="danger"
                      className="mx-2"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isRenameModalOpen}
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={toggleRenameModal}
      >
        <ModalHeader
          style={{ borderBottom: "none" }}
          className="d-flex justify-content-center border-none"
          toggle={toggleRenameModal}
        ></ModalHeader>
        <ModalBody className="border-none">
          <div className="text-center ">
            <div className="row justify-content-md-center">
              <div className="col-10">
                <h4 className="text-primary mb-3 mt-0 font-size-22">
                  Rename Study
                </h4>
                <Input
                  type="text"
                  placeholder="Enter a new name for the study"
                  value={renameStudyName}
                  onChange={handleRenameChange}
                />

                <div className="mt-4 mb-2">
                  <Button
                    type="primary"
                    className="mx-2"
                    color="primary"
                    onClick={handleConfirmRename}
                  >
                    Rename
                  </Button>
                  <Button
                    color="secondary"
                    className="mx-2"
                    onClick={toggleRenameModal}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={toggleDeleteModal}
        // className="p-5" // Custom modal class
      >
        <ModalHeader
          style={{ borderBottom: "none", height: "0px" }}
          toggle={toggleDeleteModal}
        ></ModalHeader>
        <ModalBody>
          {/* <div className="modal-body"> */}
          <div className="text-center ">
            <div className="row justify-content-md-center">
              <div className="col-10">
                <p className="mt-0 font-size-20 font-weight-normal">
                  Are you sure you wish to delete this study?
                </p>
                <p className="mt-3 font-weight-lighter">
                  Deleting this action{" "}
                  <strong className="text-danger">cannot</strong> be undone. It
                  will permanently erase all meetings, transcripts, notes, and
                  reports linked to this study.
                </p>
                <div className="mt-4 mb-2">
                  <Button
                    color="secondary"
                    outline
                    className="mx-2"
                    onClick={toggleDeleteModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="danger"
                    className="mx-2"
                    onClick={handleConfirmDelete}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
        {/* <ModalFooter style={{ borderTop: "none"}} className="d-flex justify-content-center mt-0 mb-2">
        </ModalFooter> */}
      </Modal>
    </>
  )
}

export default PastStudiesCard
