import { Space, Steps } from "antd"
import React, { useState } from "react"
import { Button, Col, Form, Row, Card, CardBody, CardTitle } from "reactstrap"
import EditQuestionBlock from "./EditQuestionBlock"
import ReadQuestionBlock from "./ReadQuestionBlock"
import { EMPTY_QUESTION_BLOCK, PARTICIPANT_SCREENER } from "constants/studyForm"
import "./study.scss"

const FormSection = ({
  sectionData,
  sectionIndex,
  updateFormData,
  currentFormData,
  handleAddNewSection,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(null)

  const handleCurrentQue = (data) => {
    setCurrentQuestion(data)
  }

  const handleQuestionData = (data) => {
    setCurrentQuestion(data)
  }

  const addEmptyQuestionBlock = () => {
    const queList = sectionData.questions
    const updatedQueList = [...queList, EMPTY_QUESTION_BLOCK]
    const updatedSectionData = { ...sectionData, questions: updatedQueList }
    updateFormData(updatedSectionData, sectionIndex)
  }

  const removeQuestionBlock = (queIndex) => {
    const queList = sectionData.questions
    queList.splice(queIndex, 1)
    const updatedSectionData = { ...sectionData, questions: queList }
    updateFormData(updatedSectionData, sectionIndex)
  }

  const addNewSection = () => {
    const newFormData = [...currentFormData]
    PARTICIPANT_SCREENER.forEach((section) => newFormData.push(section))
    handleAddNewSection(newFormData)
  }

  return (
    <>
  
      <Col xs="12" style={{ maxWidth: "800px" }}>
        {/* <Card className="form-section "> */}
          {/* <CardBody> */}
           
            {/* <CardTitle>
              <h5 className="font-size-18 text-primary">{sectionData.title ?? ""}</h5>
            </CardTitle> */}

            {/* <h5 className="font-size-13 text-muted mb-4 mt-1">
              {sectionData.desc ?? ""}
            </h5> */}

            <Form onSubmit={(e) => {e.preventDefault(); console.log("form sub mission  - ",e)}}>
              <Row>
                {sectionData.questions &&
                  sectionData.questions.length > 0 &&
                  sectionData.questions.map((question, index) => {
                    return (
                      <>
                        <Row className="w-100">
                          <Col>
                            <div className="mb-0">
                              {currentQuestion &&
                                currentQuestion.queIndex === index && (
                                  <EditQuestionBlock
                                  key={question.queId}
                                    questionData={question}
                                    queIndex={index}
                                    sectionData={sectionData}
                                    secIndex={sectionIndex}
                                    handleQuestionData={handleQuestionData}
                                  />
                                )}

                              {currentQuestion &&
                                currentQuestion.queIndex !== index && (
                                  <ReadQuestionBlock
                                  key={question.queId}
                                    questionData={question}
                                    queIndex={index}
                                    sectionData={sectionData}
                                    secIndex={sectionIndex}
                                    handleCurrentQue={handleCurrentQue}
                                  />
                                )}

                              {!currentQuestion && (
                                <ReadQuestionBlock
                                key={question.queId}
                                  questionData={question}
                                  queIndex={index}
                                  sectionData={sectionData}
                                  secIndex={sectionIndex}
                                  handleCurrentQue={handleCurrentQue}
                                />
                              )}
                            </div>
                          </Col>
                          
                          <Col sm="1">
                            <div className="mb-3 xyz">
                              <Space direction="vertical" align="baseline">
                                {/* {!question.isRequired && (
                                  <Button
                                    // onClick={() =>
                                    //   dispatch(addNewQuestionBlock())
                                    // }
                                    onClick={() => removeQuestionBlock(index)}
                                    className="empty-btn"
                                  >
                                    <i className="bx bxs-trash" />
                                  </Button>
                                )} */}
                                {index === sectionData.questions.length - 1 && (
                                  <>
                                    {/* <Button
                                      // onClick={() =>
                                      //   dispatch(addNewQuestionBlock())
                                      // }
                                      onClick={() => addEmptyQuestionBlock()}
                                      className="empty-btn"
                                    >
                                      <i className="bx bx-plus" />
                                      
                                    </Button> */}
                                  </>
                                )}
                              </Space>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )
                  })}
              </Row>
            </Form>
          {/* </CardBody>
        </Card> */}
      </Col>
      
    </>
  )
}

export default FormSection
