import flag from "../assets/images/rectangle.png"

const languages = {
  sp: {
    label: "Spanish",
    flag: flag,
  },
  gr: {
    label: "German",
    flag: flag,
  },
  it: {
    label: "Italian",
    flag: flag,
  },
  rs: {
    label: "Russian",
    flag: flag,
  },
  en: {
    label: "English",
    flag: flag,
  },
}

export default languages
