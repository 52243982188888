import React, { useState, useEffect, useRef } from "react"
import "./VideoWithTranscript.scss" // Import your CSS file for styling
import { Button, Card, Col, Progress, Row, Spinner } from "reactstrap"
import { Input, Skeleton } from "antd"
import { debounce } from "lodash"
import { Link, useNavigate, useParams } from "react-router-dom"
import { displayNotification } from "utils/utility"
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import {
  collection,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
  serverTimestamp,
  addDoc, 
  limit,
} from "firebase/firestore"
import FirebaseService from "services/firebaseServices"
import { onAuthStateChanged } from "firebase/auth"
import { auth } from "config/firebase"
import { saveStudyDetails } from "store/study/action"
import { useDispatch } from "react-redux"
import { getFunctions, httpsCallable } from "firebase/functions"

const VideoWithTranscript = () => {
  const { studyId, meetingId } = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [updatedtranscript, setUpdatedTranscript] = useState([])
  const debouncedSetInputs = debounce(setUpdatedTranscript, 1000)
  const recordingRef = useRef()
  const navigate = useNavigate()
  const [isZoomAuthorized, setIsZoomAuthorized] = useState(false)
  const [downloadURL, setDownlaodURL] = useState(false)



  const AuthState = (state) => state.AuthState
  const AuthStateProps = createSelector(AuthState, (data) => ({
    authUserData: data.authUserData,
  }))
  const { authUserData } = useSelector(AuthStateProps)
  const userId = authUserData.uid

  const CurrentStudyState = (state) => state.CurrentStudy
  const CurrentStudyProps = createSelector(CurrentStudyState, (state) => ({
    studyId: state.studyId,
    studyDetails: state.studyDetails,
    isFetching: state.isFetching,
  }))
  const { studyDetails, isFetching } = useSelector(CurrentStudyProps)
  const { template, templateForm, templateId, templateFormId } =
    studyDetails ?? {}

    const fetchRecording = async (user) => {
      try {
        
        if (!user) {
          return;
        }
        const isMeetingIdNumber = !isNaN(meetingId);
        const db = getFirestore();
        const meetingsCollectionRef = collection(db, "zoomMeetings");
        const queryConstraints = [
          where("userId", "==", user),
          where("id", "==", isMeetingIdNumber ? Number(meetingId) : meetingId),
          where("formId", "==", studyId),
          limit(1),
        ];
        const q = query(meetingsCollectionRef, ...queryConstraints);
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          displayNotification("error", "Failed to load recording");
          return;
        }
        let downloadUrl;
        querySnapshot.forEach(async (doc) => {
          setDownlaodURL(doc.data().downloadUrl)
          getTranscript()
          try {
            downloadUrl = doc.data()?.downloadUrl;
          } catch (error) {
            displayNotification("error", "Failed to load recording");
            console.error("Error getting download URL:", error);
          }
        });
        if (downloadUrl) {
          recordingRef.current.src = downloadUrl;
        } else {
          displayNotification("error", "Failed to load recording");
        }
      } catch (error) {
        console.log("ERROR : ", error);
        displayNotification(
          "error",
          error?.message ?? "Failed to load recording"
        );
      }
    };
    

  const getTranscript = async () => {
    try {
      // const uid = user?.uid
      if (!userId) {
        return
      }
      const db = getFirestore()
      const transcriptsCollectionRef = collection(
        db,
        "studies",
        studyId,
        "transcripts",
      )
      const snapShot = query(
        transcriptsCollectionRef,
        where("userId", "==", userId),
        where("meetingId", "==", meetingId),
        limit(1),
      )
      const querySnap = await getDocs(snapShot)
      if (querySnap.empty) {
        displayNotification("error", "Transcript not found!")
        return
      }
      querySnap.forEach(async (doc) => {
        const transcript = doc.data()?.transcriptJson
        if (!transcript) {
          displayNotification("error", "Transcript not found!")
          return
        }
        setUpdatedTranscript( 
         {data: transcript },
        )
      })
    } catch (error) {
      console.log("ERROR : ", error)
      displayNotification(
        "error",
        error?.message ?? "Failed to load transcript",
      )
    }
  }

  const formatSecondsToString = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000)
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    const formattedTime = [
      hours.toString() + " hr",
      minutes.toString() + " min",
      remainingSeconds.toString() + " sec",
    ].join(" ")
    return formattedTime
  }

  const formatTimeToSeconds = (timestr) => {
    try {
        const [hours, minutes, secondsWithMillis] = timestr.split(":");
        const [seconds, milliseconds] = secondsWithMillis.split(".");
        // Convert each component to integer
        const hoursInt = parseInt(hours, 10) || 0;
        const minutesInt = parseInt(minutes, 10) || 0;
        const secondsInt = parseInt(seconds, 10) || 0;
        const millisecondsFloat = parseFloat("0." + milliseconds) || 0;

        // Calculate total seconds
        let totalSeconds = hoursInt * 3600 + minutesInt * 60 + secondsInt + millisecondsFloat;
        return totalSeconds;
    } catch (error) {
        console.log("ERROR formatTimeToSeconds: ", error);
        return 0;
    }
};



  const handleInputChange = (event, id) => {
    const { value } = event.target
    const updatedTranscript = updatedtranscript.map((item) =>
      item.id === id ? { ...item, message: value } : item,
    )
    debouncedSetInputs(updatedTranscript)
  }

  const handleSave = async () => {
    try {
      const db = getFirestore()
      const transcriptsCollectionRef = collection(
        db,
        "studies",
        studyId,
        "transcripts",
      )
      const snapShot = await getDocs(
        query(
          transcriptsCollectionRef,
          where("userId", "==", userId),
          where("meetingId", "==", meetingId),
          limit(1),
        ),
      )
      if (snapShot.empty) {
        // If no existing transcript found, add a new one
        await addDoc(transcriptsCollectionRef, {
          userId: userId,
          meetingId: meetingId,
          transcriptJson: updatedtranscript, // Assuming transcriptJson contains the data you want to save
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        })
        displayNotification("success", "Transcript saved successfully.")
      } else if (!snapShot.empty) {
        // If transcript found, update it
        const docRef = snapShot.docs[0].ref
        await updateDoc(docRef, {
          transcriptJson: updatedtranscript, // Update transcriptJson field with new data
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        })
        displayNotification("success", "Transcript saved successfully.")
      } else {
        displayNotification("error", "Failed to save transcript")
      }
    } catch (error) {
      console.error("ERROR:", error)
      displayNotification("error", "Failed to save transcript")
    }
  }

  const fetchStudyDetails = async () => {
    try {
      const response = await FirebaseService.fetchStudyDetails(studyId)
      const { success, data } = response
      if (success) {
        dispatch(
          saveStudyDetails({
            studyId: studyId,
            studyDetails: data,
            isFetching: false,
          }),
        )
      } else {
        dispatch(
          saveStudyDetails({
            studyId: studyId,
            studyDetails: {
              template: [],
              templateForm: [],
              templateId: "",
              templateFormId: "",
            },
            isFetching: false,
          }),
        )
        console.log("ELSE CONDITION : ", response)
        displayNotification("error", "Failed to load script")
      }
    } catch (error) {
      console.log("CATCH ERR : ", error)
      dispatch(
        saveStudyDetails({
          studyId: studyId,
          studyDetails: {
            template: [],
            templateForm: [],
            templateId: "",
            templateFormId: "",
          },
          isFetching: false,
        }),
      )
      displayNotification("error", "Failed to load script")
    }
  }
  const checkZoomAuthorization = async () => {
    try {
      const functions = getFunctions()
      const isZoomAuthorized = httpsCallable(functions, "isZoomAuthorized")
      const response = await isZoomAuthorized()
      const { status } = response.data
      setIsZoomAuthorized(status)
      return status
    } catch (error) {
      console.log("checkZoomAuth error : ", error)
    }
  }

  
  const  handleGenerateTranscription =  async () => {
      setIsLoading(true)
      try {
        const response = await FirebaseService.generateTranscript(
          studyId,
          meetingId,
          {
            body: {FILE_URL: downloadURL },
          },
        )
        setUpdatedTranscript(response)
        if(success){
          setIsLoading(false)
          displayNotification("success", response.data.message)
        }
        
      } catch (error) {
        setIsLoading(false)
        console.log("generateTranscript ERROR : ", error.message)
      }
    
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    ;(async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          dispatch(saveStudyDetails({ studyId: studyId }))
          checkZoomAuthorization()
          fetchStudyDetails()
          fetchRecording(userId)
          // getTranscript(user)
        } else {
          setIsLoading(false)
          console.log("Something went wrong while fetching data!")
        }
      })
    })()
  }, [])

  return (
    <div className="page-content">
      <Row>
        <Col className="col-12">
          <Link
            onClick={() => navigate(-1)}
            className="mb-1 d-sm-flex align-items-center justify-content-between"
          >
            {!isFetching && (
              <h4 className="mb-sm-0 text-black p-2 font-size-18">
                {studyDetails?.studyName ?? ""} :{" "}
                {studyDetails?.fieldValues?.studyType ?? ""}
              </h4>
            )}
            {isFetching && <Skeleton.Input active size="small" />}
          </Link>
        </Col>
      </Row>
      <Row>
        <Col sm="7">
          <video
            ref={recordingRef}
            className="m-1 video-tag w-100"
            controls
            playsInline
          >
            <source type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Col>
      
        <Col sm="5">
  {updatedtranscript && updatedtranscript.length === 0 ? (
  <>
  <Card className="generate-card">
  <div className="generate-description">
    <p>Please click on the below button to generate transcript!</p>
  </div>
  <div className="generate-button">
    <span>
      <Button color="primary" style={{ display: "flex", justifyContent: "center" }} onClick={handleGenerateTranscription}>
        {isLoading ? (
          <>
            <Spinner size="sm" color="light" className="mr-2" />
            Generating...
          </>
        ) : (
          "Generate"
        )}
      </Button>
    </span>
  </div>
  {isLoading && (

  <div className="refresh-warning">
    Please don't refresh the page
  </div>
  ) 
  }
  </Card>
</>
  ) : (
    <>
      <Card className="transcript-card p-4">
        {!isLoading ? (
          <div className="transcript-container">
            <pre>
              {updatedtranscript?.data?.map((cue) => (
                <div key={cue.id}>
                  <p className="m-2">
                    <strong>Time: </strong> {formatSecondsToString(cue.startTime)}
                  </p>
                  <p className="m-2 text-primary">
                    <strong>{cue.name}:</strong>
                  </p>
                  <Input.TextArea
                    size="large"
                    className="transcript-input m-2 mb-6 mt-0 p-2"
                    defaultValue={cue.message}
                    onChange={(e) => handleInputChange(e, cue.id)}
                    autoSize={{
                      minRows: 1,
                      maxRows: 5,
                    }}
                  />
                </div>
              ))}
            </pre>
          </div>
        ) : (
          <Skeleton active={true} loading={isLoading} />
        )}
      </Card>
      <div className="actions clearfix d-flex align-items-center justify-content-end">
        <Button color="primary" onClick={handleSave}>
          {isSaving ? (
            <>
              <Spinner size="sm" color="light" className="mr-2" /> Saving...
            </>
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </>
  )}
</Col>


      </Row>
    </div>
  )
}

export default VideoWithTranscript
