import React from "react"
import { Card, CardBody, Container } from "reactstrap"

const PrivacyPolicy = () => {
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Card>
            <CardBody>
              <h1>Privacy Policy</h1>

              <p>
                We, Hureo (“Hureo”, “We”, “our”) are the owners of the website{" "}
                <a href="https://www.hureo.com">www.hureo.com</a> (“Website”).
              </p>

              <p>
                We respect data privacy rights and are committed to protecting
                personal information collected on this Website. This privacy
                policy (“Privacy Policy”) sets forth how we collect, use and
                protect the personal information collected on this Website.
              </p>

              <p>
                <strong>
                  PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY CLICKING “I
                  AGREE” OR BY CONTINUING TO USE THE WEBSITE, PROVIDING US
                  PERSONAL INFORMATION, YOU CONSENT TO OUR USE OF YOUR PERSONAL
                  INFORMATION IN ACCORDANCE WITH THE TERMS OF THIS PRIVACY
                  POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU MAY
                  WITHDRAW YOUR CONSENT OR ALTERNATIVELY CHOOSE NOT TO PROVIDE
                  YOUR PERSONAL INFORMATION ON THE WEBSITE. SUCH AN INTIMATION
                  TO WITHDRAW YOUR CONSENT CAN BE PROVIDED BY EMAIL TO
                  COMMUNICATION DETAILS MENTIONED BELOW.
                </strong>
              </p>

              <p>
                <strong>
                  IF YOU ARE ACCESSING THE WEBSITE ON BEHALF OF A THIRD PARTY,
                  YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH
                  THIRD-PARTY TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY
                  AND, IN SUCH AN EVENT YOUR USE OF THE WEBSITE SHALL REFER TO
                  USE BY SUCH THIRD PARTY. IF YOU DO NOT HAVE SUCH AN AUTHORITY
                  (TO PROVIDE ANY PERSONAL INFORMATION OF A THIRD PARTY) OR DO
                  NOT AGREE TO THE TERMS OF THIS PRIVACY POLICY, THEN YOU SHOULD
                  REFRAIN FROM USING THE WEBSITE.
                </strong>
              </p>

              <p>
                This Privacy Policy is an electronic record in the form of an
                electronic contract being compliant and construed in accordance
                with the data protection laws of various jurisdiction such as
                Indian Information Technology (Reasonable Security Practices and
                Procedures and Sensitive Personal Data or Information) Rules,
                2011 under Information Technology Act 2000 (“Privacy Rules”)
                that require publishing of privacy policy for collection, use,
                storage and transfer of sensitive personal data or information
                and The European Union (“EU”) general data protection laws (“the
                GDPR”).
              </p>

              <h2>INTRODUCTION</h2>

              <p>
                This Website showcases our services offerings (“Offerings”).
              </p>

              <h2>WHAT DATA DO WE COLLECT?</h2>

              <p>
                If you wish to get in touch with us through our ‘contact us’
                page or subscribe to our blogs and submit your details through
                our ‘career’ page on our Website you are required to provide us
                with your name, e-mail address and contact number. For the sake
                of brevity, the aforementioned information shall be referred to
                as “Personal Information”.
              </p>

              <p>
                We may also automatically collect certain information through
                cookies to improve our Website and Offerings, such as pattern of
                your use of the Website, our Offerings, visits, material that
                you viewed or searched for; page response times, download
                errors, length of visits to certain pages, page interaction
                information, IP address and date and time when you access or use
                website. Cookies are small encrypted ﬁles, that the Website
                transfers to the device through which you access our Website.
                For more information, please refer to our cookie policy (insert
                link of cookie policy)
              </p>

              <p>
                <strong>
                  Accuracy of information. Please make sure that any Personal
                  Information you share with us is accurate and up to date
                  information.
                </strong>
              </p>

              <h2>WHAT DO WE DO WITH YOUR DATA?</h2>

              <p>We use the Personal Information for the following purposes:</p>

              <ul>
                <li>
                  to inform you about our Offerings and to respond to your
                  requests;
                </li>
                <li>
                  for creation or development of business intelligence or data
                  analytics in relation to the Offerings provided by us (for
                  this purpose we may share the Personal Information with
                  certain software or tools available online)
                </li>
                <li>
                  to process job applications (if you apply for a position with
                  our organization);
                </li>
                <li>to manage our relationship with you;</li>
                <li>for internal record keeping; and</li>
                <li>to comply with our legal or statutory obligations.</li>
              </ul>

              <h2>WHO DO WE DISCLOSE YOUR DATA TO?</h2>

              <p>
                We do not sell, rent, share, distribute, lease or otherwise
                provide your Personal Information to third parties, without your
                prior consent. Keeping this in mind, we may disclose your
                Personal Information in the following cases:
              </p>

              <ul>
                <li>
                  Affiliates: We may provide your Personal Information to our
                  afﬁliates to enable them to improve the Offerings and provide
                  services related to the Offerings.
                </li>
                <li>
                  Service Providers: We may share your Personal Information with
                  the service providers who work with us in connection with
                  operating the Website and/ or providing the Offerings. All
                  such service provider are subject to stringent conﬁdentiality
                  restrictions consistent with this Privacy Policy.
                </li>
                <li>
                  Merger or Acquisition: We may transfer your Personal
                  Information if we are acquired by another entity, or if we
                  merge with another company or transfer a part of our business
                  to a third party, or in case of change in legal status of
                  entity, or change in management or change in stakeholders as
                  the case may be , including the Website, . Any such third
                  party or resultant entity that receives your Personal
                  Information shall have the right to continue to use your
                  Personal Information in line with the purposes set out herein.
                </li>
                <li>
                  Legal and Regulatory Authorities: We may disclose your
                  Personal Information to comply with our legal obligations/
                  court orders/ requests by Govt. authorities.
                </li>
              </ul>

              <h2>WHAT DO WE DO WITH YOUR DATA?</h2>

              <p>
                We will retain your Personal Information as long as it is
                required to be retained for the purpose of provision of the
                Offerings and to comply with our legal obligations. Subject to
                this section, we will delete your Personal Information upon
                reasonable written request for the same. Please note, however,
                that there might be latency in deleting Personal Information
                from our servers. For more information on where and how long
                your personal data is stored, and for more information on your
                rights of erasure and portability, please contact{" "}
                <a href="mailto:talk@hureo.com">talk@hureo.com</a>.
              </p>

              <h2>OUR SECURITY MEASURES</h2>

              <p>
                Your Personal Information is stored on third party cloud servers
                such as Google servers. Although we provide appropriate ﬁrewalls
                and protections, we cannot warrant the security of Personal
                Information transmitted as these systems are not hack proof.
                Data pilferage due to unauthorized hacking, virus attacks,
                technical issues is possible, and we assume no liability or
                responsibility for it. You are required to be careful to avoid
                “phishing” scams, where someone may send you an e-mail that
                looks like it is from us asking for your personal information.
              </p>

              <h2>INFORMATION FOR EU/EEA VISITORS</h2>

              <p>
                Applicability of GDPR: Residents of the European Union (“EU”)
                should note that this Privacy Policy has been updated in
                accordance with the requirements of the EU General Data
                Protection Regulation (the “GDPR”). As per the provisions of the
                GDPR we shall be considered the Controllers of the Personal
                information collected as a result of your use/access of the
                Website.
              </p>

              <p>
                Legal Basis: We will not process your Personal Information
                without a lawful basis to do so. We will process your Personal
                Information only on the legal bases of consent [as provided in
                Art. 6 (1) (a) of the GDPR], contract [as provided in Art. 6 (1)
                (b) of the GDPR], or based on our legitimate interests [as
                provided in Art. 6 (1) (f) of the GDPR], provided that such
                interests are not overridden by your privacy rights and
                interests.
              </p>

              <p>
                Transfer of Your Personal Information Across Borders: The
                Personal Information we collect (of EU and UK residents) might
                be processed outside the EU and UK at a secure center located in
                India. We collect and transfer Personal Information outside the
                EU in accordance with the GDPR and UK data protection laws. If
                you have questions, please contact. If you have questions,
                please contact us as at{" "}
                <a href="mailto:talk@hureo.com">talk@hureo.com</a>
              </p>

              <p>
                Rights of EU residents: You have the right to request us to let
                you know what Personal Information belonging to you do we hold
                in our possession, right to have us rectify or modify any such
                Personal Information, right to have us erase/delete your
                Personal Information, right to restrict us from processing such
                Personal Information, withdraw consent at any time where we are
                relying on consent to process your Personal Information, right
                to object to our use of your Personal Information, you have the
                right to lodge a complaint with a data protection authority. We
                may need to request speciﬁc information from you to help us
                conﬁrm your identity or also contact you for further information
                in relation to your request. If you would like to exercise ANY
                of these rights, please contact{" "}
                <a href="mailto:talk@hureo.com">talk@hureo.com</a>
              </p>

              <p>
                For any EU residents, this Privacy Policy shall be governed by
                the provisions of the GDPR.
              </p>

              <h2>YOUR RIGHTS</h2>

              <p>
                You have the right to access your Personal Information in our
                possession, right to have us rectify or modify any such Personal
                Information, right to have us erase/delete your Personal
                Information, right to restrict us from processing such Personal
                Information, right to object to our use of your Personal
                Information, withdraw consent at any time where we are relying
                on consent to process your Personal Information. We may need to
                request speciﬁc information from you to help us conﬁrm your
                identity or also contact you for further information in relation
                to your request. If you would like to exercise ANY of these
                rights, please contact{" "}
                <a href="mailto:talk@hureo.com">talk@hureo.com</a>
              </p>

              <h2>CHOICE AND OPT-OUT</h2>

              <p>
                We may send you communications including but not limited to (a)
                notices about your use of our Website and Offerings, including
                those concerning violations of use, (b) updates, (c) promotional
                information regarding our Offerings, and (d) newsletters. You
                may opt out of receiving promotional emails and newsletters from
                us by following the unsubscribe instructions provided in those
                emails. Alternatively, you can opt out, at any time, by emailing
                to <a href="mailto:talk@hureo.com">talk@hureo.com</a> with your
                speciﬁc request.
              </p>

              <h2>LINKS TO OTHER WEBSITES</h2>

              <p>
                Our Website may contain links to other websites of your
                interest. Please note that we do not have any control over such
                other websites, and you will be accessing these websites at your
                own risk. Therefore, we cannot be responsible for the protection
                and privacy of any information which you provide whilst visiting
                such websites and those are not governed by this Privacy Policy.
                You should exercise caution and look at the privacy policy
                applicable to such websites.
              </p>

              <h2>LIMITATION OF LIABILITY</h2>

              <p>
                To the extent permissible under the law, we shall not be liable
                for any direct, indirect, incidental, special, consequential, or
                exemplary damages, including but not limited to, damages for
                loss of proﬁts, goodwill, data, information, or other intangible
                losses (even if we have been advised of the possibility of such
                damages), arising out of this Privacy Policy.
              </p>

              <h2>GOVERNING LAWS AND DISPUTES</h2>

              <p>
                This Privacy Policy shall be construed and governed by the laws
                of India Any dispute arising, between you and us shall be
                submitted to the arbitration to be conducted in Pune, India in
                English language, in accordance with the rules of Arbitration
                and Conciliation Act of 1996, by a sole arbitrator, who shall be
                appointed by us, and the award made in pursuance thereof shall
                be binding on you and us. Subject to the arbitration provisions,
                you agree that the courts in Pune, India shall have an exclusive
                jurisdiction over such disputes.
              </p>

              <h2>CHANGES TO THIS POLICY</h2>

              <p>
                We may update this Privacy Policy from time to time and in case
                of any change in our privacy practices we shall notify you
                through email.
              </p>

              <p>This Privacy Policy was last modified on 11Nov2021</p>

              <h2>CONTACT US</h2>

              <p>
                If you have any questions or concerns or grievances regarding
                this Privacy Policy, you can contact our grievance officer at{" "}
                <a href="mailto:talk@hureo.com">talk@hureo.com</a>
              </p>

              <p>“I AGREE”</p>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    </>
  )
}

export default PrivacyPolicy
