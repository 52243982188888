import { Button, Checkbox, Input, Radio, Space } from "antd"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { updateAnswer, updateButtonState } from "store/studyform/actions"
import { object } from "prop-types"
import { validateFormFields } from "utils/utility"
import Select from "react-select"

const ReadOptionView = ({
  list,
  type,
  queIndex,
  secIndex,
  defaultValue,
  ansValue,
  disabled,
}) => {
  const dispatch = useDispatch()
  const [answer, setAnswer] = useState(ansValue !== "" ? ansValue : null)
  const [showProductDomainQuestion, setShowProductDomainQuestion] =
    useState(false)

  const selectFormState = (state) => state.studyForm

  const selectStudyFormProperties = createSelector(
    selectFormState,
    (formData) => ({
      currentForm: formData.form,
      currentQue: formData.currentQuestion,
      currentSec: formData.currentSection,
    }),
  )
  const { currentForm } = useSelector(selectStudyFormProperties)
  const handleRadioChange = (e) => {
    const selectedValue = e.target.value
    setAnswer(selectedValue)

    setShowProductDomainQuestion(selectedValue === "B2C")
  }

  const handleCheckboxes = (status, value) => {
    if (status) {
      if (answer) {
        setAnswer([...answer, value])
      } else {
        setAnswer([value])
      }
    } else {
      if (answer) {
        const newlist = answer.filter((val) => val != value)
        setAnswer(newlist)
      }
    }
  }
  const productDomainOptions = [
    { value: "Food Tech", label: "Food Tech" },
    { value: "Retail", label: "Retail" },
    { value: "Travel", label: "Travel" },
    { value: "Social Media", label: "Social Media" },
    { value: "Music", label: "Music" },
    { value: "Online Streaming Platform", label: "Online Streaming Platform" },
    { value: "Gaming", label: "Gaming" },
    { value: "Other", label: "Other" },
  ]

  useEffect(() => {
    const payload = {
      queIndex: queIndex,
      secIndex: secIndex,
      ansValue: answer,
      currentForm: currentForm,
    }
    dispatch(updateAnswer(payload))
    const isSubmittable = validateFormFields(currentForm)
    dispatch(updateButtonState(isSubmittable))
  }, [answer])

  // console.log('answer --->',answer)
  return (
    <>
      <div className="mt-0">
        <div className="table-responsive">
          {type === "text" && (
            <div>
              <input
                className="form-control mb-2"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder="short answer text..."
              />
            </div>
          )}

          {type === "mcq" && (
            <>
              <div className="radio-check  form-check-inline mb-3">
                <Radio.Group onChange={handleRadioChange} defaultValue={null}>
                  <Space direction="vertical">
                    {list.map((option, index) => {
                      return (
                        <>
                          <Radio value={option.value}>
                            {option.label ?? "NA"}
                          </Radio>
                        </>
                      )
                    })}
                  </Space>
                </Radio.Group>
              </div>
              &nbsp;
            </>
          )}
          {showProductDomainQuestion && (
            <>
              <div className="form-checkbox mb-0">
                <label htmlFor="productDomain">What is product domain?</label>
                <select
                  id="productDomain"
                  className="form-select"
                  onChange={(e) => setAnswer(e.target.value)}
                  value={answer} 
                  disabled={disabled}
                >
                  <option value="">Select Product Domain</option>
                  {productDomainOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              &nbsp;
            </>
          )}

          {type === "checkboxes" && (
            <>
              <div className="checkboxes row mb-2">
                {/* <Space direction="vertical"> */}
                {list.map((option) => {
                  return (
                    <>
                      <div className="col-md-6">
                        <div>
                          <Checkbox
                            className="checkbox-option mb-2"
                            // defaultChecked
                            style={{ fontWeight: "400" }}
                            onChange={(e) =>
                              handleCheckboxes(e.target.checked, option.value)
                            }
                          >
                            {option.label}
                          </Checkbox>
                        </div>
                      </div>
                    </>
                  )
                })}
                {/* </Space> */}
              </div>
            </>
          )}

          {type === "dropdown" && (
            <>
              <div className="form-checkbox mb-0">
                {/* <Select className="dropdown-content"
                  style={{ width: "100%", }}
                  onSelect={(val) => setAnswer(val)}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  options={list ?? []}
                ></Select> */}
                <select
                  className="form-select"
                  onChange={(val) => setAnswer(val.target.value)}
                >
                  {defaultValue && (
                    <option value={defaultValue.value}>
                      {defaultValue.label}
                    </option>
                  )}
                  {/* {!defaultValue && <option value="other">
                    Other
                  </option>} */}
                  {list.length > 0 &&
                    list.map((opt) => {
                      return (
                        <>
                          <option value={opt?.value ?? "NA"}>
                            {opt?.label ?? "na"}
                          </option>
                        </>
                      )
                    })}
                </select>
              </div>
              &nbsp;
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ReadOptionView
