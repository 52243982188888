// form
export const CREATE_FORM = "CREATE_FORM"
export const UPDATE_CURRENT_FORM = "UPDATE_CURRENT_FORM"
export const SAVE_STUDY_FORM = "SAVE_STUDY_FORM"
export const CHANGE_CURRENT_EDITABLE_FORM = "CHANGE_CURRENT_EDITABLE_FORM"
export const UPDATE_BUTTON_STATE = "UPDATE_BUTTON_STATE"

// sections
export const ADD_NEW_SECTION = "ADD_NEW_SECTION"
export const REMOVE_SECTION = "REMOVE_SECTION"
export const CHANGE_CURRENT_SECTION = "CHANGE_CURRENT_SECTION"
export const UPDATE_CURRENT_SECTION_DATA = "UPDATE_CURRENT_SECTION_DATA"

// questions
export const ADD_QUESTION = "ADD_QUESTION"
export const REMOVE_QUESTION = "REMOVE_QUESTION"
export const CHANGE_CURRENT_QUESTION = "CHANGE_CURRENT_QUESTION"
export const UPDATE_FIELDS_IN_CURRENT_QUESTION =
  "UPDATE_FIELDS_IN_CURRENT_QUESTION"
export const ADD_NEW_QUESTION_BLOCK = "ADD_NEW_QUESTION_BLOCK"
export const UPDATE_ANSWER = "UPDATE_ANSWER"
export const SEND_EMAIL = "SEND_EMAIL"
