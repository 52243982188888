import {
  VAR_FEATURE_1,
  VAR_FEATURE_2,
  VAR_FEATURE_3,
  VAR_METRICS,
  VAR_PRODUCT_CATEGORY,
  VAR_PRODUCT_LINK,
  VAR_PRODUCT_NAME,
  VAR_PRODUCT_TYPE,
  VAR_STUDY_NAME,
  VAR_STUDY_TYPE,
  VAR_USER_NAME,
  VAR_USER_ROLE,
  VAR_PARTICIPANT_COUNT,
  VAR_PARTICIPANT_AWARENESS,
} from "../constants/studyForm"
import _ from "lodash"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  GREEN,
  OTHER,
  PURPLE,
  USER_INTERVIEW,
  UT_FEATURE_TESTING,
  UT_ONBOARDING,
  YELLOW,
} from "../constants/dashboard"
import message from "../assets/images/message.svg"
import user from "../assets/images/user.svg"
import usability from "../assets/images/usability.svg"
import other from "../assets/images/other.svg"

export const validateFormFields = (form) => {
  let isSubmittable = true
  for (let i = 0; i < form.length; i++) {
    const section = form[i]
    if (section.questions) {
      for (let j = 0; j < section.questions.length; j++) {
        const question = section.questions[j]
        if (
          question.isRequired &&
          (question.ansValue === "" || !question.ansValue)
        ) {
          isSubmittable = false
        }
      }
    }
  }
  return isSubmittable
}

/**
 * React.Js
 * @param {Object} input - form data
 * }
 */

export const generateVariables = (formData) => {
  // if (!_.isArray(formData)) {
  //   return null
  // }

  return [
    {
      identifier: VAR_USER_ROLE,
      value: "", // You need to define where to get this value from in your formData object
      type: "text",
    },
    {
      identifier: VAR_USER_NAME,
      value: "", // You need to define where to get this value from in your formData object
      type: "text",
    },
    {
      identifier: VAR_STUDY_NAME,
      value: formData.studyName,
      type: "text",
    },
    {
      identifier: VAR_STUDY_TYPE,
      value: formData.studyType,
      type: "text",
    },
    {
      identifier: VAR_PRODUCT_CATEGORY,
      value: formData.productCategory,
      type: "text",
    },
    {
      identifier: VAR_PRODUCT_TYPE,
      value: formData.productType,
      type: "text",
    },
    {
      identifier: VAR_PRODUCT_NAME,
      value: formData.productName,
      type: "text",
    },
    {
      identifier: VAR_PRODUCT_LINK,
      value: formData.productLink,
      type: "text",
    },
    {
      identifier: VAR_METRICS,
      value: formData.metrics, // Assuming metrics is an array and needs to be joined as a comma-separated string
      type: "list",
    },
    {
      identifier: VAR_PARTICIPANT_AWARENESS,
      value: formData.participantAware, // Assuming participantAware is an array and needs to be joined as a comma-separated string
      type: "text",
    },
    {
      identifier: VAR_PARTICIPANT_COUNT,
      value: formData.participantCount,
      type: "text",
    },
  ]
}

export const generateFormData = (formData) => {
  let data = {}
  try {
    for (let section of formData) {
      for (let question of section.questions) {
        const keyName = question.name.slice(1)
        const wordList = keyName.split("_")
        if (wordList.length > 1) {
          const updatedKeyName =
            wordList[0] +
            wordList[1].charAt(0).toUpperCase() +
            wordList[1].slice(1) // won't work for multiple words
          data[updatedKeyName] = question.ansValue
        } else {
          const updatedKeyName = keyName
          data[updatedKeyName] = question.ansValue
        }
      }
    }
    return data
  } catch (error) {
    return data
  }
}

function showToast() {
  const ele = document.getElementsByName("toastType")
  const position = document.getElementsByName("positions")
  let toastType
  const title = document.getElementById("title").value
  let message = "Have fun storming the castle!"

  if (document.getElementById("message").value !== "")
    message = document.getElementById("message").value
}
// let message = "Have fun storming the castle!";

export const displayNotification = (type, message, duration) => {
  toastr.options = {
    positionClass: "toast-top-center",
    showDuration: 1000,
    progressBar: true,
    closeButton: true,
    timeOut: duration ?? 3000,
  }
  if (type === "success") toastr.success(message, "Success")
  else if (type === "warning") toastr.warning(message, "Warning")
  else if (type === "error") toastr.error(message, "Error")
  else if (type === "info") toastr.info(message, "Info")
}

export const getWidth = () => {
  const windowWidth = window.innerWidth
  return windowWidth * 0.9
}

export const getHeight = () => {
  const windowHeight = window.innerHeight
  return windowHeight * 0.6
}

export const formatTime = (isoString) => {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
    hour12: true,
  }
  const date = new Date(isoString)
  return date.toLocaleDateString("en-US", options).replace(/\//g, "-")
}

export const getWeekDifference = (isoString) => {
  const givenDate = new Date(isoString)
  const currentDate = new Date()
  const differenceInMilliseconds = currentDate - givenDate
  const millisecondsInWeek = 1000 * 60 * 60 * 24 * 7
  const differenceInWeeks = Math.floor(
    differenceInMilliseconds / millisecondsInWeek,
  )
  return differenceInWeeks
}

export const getImage = (studyType) => {
  if (studyType === USER_INTERVIEW) {
    return message
  } else if (studyType === UT_ONBOARDING) {
    return usability
  } else if (studyType === UT_FEATURE_TESTING) {
    return user
  } else {
    return other
  }
}

export const getColor = (studyType) => {
  switch (studyType) {
    case USER_INTERVIEW:
      return YELLOW
    case UT_ONBOARDING:
      return GREEN
    case UT_FEATURE_TESTING:
      return PURPLE
    default:
      return OTHER
  }
}

export const transformBubbleData = (data, type) => {
  let filteredData = []
  if (type === "Issue") {
    filteredData = data.filter((item) => item.type === "Issue")
  } else {
    filteredData = data.filter((item) => item.type === "Good Feature")
  }
  const cluster = { name: "AiData", children: [] }

  filteredData.forEach((item) => {
    const {
      category_level_1,
      category_level_2,
      category_level_3,
      no_of_instances,
    } = item

    let level1Node = cluster.children.find(
      (node) => node.name === category_level_1,
    )

    if (!level1Node) {
      level1Node = { name: category_level_1, children: [] }
      cluster.children.push(level1Node)
    }

    if (!category_level_2) {
      level1Node.value = (level1Node.value || 0) + no_of_instances * 1000
      if (level1Node.children.length === 0) {
        delete level1Node.children
      }
    } else {
      let level2Node = level1Node.children.find(
        (node) => node.name === category_level_2,
      )

      if (!level2Node) {
        level2Node = { name: category_level_2, children: [] }
        level1Node.children.push(level2Node)
      }

      if (!category_level_3) {
        level2Node.value = (level2Node.value || 0) + no_of_instances * 1000
        if (level2Node.children.length === 0) {
          delete level2Node.children
        }
      } else {
        let level3Node = level2Node.children.find(
          (node) => node.name === category_level_3,
        )

        if (!level3Node) {
          level3Node = { name: category_level_3 }
          level2Node.children.push(level3Node)
        }

        level3Node.value = (level3Node.value || 0) + no_of_instances * 1000
      }
    }
  })

  return cluster
}

export const transformDataForExport = (data) => {
  let prevCategoryLevel1 = ""
  let prevCategoryLevel2 = ""
  let sumInstances = 0
  const transformedData = data
    .sort((a, b) => {
      if (a.category_level_1 !== b.category_level_1) {
        return a.category_level_1.localeCompare(b.category_level_1)
      } else if (a.category_level_2 !== b.category_level_2) {
        return a.category_level_2.localeCompare(b.category_level_2)
      } else if (a.category_level_3 !== b.category_level_3) {
        return a.category_level_3.localeCompare(b.category_level_3)
      }
      return 0
    })
    .reduce((acc, node) => {
      let categoryLevel1 = node.category_level_1
      let categoryLevel2 = node.category_level_2
      let categoryLevel3 = node.category_level_3

      // If subsequent category_level_1, set it as empty string
      if (prevCategoryLevel1 === categoryLevel1) {
        categoryLevel1 = ""
      } else {
        // If category_level_1 changes, push total entry
        if (prevCategoryLevel1 !== "") {
          acc.push(
            {
              Category: prevCategoryLevel1 + " total",
              "Issue/Feature (1)": "",
              "Issue/Feature (2)": "",
              "SUM of No of Instances": sumInstances,
            },
            {
              Category: "",
              "Issue/Feature (1)": "",
              "Issue/Feature (2)": "",
              "SUM of No of Instances": "",
            },
          )
          sumInstances = 0 // Reset sum for new category_level_1
        }
        prevCategoryLevel1 = categoryLevel1
        prevCategoryLevel2 = ""
      }

      // If subsequent category_level_2 under same category_level_1, set it as empty string
      if (prevCategoryLevel2 === categoryLevel2) {
        categoryLevel2 = ""
      } else {
        prevCategoryLevel2 = categoryLevel2
      }

      // Add no_of_instances to the sum
      sumInstances += node.no_of_instances

      // Find existing entry
      const existingEntry = acc.find(
        (entry) =>
          entry.Category === categoryLevel1 &&
          entry["Issue/Feature (1)"] === categoryLevel2 &&
          entry["Issue/Feature (2)"] === categoryLevel3,
      )

      // If existing entry found, update its no_of_instances
      if (existingEntry) {
        existingEntry["SUM of No of Instances"] += node.no_of_instances
      } else {
        acc.push({
          Category: categoryLevel1,
          "Issue/Feature (1)": categoryLevel2 ? categoryLevel2 : "",
          "Issue/Feature (2)": categoryLevel3 ? categoryLevel3 : "",
          "SUM of No of Instances": node.no_of_instances,
        })
      }

      return acc
    }, [])

  // Add total entry for the last category_level_1 group
  if (prevCategoryLevel1 !== "") {
    transformedData.push({
      Category: prevCategoryLevel1 + " total",
      "Issue/Feature (1)": "",
      "Issue/Feature (2)": "",
      "SUM of No of Instances": sumInstances,
    })
  }

  return transformedData
}


