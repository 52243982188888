/* eslint-disable no-undef */
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFunctions } from "firebase/functions"
import { connectFunctionsEmulator } from 'firebase/functions';

const APIKEY = process.env.REACT_APP_APIKEY
const AUTHDOMAIN = process.env.REACT_APP_AUTHDOMAIN
const PROJECT_ID = process.env.REACT_APP_PROJECTID
const STORAGE_BUCKET = process.env.REACT_APP_STORAGEBUCKET
const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGINGSENDERID
const APP_ID = process.env.REACT_APP_APPID
const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENTID

const firebaseConfig = {
  apiKey: APIKEY,
  authDomain: AUTHDOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)

const functions = getFunctions(app);
// connectFunctionsEmulator(functions,"localhost",5002)
const auth = getAuth(app)

export { app, auth, firebaseConfig, functions }
