import {
  GET_GROUPS_SUCCESS,
  GET_CHATS_SUCCESS,
  GET_GROUPS_FAIL,
  GET_CHATS_FAIL,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  chats: [{"id": 1,
        "roomId": 1,
        "status": "intermediate",
        "name": "Prathmesh",
        "description":"This is a message",
        "time":"24 min",
        "isImg": false,
        "profile":"K"
      }],
  groups: [],
  contacts: [],
  messages: [{
    "id": 1,
    "roomId": 1,
    "sender": "Steven Franklin",
    "message": "Hello!",
    "usermessages": [
        {
            "id": 1,
            "to_id": 1,
            "msg": "Good morning 😊",
            "time": "10:00",
            "isImages": false
        },
        {
            "id": 2,
            "to_id": 2,
            "msg": "Hi, How are you? What about our next meeting?",
            "isImages": false,
            "time": "10:02"
        },
        {
            "id": 3,
            "to_id": 1,
            "msg": "Yeah everything is fine",
            "isImages": false,
            "time": "10:06"
        },
        {
            "id": 4,
            "to_id": 2,
            "msg": "& Next meeting tomorrow 10.00AM",
            "isImages": false,
            "time": "10:06"
        },
        {
            "id": 5,
            "to_id": 1,
            "msg": "Wow that's great",
            "isImages": false,
            "time": "10:07"
        }
    ]
}],
  error: {},
  loading: true
}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload,
        loading: true
      }

    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      }

    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
      }

    case GET_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
        loading: true
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.map((item) => ({
          ...item,
          usermessages: [...item.usermessages, action.payload]
        }))
      }
    case POST_ADD_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.map((item) => ({
          ...item,
          usermessages: item.usermessages.filter(data => data.id !== action.payload)
        }))
      };
    case DELETE_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state
  }
}

export default Chat
