const { SET_INITIAL_TEMPLATE, UPDATE_TEMPLATE } = require("./actionTypes")

const INIT_STATE = {
  template: [],
  formId: null,
  studyName: "",
  studyType: "",
  templateId: null,
  currentSection: null,
  lastSaveTime: Date.now(),
}

const Template = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_INITIAL_TEMPLATE:
      return {
        ...state,
        template: action.payload.template,
        formId: action.payload.formId,
        studyName: action.payload.studyName,
        studyType: action.payload.studyType,
        templateId: action.payload.templateId,
        currentSectionIndex: action.payload.currentSectionIndex,
      }
    case UPDATE_TEMPLATE:
      return {
        ...state,
        template: action.payload.template,
        currentSectionIndex: action.payload.currentSectionIndex,
      }
    default:
      return state
  }
}

export default Template
