import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
import Pages404 from "../utils/pages-404"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import LandingPage from "../pages/Authentication/LandingPage"
import StudyForm from "../pages/StudyForm/index"
import Template from "pages/Template"
import ReadOnlyTemplateView from "pages/Template/ReadOnlyTemplateView"
import DisplayTemplate from "pages/Template/DisplayTemplate"
import GeneratedReport from "../pages/Template/GeneratedReport"
import MeetComponent from "pages/Template/MeetComponent"
import Register from "pages/Authentication/Register"
import ZoomRedirectPage from "pages/zoomRedirectPage"
import VideoWithTranscript from "pages/Template/VideoWithTranscript"
import PrivacyPolicy from "pages/Authentication/landingPgaeComponents/PrivacyPolicy"
import Documentation from "pages/Authentication/landingPgaeComponents/Documentation"
import TermsCondition from "pages/Authentication/landingPgaeComponents/TermsCondition"
import DefaultForm from "pages/StudyForm/defaultForm"
import OnboardingForm from "pages/StudyForm/OnboardingForm"
import UserInterviewForm from "pages/StudyForm/UserInterviewForm"
import FeatureForm from "pages/StudyForm/FeatureForm"
import FormResponse from "pages/Template/FormResponse"
import BlogDetails from "pages/Authentication/BlogDetails"
import KeyChallenges from "pages/Template/KeyChallenges"
import KeySentiments from "pages/Template/KeySentiments"
import FileUpload from "pages/Study/FileUpload"
import DisplayNotes from "pages/DynamicForm/DisplayNotes"
import StudyOverview from "pages/Study/StudyDetails"
import DisplayScript from "pages/Study/DisplayScript"
import MeetingNotes from "pages/Study/MeetingNotes"
import JoinMeetComponent from "pages/Meetings/JoinMeetComponent"
import Script from "pages/Meetings/Script"
// import Test from "pages/Study/test"
import Test from "pages/test"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/test", component: <Test /> },
  { path: "/studyform", component: <StudyForm /> },
  { path: "/template", component: <Template /> },
  { path: "/study/:studyId", component: <StudyOverview /> },
  {
    path: "/study/:studyId/:meetingId/form-response",
    component: <MeetingNotes />,
  },
  { path: "/study/:studyId/report", component: <GeneratedReport /> },
  { path: "/study/:studyId/script", component: <DisplayScript /> },
  { path: "/study/:studyId/:templateFormId/notes", component: <DisplayNotes /> },
  { path: "/study/:studyId/:meetingId/file-upload", component: <FileUpload /> },
  // { path: "/study/:studyId/:templateFormId/file-upload", component: <FileUpload /> },
  {
    path: "/study/:studyId/:templateId/:templateFormId",
    component: <ReadOnlyTemplateView />,
  },
  {
    path: "/study/:studyId/:templateId/:templateFormId/view",
    component: <DisplayTemplate />,
  },
  {
    path: "/study/:studyId/:templateId/:templateFormId/report",
    component: <GeneratedReport />,
  },
  {
    path: "/study/:studyId/:templateId/:templateFormId/report/keychallenges",
    component: <KeyChallenges />,
  },
  {
    path: "/study/:studyId/:templateId/:templateFormId/report/keysentiments",
    component: <KeySentiments />,
  },
  {
    path: "/study/:studyId/report/keychallenges",
    component: <KeyChallenges />,
  },
  {
    path: "/study/:studyId/report/keysentiments",
    component: <KeySentiments />,
  },
  { path: "/video", component: <VideoWithTranscript /> },
  { path: "/default", component: <DefaultForm /> },
  { path: "/onboarding", component: <OnboardingForm /> },
  { path: "/user-interview", component: <UserInterviewForm /> },
  { path: "/feature", component: <FeatureForm /> },

  {
    path: "/study/:studyId/:templateId/:templateFormId/:meetingId/form-response",
    component: <FormResponse />,
  },
  {
    path: "/study/:studyId/:templateId/:templateFormId/notes",
    component: <DisplayNotes />,
  },

  {
    path: "/study/:studyId/:meetingId/recording",
    component: <VideoWithTranscript />,
  },
  { path: "/profile", component: <UserProfile /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
    path: "/studyform",
    exact: true,
    component: <Navigate to="/studyform" />,
  },
  { path: "/redirect", component: <ZoomRedirectPage /> },
  // { path: "/test", component: <Test /> },
]

const authProtectedRoutesEmptyLayout = [
  {
    path: "/study/:studyId/:templateId/:templateFormId/meet/:meetingId",
    component: <MeetComponent />,
  },
  // {
  //   path: "/study/:studyId/:templateFormId/meet/:meetingId",
  //   component: <JoinMeetComponent />,
  // },
  {
    path: "/study/:studyId/:templateFormId/meet/:meetingId",
    component: <Script />,
  },
]
const publicRoutes = [
  { path: "/", component: <LandingPage /> },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "*", component: <Pages404 /> },
  { path: "/documentation", component: <Documentation /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-and-condition", component: <TermsCondition /> },
  { path: "/blog", component: <BlogDetails /> },
]

export { authProtectedRoutes, publicRoutes, authProtectedRoutesEmptyLayout }
