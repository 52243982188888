import React, { createContext, useState, useEffect } from "react"
import "firebase/auth"
import { auth } from "config/firebase"
import { getIdToken, onAuthStateChanged } from "firebase/auth"
import { useDispatch } from "react-redux"
import { setCurrentUserData } from "store/auth/authState/action"


export const FirebaseAuthContext = createContext()

export const FirebaseAuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        const idToken = getIdToken(user)
        dispatch(setCurrentUserData({ data: { ...user, idToken: idToken }, isLoggedIn: true }))
      } else {
        setUser(user)
        dispatch(setCurrentUserData({ data: null, isLoggedIn: false }))
      }
    })

    return () => unsubscribe()
  }, [])

  return <FirebaseAuthContext.Provider value={{ user }}>{children}</FirebaseAuthContext.Provider>
}
