import React, { useEffect, useState } from "react"
import {
  Row,
  Container,
  Card,
  Col,
  TabPane,
  TabContent,
  NavItem,
  CardBody,
  Button,
  Spinner,
  Nav,
} from "reactstrap"
import LexicalEditorWrapper from "../../components/Pages/Template/LexicalEditorWrapper"
import {
  Link,
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom"
import { getFunctions, httpsCallable } from "firebase/functions"
import "./template.scss"
import "toastr/build/toastr.min.css"
import { displayNotification } from "utils/utility"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import classnames from "classnames"
import DynamicForm from "../DynamicFormTest/form"
import {
  updateTemplateForm,
} from "store/dynamicForm/actions"
import "../DynamicFormTest/form.scss"
import { onAuthStateChanged } from "firebase/auth"
import { saveStudyDetails } from "store/study/action"
import { auth } from "config/firebase"
import FirebaseService from "services/firebaseServices"
import { Empty, Alert, Skeleton } from "antd"

const DisplayScript = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { studyId } = useParams()

  const [currentStep, setCurrentStep] = useState(0)
  const [formCurrentStep, setFormCurrentStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [isSaving, setIsSaving] = useState(false)

  const SavingState = Object.freeze({
    NOT_SAVED: 0,
    SAVING: 1,
    SAVED: 2,
  })

  const handleSaving = (status) => {
    setIsSaving(status)
  }

  const CurrentStudyState = (state) => state.CurrentStudy
  const CurrentStudyProps = createSelector(CurrentStudyState, (state) => ({
    studyId: state.studyId,
    studyDetails: state.studyDetails,
    isFetching: state.isFetching,
  }))
  const { studyDetails, isFetching } = useSelector(CurrentStudyProps)
  const { template, templateForm, templateId, templateFormId } =
  studyDetails ?? {}

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleSubmission = async () => {
    try {
      const functions = getFunctions()
      const saveTemplate = httpsCallable(functions, "saveTemplate")
      const data = {
        template: template,
        formId: studyId,
        templateId: templateId,
      }
      setIsLoading(true)
      const result = await saveTemplate(data)
      if (result.data.success) {
        displayNotification("success", result.data.message)
      }
      setIsLoading(false)
      navigate("/dashboard")
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error?.message ?? "Something went wrong!")
      console.log("error", error)
    }
    // navigate("/dashboard")
  }

  const handleFormSubmission = async (updatedData) => {
    // console.log("DisplayScript.js (Parent) (handleFormSubmission) : Calling save script API to save the updated script. It should be an array ==", "Form Id == ", studyId, "Script Id ==",templateId, " Updated Script ===", updatedData)
    try {
      const functions = getFunctions()
      const saveTemplateForm = httpsCallable(functions, "saveTemplateForm")
      const data = {
        templateForm: updatedData,
        formId: studyId,
        templateFormId: templateFormId,
      }
      const result = await saveTemplateForm(data)
      console.log('result: ', result);
      if (result.data.success) {
        handleSaving(SavingState.SAVED)
      } else {
        console.log("result : ", result)
        displayNotification("error", result.data.message ?? "Failed to save changes")
        handleSaving(SavingState.NOT_SAVED)
      }
      dispatch(
        updateTemplateForm({
          data: updatedData,
        }),
      )
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error?.message ?? "Something went wrong!")
      handleSaving(SavingState.NOT_SAVED)
      console.log("error", error)
    }
  }

  const lexicalSteps = Array.isArray(template)
    ? template.map((temp, index) => ({ title: temp.title }))
    : []

  const dynamicFormSteps = Array.isArray(templateForm)
    ? templateForm.map((temp, index) => ({ title: temp.title }))
    : []

  const fetchStudyDetails = async () => {
    try {
      const response = await FirebaseService.fetchStudyDetails(studyId)
      const { success, data } = response
      if (success) {
        dispatch(
          saveStudyDetails({
            studyId: studyId,
            studyDetails: data,
            isFetching: false,
          }),
        )
      } else {
        dispatch(
          saveStudyDetails({
            studyId: studyId,
            studyDetails: {
              template: [],
              templateForm: [],
              templateId: "",
              templateFormId: "",
            },
            isFetching: false,
          }),
        )
        console.log("ELSE CONDITION : ", response)
        displayNotification("error", "Failed to load script")
      }
    } catch (error) {
      console.log("CATCH ERR : ", error)
      dispatch(
        saveStudyDetails({
          studyId: studyId,
          studyDetails: {
            template: [],
            templateForm: [],
            templateId: "",
            templateFormId: "",
          },
          isFetching: false,
        }),
      )
      displayNotification("error", "Failed to load script")
    }
  }

  const navigateToBackPage = () => {
    navigate(-1)
  }

  useEffect(() => {
    ;(async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          dispatch(saveStudyDetails({ isFetching: true }))
          fetchStudyDetails()
        } else {
          setIsLoading(false)
          console.log("Something went wrong while fetching data!")
        }
      })
    })()
  }, [])

  return (
    <>
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            {!isLoading && (
              <Link
                onClick={navigateToBackPage}
                className="mb-1 d-sm-flex align-items-center justify-content-between"
              >
                {!isFetching && (
                  <h4 className="mb-sm-0 text-black p-2 font-size-18">
                    {studyDetails?.studyName ?? ""} :{" "}
                    {studyDetails?.fieldValues?.studyType ?? ""}
                  </h4>
                )}
                {isFetching && <Skeleton.Input active size="small" />}
              </Link>
            )}

            <Nav tabs className="d-flex justify-content-center">
              <NavItem className="mx-3">
                <NavLink
                  className={`nav-link ${activeTab === "1" ? "active" : ""}`}
                  style={{
                    backgroundColor: activeTab === "1" ? "#dde2fa" : "",
                  }}
                  onClick={() => toggle("1")}
                >
                  Template
                </NavLink>
              </NavItem>
              <NavItem className="mx-3">
                <NavLink
                  className={`nav-link ${activeTab === "2" ? "active" : ""}`}
                  style={{
                    backgroundColor: activeTab === "2" ? "#dde2fa" : "",
                  }}
                  onClick={() => toggle("2")}
                >
                  Form Builder
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" className="mt-3">
                <Row>
                  <Col sm="12">
                    {!isFetching && (
                      <Row>
                        {template && template.length !== 0 && (
                          <Col lg="12">
                            <Card className="form-wizard-wrapper">
                              <CardBody>
                                <div className="vertical-wizard wizard clearfix vertical">
                                  <div
                                    className="steps clearfix"
                                    style={{ marginTop: "50px" }}
                                  >
                                    <ul>
                                      {lexicalSteps &&
                                        lexicalSteps.map((step, index) => (
                                          <NavItem
                                            key={index}
                                            className={classnames({
                                              current: currentStep === index,
                                            })}
                                          >
                                            <NavLink
                                              className={classnames({
                                                active: currentStep === index,
                                                done: currentStep > index,
                                              })}
                                              onClick={() =>
                                                setCurrentStep(index)
                                              }
                                              disabled={currentStep < index}
                                            >
                                              <span className="number">
                                                {index + 1}.
                                              </span>{" "}
                                              {step.title}
                                            </NavLink>
                                          </NavItem>
                                        ))}
                                    </ul>
                                  </div>
                                  <div className="content clearfix">
                                    <TabContent
                                      activeTab={currentStep + 1}
                                      className="body"
                                    >
                                      {template &&
                                        template.length !== 0 &&
                                        template.map((temp, index) => {
                                          return (
                                            <TabPane
                                              key={index}
                                              tabId={index + 1}
                                            >
                                              {currentStep === index && (
                                                <div
                                                  className="lexicaltemplate"
                                                  style={{
                                                    border:
                                                      "1px solid lightgray",
                                                  }}
                                                >
                                                  <Card className="templatecard">
                                                    <LexicalEditorWrapper
                                                      // isEditable={template[currentStep].isEditable ?? true}
                                                      initialData={JSON.stringify(
                                                        template[currentStep]
                                                          .editorContent,
                                                      )}
                                                      sectionIndex={currentStep}
                                                      template={template}
                                                      showTopBar={true}
                                                    />
                                                  </Card>
                                                </div>
                                              )}
                                            </TabPane>
                                          )
                                        })}
                                    </TabContent>
                                  </div>
                                  <div className="actions clearfix">
                                    <ul>
                                      <li
                                        className={
                                          currentStep === 0
                                            ? "previous disabled"
                                            : "previous"
                                        }
                                      >
                                        {currentStep > 0 && (
                                          <div className="d-flex flex-wrap gap-2">
                                            <Button
                                              color="primary"
                                              outline
                                              onClick={() =>
                                                setCurrentStep(currentStep - 1)
                                              }
                                            >
                                              Previous
                                            </Button>
                                          </div>
                                        )}
                                      </li>
                                      <li>
                                        {currentStep ===
                                        lexicalSteps.length - 1 ? (
                                          <Button
                                            className={
                                              isLoading ? "disabled" : ""
                                            }
                                            loading={isLoading}
                                            onClick={handleSubmission}
                                            color="primary"
                                            disabled={isLoading}
                                            style={{
                                              cursor: isLoading
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                          >
                                            {isLoading ? (
                                              <>
                                                <Spinner
                                                  size="sm"
                                                  color="light"
                                                  className="mr-2"
                                                />{" "}
                                                Loading...
                                              </>
                                            ) : (
                                              "Save"
                                            )}
                                          </Button>
                                        ) : (
                                          <div className="d-flex flex-wrap gap-2">
                                            <Button
                                              color="primary"
                                              className="btn btn-primary waves-effect waves-light"
                                              onClick={() =>
                                                setCurrentStep(currentStep + 1)
                                              }
                                            >
                                              Next
                                            </Button>
                                          </div>
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )}

                        {template && template.length === 0 && (
                          <Empty description={<span>Script not found</span>} />
                        )}

                      </Row>
                    )}
                    {!isFetching && !template && (
                      <Alert  
                        message="Error"
                        description="Failed to load script"
                        type="error"
                        showIcon
                      />
                    )}
                    {isFetching && <Skeleton active size="small" />}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" className="mt-3">
                <Row>
                  <Col sm="12">
                    {!isFetching && Array.isArray(templateForm) && templateForm.length > 0 && (
                      <DynamicForm
                        studyDetails={studyDetails}
                        script={templateForm}
                        wizardSteps={dynamicFormSteps}
                        setStep={setFormCurrentStep}
                        step={formCurrentStep}
                        saveScript={handleFormSubmission}
                        isApiFetching={isLoading}
                        studyId={studyId}
                        scriptId={templateFormId}
                        SavingState={SavingState}
                        isSaving={isSaving}
                        handleSaving={handleSaving}
                      />
                    )}
                    {isFetching && <Skeleton active size="small" />}
                    {!isFetching &&
                      Array.isArray(templateForm) &&
                      templateForm.length === 0 && (
                        <Empty description={<span>Script not found</span>} />
                      )}
                    {!isFetching &&
                      !templateForm && (
                        <Alert  
                          message="Error"
                          description="Failed to load script"
                          type="error"
                          showIcon
                        />
                      )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </React.Fragment>
    </>
  )
}
export default DisplayScript
