import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { updateChat } from "store/chatWindow/actions"

const useAutoSaveChatBot = (
  dataToSave,
  list,
  name,
  title,
  sectionIndex,
  onSave,
) => {
  const dispatch = useDispatch()
  const prevDataToSaveRef = useRef(null)

  useEffect(() => {
    // Check if the dataToSave is defined and not null
    if (dataToSave && dataToSave !== prevDataToSaveRef.current) {
      const saveData = async () => {
        try {
          const newList = [...list]
          newList[sectionIndex] = { name: name, title: title, root: dataToSave }
          dispatch(
            updateChat({
              data: newList,
            }),
          )

          await onSave(dataToSave)
        } catch (error) {
          console.error("Error auto-saving data:", error)
        }
      }

      // Call the saveData function after a certain interval (every 5 seconds)
      const saveInterval = setInterval(saveData, 15000) // 5000ms (5 seconds)

      return () => clearInterval(saveInterval)
    }
  }, [dataToSave, onSave])

  // Update the reference to the previous data after each render
  useEffect(() => {
    prevDataToSaveRef.current = dataToSave
  }, [dataToSave])
}

export default useAutoSaveChatBot
