import React from 'react'
import { Card, CardBody } from 'reactstrap'

const TermsCondition = () => {
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Card>
            <CardBody>
            <h1>Terms and Conditions</h1>
    <p>These Terms and Conditions (“Agreement”) govern the use of the services (“Service”) that are made available by Hureo.com (“Website”, “we” or “us”). These Terms and Conditions represent the whole agreement and understanding between Hureo.com and the individual or entity who visits our website (“Visitor” or “you”).</p>
    <p>This page states the Terms and Conditions under which you (Visitor) may visit this website (“Website”). Please read this page carefully. If you do not accept the Terms and Conditions stated here, we would request you to exit this site. The business, any of its business divisions and / or its subsidiaries, associate companies or subsidiaries to subsidiaries or such other investment companies (in India or abroad) reserve their respective rights to revise these Terms and Conditions at any time by updating this posting. You should visit this page periodically to re-appraise yourself of the Terms and Conditions, because they are binding on all visitors of this Website.</p>

    <h2>Use Of Content</h2>
    <p>All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combinations thereof, appearing in this site, except as otherwise noted, are properties either owned, or used under licence, by the business and / or its associate entities who feature on this Website. The use of these properties or any other content on this site, except as provided in these terms and conditions or in the site content, is strictly prohibited.</p>
    <p>You may not sell or modify the content of this Website or reproduce, display, publicly perform, distribute, or otherwise use the materials in any way for any public or commercial purpose without the respective organisation’s or entity’s written permission.</p>
    <p>The website grants you limited rights to access and make personal use of this website, but not to download (other than page caching) or modify it, or any portion of it. These rights do not include any commercial use of this website or its contents; any collection and use of any content, descriptions, or prices; any derivative use of this website or its contents; any downloading or copying of account information for the benefit of a third-party; or any use of data mining, robots, or similar data gathering and extraction tools.</p>
    <p>This website or any portion of this website (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose.</p>

    <h2>Acceptable Website Use</h2>
    <p>You must not use the Website in any way that causes, or is likely to cause, the Website or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not Company, are responsible for all electronic communications and content sent from your computer to us and you must use the Website for lawful purposes only. You must not use the Website for any of the following:</p>
    <ul>
        <li>For Fraudulent Purposes, Or In Connection With A Criminal Offense Or Other Unlawful Activity;</li>
        <li>To Send, Use Or Reuse Any Material That Does Not Belong To You; Or Is Illegal, Offensive (Including But Not Limited To Material That Is Sexually Explicit Content Or Which Promotes Racism, Bigotry, Hatred Or Physical Harm), Deceptive, Misleading, Abusive,  Indecent,  Harassing,  Blasphemous,  Defamatory,  Libelous,  Obscene, Pornographic, Pedophilic Or Menacing; Ethnically Objectionable, Disparaging Or In Breach Of Copyright, Trademark, Confidentiality, Privacy Or Any Other Proprietary Information Or Right; Or Is Otherwise Injurious To Third Parties; Or Relates To Or Promotes Money Laundering Or Gambling; Or Is Harmful To Minors In Any Way; Or Impersonates Another Person; Or Threatens The Unity, Integrity, Security Or Sovereignty Of India Or Friendly Relations With Foreign States; Or Objectionable Or Otherwise Unlawful In Any Manner Whatsoever; Or Which Consists Of Or Contains Software Viruses, Political Campaigning, Commercial Solicitation, Chain Letters, Mass Mailings Or Any “Spam”; Or Violates Any Law For The Time Being In Force; Or Deceives Or Misleads The Addressee About The Origin Of Such Messages Or Communicates Any Information Which Is Grossly Offensive Or Menacing In Nature;</li>
        <li>To Not Make Any Unauthorized Commercial Use Of This Website Or Service And You May Not Resell This Service Or Assign Your Rights Or Obligations Under These Terms Of Usage. You May Not Reverse Engineer Any Part Of This Website Or Service.</li>
        <li>For Any Act That Causes, Or May Be Likely To Cause Damage Or Impairment To The Website Or In Any Manner Harms The Company Or Any Other Person Or Entity (As Determined By The Company In Its Sole Discretion) Or Interrupt Free Access To It In Any Way.</li>
    </ul>
    <h2>Disclaimer Of Warranties And Liabilities</h2>
    <p>We expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products listed or displayed or the content (including product information and/or specifications) on the Website.</p>

    <h2>Indemnity & Liability</h2>
    <p>The Visitor shall indemnify and hold harmless Company, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney’s fees, made by any third party or penalty imposed due to or arising out of the Visitor’s breach of these Conditions of Use or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.</p>
    <p>The Visitor shall hereby expressly release Company and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the vendors and specifically waiver any claims or demands that they may have in this behalf under any statute, contract or otherwise.</p>

    <h2>GDPR Compliance</h2>
    <p>On May 25, 2018, the European Union (the “EU”) implemented a new data privacy and protection regulation, called the General Data Protection Regulation (the “GDPR”). This regulation aims to standardize data protection laws across the EU and also lay down standards to be observed worldwide while processing of personal data originating in the EU; The GDPR also has a strong emphasis on affording individuals stronger, more consistent rights to access and control their personal information.</p>
    <h3>Hureo GDPR Compliance</h3>
    <p>Hureo assist its clients by providing various services which enable clients to obtain information about their products from an end users, prospective users perspective. Pursuant to its services offerings, Hureo conducts various studies with respect to usability of a product, the challenges end users may face using the products. Hureo undertakes activities related to product evaluation, conducts interviews of end user or groups of individuals to enable Hureo’s clients to strategise their product offerings.</p>
    <p>Our services and website are used and explored by our clients (existing and prospective), we at Hureo process certain amount of personal information of our clients (existing and prospective) in the capacity of a Data Controller (for any personal information submitted on the website) and in the capacity of a Data Processor (for any personal information provided by our clients when they use our services).</p>
    <h3>Risk Assessment</h3>
    <p>We have performed a company-wide information discovery exercise to identify and assess what personal information we hold, where it comes from, how and why it is processed, and to whom it is disclosed.</p>
    <h3>Data Subject Consent</h3>
    <p>As a Data Controller, Hureo has updated its Privacy Policies, Cookies Policy and Disclaimer for the usage of the Cookies in as per the requirements of GDPR on its website <a href="http://www.hureo.com">http://www.hureo.com</a> and it requires all the visitors, users of its website to provide consent for use of personal information. Hureo also allows such users to exercise their rights in relation to their personal information such as modification, rectification, deletion of their data.</p>
    <p>As a Data Controller we execute contracts required under the GDPR with our data processor to ensure that the data processor process the personal information as per the GDPR. Additionally, we implement technical and organizational security measures to ensure compliances.</p>
    <p>As a Data Processor we comply with the required safeguards pertaining to safety of personal information received from our clients.</p>
    <h3>Data Subject Rights & Transfer of Data Outside EU</h3>
    <p>Hureo has in place an article 28 GDPR-compliant data processing addendum including the EU Model Clauses to ensure an appropriate legal basis for data transfers outside the EU.</p>
    <h3>Data Retention & Erasure</h3>
    <p>We have formulated an internal data protection compliance policy that includes the data retention schedule to ensure that we comply with the ‘data minimization’ and ‘storage limitation’ and other such principles under the GDPR and that personal information is stored, archived, and destroyed in accordance with the GDPR.</p>
    <h3>Record Keeping as per the GDPR</h3>
    <p>According to Article 30 of the GDPR, each processor and controller’s representative needs to maintain a record of all activities pertaining to the processing of personal information in such an organization. Hureo maintains such records as required under Article 30(1) and Article 30(2) of the GDPR</p>
    <h3>Data Breach and Mitigation Process</h3>
    <p>The GDPR has stipulated measures and notifications that must be made upon discovery of a data security breach. Hureo has put in place internal measures to minimize the risk of any data security breach happening. However, in the unlikely event of any such breach happening, Hureo intends to honour its responsibilities as laid down under the GDPR, which includes notifying in a timely manner, its customers, and the supervisory authorities.</p>
    <h3>Hureo Promise on GDPR</h3>
    <p>At Hureo, maintaining the security, integrity, safety and confidentiality of personal data in our possession is of the highest priority. Hureo has already taken adequate measures to ensure that we fulfil our promise of being fully compliant with GDPR. In case you have any queries, please feel free to reach us at <a href="mailto:talk@hureo.com">talk@hureo.com</a>.</p>
    <h2>User Confidentiality Statement</h2>
    <p>All responses given by you, including any personal information you provide, will be kept strictly confidential. Your input will only be used in combination with the responses of others participating in the survey to help us recruit the appropriate candidate for the study. Your individual responses are not shown to anyone except it will be shared with the employees working on this project as we are conducting this research in collaboration with them. We also ask for your location, name, email address, and phone number in order to contact you regarding the study.</p>
    <p>Hureo follows the strictest guidelines in terms of respondent confidentiality and your information will not be distributed to third parties. If you have additional questions about our Privacy and Confidentiality Statement, please contact us at <a href="mailto:talk@hureo.com">talk@hureo.com</a>.</p>
    <p>This survey is powered by Google Forms, which means Google’s use of your information is governed by their <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a>.</p>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    </>
  )
}

export default TermsCondition