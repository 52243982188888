import React, { useEffect, useState } from "react"
import {
  Row,
  Container,
  Card,
  Col,
  TabPane,
  TabContent,
  NavItem,
  CardBody,
  Button,
  Spinner,
  Nav,
} from "reactstrap"
import LexicalEditorWrapper from "../../components/Pages/Template/LexicalEditorWrapper"
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { getFunctions, httpsCallable } from "firebase/functions"
import "./template.scss"
import { Popover, Skeleton, Steps } from "antd"
import "toastr/build/toastr.min.css"
import { displayNotification } from "utils/utility"
import { useDispatch } from "react-redux"
import { setInitialTemplate } from "store/template/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import classnames from "classnames"
import { USER_INTERVIEW } from "constants/dashboard"
import DynamicForm from "../DynamicForm/form"
import {
  setInitialTemplateForm,
  updateTemplateForm,
  updateTemplateFormId,
} from "store/dynamicForm/actions"

import "../DynamicForm/form.scss"
const DisplayTemplate = () => {
  const navigate = useNavigate()
  const { studyId, templateId, templateFormId } = useParams()
  const [currentStep, setCurrentStep] = useState(0)
  const [studyDetails, setStudyDetails] = useState({})
  const [curTemp, setCurTemp] = useState([])
  const [formCurrentStep, setFormCurrentStep] = useState(0)
  const [formCurTemp, setFormCurTemp] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isClicked, setISClicked] = useState(false)
  const [formTemplates, setFormTemplates] = useState([])

  const { state } = useLocation()
  const { formTitle } = state || {}
  const dispatch = useDispatch()
  const selectTemplateState = (state) => state.Template
  const selectFormTemplateState = (state) => state.TemplateForm
  const selectTemplateProperties = createSelector(
    selectTemplateState,
    (tempData) => ({
      template: tempData.template,
      formId: tempData.formId,
      currentSectionIndex: tempData.currentSectionIndex,
    }),
  )
  const { template, formId, studyName, currentSectionIndex } = useSelector(
    selectTemplateProperties,
  )
  const selectFormTemplateProperties = createSelector(
    selectFormTemplateState,
    (tempData) => ({
      templateForm: tempData.templateForm,

      currentSectionIndex: tempData.currentSectionIndex,
    }),
  )

  const { templateForm } = useSelector(selectFormTemplateProperties)
  const [activeTab, setActiveTab] = useState("1")

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const fetchTemplateDetails = async (studyData) => {
    try {
      setIsLoading(true)
      const functions = getFunctions()
      const getTemplate = httpsCallable(functions, "getTemplate")
      const result = await getTemplate({
        templateId: templateId,
        formId: studyId,
        isEditable: true,
      })
      if (result.data.success) {
        if (studyData.studyType === USER_INTERVIEW) {
          let currentTemp = result.data?.data?.template.filter(
            (section) => section.name !== "task",
          )
          setCurTemp(currentTemp)
        } else {
          setCurTemp(result.data?.data?.template ?? [])
        }
      }
      dispatch(
        setInitialTemplate({
          template: result.data?.data.template ?? [], // array
          formId: result.data?.data?.formId ?? "",
          templateId: result.data?.data?.templateId ?? "",
          studyName: formTitle,
          studyType: studyDetails?.studyType,
          currentSectionIndex: 0,
        }),
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error.message ?? "something went wrong")
      console.log("error", error)
    }
  }

  const fetchFormTemplateDetails = async (studyData) => {
    try {
      setIsLoading(true)
      const functions = getFunctions()
      const getTemplateForm = httpsCallable(functions, "getTemplateForm")
      const result = await getTemplateForm({
        templateFormId: templateFormId,
        formId: studyId,
      })
      if (result.data.success) {
        if (studyData.studyType === USER_INTERVIEW) {
          let currentTemp = result.data?.data?.templateForm.filter(
            (section) => section.name !== "task",
          )
          setFormTemplates(currentTemp)
          dispatch(
            updateTemplateForm({
              data: currentTemp,
            }),
          )
        } else {
          setFormTemplates(result.data?.data?.templateForm ?? [])
          dispatch(
            updateTemplateForm({
              data: result.data?.data?.template ?? [],
            }),
          )
        }
      }
      dispatch(
        setInitialTemplateForm({
          templateForm: result.data?.data.templateForm ?? [], // array
          formId: result.data?.data?.formId ?? "",
          templateFormId: result.data?.data?.templateFormId ?? "",
          studyName: formTitle,
          studyType: studyDetails?.studyType,
          currentSectionIndex: 0,
        }),
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error.message ?? "something went wrong")
      console.log("error", error)
    }
  }

  const handleSubmission = async () => {
    try {
      const functions = getFunctions()
      const saveTemplate = httpsCallable(functions, "saveTemplate")
      const data = {
        template: template,
        formId: formId,
        templateId: templateId,
      }
      setIsLoading(true)
      setISClicked(true)
      const result = await saveTemplate(data)
      if (result.data.success) {
        displayNotification("success", result.data.message)
      }
      setIsLoading(false)
      navigate("/dashboard")
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error?.message ?? "Something went wrong!")
      console.log("error", error)
    }
    // navigate("/dashboard")
  }

  const handleFormSubmission = async () => {
    try {
      const functions = getFunctions()
      const saveTemplateForm = httpsCallable(functions, "saveTemplateForm")
      const data = {
        templateForm: templateForm,
        formId: formId,
        templateFormId: templateFormId,
      }
      setIsLoading(true)
      setISClicked(true)
      const result = await saveTemplateForm(data)
      if (result.data.success) {
        displayNotification("success", result.data.message)
      }
      setIsLoading(false)
      navigate("/dashboard")
      dispatch(
        updateTemplateForm({
          data: [],
        }),
      )
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error?.message ?? "Something went wrong!")
      console.log("error", error)
    }
    // navigate("/dashboard")
  }

  const stepsData =
    curTemp.length !== 0
      ? curTemp.map((temp, index) => ({
          title: temp.title,
          // content: generateContent(temp, index),
        }))
      : []

  const stepsFormData =
    formTemplates.length !== 0
      ? formTemplates.map((temp, index) => ({
          title: temp.title,
          // content: generateContent(temp, index),
        }))
      : []

  const fetchStudyDetails = async () => {
    setIsLoading(true)
    try {
      const functions = getFunctions()
      const getStudyDetails = httpsCallable(functions, "getStudyDetails")
      const { data } = await getStudyDetails({ formId: studyId })

      if (!data) {
        return
      }
      if (!data.success) {
        return
      }
      fetchTemplateDetails(data.data)
      fetchFormTemplateDetails(data.data)
      setStudyDetails(data.data)
      setIsLoading(false)
    } catch (error) {
      displayNotification("error", error.message ?? "something went wrong")
      setIsLoading(false)
    }
  }
  const handleNavigate = () => {
    navigate(-1)
  }

  useEffect(() => {
    fetchStudyDetails()
    dispatch(updateTemplateFormId({ templateFormId: templateFormId }))
  }, [])

  return (
    <>
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            {isLoading && (
              <h2 className="text-truncate font-size-15">
                <Skeleton.Input active={true} size={"small"} />
              </h2>
            )}
            {!isLoading && (
              <Link
                onClick={handleNavigate}
                className="mb-1 d-sm-flex align-items-center justify-content-between"
              >
                <h4 className="mb-sm-0 text-black p-2 font-size-18">
                  {studyDetails?.studyName ?? ""} :{" "}
                  {studyDetails?.studyType ?? ""}
                </h4>
              </Link>
            )}
            <Nav tabs className="d-flex justify-content-center">
              <NavItem className="mx-3">
                <NavLink
                  className={`nav-link ${activeTab === "1" ? "active" : ""}`}
                  style={{
                    backgroundColor: activeTab === "1" ? "#dde2fa" : "",
                  }}
                  onClick={() => toggle("1")}
                >
                  Template
                </NavLink>
              </NavItem>
              <NavItem className="mx-3">
                <NavLink
                  className={`nav-link ${activeTab === "2" ? "active" : ""}`}
                  style={{
                    backgroundColor: activeTab === "2" ? "#dde2fa" : "",
                  }}
                  onClick={() => toggle("2")}
                >
                  Form Builder
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" className="mt-3">
                <Row>
                  <Col sm="12">
                    {/* <Row>
                      <Col className="col-12">
                        <Link
                          onClick={handleNavigate}
                          className="mb-1 d-sm-flex align-items-center justify-content-between"
                        >
                          <h4 className="mb-sm-0 text-black p-2 font-size-18">
                            {studyDetails?.studyName ?? ""} :{" "}
                            {studyDetails?.studyType ?? ""}
                          </h4>
                        </Link>
                      </Col>
                    </Row> */}
                    <Row>
                      {curTemp.length !== 0 && (
                        <Col lg="12">
                          <Card className="form-wizard-wrapper">
                            <CardBody>
                              <div className="vertical-wizard wizard clearfix vertical">
                                <div
                                  className="steps clearfix"
                                  style={{ marginTop: "50px" }}
                                >
                                  <ul>
                                    {stepsData.map((step, index) => (
                                      <NavItem
                                        key={index}
                                        className={classnames({
                                          current: currentStep === index,
                                        })}
                                      >
                                        <NavLink
                                          className={classnames({
                                            active: currentStep === index,
                                            done: currentStep > index,
                                          })}
                                          onClick={() => setCurrentStep(index)}
                                          disabled={currentStep < index}
                                        >
                                          <span className="number">
                                            {index + 1}.
                                          </span>{" "}
                                          {step.title}
                                        </NavLink>
                                      </NavItem>
                                    ))}
                                  </ul>
                                </div>
                                <div className="content clearfix">
                                  <TabContent
                                    activeTab={currentStep + 1}
                                    className="body"
                                  >
                                    {curTemp.length !== 0 &&
                                      curTemp.map((temp, index) => {
                                        return (
                                          <TabPane
                                            key={index}
                                            tabId={index + 1}
                                          >
                                            {currentStep === index && (
                                              <div
                                                className="lexicaltemplate"
                                                style={{
                                                  border: "1px solid lightgray",
                                                }}
                                              >
                                                <Card className="templatecard">
                                                  <LexicalEditorWrapper
                                                    // isEditable={curTemp[currentStep].isEditable ?? true}
                                                    initialData={JSON.stringify(
                                                      curTemp[currentStep]
                                                        .editorContent,
                                                    )}
                                                    sectionIndex={currentStep}
                                                    template={template}
                                                    showTopBar={true}
                                                  />
                                                </Card>
                                              </div>
                                            )}
                                          </TabPane>
                                        )
                                      })}
                                  </TabContent>
                                </div>
                                <div className="actions clearfix">
                                  <ul>
                                    <li
                                      className={
                                        currentStep === 0
                                          ? "previous disabled"
                                          : "previous"
                                      }
                                    >
                                      {currentStep > 0 && (
                                        <div className="d-flex flex-wrap gap-2">
                                          <Button
                                            color="primary"
                                            outline
                                            onClick={() =>
                                              setCurrentStep(currentStep - 1)
                                            }
                                          >
                                            Previous
                                          </Button>
                                        </div>
                                      )}
                                    </li>
                                    <li>
                                      {currentStep === stepsData.length - 1 ? (
                                        <Button
                                          className={
                                            isLoading ? "disabled" : ""
                                          }
                                          loading={isLoading}
                                          onClick={handleSubmission}
                                          color="primary"
                                          disabled={isLoading}
                                          style={{
                                            cursor: isLoading
                                              ? "not-allowed"
                                              : "pointer",
                                          }}
                                        >
                                          {isLoading ? (
                                            <>
                                              <Spinner
                                                size="sm"
                                                color="light"
                                                className="mr-2"
                                              />{" "}
                                              Loading...
                                            </>
                                          ) : (
                                            "Save"
                                          )}
                                        </Button>
                                      ) : (
                                        <div className="d-flex flex-wrap gap-2">
                                          <Button
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={() =>
                                              setCurrentStep(currentStep + 1)
                                            }
                                          >
                                            Next
                                          </Button>
                                        </div>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )}

                      {curTemp.length === 0 && (
                        <Skeleton isLoading={isLoading} active={isLoading} />
                      )}
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" className="mt-3">
                <Row>
                  <Col sm="12" className="p-0">
                    <DynamicForm
                      data={templateForm}
                      stepsFormData={stepsFormData}
                      setFormCurrentStep={setFormCurrentStep}
                      formCurrentStep={formCurrentStep}
                      formTemplates={templateForm}
                      handleFormSubmission={handleFormSubmission}
                      isLoading={isLoading}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </React.Fragment>
    </>
  )
}
export default DisplayTemplate
