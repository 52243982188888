const { SET_INITIAL_TEMPLATE_FORM, UPDATE_TEMPLATE_FORM, UPDATE_FORM_IDS } = require("./actionTypes")

const INIT_STATE = {
  templateForm: [],
  formId: null,
  studyName: "",
  studyType: "",
  templateFormId: null,
  currentSection: null,
  lastSaveTime: Date.now(),
}

const TemplateForm = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_INITIAL_TEMPLATE_FORM:
      return {
        ...state,
        templateForm: action.payload.templateForm,
        formId: action.payload.formId,
        studyName: action.payload.studyName,
        studyType: action.payload.studyType,
        templateFormId: action.payload.templateFormId,
        currentSectionIndex: action.payload.currentSectionIndex,
      }
    case UPDATE_TEMPLATE_FORM:
      return {
        ...state,
        templateForm: action.payload.data,
        currentSectionIndex: action.payload.index,
      }
    case UPDATE_FORM_IDS:
      return {
        ...state,
        templateFormId: action.payload.templateFormId
      }
    default:
      return state
  }
}

export default TemplateForm
