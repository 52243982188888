import React, { useEffect, useState } from "react"
import {
  Row,
  Container,
  Card,
  Col,
  TabPane,
  TabContent,
  NavItem,
  CardBody,
  Button,
  Spinner,
  Nav,
} from "reactstrap"
import LexicalEditorWrapper from "../../components/Pages/Template/LexicalEditorWrapper"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { getFunctions, httpsCallable } from "firebase/functions"
import "./template.scss"
import { useDispatch } from "react-redux"
import { setInitialTemplate } from "store/template/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { Skeleton } from "antd"
import "toastr/build/toastr.min.css"
import { displayNotification } from "utils/utility"
import classnames from "classnames"
import { USER_INTERVIEW } from "constants/dashboard"
import DynamicForm from "pages/DynamicForm/form"
import { setInitialTemplateForm, updateTemplateForm } from "store/dynamicForm/actions"

const Template = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectTemplateState = (state) => state.Template
  const selectFormTemplateState = (state) => state.TemplateForm
  const selectTemplateProperties = createSelector(
    selectTemplateState,
    (tempData) => ({
      template: tempData.template,
      formId: tempData.formId,
      templateId: tempData.templateId,
      studyName: tempData.studyName,
      studyType: tempData.studyType,
      currentSectionIndex: tempData.currentSectionIndex,
    }),
  )
  const selectFormTemplateProperties = createSelector(
    selectFormTemplateState,
    (tempData) => ({
      templateForm: tempData.templateForm,
      formId: tempData.formId,
      templateFormId: tempData.templateFormId,
      studyName: tempData.studyName,
      studyType: tempData.studyType,
      currentSectionIndex: tempData.currentSectionIndex,
    }),
  )
  const { template, formId, templateId, studyName, currentSectionIndex } =
    useSelector(selectTemplateProperties)
  const { templateForm, templateFormId } = useSelector(
    selectFormTemplateProperties,
  )
  const [currentStep, setCurrentStep] = useState(0)
  const [formCurrentStep, setFormCurrentStep] = useState(0)
  const [curTemp, setCurTemp] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [formTemplates, setFormTemplates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const { state } = useLocation()
  const { docId, formTitle, studyType } = state || {}
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const [form, setForm] = useState({})
  const [activeTabVertical, setActiveTabVertical] = useState(1)

  const toggleTabVertical = (tab) => {
    setActiveTabVertical(tab)
  }

  const generateTemplate = async (docId) => {
    /**
     * const input = {
     *  formId: docId,
     * studyName:formTitle
     * }
     * dispatch(generateTemplate(input))
     */
    try {
      const functions = getFunctions()
      const generateTemplate = httpsCallable(functions, "generateTemplate")
      const result = await generateTemplate({
        formId: docId,
        studyName: formTitle,
        studyType: studyType,
      })
      if (studyType === USER_INTERVIEW) {
        let currentTemp = result.data?.templateList.filter(
          (section) => section.name !== "task",
        )
        setCurTemp(currentTemp)
      } else {
        setCurTemp(result.data?.templateList ?? [])
      }

      // let newTemplateList = result.data.templateList.map(template => template.editorContent = JSON.stringify(template.editorContent))
      dispatch(
        setInitialTemplate({
          template: result.data?.templateList ?? [], // array
          formId: result.data?.formId,
          templateId: result.data?.templateId,
          studyName: formTitle,
          studyType: studyType,
          currentSectionIndex: 0,
        }),
      )
      displayNotification("success", result.data.message)
    } catch (error) {
      console.log("error - ", error)
    }
  }

  const generateFormTemplate = async (docId) => {
    try {
      const functions = getFunctions()
      const generateTemplateForm = httpsCallable(
        functions,
        "generateTemplateForm",
      )
      const result = await generateTemplateForm({
        formId: docId,
        studyName: formTitle,
        studyType: studyType,
      })
      if (studyType === USER_INTERVIEW) {
        let currentTemp = result.data?.templateList.filter(
          (section) => section.name !== "task",
        )
        setFormTemplates(currentTemp)
        dispatch(
          updateTemplateForm({
            data: currentTemp,
          }),
        )

      } else {
        setFormTemplates(result.data?.templateList ?? [])
        dispatch(
          updateTemplateForm({
            data: result.data?.templateList ?? [],
          }),
        )
      }
      dispatch(
        setInitialTemplateForm({
          templateForm: result.data?.templateList ?? [],
          formId: result.data?.formId,
          templateFormId: result.data?.templateFormId,
          studyName: formTitle,
          studyType: studyType,
          currentSectionIndex: 0,
        }),
      )
    } catch (error) {
      console.log("error - ", error)
    }
  }

  const handleSubmission = async () => {
    try {
      const functions = getFunctions()
      const saveTemplate = httpsCallable(functions, "saveTemplate")
      const data = {
        template: template,
        formId: formId,
        templateId: templateId,
        studyName: studyName,
      }
      setIsLoading(true)
      const result = await saveTemplate(data)
      if (result.data.success) {
        displayNotification("success", result.data.message)
      }
      setIsLoading(false)
      navigate("/dashboard")
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error?.message ?? "Something went wrong!")
      console.log("error.meessage", error.message)
    }
    // navigate("/dashboard")
  }

  const handleFormSubmission = async () => {
    try {
      const functions = getFunctions()
      const saveTemplateForm = httpsCallable(functions, "saveTemplateForm")
      const data = {
        templateForm: templateForm,
        formId: formId,
        templateFormId: templateFormId,
        studyName: studyName,
      }
      setIsLoading(true)
      const result = await saveTemplateForm(data)
      if (result.data.success) {
        displayNotification("success", result.data.message)
      }
      setIsLoading(false)
      navigate("/dashboard")
      dispatch(
        updateTemplateForm({
          data: [],
        }),
      )
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error?.message ?? "Something went wrong!")
      console.log("error.meessage", error.message)
    }
    // navigate("/dashboard")
  }

  const handlePublication = async () => {
    try {
      const functions = getFunctions()
      const saveTemplate = httpsCallable(functions, "saveTemplate")
      const data = {
        template: template,
        formId: formId,
        studyName: studyName,
        templateId: templateId,
      }

      setIsPublishing(true)
      const result = await saveTemplate(data)
      if (result.data.success) {
        displayNotification("success", result.data.message)
        navigate(`/template/publish`, {
          state: {
            formId: result.data.data.formId,
            templateId: result.data.data.templateId,
          },
        })
      }
      setIsPublishing(false)
    } catch (error) {
      setIsPublishing(false)
      displayNotification("error", error?.message ?? "Something went wrong!")
      console.log("error", error)
    }
  }

  const stepsData =
    curTemp.length !== 0
      ? curTemp.map((temp, index) => ({
          title: temp.title,
          // content: generateContent(temp, index),
        }))
      : []

  const stepsFormData =
    formTemplates.length !== 0
      ? formTemplates.map((temp, index) => ({
          title: temp.title,
          // content: generateContent(temp, index),
        }))
      : []

  useEffect(() => {
    generateTemplate(docId)
    generateFormTemplate(docId)
  }, [])

  const handleUpdateData = (newData) => {
    setFormTemplates(newData)
  }

  return (
    <>
      <div className="page-content">
        <Nav tabs className="d-flex justify-content-center">
          <NavItem className="mx-3">
            <NavLink
              className={`nav-link ${activeTab === "1" ? "active" : ""}`}
              style={{
                backgroundColor: activeTab === "1" ? "#dde2fa" : "",
              }}
              onClick={() => toggle("1")}
            >
              Template
            </NavLink>
          </NavItem>
          <NavItem className="mx-3">
            <NavLink
              className={`nav-link ${activeTab === "2" ? "active" : ""}`}
              style={{
                backgroundColor: activeTab === "2" ? "#dde2fa" : "",
              }}
              onClick={() => toggle("2")}
            >
              Form Builder
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="mt-3">
            <Row>
              <Col sm="12">
                <Row>
                  {curTemp.length !== 0 && (
                    <Col lg="12">
                      <Card className="form-wizard-wrapper">
                        <CardBody>
                          <div className="vertical-wizard wizard clearfix vertical">
                            <div
                              className="steps clearfix"
                              style={{ marginTop: "50px" }}
                            >
                              <ul>
                                {stepsData.map((step, index) => (
                                  <NavItem
                                    key={index}
                                    className={classnames({
                                      current: currentStep === index,
                                    })}
                                  >
                                    <NavLink
                                      style={{ fontSize: "24px" }}
                                      className={classnames({
                                        active: currentStep === index,
                                        done: currentStep > index,
                                      })}
                                      onClick={() => setCurrentStep(index)}
                                      disabled={currentStep < index}
                                    >
                                      <span className="number">
                                        {index + 1}.
                                      </span>{" "}
                                      {step.title}
                                    </NavLink>
                                  </NavItem>
                                ))}
                              </ul>
                            </div>
                            <div className="content clearfix">
                              <TabContent
                                activeTab={currentStep + 1}
                                className="body"
                              >
                                {curTemp.length !== 0 &&
                                  curTemp.map((temp, index) => {
                                    return (
                                      <TabPane key={index} tabId={index + 1}>
                                        {currentStep === index && (
                                          <div
                                            className="lexicaltemplate"
                                            style={{
                                              border: "1px solid lightgray",
                                            }}
                                          >
                                            <Card className="templatecard">
                                              <LexicalEditorWrapper
                                                // isEditable={curTemp[currentStep].isEditable ?? true}
                                                initialData={JSON.stringify(
                                                  curTemp[currentStep]
                                                    .editorContent,
                                                )}
                                                sectionIndex={currentStep}
                                                template={template}
                                                showTopBar={true}
                                              />
                                            </Card>
                                          </div>
                                        )}
                                      </TabPane>
                                    )
                                  })}
                              </TabContent>
                            </div>
                            <div className="actions clearfix">
                              <ul>
                                <li
                                  className={
                                    currentStep === 0
                                      ? "previous disabled"
                                      : "previous"
                                  }
                                >
                                  {currentStep > 0 && (
                                    <div className="d-flex flex-wrap gap-2">
                                      <Button
                                        color="primary"
                                        outline
                                        onClick={() =>
                                          setCurrentStep(currentStep - 1)
                                        }
                                      >
                                        Previous
                                      </Button>
                                    </div>
                                  )}
                                </li>
                                <li>
                                  {currentStep === stepsData.length - 1 ? (
                                    <Button
                                      color="primary"
                                      className={
                                        isLoading
                                          ? "disabled btn btn-primary waves-effect waves-light "
                                          : ""
                                      }
                                      loading={isLoading}
                                      onClick={handleSubmission}
                                      disabled={isLoading}
                                      style={{
                                        cursor: isLoading
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    >
                                      {isLoading ? (
                                        <>
                                          <Spinner
                                            size="sm"
                                            color="light"
                                            className="mr-2"
                                          />{" "}
                                          Loading...
                                        </>
                                      ) : (
                                        "Save"
                                      )}
                                    </Button>
                                  ) : (
                                    <div className="d-flex flex-wrap gap-2">
                                      <Button
                                        color="primary"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={() =>
                                          setCurrentStep(currentStep + 1)
                                        }
                                      >
                                        Next
                                      </Button>
                                    </div>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )}

                  {curTemp.length === 0 && (
                    <Skeleton isLoading={isLoading} active={isLoading} />
                  )}
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2" className="mt-3">
            <Row>
              <Col sm="12" className="p-0">
                <DynamicForm
                  data={templateForm}
                  // data={formTemplates}
                  updateData={handleUpdateData}
                  handleFormSubmission={handleFormSubmission}
                  stepsFormData={stepsFormData}
                  setFormCurrentStep={setFormCurrentStep}
                  formCurrentStep={formCurrentStep}
                  formTemplates={templateForm}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  )
}
export default Template
