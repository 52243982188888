import { SET_CURRENT_USER_DATA } from "./actionTypes"

const INIT_STATE = {
    isLoggedIn: false,
    authUserData: null
}

const AuthState = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER_DATA:
      return {
        ...state,
        authUserData: action.payload.data,
        isLoggedIn: action.payload.isLoggedIn
      }
    default:
      return state
  }
}

export default AuthState
