import React from "react"
import FormBuilder from "./formBuilder"
import FormRender from "./formRender"
import { MyContext } from "./context"
import { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabPane,
  TabContent,
  NavLink,
  NavItem,
  Button,
  Spinner,
} from "reactstrap"
import classnames from "classnames"

import "./form.scss"
import { template } from "lodash"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { displayNotification } from "utils/utility"
import { getFunctions, httpsCallable } from "firebase/functions"
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import { updateTemplateForm } from "store/dynamicForm/actions"
import { useDispatch } from "react-redux"

function DynamicForm({
  data,
  handleFormSubmission,
  stepsFormData,
  formCurrentStep,
  setFormCurrentStep,
  isLoading,
  studyId,
  templateFormId
}) {
  const [form, setForm] = useState({})
  const [activeTabVertical, setActiveTabVertical] = useState(1)
  const [formData, setFormData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [dataOfForm, setDataOfForm] = useState(data)
  const dispatch = useDispatch()
  // const [isEdited, setIsEdited] = useState(false)
  const [isTitle, setIsTitle] = useState("")
  const [wizardIndex, setWizardIndex] = useState(0)
  const [currentTitle, setcurrentTitle] = useState("")
  const [tooltipVisible, setTooltipVisible] = useState(false)

  // const selectFormTemplateState = (state) => state.TemplateForm
  // const selectTemplateProperties = createSelector(
  //   selectFormTemplateState,
  //   (tempData) => ({
  //     templateForm: tempData.templateForm,
  //     formId: tempData.formId,
  //     templateFormId: tempData.templateFormId,
  //   }),
  // )
  // const { templateForm, templateFormId, formId } = useSelector(
  //   selectTemplateProperties,
  // )
  // const saveForm = async () => {
  //   if (isEdited) {
  //     try {
  //       const functions = getFunctions()
  //       const saveTemplateForm = httpsCallable(functions, "saveTemplateForm")
  //       const data = {
  //         templateForm: templateForm,
  //         formId: formId,
  //         templateFormId: templateFormId,
  //       }
  //       // setIsLoading(true)
  //       const result = await saveTemplateForm(data)
  //       // setDataOfForm(templateForm)dispatch
  //       if (result.data.success) {
  //         // displayNotification("success", result.data.message)
  //       }
  //       // setIsLoading(false)
  //       setIsEdited(false)
  //     } catch (error) {
  //       // setIsLoading(false)
  //       // displayNotification("error", error?.message ?? "Something went wrong!")
  //       console.log("error.meessage", error.message)
  //     }
  //   }
  // }

  const handleSaving = (status) => {
    setIsSaving(status)
  }
  const saveForm = async () => {
    if (isEdited) {
      try {
        const functions = getFunctions()
        const saveTemplateForm = httpsCallable(functions, "saveTemplateForm")
        const data = {
          templateForm: data,
          // title: templateForm.title,
          formId: studyId,
          templateFormId: templateFormId,
        }
        // setIsLoading(true)
        const result = await saveTemplateForm(data)
        console.log("result.data.data --->", result.data.data)
        // dispatch(
        //   updateTemplateForm({
        //     data: result.data.data,
        //   }))
        if (result.data.success) {
          // displayNotification("success", result.data.message)
        }
        // setIsLoading(false)
        setIsEdited(false)
      } catch (error) {
        // setIsLoading(false)
        // displayNotification("error", error?.message ?? "Something went wrong!")
        console.log("error.meessage", error.message)
      }
    }
  }

  const updateStepsFormData = (title, index) => {
    data[index]["title"] = title
    setIsTitle(data[index]["title"])
    setWizardIndex(index)
  }

  return (
    <Container>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4"></h4>
              <div className="vertical-wizard wizard clearfix vertical">
                <div className="steps clearfix">
                  <ul>
                    {stepsFormData &&
                      stepsFormData.map((step, index) => {
                        return (
                          <NavItem
                            key={index}
                            className={classnames({
                              current: formCurrentStep === index,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: formCurrentStep === index,
                                done: formCurrentStep > index,
                              })}
                              onClick={() => {
                                setFormCurrentStep(index)
                              }}
                              disabled={
                                formCurrentStep === index ||
                                (currentTitle.length === 0 &&
                                  formCurrentStep.length !== 0)
                              }
                            >
                              <span className="number">{index + 1}.</span>{" "}
                              {isTitle && wizardIndex === index
                                ? ""
                                : !currentTitle && wizardIndex === index
                                ? ""
                                : step.title}
                              {index === wizardIndex ? isTitle : ""}
                            </NavLink>
                          </NavItem>
                        )
                      })}
                  </ul>
                </div>
                <div
                  className="content clearfix"
                  style={{
                    height: "500px",
                    // overflowY: "scroll",
                    position: "relative",
                  }}
                >
                  <TabContent activeTab={formCurrentStep + 1} className="body">
                    {data.length !== 0 &&
                      data.map((temp, index) => {
                        return (
                          <TabPane key={index} tabId={index + 1}>
                            <span
                              className="d-flex justify-content-center"
                              style={{
                                marginTop: "-40px",
                                color: isSaving ? "#26dc15" : "inherit",
                                fontSize: "15px",
                              }}
                            >
                              {isSaving ? (
                                <>
                                  <LoadingOutlined />
                                  Saving...
                                </>
                              ) : (
                                "Saved"
                              )}
                            </span>

                            {data[index] && (
                              <MyContext.Provider value={{ form, setForm }}>
                                <FormBuilder
                                  sectionIndex={index}
                                  data={temp.root}
                                  list={data}
                                  name={temp.name}
                                  title={temp.title}
                                  handleFormSubmission={handleFormSubmission}
                                  stepsFormData={stepsFormData}
                                  formCurrentStep={formCurrentStep}
                                  setFormData={setFormData}
                                  formData={formData}
                                  setIsEdited={setIsEdited}
                                  handleSaving={handleSaving}
                                  isSaving={isSaving}
                                  setDataOfForm={setDataOfForm}
                                  updateStepsFormData={updateStepsFormData}
                                  setcurrentTitle={setcurrentTitle}
                                  studyId={studyId}
                                  templateFormId={templateFormId}
                                />
                              </MyContext.Provider>
                            )}
                          </TabPane>
                        )
                      })}
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    {formCurrentStep !== 0 && (
                      <li className="previous">
                        <Button
                          color="primary"
                          onClick={() =>
                            setFormCurrentStep(formCurrentStep - 1)
                          }
                          disabled={
                            currentTitle.length === 0 &&
                            formCurrentStep.length !== 0
                          }
                        >
                          Previous
                        </Button>
                      </li>
                    )}

                    {formCurrentStep === stepsFormData.length - 1 ? (
                      <li className="next">
                        <Button color="primary" onClick={handleFormSubmission}>
                          {isLoading ? (
                            <>
                              <Spinner
                                size="sm"
                                color="light"
                                className="mr-2"
                              />{" "}
                              Loading...
                            </>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </li>
                    ) : (
                      <li className="next">
                        <Tooltip
                          placement="top"
                          title={"  🛈  Please enter the title "}
                          open={
                            currentTitle.length === 0 &&
                            formCurrentStep.length !== 0
                          }
                          color={
                            currentTitle.length === 0 &&
                            formCurrentStep.length !== 0
                              ? "red"
                              : undefined
                          }
                        >
                          <Button
                            id="nextButton"
                            color="primary"
                            onClick={() => {
                              setFormCurrentStep(formCurrentStep + 1)
                              saveForm()
                              
                            }}
                            disabled={
                              currentTitle.length === 0 &&
                              formCurrentStep.length !== 0
                            }
                          >
                            Next
                          </Button>
                        </Tooltip>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default DynamicForm
