import { Skeleton } from "antd"
import { Helmet } from "react-helmet"
import { createSelector } from "reselect"
import { changeLayout } from "store/actions"
import { layoutTypes } from "constants/layout"
import Spinners from "components/Common/Spinner"
import React, { useEffect, useState } from "react"
import { Button, Card, Spinner } from "reactstrap"
import { Link, useParams } from "react-router-dom"
import { displayNotification } from "utils/utility"
import FormRender from "pages/DynamicForm/formRender"
import { updateChat } from "store/chatWindow/actions"
import { useSelector, useDispatch } from "react-redux"
import { getFunctions, httpsCallable } from "@firebase/functions"
// import { scriptParser } from "utils/scriptParser"
// import ChatWindow from "components/Chat/chatwindow"

function JoinMeetComponent() {
  const dispatch = useDispatch()
  const { studyId, templateId, meetingId, templateFormId } = useParams()

  const [isOpen, setIsOpen] = useState(true)
  const [template, setTemplate] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const passWord = "123456"
  const leaveUrl = "hureo.ai/dashboard"

  const selectChatWindowState = (state) => state.ChatWindow
  const selectChatWindowProperties = createSelector(
    selectChatWindowState,
    (chatWindow) => ({
      formResponse: chatWindow.formResponse,
    }),
  )
  const { formResponse } = useSelector(selectChatWindowProperties)

  const toggleChat = () => {
    setIsOpen(!isOpen)
  }
  
  const handleUpdateTemplate = (latestData) => {
    setTemplate(latestData)
    dispatch(
      updateChat({
        data: latestData,
      }),
    )
  }
  
  const fetchFormTemplateDetails = async () => {
    try {
      setIsLoading(true)
      const functions = getFunctions()
      const getTemplateForm = httpsCallable(functions, "getTemplateForm")
      const result = await getTemplateForm({
        templateFormId: templateFormId,
        formId: studyId,
        isEditable: true,
      })
      if (result.data.success) {
        const newTemplate = result.data.data.templateForm.slice(1)
        setTemplate(newTemplate)
        dispatch(
          updateChat({
            data: newTemplate,
          }),
        )
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error.message ?? "something went wrong")
      console.log("error", error)
    }
  }
  /**
   * @async
   * @function getSignature
   * @param {meetingId} - zoom meeting ID
   * @description generates signature required for joining a zoom meet
   */
  const getSignature = async (meetingId) => {
    setIsLoading(true)
    try {
      const functions = getFunctions()
      const testgetSignature = httpsCallable(functions, "testgetSignature")
      const responseFromSignatureEndpoint = await testgetSignature({
        meetingId: meetingId,
        role: 1,
      })
      console.log("signature: ", responseFromSignatureEndpoint)
      const getSDKClientIdfunc = httpsCallable(functions, "testgetSDKClientId")
      const response = await getSDKClientIdfunc({ meetingId: meetingId })
      const testgetProfileData = httpsCallable(functions, "testgetProfileData")
      const profileResponse = await testgetProfileData()
      const testgetZakToken = httpsCallable(functions, "testgetZakToken")
      const zakResponse = await testgetZakToken()
      startMeeting(
        responseFromSignatureEndpoint.data,
        response.data.ZOOM_CLIENT_ID,
        meetingId,
        profileResponse.data.email,
        profileResponse.data.userName,
        zakResponse.data.token,
      )
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }
  const handlePromiseRejection = (e) => {
    const reason = e.reason
    console.error("Caught unhandled promise rejection:", reason)
    e.preventDefault()
  }

  window.addEventListener("unhandledrejection", handlePromiseRejection)

  const startMeeting = (
    signature,
    sdkKey,
    meetingId,
    zoomEmail,
    zoomUserName,
    zakToken,
  ) => {
    try {
      ZoomMtg.init({
        leaveUrl: leaveUrl,
        success: (success) => {
          console.log(" inside success: ", zoomUserName)
          console.log(" inside success: ", zoomEmail)
          console.log(" inside success zakToken: ", zakToken)
          const zoomMeetingSDK = document.getElementById("zmmtg-root")
          const reactRoot = document.getElementById("root")
          if (zoomMeetingSDK && reactRoot) {
            zoomMeetingSDK.style.display = "block"
            reactRoot.style.position = "absolute"
          }
          const mainContentDiv = document.getElementById("content_container")
          if (mainContentDiv) {
            mainContentDiv.style.marginLeft = "0px"
          }
          ZoomMtg.join({
            signature: signature,
            sdkKey: sdkKey,
            meetingNumber: meetingId,
            passWord: passWord,
            userName: zoomUserName,
            userEmail: zoomEmail,
            // tk: registrantToken,
            zak: zakToken,
            success: (success) => {
              setIsLoading(false)
              const footer = document.getElementById("wc-footer")
              if (footer) {
                footer.style.marginBottom = "10px"
              }
              const mainContentDiv = document.getElementById("wc-footer")
              if (mainContentDiv) {
                mainContentDiv.style.left = "0px"
              }
              console.log("success :", success)
            },
            error: (error) => {
              setIsLoading(false)
              console.log("error :", error)
            },
          })
        },
        error: (error) => {
          setIsLoading(false)
          console.log("error 2 - ", error)
        },
      })
    } catch (error) {
      console.log("ERROR : ", error)
    }
  }
  const stepsData =
    template?.length !== 0
      ? template.map((temp, index) => ({
          title: temp.title,
        }))
      : []
  const next = () => {
    setCurrentStep(currentStep + 1)
  }
  const prev = () => {
    setCurrentStep(currentStep - 1)
  }
  const handleFormSubmission = async () => {
    const functions = getFunctions()
    const saveFormResponses = httpsCallable(functions, "saveFormResponses")
    const data = {
      formResponse: formResponse,
      formId: studyId,
      meetingId: meetingId,
      studyName: "",
    }
    const result = await saveFormResponses(data)
    if (result.data.success) {
      displayNotification("success", result.data.message)
    }
  }
  const saveForm = async () => {
    try {
      const functions = getFunctions()
      const saveFormResponses = httpsCallable(functions, "saveFormResponses")
      setIsLoading(true)
      const data = {
        formResponse: formResponse,
        formId: studyId,
        meetingId: meetingId,
        studyName: "",
        templateFormId: templateFormId,
      }
      const result = await saveFormResponses(data)
      if (result.data.success) {
        // displayNotification("success", result.data.message)
        handleUpdateTemplate(result.data.data)
        setIsLoading(false)
      }
    } catch (error) {
      // displayNotification("error", "Something went wrong!")
      console.log("Error : ", error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    dispatch(changeLayout(layoutTypes.EMPTY))
    const mainContentDiv = document.getElementById("content_container")
    if (mainContentDiv) {
      mainContentDiv.style.marginLeft = "0px"
    }
    // fetchTemplateDetails()
    fetchFormTemplateDetails()
    getSignature(meetingId)
  }, [])

  return (
    <>
      {/* <Helmet>
        <script
          src="https://source.zoom.us/3.1.0/lib/vendor/react.min.js"
          async
        />
        <script
          src="https://source.zoom.us/3.1.0/lib/vendor/react-dom.min.js"
          async
        />
        <script
          src="https://source.zoom.us/3.1.0/lib/vendor/redux.min.js"
          async
        />
        <script
          src="https://source.zoom.us/3.1.0/lib/vendor/redux-thunk.min.js"
          async
        />
        <script
          src="https://source.zoom.us/3.1.0/lib/vendor/lodash.min.js"
          async
        />
        <script src="https://source.zoom.us/zoom-meeting-3.1.0.min.js" async />
      </Helmet> */}
      <React.Fragment>
        {/* {isLoading && <Spinners text={"Getting ready to join..."} />} */}
        <div className="chat-script">
          <div className="chat-content">
            <div className="d-flex">
              <div className="flex-grow-1 overflow-hidden">
                <div>
                  <p className="text-muted ">
                    {isOpen && isLoading && (
                      <Skeleton
                        className="p-2"
                        loading={isLoading}
                        active={isLoading}
                      />
                    )}
                    {isOpen &&
                      !isLoading &&
                      formResponse.length !== 0 &&
                      formResponse.map((temp, index) => {
                        return (
                          <>
                            {currentStep === index && (
                              <FormRender
                                data={temp.root}
                                sectionIndex={index}
                                list={formResponse}
                                name={temp.name}
                                title={temp.title}
                                showButton={true}
                                handleUpdateTemplate={handleUpdateTemplate}
                              />
                            )}
                          </>
                        )
                      })}
                  </p>
                </div>
                {isOpen && !isLoading && (
                  <div
                    className="lexicaltemplate"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <Card
                      className="templatecard"
                      style={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        display: "flex",
                        border: "none",
                      }}
                    >
                      <div
                        className="ml-auto"
                        style={{
                          position: "fixed",
                          display: "flex",
                          marginBottom: "15px",
                          bottom: "100px",
                          right: "20px",
                          zIndex: "999",
                        }}
                      >
                        {currentStep > 0 && (
                          <Link
                            className="btn btn-outline-primary mx-2"
                            onClick={() => {
                              prev()
                              // saveForm()
                            }}
                          >
                            <i className="fas fa-arrow-left font-size-16 align-middle me-2"></i>
                          </Link>
                        )}

                        {/* {currentStep === stepsData.length - 1 ? (
                          <Button
                            color="primary"
                            className={
                              isLoading
                                ? "disabled btn btn-primary waves-effect waves-light "
                                : ""
                            }
                            loading={isLoading}
                            onClick={saveForm()}
                            disabled={isLoading}
                            style={{
                              cursor: isLoading ? "not-allowed" : "pointer",
                            }}
                          >
                            {isLoading ? (
                              <>
                                <Spinner
                                  size="sm"
                                  color="light"
                                  className="mr-2"
                                />{" "}
                                Saving...
                              </>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        ) : (
                          <div className="d-flex flex-wrap gap-2">
                            <Button
                              color="primary"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => {
                                next()
                                saveForm()
                              }}
                            >
                              <i className="fas fa-arrow-right font-size-16 align-middle me-2"></i>
                            </Button>
                          </div>
                        )} */}

                        {currentStep === 5 && (
                          <Link
                            className="btn btn-primary mx-2"
                            type="primary"
                            onClick={() => {
                              saveForm()
                            }}
                          >
                            save
                          </Link>
                        )}
                        {currentStep < stepsData.length - 1 && (
                          <Link
                            className="btn btn-primary mx-2"
                            type="primary"
                            onClick={() => {
                              next()
                              saveForm()
                            }}
                          >
                            <i className="fas fa-arrow-right font-size-16 align-middle me-2"></i>
                          </Link>
                        )}
                      </div>
                    </Card>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ marginTop: "-20px" }}>
          <Link
            className="open-chat-btn btn btn-outline-primary"
            onClick={toggleChat}
            style={{ paddingTop: "10px" }}
          >
            {isOpen ? (
              <i className="bx bx-chat font-size-16 align-middle me-2"></i>
            ) : (
              <i className="bx bxs-chat font-size-16 align-middle me-2"></i>
            )}
          </Link>
        </div> */}
      </React.Fragment>
    </>
  )
}

export default JoinMeetComponent
