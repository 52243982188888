import React, { useState } from "react"
import { DatePicker, Form, Input, Select } from "antd"
import { Button, Col, Row, Spinner } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import dayjs from "dayjs"
import {
  AM_PM_OPTION,
  SCHEDULER_FORM_INVITATION_TIME_OPTIONS,
  SCHEDULER_FORM_TIME_OPTIONS,
  TIMEZONE_OPTIONS,
  platformOptions,
} from "constants/zoom"
import { displayNotification } from "utils/utility"
import FirebaseService from "services/firebaseServices"
import { useParams } from "react-router-dom"
import "moment-timezone"
import moment from "moment-timezone"
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore"

const EditSchedulerForm = ({
  studyDetails,
  fetchMeetings,
  editedMeet,
  setEditModal,
  isZoomAuthorized,
  getMeetingsNz,
}) => {
  const { studyId } = useParams()
  const [isUpdating, setIsUpdating] = useState(false)
  const localTime = moment.utc(editedMeet.start_time).local()
  const defaultValue = moment
    .utc(editedMeet.start_time)
    .local()
    .format("YYYY-MM-DD")
  const formattedTime = localTime.format("hh:mm ")
  const timeFormat = "HH:mm"

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("Topic is required"),
    duration: Yup.string(),
    date: Yup.date().required("Please select a Time and Date"),
    time: Yup.string().required(""),
    period: Yup.string().required("Please select AM or PM"),
    timezone: Yup.string().required("Please select Timezone"),
    join_url: Yup.string().url("Invalid URL format"),
    platform: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      topic: editedMeet.topic,
      duration: editedMeet.duration,
      date: dayjs(defaultValue, "YYYY-MM-DD"),
      time: localTime.format("hh:mm "),
      period: localTime.format("A"),
      timezone: "Asia/Kolkata",
      join_url: editedMeet.join_url || "",
      platform: editedMeet.platform || "",
    },

    validationSchema: validationSchema,
    onSubmit: (val) => {
      const formValuesCopy = { ...val }
      handleEditSubmit(formValuesCopy)
    },
  })

  function convertTo24HourFormat(timestr, period) {
    const hours = timestr.split(":")[0]
    const minutes = timestr.split(":")[1]
    let hoursInt = parseInt(hours, 10)
    const minutesInt = parseInt(minutes, 10)
    if (period === "PM" && hoursInt < 12) {
      hoursInt += 12
    } else if (period === "AM" && hoursInt === 12) {
      hoursInt = 0
    }
    const formattedHours = hoursInt.toString().padStart(2, "0")
    const formattedMinutes = minutesInt.toString().padStart(2, "0")
    const formattedTime = `${formattedHours}:${formattedMinutes}`
    return formattedTime
  }

  const handleEditSubmit = async (values) => {
    if (isZoomAuthorized){
      setIsUpdating(true)
      const datestring = values.date.format("YYYY-MM-DD")
      const returnedtime = convertTo24HourFormat(values.time, values.period)
      const updatedDateString = datestring + " " + returnedtime
      let revisedValue = moment.tz(updatedDateString, values.timezone)
      const timeString = revisedValue.utc().format()
      const start_time = timeString
      try {
        // const formattedTime = values.time.format("HH:mm:ss")
        // const formattedDate = values.date.format("YYYY-MM-DD")
        const userTimeZoneOffset = moment().utcOffset()
        const timestr = moment().utcOffset(userTimeZoneOffset).toISOString()
        delete values.date
        delete values.time
        delete values.period
        delete values.timezone
        delete values.duration
        const response = await FirebaseService.editMeeting(
          studyId,
          editedMeet.id,
          {
            body: { ...values, start_time: start_time },
          },
        )
        const { success } = response
        if (success) {
          setEditModal(false)
          setIsUpdating(false)
          displayNotification("success", response.data.message)
          await fetchMeetings()
        } else {
          setEditModal(false)
          setIsUpdating(false)
          displayNotification("error", "Failed to update meeting")
        }
      } catch (error) {
        setEditModal(false)
        setIsUpdating(false)
        console.error("Error updating user profile:", error)
        displayNotification("error", error?.message ?? "Failed to update Meeting")
      }
    } else{
      setIsUpdating(true)
      const id = editedMeet.id
      const datestring = values.date.format("YYYY-MM-DD")
      const returnedtime = convertTo24HourFormat(values.time, values.period)
      const updatedDateString = datestring + " " + returnedtime
      let revisedValue = moment.tz(updatedDateString, values.timezone)
      const timeString = revisedValue.utc().format()
      const start_time = timeString
      try {
        // const formattedTime = values.time.format("HH:mm:ss")
        // const formattedDate = values.date.format("YYYY-MM-DD")
        const userTimeZoneOffset = moment().utcOffset()
        const timestr = moment().utcOffset(userTimeZoneOffset).toISOString()
        delete values.date
        delete values.time
        delete values.period
        delete values.timezone
  
        const db = getFirestore()
        const meetingsCollectionRef = collection(db, "zoomMeetings")
        const q = query(meetingsCollectionRef, where("id", "==", id))
  
        const querySnapshot = await getDocs(q)
        if (querySnapshot.empty) {
          console.log("No meetings found to edit")
          return
        }
  
        // Loop through each document in the query snapshot and update it
        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, {
            ...values,
            start_time: start_time,
            // Update other fields as needed
          })
          setEditModal(false)
          setIsUpdating(false)
          getMeetingsNz()
          displayNotification("success", "Meeting updated successfully")
          // Optionally, update the UI or perform any other actions after update
        })
  
        // Call getMeetingsNz to refresh the data
      } catch (error) {
        setEditModal(false)
        setIsUpdating(false)
        displayNotification("error", error ?? "Failed to update Meeting")
        // Handle error, display error message, etc.
      }
    }
    
  }
  const handleEditSubmitNZ = async (values) => {
  
  }

  return (
    <div>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="horizontal"
        style={{
          maxWidth: 600,
        }}
        onSubmit={formik.handleSubmit}
      >
        <Form.Item label="Topic">
          <Input
            name="topic"
            placeholder={studyDetails?.studyName ?? ""}
            value={formik.values.topic ?? studyDetails?.studyDetails?.studyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.topic && formik.errors.topic ? (
            <div style={{ color: "red" }}>{formik.errors.topic}</div>
          ) : null}
        </Form.Item>

        <div className="when-div d-flex  justify-content-center ">
          <div className=" when-text mx-4 mt-1  color-black">When :</div>
          <Row className="when-row d-flex justify-content-evenly   ">
            <Col className="modal-when-col mr-1">
              <Form.Item label="">
                <DatePicker
                  className="form-control"
                  name="date"
                  defaultValue={dayjs(defaultValue, "YYYY-MM-DD")}
                  value={formik.values.date}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.date && formik.errors.date ? (
                  <div style={{ color: "red", width: "220px" }}>
                    {formik.errors.date}
                  </div>
                ) : null}
              </Form.Item>
            </Col>

            <Col className="modal-when-col mr-1 p-0 ">
              <Form.Item label="">
                <div className="ant-select-1">
                  <Select
                    placeholder="Time"
                    name="time"
                    options={SCHEDULER_FORM_INVITATION_TIME_OPTIONS}
                    value={formik.values.time}
                    onChange={(value) => formik.setFieldValue("time", value)}
                    onBlur={formik.handleBlur}
                    format={timeFormat}
                  />
                </div>
                {formik.touched.time && formik.errors.time ? (
                  <div style={{ color: "red" }}>{formik.errors.time}</div>
                ) : null}
              </Form.Item>
            </Col>
            <Col className="modal-when-col ">
              <Form.Item label="">
                <div className="ant-select-1  ">
                  <Select
                    placeholder="AM/PM"
                    name="period"
                    options={AM_PM_OPTION}
                    value={formik.values.period}
                    onChange={(value) => formik.setFieldValue("period", value)}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.period && formik.errors.period ? (
                  <div style={{ color: "red", width: "220px" }}>
                    {formik.errors.period}
                  </div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Form.Item label="Timezone">
          <Select
            name="timezone"
            defaultValue={TIMEZONE_OPTIONS[0]}
            options={TIMEZONE_OPTIONS} // Replace with your array of timezone options
            value={formik.values.timezone}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onChange={(value) => formik.setFieldValue("timezone", value)}
            onBlur={formik.handleBlur}
          />
          {formik.touched.timezone && formik.errors.timezone ? (
            <div style={{ color: "red" }}>{formik.errors.timezone}</div>
          ) : null}
        </Form.Item>
        <Form.Item label="Duration">
          <Select
            name="duration"
            value={formik.values.duration}
            placeholder="Select Duration"
            onChange={(value) => formik.setFieldValue("duration", value)}
            onBlur={formik.handleBlur}
            options={SCHEDULER_FORM_TIME_OPTIONS}
          ></Select>
        </Form.Item>
        {!isZoomAuthorized && (
          <Form.Item label="Join URL">
            <Input
              name="join_url"
              placeholder="Enter Join URL"
              value={formik.values.join_url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.join_url && formik.errors.join_url ? (
              <div style={{ color: "red" }}>{formik.errors.join_url}</div>
            ) : null}
          </Form.Item>
        )}
        {!isZoomAuthorized && (
          <Form.Item label="Platform">
            <Select
              name="platform"
              placeholder="Select Platform"
              value={formik.values.platform}
              onChange={(value) => formik.setFieldValue("platform", value)}
              onBlur={formik.handleBlur}
            >
              {platformOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
            {formik.touched.platform && formik.errors.platform ? (
              <div style={{ color: "red" }}>{formik.errors.platform}</div>
            ) : null}
          </Form.Item>
        )}

        <Form.Item className="d-flex justify-content-center ">
          <Button
            type="primary"
            htmlType="submit"
            color="primary"
            style={{ whiteSpace: "nowrap" }}
            disabled={isUpdating}
            onClick={formik.submitForm}
          >
            {isUpdating ? (
              <>
                <Spinner size="sm" color="light" className="mr-2" />
                Updating...
              </>
            ) : (
              "Update Meeting"
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default EditSchedulerForm
