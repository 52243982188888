import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, CardBody, Spinner } from "reactstrap"
import "../../components/Pages/dashCard.scss"
import { withTranslation } from "react-i18next"
import { Card, Row, Col } from "reactstrap"
import plusicon from "../../assets/images/plusicon.svg"
import { useNavigate } from "react-router-dom"
import { DASHBOARD_DATA } from "constants/dashboard"
import DashCard from "components/Pages/dashCard"
import { DEFAULT } from "constants/studyForm"
import { createForm } from "store/actions"
import { useDispatch } from "react-redux"
import PastStudiesCard from "../../components/Pages/pastStudiesCard"
import { Skeleton } from "antd"
import { getFunctions, httpsCallable } from "firebase/functions"
import { displayNotification } from "utils/utility"
import PastStudiesSkeleton from "components/Pages/PastStudiesSkeleton"
import FirebaseService from "services/firebaseServices"
import { auth } from "config/firebase"
import { onAuthStateChanged } from "firebase/auth"
import "./dash.scss"

const Dashboard = () => {
  document.title = "Dashboard | Hureo.Ai"
  const [studies, setStudies] = useState([])
  const [zoomLoading, setZoomLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isZoomAuthInProgress, setIsZoomAuthInProgress] = useState(false)
  const [isZoomAuthorized, setIsZoomAuthorized] = useState(false)

  const addHandler = () => {
    dispatch(createForm(DEFAULT))
    navigate("/default")
  }

  const fetchStudies = async () => {
    try {
      setIsLoading(true)
      const result = await FirebaseService.fetchAllStudies()
      console.log('result: ', result);
      setIsLoading(false)
      setStudies(result?.data  ?? [])
    } catch (error) {
      console.log('error: ', error);
      setIsLoading(false)
      setStudies([])
      displayNotification("error", "Something went wrong!")
    }
  }

  const handleSelection = async (data) => {
    navigate(
      `/study/${data.docId}`,
      {
        state: {
          studyType: data.studyType,
          studyName: data.studyName,
          templateId: data.templateId,
          templateFormId: data.templateFormId,
        },
      },
    )
  }

  const handleList = (id) => {
    const updatedList = studies.filter((obj) => {
      return obj.formId !== id
    })
    setStudies(updatedList)
  }
  const handleZoomAuthorization = async () => {
    setIsZoomAuthInProgress(true)
    try {
      const functions = getFunctions()
      const authorizeZoom = httpsCallable(functions, "authorizeZoom")
      const response = await authorizeZoom()
      const { status, redirect, url } = response.data
      setIsZoomAuthInProgress(false)
      if (status && redirect) {
        window.location.replace(url)
      }
    } catch (error) {
      setIsZoomAuthInProgress(false)
      displayNotification("error", error)
      console.log("ERROR : ", error)
    }
  }
  const checkZoomAuthorization = async () => {
    try {
      setZoomLoading(true)
      const functions = getFunctions()
      const isZoomAuthorized = httpsCallable(functions, "isZoomAuthorized")
      const response = await isZoomAuthorized()
      const { status } = response.data
      setIsZoomAuthorized(status)
      setZoomLoading(false)
      return status
    } catch (error) {
      setZoomLoading(false)
      console.log("checkZoomAuth error : ", error)
    }
  }
  const deleteStudy = async (id) => {
    try {
      const response = await FirebaseService.deleteStudy(id)
      const {success, message, data} = response
      if(success){
        displayNotification("success", message)
        try {
          const result = await FirebaseService.fetchAllStudies()
          setStudies(result?.data  ?? [])
        } catch (error) {
          console.log('error: ', error);
          setStudies([])
          displayNotification("error", "Failed to fetch studies")
        }
      } else {
        displayNotification("error", message ?? "Something went wrong")
      }
    } catch (error) {
      console.log('error: ', error);
      setIsLoading(false)
      displayNotification("error", "Something went wrong")
      await fetchStudies()
    }
  }
  const duplicateStudy = async (studyId,studyName) => {
    try {
      const response = await FirebaseService.duplicateStudy(studyId,studyName)
      const {success, message, data} = response
      if(success){
        displayNotification("success", message)
        try {
          const result = await FirebaseService.fetchAllStudies()
          setStudies(result?.data  ?? [])
        } catch (error) {
          console.log('error: ', error);
          setStudies([])
          displayNotification("error", "Failed to fetch studies")
        }
      } else {
        displayNotification("error", message ?? "Something went wrong")
      }
    } catch (error) {
      console.log('error: ', error);
      setIsLoading(false)
      displayNotification("error", "Something went wrong")
      await fetchStudies()
    }
  }
  const renameStudy = async (updatedData,studyId) => {
    const dataToSend = {
      body:updatedData
    }
    console.log('updatedData: ', updatedData);
    try {
      const response = await FirebaseService.renameStudy(dataToSend,studyId)
      const {success, message, data} = response
      if(success){
        displayNotification("success", message)
        try {
          const result = await FirebaseService.fetchAllStudies()
          setStudies(result?.data  ?? [])
        } catch (error) {
          console.log('error: ', error);
          setStudies([])
          displayNotification("error", "Failed to fetch studies")
        }
      } else {
        displayNotification("error", message ?? "Something went wrong")
      }
    } catch (error) {
      console.log('error: ', error);
      setIsLoading(false)
      displayNotification("error", "Something went wrong")
      await fetchStudies()
    }
  }

  useEffect(() => {
    (async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          fetchStudies()
          checkZoomAuthorization()
        } else {
          setIsLoading(false);
          console.log("Something went wrong while fetching data!");
        }
      });
    })();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Container fluid> */}
        {/* Render Breadcrumb */}
        {/* <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Home")}
          /> */}
        <Row>
          <Col className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <h2 className="form-section-title mb-sm-0 font-size-20 ">
                  Create Study
                </h2>
              </div>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <div>
                    {!zoomLoading && !isZoomAuthorized && (
                      <>
                        <Button
                          type="primary"
                          color="primary"
                          disabled={isZoomAuthInProgress}
                          onClick={handleZoomAuthorization}
                        >
                          {isZoomAuthInProgress ? (
                            <>
                              <Spinner
                                size="sm"
                                color="light"
                                className="mr-2"
                              />{" "}
                              Authorization in progress...
                            </>
                          ) : (
                            "Connect to Zoom account"
                          )}
                        </Button>
                      </>
                    )}
                    {
                      zoomLoading && <Skeleton.Input active={true} size={"small"} />
                    }
                  </div>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <div className="back mt-3 mb-3">
          <div className="cards">
            <Row>
              {DASHBOARD_DATA.map((data, index) => {
                return (
                  <>
                    <DashCard
                      key={index}
                      title={data.title}
                      body={data.body}
                      img={data.img}
                      route={data.route}
                    />
                  </>
                )
              })}
              <Col xl="2" lg="3" md="4" sm="6" xs="12">
                <Card className="study-containers" onClick={addHandler}>
                  <CardBody className="studies-card">
                    <div className="favorite-icon">
                      {/* <div className="mt-1 hstack gap-2">
                          <span className="badge p-2 badge-text ms-auto rounded-1 badge-soft-success">
                          </span>
                      </div> */}
                      <div className="img-contents">
                        {/* <PlusCircleFilled className="" /> */}
                        <img src={plusicon}></img>
                      </div>
                    </div>
                    <div className="studies-card-content">
                      <h5 className="study-title mb-2">
                        <medium className="study-primary-text text-title ">
                          Create new study
                        </medium>
                      </h5>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <p className="text-muted study-secondary-text"></p>
                        </li>{" "}
                      </ul>
                      <div className="mt-3 hstack gap-2"></div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <h2 className="form-section-title mb-sm-3 font-size-20">
            Ongoing Studies
          </h2>
          {isLoading === false && (
            <div>
              {studies && studies.length > 0 && (
                <div className="back">
                  <div className="cards">
                    <Row>
                      {isLoading && <Skeleton />}
                      {!isLoading &&
                        studies.map((data, index) => {
                          return (
                            <>
                              <PastStudiesCard
                                handleClick={handleSelection}
                                updateList={handleList}
                                data={data}
                                key={index}
                                deleteStudy={deleteStudy}
                                duplicateStudy={duplicateStudy}
                                renameStudy={renameStudy}
                              />
                            </>
                          )
                        })}
                    </Row>
                  </div>
                </div>
              )}
            </div>
          )}
          {isLoading && <PastStudiesSkeleton />}
        </div>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
