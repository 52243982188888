import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirector = ({ path, params, replace }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Navigate to the specified path
    if(replace){
      params = {...params, replace: replace}
    }
    navigate(path, params);
  }, [navigate, path]);

  // This component doesn't render anything
  return null;
};

export default Redirector;
