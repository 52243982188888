import React, { useRef } from "react"
import { Tooltip } from "antd"
import { useState } from "react"
import classnames from "classnames"
import FormBuilder from "./formBuilder"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabPane,
  TabContent,
  NavLink,
  NavItem,
  Button,
  Spinner,
} from "reactstrap"
import "./form.scss"
import { displayNotification } from "utils/utility"
import { saveStudyDetails } from "store/study/action"
import { isEqual } from "lodash"
import { useDispatch } from "react-redux"

function DynamicForm({
  script,
  wizardSteps,
  setStep,
  step,
  saveScript,
  isApiFetching,
  studyId,
  scriptId,
  studyDetails,
  isSaving,
  SavingState,
  handleSaving
}) {
  const autoSaveTimer = useRef(null)
  const dispatch = useDispatch()
  const [isTitle, setIsTitle] = useState("")
  const [formData, setFormData] = useState()
  const [isEdited, setIsEdited] = useState(false)
  const [wizardIndex, setWizardIndex] = useState(0)
  const [currentTitle, setcurrentTitle] = useState("")

  const updateStepsFormData = (title, index) => {
    script[index]["title"] = title
    setIsTitle(script[index]["title"])
    setWizardIndex(index)
  }

  // Final function to save script
  const APICall = async (updatedScript) => {
    // console.log("API CALLING --- ", studyDetails.templateForm) // from REDUX
    // console.log("API CALLING latestdata --- ", updatedScript);
    await saveScript(updatedScript)
  }

  const addSection = async () => {
    try {
      const emptySection = {
        name: `Untitled`,
        title: `Untitled`,
        root: [] 
      }
      const newScript = [...script]
      newScript.push(emptySection)
      const newStudyDetails = { ...studyDetails, templateForm: newScript }
      const areTheyEqual = isEqual(studyDetails, newStudyDetails)
      // console.log('areTheyEqual: ', areTheyEqual);
      // console.log("New : ", newStudyDetails)
      // console.log("Old : ", studyDetails)
      if(!areTheyEqual){
        // console.log("A new section is being added...")
        dispatch(saveStudyDetails({ studyDetails: newStudyDetails }))
        await APICall(newScript)
      }
    } catch (error) {
      console.log('Error add new section: ', error);
      displayNotification("error", "Failed to add a new section")
    }
  }

  const removeSection = async (index) => {
    // console.log('remove sectioin at index: ', index);
    try {
      const newScript = [...script]
      newScript.splice(index, 1);
      const newStudyDetails = { ...studyDetails, templateForm: newScript }
      const areTheyEqual = isEqual(studyDetails, newStudyDetails)
      // console.log('areTheyEqual: ', areTheyEqual);
      // console.log("New : ", newStudyDetails)
      // console.log("Old : ", studyDetails)
      if(!areTheyEqual){
        // console.log("Current section is being removed...")
        dispatch(saveStudyDetails({ studyDetails: newStudyDetails }))
        await APICall(newScript)
      }
    } catch (error) {
      console.log('Error remove section: ', error);
      displayNotification("error", "Failed to remove the section")
    }
  }



  return (
    <Container>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              {/* <h4 className="card-title mb-4"></h4> */}
              <div className="vertical-wizard wizard clearfix vertical">
                <div className="steps clearfix pt-4">
                  <ul>
                    { Array.isArray(wizardSteps) &&
                      wizardSteps.map((stepData, index) => {
                        return (
                          <>
                          <NavItem
                            key={index}
                            className={classnames({
                              current: step === index,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: step === index,
                                done: step > index,
                              })}
                              onClick={() => {
                                setStep(index)
                              }}
                              disabled={
                                step === index ||
                                (currentTitle.length === 0 &&
                                  step.length !== 0)
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span className="number">{index + 1}.</span>{" "}
                              {isTitle && wizardIndex === index
                                ? ""
                                : !currentTitle && wizardIndex === index
                                ? ""
                                : stepData.title}
                              {index === wizardIndex ? isTitle : ""}
                              <div className="ml-auto remove-button">
                                <Button
                                  color="transparent"
                                  size="sm"
                                  // outline
                                  onClick={() => removeSection(index)}
                                  className="text-muted"
                                  disabled={isSaving === SavingState.SAVING}
                                >
                                  <i className="mdi mdi-trash-can d-block font-size-16"></i>
                                </Button>
                              </div>
                            </NavLink>
                          </NavItem>
                          </>

                        )
                      })}
                  </ul>
                </div>
                <div className="content clearfix pt-0" >
                  <TabContent activeTab={step + 1} className="body">
                    {Array.isArray(script) && script.length !== 0 &&
                      script.map((section, secIdx) => {
                        return (
                          <TabPane key={secIdx} tabId={secIdx + 1}>
                            {Array.isArray(script) && script[secIdx] && (
                                <FormBuilder
                                  studyDetails={studyDetails}
                                  secIdx={secIdx}
                                  section={section.root}
                                  script={script}
                                  name={section.name}
                                  title={section.title}
                                  saveScript={saveScript}
                                  wizardSteps={wizardSteps}
                                  step={step}
                                  setFormData={setFormData}
                                  formData={formData}
                                  setIsEdited={setIsEdited}
                                  handleSaving={handleSaving}
                                  updateStepsFormData={updateStepsFormData}
                                  setcurrentTitle={setcurrentTitle}
                                  studyId={studyId}
                                  scriptId={scriptId}
                                  autoSaveTimer={autoSaveTimer}
                                  APICall={APICall}
                                  SavingState={SavingState}
                                  isSaving={isSaving}
                                />
                            )}
                          </TabPane>
                        )
                      })}
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                  <li>
                      <Button
                        id="addButton"
                        color="success"
                        onClick={() => {addSection()}}
                        disabled={isSaving === SavingState.SAVING}
                      >
                        Add
                      </Button>
                    </li>
                    {step !== 0 && (
                      <li className="previous">
                        <Button
                          color="primary"
                          onClick={() =>
                            setStep(step - 1)
                          }
                          disabled={
                            currentTitle.length === 0 &&
                            step.length !== 0
                          }
                        >
                          Previous
                        </Button>
                      </li>
                    )}

                    {step === wizardSteps.length - 1 ? (
                      <li className="next">
                        <Button color="primary" >
                          {isApiFetching ? (
                            <>
                              <Spinner
                                size="sm"
                                color="light"
                                className="mr-2"
                              />{" "}
                              Loading...
                            </>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </li>
                    ) : (
                      <li className="next">
                        <Tooltip
                          placement="top"
                          title={"  🛈  Please enter the title "}
                          open={
                            currentTitle.length === 0 &&
                            step.length !== 0
                          }
                          color={
                            currentTitle.length === 0 &&
                            step.length !== 0
                              ? "red"
                              : undefined
                          }
                        >
                          <Button
                            id="nextButton"
                            color="primary"
                            onClick={() => {
                              setStep(step + 1)
                            }}
                            disabled={
                              currentTitle.length === 0 &&
                              step.length !== 0
                            }
                          >
                            Next
                          </Button>
                        </Tooltip>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default DynamicForm
