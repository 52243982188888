const { SET_INITIAL_CHAT, UPDATE_CHAT } = require("./actionTypes")

const INIT_STATE = {
  formResponse: [],
  // formId: null,
  // studyName: "",
  // studyType: "",
  // templateFormId: null,
  currentSection: null,
  // lastSaveTime: Date.now(),
}

const ChatWindow = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_INITIAL_CHAT:
      return {
        ...state,
        formResponse: action.payload.formResponse,
        // formId: action.payload.formId,
        // studyName: action.payload.studyName,
        // studyType: action.payload.studyType,
        // templateFormId: action.payload.templateFormId,
        currentSectionIndex: action.payload.currentSectionIndex,
      }
    case UPDATE_CHAT:
      return {
        ...state,
        formResponse: action.payload.data,
        currentSectionIndex: action.payload.index,
      }
    default:
      return state
  }
}

export default ChatWindow
