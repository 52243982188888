import React, { useState } from "react"
import { Button,Card, Col, Row,Input } from "reactstrap"
import "./study.scss"
// import {Steps } from "antd"
import OptionView from "./EditOptionView"
import {
  DEFAULT_DROPDOWN_OPTIONS,
  QUESTION_TYPE_OPTIONS,
} from "constants/studyForm"
import "./study.scss"
import Select from "react-select"

const EditQuestionBlock = ({ questionData }) => {
  const [currentQue, setCurrentQue] = useState(questionData)
  const [checkList, setCheckList] = useState(DEFAULT_DROPDOWN_OPTIONS)

  const updateQuestionTitle = (val) => {
    setCurrentQue({ ...currentQue, title: val })
  }
  const updateQuestionType = (val) => {
    setCurrentQue({ ...currentQue, type: val })
  }
  const updateQuestionOptions = (val) => {
    setCurrentQue({ ...currentQue, options: val })
  }
  const getDefaultValue = () => {
    if (questionData.type === "text") {
      return QUESTION_TYPE_OPTIONS[0]
    } else if (questionData.type === "mcq") {
      return QUESTION_TYPE_OPTIONS[1]
    } else if (questionData.type === "dropdown") {
      return QUESTION_TYPE_OPTIONS[2]
    } else if (questionData.type === "checkboxes") {
      return QUESTION_TYPE_OPTIONS[3]
    } else {
      return QUESTION_TYPE_OPTIONS[0]
    }
  }

  return (
    <>
      <React.Fragment>
        <Card>
          <div>
            <Row className="form-component-min-width">
              <Col lg="24" sm="24">
                <div className=" que-block-container">
                  <div className="d-flex que-block">
                    <textarea
                      rows={2}
                      // autoFocus
                      placeholder="Type your question..."
                      value={
                        currentQue.title && currentQue.title !== ""
                          ? currentQue.title
                          : ""
                      }
                      onChange={(e) => updateQuestionTitle(e.target.value)}
                    />

                    <Select
                      onChange={(v) => updateQuestionType(v)}
                      className="w-25"
                      defaultValue={getDefaultValue()}
                      options={QUESTION_TYPE_OPTIONS}
                    ></Select>
                    {/* {!questionData.isRequired && (
                      <Button className="bg-transparent">
                        <i className="bx bxs-trash" />
                      </Button>
                    )} */}
                  </div>
                  {currentQue.type === "text" && (
                    <>
                      <input
                        className="mt-3 border-0 bg-transparent font-md"
                        value=""
                        disabled
                        placeholder={
                          questionData.placeholder
                            ? questionData.placeholder
                            : "Custom answer"
                        }
                      ></input>
                    </>
                  )}
                  {currentQue.type === "mcq" && (
                    <>
                      <OptionView
                        list={checkList}
                        updateOptions={updateQuestionOptions}
                        options={currentQue.options ?? []}
                        optionType={"radio"}
                      />
                    </>
                  )}
                  {currentQue.type === "dropdown" && (
                    <>
                      <OptionView
                        list={checkList}
                        updateOptions={updateQuestionOptions}
                        options={currentQue.options ?? []}
                        optionType={"checkbox"}
                      />
                    </>
                  )}
                  {currentQue.type === "checkboxes" && (
                    <>
                      <OptionView
                        list={checkList}
                        updateOptions={updateQuestionOptions}
                        options={currentQue.options ?? []}
                        optionType={"dropdown"}
                      />
                    </>
                  )}

                  <div className="text-muted">
                    <Button type="primary">Save</Button>
                  </div>
                </div>
              </Col>
              {/* <Col lg="3" sm="4" className="align-self-center">
                <div>
                  <img src={features} alt="" className="img-fluid d-block" />
                </div>
              </Col> */}
            </Row>
          </div>
        </Card>
      </React.Fragment>
    </>
  )
}

export default EditQuestionBlock
