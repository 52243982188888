import { SET_PROGRESS_COUNT, UPDATE_PROGRESS_COUNT } from "./actionType"


const INIT_STATE = {
  progressCount: 0,
}

const UploadFiles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PROGRESS_COUNT:
      return {
        ...state,
        progressCount: action.payload.progressCount,
    
      }
    case UPDATE_PROGRESS_COUNT:
      return {
        ...state,
        progressCount: action.payload.progressCount,
      }
    default:
      return state
  }
}

export default UploadFiles
