import ConfirmMsg from "components/Common/ConfirmMsg"
import Spinners from "components/Common/Spinner"
import { getFunctions, httpsCallable } from "firebase/functions"
import React, { useEffect, useState } from "react"
import { displayNotification } from "utils/utility"

const ZoomRedirectPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const handleZoomRedirection = async () => {
    setIsLoading(true)
    try {
      const queryParams = new URLSearchParams(window.location.search)
      const code = queryParams.get("code")
      const functions = getFunctions()
      const redirectZoom = httpsCallable(functions, "redirectZoom")
      const response = await redirectZoom({ code: code })
      if (response.data.status) {
        displayNotification("success", response.data.message)
      }
      setIsLoading(false)
    } catch (error) {
      displayNotification("error", error.message)
      window.close()
    }
  }
  useEffect(() => {
    handleZoomRedirection()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Spinners text={"Authorization in progress..."} />}
        {!isLoading && (
          <ConfirmMsg/>
        )}
      </div>
    </React.Fragment>
  )
}

export default ZoomRedirectPage
