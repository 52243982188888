import { $getSelection, $INTERNAL_isPointSelection } from "lexical"
import React, { useCallback } from "react"
import { Dropdown, Select } from "antd"
import { $patchStyleText } from "@lexical/selection"
import { DropdownItem } from "reactstrap"

const FONT_FAMILY_OPTIONS = [
  { label: "Arial", value: "Arial" },
  { label: "Courier New", value: "Courier New" },
  { label: "Georgia", value: "Georgia" },
  { label: "Times New Roman", value: "Times New Roman" },
  { label: "Trebuchet MS", value: "Trebuchet MS" },
  { label: "Verdana", value: "Verdana" },
]

const FONT_SIZE_OPTIONS = [
  { label: "10px", value: "10px" },
  { label: "11px", value: "11px" },
  { label: "12px", value: "12px" },
  { label: "13px", value: "13px" },
  { label: "14px", value: "14px" },
  { label: "15px", value: "15px" },
  { label: "16px", value: "16px" },
  { label: "17px", value: "17px" },
  { label: "18px", value: "18px" },
  { label: "19px", value: "19px" },
  { label: "20px", value: "20px" },
]

const FontDropDown = ({ editor, value, style, disabled = false }) => {
  const handleClick = useCallback(
    (option) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($INTERNAL_isPointSelection(selection)) {
          $patchStyleText(selection, {
            [style]: option,
          })
        }
      })
    },
    [editor, style],
  )

  return (
    <Select
    style={{width:"120px", columnGap:"0px"}}
      options={
        style === "font-family" ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS
      }
      onChange={(value) => handleClick(value)}

      defaultValue={style=== "font-family" ? { label: "Arial", value: "Arial" } : { label: "13px", value: "13px" }}
    ></Select>
  
  )
}

export default FontDropDown
