import { Skeleton } from 'antd'
import { getFunctions, httpsCallable } from 'firebase/functions'
import FormRender from 'pages/DynamicForm/formRender'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Card } from 'reactstrap'
import { createSelector } from 'reselect'
import { updateChat } from 'store/chatWindow/actions'
import { displayNotification } from 'utils/utility'

const Script = () => {
  const dispatch = useDispatch()
  const { studyId, templateId, meetingId, templateFormId } = useParams()
  const [isOpen, setIsOpen] = useState(true)
  const [template, setTemplate] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const selectChatWindowState = (state) => state.ChatWindow
  const selectChatWindowProperties = createSelector(
    selectChatWindowState,
    (chatWindow) => ({
      formResponse: chatWindow.formResponse,
    }),
  )
  const { formResponse } = useSelector(selectChatWindowProperties)


  const fetchFormTemplateDetails = async () => {
    try {
      setIsLoading(true)
      const functions = getFunctions()
      const getTemplateForm = httpsCallable(functions, "getTemplateForm")
      const result = await getTemplateForm({
        templateFormId: templateFormId,
        formId: studyId,
        isEditable: true,
      })
      if (result.data.success) {
        const newTemplate = result.data.data.templateForm.slice(1)
        setTemplate(newTemplate)
        dispatch(
          updateChat({
            data: newTemplate,
          }),
        )
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      displayNotification("error", error.message ?? "something went wrong")
      console.log("error", error)
    }
  }
  const stepsData =
  template?.length !== 0
    ? template.map((temp, index) => ({
        title: temp.title,
      }))
    : []
    const handleUpdateTemplate = (latestData) => {
      setTemplate(latestData)
      dispatch(
        updateChat({
          data: latestData,
        }),
      )
    }
    const next = () => {
      setCurrentStep(currentStep + 1)
    }
    const prev = () => {
      setCurrentStep(currentStep - 1)
    }
    const saveForm = async () => {
      try {
        const functions = getFunctions()
        const saveFormResponses = httpsCallable(functions, "saveFormResponses")
        setIsLoading(true)
        const data = {
          formResponse: formResponse,
          formId: studyId,
          meetingId: meetingId,
          studyName: "",
          templateFormId: templateFormId,
        }
        const result = await saveFormResponses(data)
        if (result.data.success) {
          // displayNotification("success", result.data.message)
          handleUpdateTemplate(result.data.data)
          setIsLoading(false)
        }
      } catch (error) {
        // displayNotification("error", "Something went wrong!")
        console.log("Error : ", error)
        setIsLoading(false)
      }
    }

  useEffect(() => {
    fetchFormTemplateDetails()
  }, [])
  return (
    <React.Fragment>
      <div className='page-content'>
        {isLoading ? (
          <Skeleton
            className="p-2"
            loading={isLoading}
            active={isLoading}
          />
        ) : (
          <div className="chat-script">
            <div className="chat-content">
              <div className="d-flex">
                <div className="flex-grow-1 overflow-hidden">
                  <div>
                    <p className="text-muted ">
                      {!isLoading && formResponse.length === 0 ? (
                        <div className="text-center text-muted mt-3">No data found</div>
                      ) : (
                        isOpen && !isLoading && formResponse.map((temp, index) => (
                          currentStep === index && (
                            <FormRender
                              key={index}
                              data={temp.root}
                              sectionIndex={index}
                              list={formResponse}
                              name={temp.name}
                              title={temp.title}
                              showButton={true}
                              handleUpdateTemplate={handleUpdateTemplate}
                            />
                          )
                        ))
                      )}
                    </p>
                  </div>
                  {isOpen && !isLoading && (
                    <div
                      className="lexicaltemplate"
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <Card
                        className="templatecard"
                        style={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          display: "flex",
                          border: "none",
                        }}
                      >
                        <div
                          className="ml-auto"
                          style={{
                            // position: "fixed",
                            display: "flex",
                            // justifyContent:"center"
                            // marginBottom: "15px",
                            // bottom: "100px",
                            // right: "20px",
                            // zIndex: "999",
                          }}
                        >
                          {currentStep > 0 && (
                            <Link
                              className="btn btn-outline-primary mx-2"
                              onClick={() => {
                                prev()
                                // saveForm()
                              }}
                            >
                              <i className="fas fa-arrow-left font-size-16 align-middle me-2"></i>
                            </Link>
                          )}
  
                          {currentStep === 5 && (
                            <Link
                              className="btn btn-primary mx-2"
                              type="primary"
                              onClick={() => {
                                saveForm()
                              }}
                            >
                              save
                            </Link>
                          )}
                          {currentStep < stepsData.length - 1 && (
                            <Link
                              className="btn btn-primary mx-2"
                              type="primary"
                              onClick={() => {
                                next()
                                saveForm()
                              }}
                            >
                              <i className="fas fa-arrow-right font-size-16 align-middle me-2"></i>
                            </Link>
                          )}
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
  
}

export default Script