import React, { createRef, useContext, useEffect, useState } from "react"
import $ from "jquery"
import { MyContext } from "./context"
import "./form.scss"
import { updateTemplateForm } from "../../store/dynamicForm/actions"
import { useDispatch } from "react-redux"
import { getFunctions, httpsCallable } from "firebase/functions"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { displayNotification } from "utils/utility"
import useAutoSave from "./useAutoSave" // Import the useAutoSave hook

import { setInitialTemplateForm } from "store/dynamicForm/actions"
import { Input, FormFeedback } from "reactstrap"
window.jQuery = $
window.$ = $
require("jquery-ui-sortable")
require("formBuilder")

const FormBuilder = ({
  data,
  sectionIndex,
  list,
  name,
  title: initialTitle,
  setFormData,
  setIsEdited,
  handleSaving,
  setDataOfForm,
  updateStepsFormData,
  setcurrentTitle,
  studyId,
  templateFormId
}) => {
  const fb = createRef()
  const [formBuilder, setFormBuilder] = useState(null)
  const { form, setForm } = useContext(MyContext)
  const [title, setTitle] = useState(initialTitle)
  const dispatch = useDispatch()
  const [prevFormData, setPrevFormData] = useState(null)
  const [formBuilderData, setFormBuilderData] = useState(data)
  // const selectFormTemplateState = (state) => state.TemplateForm
  // const selectTemplateProperties = createSelector(
  //   selectFormTemplateState,
  //   (tempData) => ({
  //     templateForm: tempData.templateForm,
  //     formId: tempData.formId,
  //     templateFormId: tempData.templateFormId,
  //   }),
  // )
  // const { templateForm, templateFormId, formId } = useSelector(
  //   selectTemplateProperties,
  // )

  const handleEdit = () => {
    setIsEdited(true)
  }

  const handleTitleChange = (e) => {
    const newTitle = e.target.value

    const isEmptyInput = newTitle === ""

    if (!isEmptyInput) {
      setTitle(newTitle)
      updateStepsFormData(newTitle, sectionIndex)
    } else {
      setTitle("")
      updateStepsFormData("", sectionIndex)
    }
  }

  useEffect(() => {
    setcurrentTitle(title)
  }, [title])

  useEffect(() => {
    if (!formBuilder?.formData) {
      setFormBuilder(
        $(fb.current).formBuilder({
          disabledActionButtons: ["data", "clear", "save"],
          editOnAdd: true,
          controlOrder: [
            "text",
            "textarea",
            "paragraph",
            "checkbox-group",
            "radio-group",
            "select",
          ],
          formData: data,
          disabledAttrs: [
            "value",
            "placeholder",
            "access",
            "className",
            "name",
            "toggle",
            "description",
            "inline",
            "other",
            "rows",
            "max",
            "subtype",
            "maxlength",
          ],
          disableFields: [
            "autocomplete",
            "datefield",
            "fileupload",
            "button",
            "date",
            "file",
            "header",
            "hidden",
            "number",
          ],
          // onOpenFieldEdit: handleEdit,
        }),
      )
    }
  }, [])
  useEffect(() => {
    if (formBuilder?.formData) {
      setFormBuilderData(formBuilder?.formData)
    }
  }, [formBuilder?.formData])

  async function saveData(formDataToSave) {
    // Check if the formDataToSave is different from the previous form data
    if (formDataToSave !== prevFormData) {
      setForm(formDataToSave)

      const updatedData =
        formDataToSave != undefined && JSON.parse(formDataToSave)
      const newList = [...list]
      newList[sectionIndex] = { name: name, title: title, root: updatedData }
      dispatch(
        updateTemplateForm({
          data: newList,
        }),
      )
      setFormData(newList)
      setDataOfForm(formDataToSave)

      try {
        handleSaving(true)
        const functions = getFunctions()
        const saveTemplateForm = httpsCallable(functions, "saveTemplateForm")
        const data = {
          templateForm: newList,
          formId: studyId,
          templateFormId: templateFormId,
        }
        const result = await saveTemplateForm(data)
        if (result.data.success) {
          // console.log("Data saved successfully")
          // displayNotification("success", result.data.message)
        }
        handleSaving(false)
      } catch (error) {
        handleSaving(false)
        console.error("Error saving data:", error.message)
        displayNotification("error", error?.message ?? "Something went wrong!")
      }

      // Update the previous form data
      setPrevFormData(formDataToSave)
    }
  }

  // Call useAutoSave hook with saveData as callback function
  useAutoSave(formBuilderData, title, list, name, sectionIndex, saveData)

  return (
    <div className="section-spacing form-builder">
      <Input
        type="text"
        value={title}
        onChange={handleTitleChange}
        className={`form-builder-Input ${
          title.length === 0 ? "empty-title" : ""
        }`}
        invalid={title.length === 0 ? true : false}
      />
      {title.length === 0 && (
        <FormFeedback type="invalid">{"Please enter the title"}</FormFeedback>
      )}
      <div className="cb-wrap pull-right" ref={fb} />
      <div>
        <div className="btn-toolbar"></div>
      </div>
    </div>
  )
}

export default FormBuilder
