import { useEffect, useRef } from "react"
import { updateTemplateForm } from "../../store/dynamicForm/actions"
import { useDispatch } from "react-redux"

const useAutoSave = (dataToSave, title, list, name, sectionIndex, onSave) => {
  const dispatch = useDispatch()
  const prevDataToSaveRef = useRef(null)

  useEffect(() => {
    // Check if the data has changed since the last render
    if (dataToSave !== prevDataToSaveRef.current) {
      const saveData = async () => {
        try {

          // const updatedData = dataToSave != undefined && JSON.parse(dataToSave)
          // const newList = [...list]
          // newList[sectionIndex] = {
          //   name: name,
          //   title: title,
          //   root: updatedData,
          // }
          // dispatch(
          //   updateTemplateForm({
          //     data: newList,
          //   }),
          // )
          await onSave(dataToSave)
        } catch (error) {
          console.error("Error auto-saving data:", error)
        }
      }

      // Call the saveData function after a certain interval
      const saveInterval = setInterval(saveData, 1000) // 2000ms (2 seconds)

      return () => clearInterval(saveInterval)
    }
  }, [dataToSave, onSave])

  // Update the reference to the previous data after each render
  useEffect(() => {
    prevDataToSaveRef.current = dataToSave
  }, [dataToSave])
}

export default useAutoSave
