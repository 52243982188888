import React, { createContext, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { getAuth, onAuthStateChanged } from "firebase/auth"

// Create context
const UserDataContext = createContext()

const Authmiddleware = (props) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [initialCheckDone, setInitialCheckDone] = useState(false)
  const [user, setUser] = useState(null) 
  const auth = getAuth()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser) 
      setAuthenticated(!!authUser) 
      setInitialCheckDone(true) 
    })

    return () => unsubscribe()
  }, [auth])

  if (!initialCheckDone) {
    return null 
  }

  if (!authenticated) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }

  return (
    <UserDataContext.Provider value={user}>
      <React.Fragment>{props.children}</React.Fragment>
    </UserDataContext.Provider>
  )
}

export default Authmiddleware
export { UserDataContext }
