import React, { useEffect, useRef } from "react"
import * as d3 from "d3"

const MyChart = ({ data, switchColor, fontSize,switchborder,switchOutline }) => {
  const svgRef = useRef()

  const blueColorRange = ["red","#B5E3EC",  "#91D0DC", "#72BDCB",]
  const redColorRange = ["#fff0f2","#ffebee", "#ffcdd2", "#fcaeae","#e57373", "#b71c1c"]
const blueBorder ="#2898ad"
const redBorder = "#f26969"
const borderRange = switchOutline ? blueBorder :redBorder
  const colorRange = switchColor ? blueColorRange : redColorRange;
  const borders = switchborder ? "1px solid black" : "none";
  useEffect(() => {
    const width = 740
    const height = width

    const color = d3
      .scaleLinear()
      .domain([0, 5])
      .range(
        switchColor
          ? ["hsl(152,80%,80%)", "hsl(228,30%,40%)"]
          : ["hsl(360,80%,80%)", "hsl(330,30%,40%)"],
      )
      .interpolate(d3.interpolateHcl)

    const pack = (data) =>
      d3.pack().size([width, height]).padding(3)(
        d3
          .hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value),
      )
    const root = pack(data)

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
      .attr("width", width)
      .attr("height", height)
      // .attr("style", `max-width: 100%; height: auto; display: block; margin: 0 -14px; background: ${color(0)}; cursor: pointer;`);
      .attr(
        "style",
        `max-width: 100%; height: auto; display: block; margin: 0 -14px; background: white ; cursor: pointer;`,
      )

    const node = svg
      .append("g")
      .selectAll("circle")
      .data(root.descendants().slice(1))
      .join("circle")
      // .attr("fill", d => d.children ? color(d.depth) : "white")
      .attr("fill", d =>  colorRange[d.depth] )
      // .attr("fill", (d) => {
      //   console.log("inside circle", d)
      //   return " #FF7F7F "
      // })
      .attr("pointer-events", (d) => (!d.children ? "none" : null))
      .on("mouseover", function () {
        d3.select(this).attr("stroke", `${borderRange}`)
      })
      .on("mouseout", function () {
        d3.select(this).attr("stroke", null)
      })
      .on(
        "click",
        (event, d) => focus !== d && (zoom(event, d), event.stopPropagation()),
      )

    const label = svg
      .append("g")
      .style("font", `${fontSize}px poppins`)
      .style("fill", "black") 
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .style("fill-opacity", (d) => (d.parent === root ? 1 : 0))
      .style("display", (d) => (d.parent === root ? "inline" : "none"))
      .text((d) => d.data.name)

    svg.on("click", (event) => zoom(event, root))
    let focus = root
    let view
    zoomTo([focus.x, focus.y, focus.r * 2])

    function zoomTo(v) {
      const k = width / v[2]

      view = v

      label.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`,
      )
      node.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`,
      )
      node.attr("r", (d) => d.r * k)
    }

    function zoom(event, d) {
      const focus0 = focus

      focus = d

      const zoomTransition = svg
        .transition()
        .duration(event.altKey ? 7500 : 750)
        .tween("zoom", (d) => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2])
          return (t) => zoomTo(i(t))
        })

      label
        .filter(function (d) {
          return d.parent === focus || this.style.display === "inline"
        })
        .transition()
        .style("fill-opacity", (d) => (d.parent === focus ? 1 : 0))
        .on("start", function (d) {
          if (d.parent === focus) this.style.display = "inline"
        })
        .on("end", function (d) {
          if (d.parent !== focus) this.style.display = "none"
        })
    }

    return () => {
      svg.selectAll("*").remove()
    }
  }, [data, switchColor, fontSize,switchborder,switchOutline])

  return <svg ref={svgRef} />
}

export default MyChart
