import React from "react"
import { Card, Col, Row,Label,Button } from "reactstrap"
import ReadOptionView from "./ReadOptionView"
import { useDispatch } from "react-redux"
import {
  updateCurrentQuestion,
  updateCurrentSection,
} from "store/studyform/actions"

const ReadQuestionBlock = ({
  questionData,
  queIndex,
  secIndex,
  sectionData,
  currentFormData,
  handleCurrentQue,
}) => {
  const dispatch = useDispatch()
  return (
    <>
      <React.Fragment>
        {/* <Card> */}
          <div>
            <Row className="form-wizard-wrapper">
              <Col lg="8" sm="8">
                <div className=" que-block-container">
                  <div className="d-flex que-block justify-content-between">
                  {/* {questionData.isRequired ? (
                      <Label>
                        {questionData.title}
                        <span className="color-red"> *</span>
                      </Label>
                    ) : ( */}
                      <Label>{questionData.title}</Label>
                    {/* )} */}
                    {/* {questionData.isEditable && (
                      <>
                        <div className="">
                          <Button
                            // onClick={() => {
                            //   dispatch(
                            //     updateCurrentQuestion({
                            //       ...questionData,
                            //       queIndex: queIndex,
                            //     }),
                            //   )
                            //   dispatch(
                            //     updateCurrentSection({
                            //       ...sectionData,
                            //       secIndex: secIndex,
                            //     }),
                            //   )
                            // }}
                            onClick={() =>
                              handleCurrentQue({
                                ...questionData,
                                queIndex: queIndex,
                              })
                            }
                          >
                            <i className="bx bx-pencil" />
                          </Button>
                        </div>
                      </>
                    )} */}
                  </div>
                  {questionData.type && (
                    <>
                      <ReadOptionView
                        list={questionData.options ?? []}
                        type={questionData.type}
                        queIndex={queIndex}
                        secIndex={secIndex}
                        defaultValue={questionData?.defaultValue}
                        disabled={questionData?.isDisabled}
                        ansValue={questionData?.ansValue ?? ""}
                      />
                    </>
                  )}
                  <div className="text-muted"></div>
                </div>
              </Col>
              <Col lg="3" sm="4" className="align-self-center">
                <div>
                  {/* <img src={features} alt="" className="img-fluid d-block" /> */}
                </div>
              </Col>
            </Row>
          </div>
        {/* </Card> */}
      </React.Fragment>
    </>
  )
}

export default ReadQuestionBlock
