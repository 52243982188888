import { Skeleton } from "antd"
import { getFunctions, httpsCallable } from "firebase/functions"
import FormRender from "pages/DynamicForm/formRender"
import React, { useEffect, useState } from "react"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import classnames from "classnames"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

const FormResponse = () => {
  const [responseData, setResponseData] = useState([])
  const { studyId, meetingId, templateFormId } = useParams()
  const [isLoading, setIsLoading] = useState()
  const [currentStep, setCurrentStep] = useState(0)
  const navigate = useNavigate()

  const formResponse = async () => {
    try {
      const functions = getFunctions()
      const getFormResponses = httpsCallable(functions, "getFormResponses")
      const response = await getFormResponses({
        formId: studyId,
        templateFormId: templateFormId,
        meetingId: meetingId,
      })
      setResponseData(response.data.data[0])
    
    } catch (error) {
      console.error("Error occurred while fetching form responses:", error)
    }
  }
  const stepsData = responseData.map((temp, index) => ({
    title: temp.title,
  }))

  useEffect(() => {
    formResponse()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm="12">
              <Row>
                {responseData.length !== 0 && (
                  <Col lg="12">
                    <Card className="form-wizard-wrapper">
                      <CardBody>
                        <div className="home-btn d-none d-sm-block">
                          <Button
                            color="primary"
                            outline
                            onClick={() => navigate(-1)}
                          >
                            Back
                          </Button>
                        </div>
                        <div className="vertical-wizard wizard clearfix vertical">
                          <div
                            className="steps clearfix"
                            style={{ marginTop: "50px" }}
                          >
                            <ul>
                              {stepsData.map((step, index) => (
                                <NavItem
                                  key={index}
                                  className={classnames({
                                    current: currentStep === index,
                                  })}
                                >
                                  <NavLink
                                    style={{ fontSize: "24px" }}
                                    className={classnames({
                                      active: currentStep === index,
                                      done: currentStep > index,
                                    })}
                                    onClick={() => setCurrentStep(index)}
                                    disabled={currentStep < index}
                                  >
                                    <span className="number">{index + 1}.</span>{" "}
                                    {step.title}
                                  </NavLink>
                                </NavItem>
                              ))}
                            </ul>
                          </div>
                          <div className="content clearfix">
                            <TabContent
                              activeTab={currentStep + 1}
                              className="body"
                            >
                              {responseData.length !== 0 &&
                                responseData.map((temp, index) => {
                                  return (
                                    <TabPane key={index} tabId={index + 1}>
                                      {responseData.length !== 0 &&
                                        responseData.map((temp, index) => {
                                          return (
                                            <>
                                              {currentStep === index && (
                                                <FormRender
                                                  data={temp.root}
                                                  sectionIndex={index}
                                                  list={responseData}
                                                  name={temp.name}
                                                  title={temp.title}
                                                  showButton={false}
                                                />
                                              )}
                                            </>
                                          )
                                        })}
                                    </TabPane>
                                  )
                                })}
                            </TabContent>
                          </div>
                          <div className="actions clearfix">
                            <ul>
                              <li
                                className={
                                  currentStep === 0
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                {currentStep > 0 && (
                                  <div className="d-flex flex-wrap gap-2">
                                    <Button
                                      color="primary"
                                      outline
                                      onClick={() =>
                                        setCurrentStep(currentStep - 1)
                                      }
                                    >
                                      Previous
                                    </Button>
                                  </div>
                                )}
                              </li>
                              <li>
                                {/* {currentStep === stepsData.length - 1 ? (
                                  <Button
                                    color="primary"
                                    className={
                                      isLoading
                                        ? "disabled btn btn-primary waves-effect waves-light "
                                        : ""
                                    }
                                    loading={isLoading}
                                    // onClick={handleSubmission}
                                    disabled={isLoading}
                                    style={{
                                      cursor: isLoading
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                  >
                                    {isLoading ? (
                                      <>
                                        <Spinner
                                          size="sm"
                                          color="light"
                                          className="mr-2"
                                        />{" "}
                                        Loading...
                                      </>
                                    ) : (
                                      "Save"
                                    )}
                                  </Button>
                                ) : (
                                  <div className="d-flex flex-wrap gap-2">
                                    <Button
                                      color="primary"
                                      className="btn btn-primary waves-effect waves-light"
                                      onClick={() =>
                                        setCurrentStep(currentStep + 1)
                                      }
                                    >
                                      Next
                                    </Button>
                                  </div>
                                )} */}
                                {currentStep !== stepsData.length - 1 ? (
                                  <div className="d-flex flex-wrap gap-2">
                                    <Button
                                      color="primary"
                                      className="btn btn-primary waves-effect waves-light"
                                      onClick={() =>
                                        setCurrentStep(currentStep + 1)
                                      }
                                    >
                                      Next
                                    </Button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                {responseData.length === 0 && (
                  <Skeleton isLoading={isLoading} active={isLoading} />
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormResponse
