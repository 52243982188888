import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/rectangle.png"
import logoLightPng from "../../assets/images/rectangle.png"
import logoLightSvg from "../../assets/images/rectangle.png"
import logoDark from "../../assets/images/rectangle.png"
import hureo from "../../assets/images/hureo.png"

const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={hureo} className="logo-sm-window" alt="" height=""/>
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {},
)(withRouter(withTranslation()(Sidebar)))
