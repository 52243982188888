import React from "react"
import { useNavigate } from "react-router-dom"
import LandingPageHTML from "./LandingPageHTML"

const LandingPage = () => {
  const navigate = useNavigate()
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <>
      <React.Fragment>
        <LandingPageHTML handleNavigate={handleNavigate} />
      </React.Fragment>
    </>
  )
}

export default LandingPage
