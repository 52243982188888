import React, { useState } from "react"
import { Button, Card, CardBody, Col, Input, Label, Table } from "reactstrap"
import SimpleBar from "simplebar-react"


const EditOptionView = ({ options, updateList, updateOptions, optionType }) => {
  const [optionTextValue, setOptionTextValue] = useState()
  const addOptionItem = () => {
    const newItem = {
      id: String(options.length + 1),
      title: "Option " + String(options.length + 1),
      placeholder: "Option " + String(options.length + 1),
      value: "Option " + String(options.length + 1),
    }
    const updatedList = [...options, newItem]
    updateOptions(updatedList)
  }
  const removeOptionItem = (item) => {
    const filteredList = options.filter((obj) => obj.id !== item.id)
    updateOptions(filteredList)
  }

  const updateOptionValue = (val, index) => {
    options[index] = val
    updateOptions(options)
  }

  return (
    <>
      <Col xl="10">
        <Card className="w-100">
          <CardBody>
            <div className="mt-4">
              <SimpleBar style={{ maxHeight: "250px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap align-middle table-hover">
                    <tbody>
                      {options &&
                        options.map((item, index) => (
                          <>
                            <tr key={index}>
                              <td>
                                <div className="form-check que-block">
                                  <input
                                    type={
                                      optionType === "mcq"
                                        ? "radio"
                                        : "checkbox"
                                    }
                                    disabled={true}
                                    className="form-check-input radio h-content-max"
                                    placeholder={item.placeholder}
                                    value={item.value}
                                    id="customCheck1"
                                  />
                                  <Label
                                    for="customCheck1"
                                  >
                                    <textarea
                                      className="form-control"
                                      // autoFocus={index === 0 ? true : false}
                                      placeholder={item.placeholder}
                                      value={item.value}
                                      onChange={(e) =>
                                        updateOptionValue(e.target.value, item)
                                      }
                                    />
                                  </Label>
                                </div>
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14">
                                  {/* --- */}
                                </h5>
                                <p className="text-muted mb-0">{/* --- */}</p>
                              </td>
                              <td>
                                <div>
                                  <ul className="list-inline mb-0 font-size-16">
                                    <li className="list-inline-item">
                                      {/* --- */}
                                    </li>
                                    <li className="list-inline-item">
                                      {options.length > 1 && (
                                        <Button
                                          style={buttonStyle}
                                          onClick={() => removeOptionItem(item)}
                                          className="text-danger p-1"
                                        >
                                          <i className="bx bxs-trash" />
                                        </Button>
                                      )}
                                    </li>
                                    <li className="list-inline-item">
                                      {/* --- */}
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                  {/* <Button
                    onClick={addOptionItem}
                    className="p-1 bg-transparent text-primary"
                  >
                    <i className="bx bx-plus" />
                  </Button> */}
                </div>
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default EditOptionView
