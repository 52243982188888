import React, { useState } from "react"
import { Divider, Grid } from "antd"
import pluginsList from "./toolbarIconsList"
// import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import useOnClickListener from "./useOnClickListener"
import FloatingLinkEditor from "./FloatingLinkEditor"
// import { createPortal } from "react-dom";
// import FloatingLinkEditor from "./FloatingLinkEditor";
// import { InsertImageDialog } from "../CustomPlugins/ImagePlugin";
import "./topbar.scss"
import { Col, Row } from "reactstrap"
import FontDropDown from "./DropDown"

const LexicalEditorTopBar = () => {
  const [fontFamily, setFontFamily] = useState('Arial');
  const [fontSize, setFontSize] = useState('13px');

  const { onClick, selectedEventTypes, blockType, isLink, editor, modal } =
    useOnClickListener()

  const isIconSelected = (plugin) =>
    selectedEventTypes.includes(plugin.event) ||
    blockType.includes(plugin.event)

  return (
    // <Grid
    //   container
    //   justifyContent="space-between"
    //   alignItems="center"
    //   // sx={{ background: "white", py: 1.5, px: 0.5 }}
    // >
    <div className="top-bar-column">
      <Row>
        <Col style={{padding:"6px"}}>
          <FontDropDown
            disabled={true}
            style={"font-family"}
            value={fontFamily}
            editor={editor}
          />
        </Col>
        <Col style={{padding:"6px"}}>
          <FontDropDown
            disabled={true}
            style={"font-size"}
            value={fontSize}
            editor={editor}
          />
        </Col>
        {pluginsList.map((plugin) => (
          <>
            <Col xl="" lg="" md="1" sm="1" xs="1" style={{padding:"6px"}}>
              <div className="lexical-top-bar">
                <plugin.Icon
                  sx={plugin.Icon}
                  onClick={() => onClick(plugin.event)}
                  color={isIconSelected(plugin) ? "secondary" : undefined}
                />
              </div>
            </Col>
          </>
        ))}
      </Row>
      {modal}
      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
      {/* </Grid> */}
    </div>
  )
}

export default LexicalEditorTopBar
