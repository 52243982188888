import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
const ConfirmMsg = ({ handleNavigate }) => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={8} xl={6}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Success !</h4>
                        <p className="text-muted">
                          Your Zoom account is now successfully authorized and
                          connected to Hureo.ai! 🚀 Head back to the dashboard
                          and start scheduling your meetings! 🌟
                        </p>
                        <div className="mt-4">
                          {/* <Button
                            color="primary"
                            onClick={() => navigate("/dashboard")}
                            className="btn btn-success"
                          >
                            Back to Dashboard
                          </Button> */}
                          <Link
                            className="btn btn-primary"
                            to="/dashboard"
                          >
                            Back to dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfirmMsg
