import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"

// import images
// import img1 from "../../assets/images/small/img-2.jpg"
// import avtar1 from "../../assets/images/users/avatar-2.jpg"
import blog2 from "./images/blog-2.jpg"

const BlogDetails = () => {
  useEffect(() => {
    scrollTo(0, 0)
  }, [])

  //meta title
  document.title = "Blog Details | Skote - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Blog" breadcrumbItem="Blog Details" /> */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            {/* <div className="mb-4">
                              <Link
                                to="#"
                                className="badge bg-light font-size-12"
                              >
                                <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                Project
                              </Link>
                            </div> */}
                            <h1>
                              <span>
                                Efficient and Budget-Friendly User Feedback
                              </span>
                              <br />
                              <span>Solutions for Product Development</span>
                            </h1>
                          </div>

                          <hr />
                          <div className="text-center">
                            <Row>
                              <Col sm={3}></Col>
                              <Col sm={3}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-4">
                                    <i className="mdi mdi-calendar me-1"></i> 5
                                    Apr, 2024
                                  </p>
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-4">
                                    <i className="mdi mdi-fountain-pen-tip"></i>{" "}
                                    Anjeli Singh
                                  </p>
                                </div>
                              </Col>
                              <Col sm={3}></Col>
                            </Row>
                          </div>
                          <hr />

                          <div className="my-5">
                            <img
                              src={blog2}
                              alt=""
                              className="img-thumbnail mx-auto d-block"
                              // style={{ width: "500px", height: "auto" }} // Adjust the width as needed
                            />
                          </div>

                          {/* <hr /> */}

                          <div className="mt-4" >
                            <div className="font-size-18">
                              <p>
                                In today's competitive market, understanding and
                                connecting with users is crucial for the success
                                of any product or business. However, for many
                                small companies and startups, the process of
                                gathering and evaluating user feedback can be
                                daunting, time-consuming, and often hindered by
                                budget constraints.
                              </p>

                              <p className="mb-4">
                                At Hureo, we've witnessed firsthand the
                                challenges that companies face when trying to
                                engage with their users effectively. While we've
                                helped numerous organizations connect with their
                                customers and make informed decisions about
                                their products, we've also encountered many
                                startups that simply couldn't afford the
                                necessary resources for regular user feedback.
                              </p>

                              {/* <blockquote className="p-4 border-light border rounded mb-4">
                                <div className="d-flex">
                                  <div className="me-3">
                                    <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                                  </div>
                                  <div>
                                    <p className="mb-0">
                                      {" "}
                                      At vero eos et accusamus et iusto odio
                                      dignissimos ducimus qui blanditiis
                                      praesentium deleniti atque corrupti quos
                                      dolores et quas molestias excepturi sint
                                      quidem rerum facilis est
                                    </p>
                                  </div>
                                </div>
                              </blockquote> */}

                              <p>
                                This dilemma led us to rethink our approach and
                                find a solution that would democratize access to
                                user feedback for businesses of all sizes. As a
                                small, bootstrapped boutique firm ourselves, we
                                understand the importance of supporting fellow
                                entrepreneurs and helping them succeed in their
                                endeavors.
                              </p>

                              <div className="mt-4">
                                <p className="mb-4">
                                  Drawing on our extensive experience in running
                                  a UX research agency and executing projects
                                  globally, we've identified the common
                                  challenges that product companies face:{" "}
                                </p>

                                <div>
                                  <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                      <div>
                                        <ol className="ps-4">
                                          <li className="py-1">
                                            <b>Cost:</b> Traditional methods of
                                            gathering user feedback can be
                                            prohibitively expensive for small
                                            businesses.
                                          </li>
                                          <li className="py-1">
                                            <b>Manual Work:</b> The process of
                                            collecting and analyzing user
                                            feedback often involves tedious
                                            manual tasks.
                                          </li>
                                          <li className="py-1">
                                            <b>Time-Consuming Work:</b> Engaging
                                            with users and interpreting feedback
                                            takes up valuable time that could be
                                            spent on other aspects of business.
                                          </li>
                                          <li className="py-1">
                                            <b>Lacking Skilled People:</b> Many
                                            companies don't have the expertise
                                            or resources to conduct effective
                                            user research.
                                          </li>
                                          <li className="py-1">
                                            <b>Fragmented Work:</b> User
                                            feedback is often scattered across
                                            different platforms and tools,
                                            making it difficult to centralize
                                            and manage.
                                          </li>
                                        </ol>
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-4 col-sm-6">
                                      <div>
                                        <ul className="ps-4">
                                          <li className="py-1">
                                            Aenean ligula eget
                                          </li>
                                          <li className="py-1">
                                            Cum sociis natoque
                                          </li>
                                        </ul>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>

                              <p className="mb-4">
                                Hureo.AI offers a seamless and cost-effective
                                solution for businesses to gather, analyze, and
                                act on user feedback. By leveraging AI-powered
                                tools and automation, we've streamlined the
                                process of user research, making it accessible
                                to businesses of all sizes.
                              </p>
                              <p className="mb-4">
                                We're excited to invite you to be part of the
                                first version of Hureo.AI. If you're interested
                                in joining our early access program and
                                providing feedback on our platform, please
                                enroll here. Your input will be invaluable as we
                                continue to refine and improve Hureo.AI.
                              </p>
                              <p className="mb-4">
                                Together, let's empower small businesses to
                                succeed through the power of empathizing with
                                our users. Build something that matters!
                              </p>
                            </div>

                            <hr />

                            {/* <div className="mt-5">
                              <h5 className="font-size-15">
                                <i className="bx bx-message-dots text-muted align-middle me-1"></i>{" "}
                                Comments :
                              </h5>

                              <div>
                                <div className="d-flex py-3">
                                  <div className="avatar-xs me-3">
                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                      <i className="bx bxs-user"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-14 mb-1">
                                      Delores Williams{" "}
                                      <small className="text-muted float-end">
                                        1 hr Ago
                                      </small>
                                    </h5>
                                    <p className="text-muted">
                                      If several languages coalesce, the grammar
                                      of the resulting language is more simple
                                      and regular than that of the individual
                                    </p>
                                    <div>
                                      <Link to="#" className="text-success">
                                        <i className="mdi mdi-reply"></i> Reply
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex py-3 border-top">
                                  <div className="avatar-xs me-3">
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-14 mb-1">
                                      Clarence Smith{" "}
                                      <small className="text-muted float-end">
                                        2 hrs Ago
                                      </small>
                                    </h5>
                                    <p className="text-muted">
                                      Neque porro quisquam est, qui dolorem
                                      ipsum quia dolor sit amet
                                    </p>
                                    <div>
                                      <Link to="#" className="text-success">
                                        <i className="mdi mdi-reply"></i> Reply
                                      </Link>
                                    </div>

                                    <div className="d-flex pt-3">
                                      <div className="avatar-xs me-3">
                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                          <i className="bx bxs-user"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14 mb-1">
                                          Silvia Martinez{" "}
                                          <small className="text-muted float-end">
                                            2 hrs Ago
                                          </small>
                                        </h5>
                                        <p className="text-muted">
                                          To take a trivial example, which of us
                                          ever undertakes laborious physical
                                          exercise
                                        </p>
                                        <div>
                                          <Link to="#" className="text-success">
                                            <i className="mdi mdi-reply"></i>{" "}
                                            Reply
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex py-3 border-top">
                                  <div className="avatar-xs me-3">
                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                      <i className="bx bxs-user"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-14 mb-1">
                                      Keith McCoy{" "}
                                      <small className="text-muted float-end">
                                        12 Aug
                                      </small>
                                    </h5>
                                    <p className="text-muted">
                                      Donec posuere vulputate arcu. phasellus
                                      accumsan cursus velit
                                    </p>
                                    <div>
                                      <Link to="#" className="text-success">
                                        <i className="mdi mdi-reply"></i> Reply
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className="mt-4">
                              <h5 className="font-size-16 mb-3">
                                Leave a Message
                              </h5>

                              <Form>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="commentname-input">
                                        Name
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="commentname-input"
                                        placeholder="Enter name"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="commentemail-input">
                                        Email
                                      </Label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="commentemail-input"
                                        placeholder="Enter email"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="mb-3">
                                  <Label htmlFor="commentmessage-input">
                                    Message
                                  </Label>
                                  <textarea
                                    className="form-control"
                                    id="commentmessage-input"
                                    placeholder="Your message..."
                                    rows="3"
                                  ></textarea>
                                </div>

                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success w-sm"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Form>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogDetails
