import { SET_PROGRESS_COUNT, UPDATE_PROGRESS_COUNT } from "./actionType"


export const setProgressCount = (data) => {
  return {
    type: SET_PROGRESS_COUNT,
    payload: data,
  }
}

export const updateProgressCount = (data) => {
  return {
    type: UPDATE_PROGRESS_COUNT,
    payload: data,
  }
}
