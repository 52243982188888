import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardSubtitle,
  Container,
  Progress,
} from "reactstrap"
import { Flex, Skeleton } from "antd"
import Dropzone from "react-dropzone"
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage" // Add uploadBytesResumable import
import { firebaseConfig } from "config/firebase" // Import the Firebase configuration object
// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useDispatch } from "react-redux"
import { updateProgressCount } from "store/UploadFiles/action"
import { displayNotification } from "utils/utility"
import { getFunctions, httpsCallable } from "firebase/functions"
import {
  getFirestore,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore"
import FirebaseService from "services/firebaseServices"
import { saveStudyDetails } from "store/study/action"
const db = getFirestore()

const FormUpload = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //meta title
  document.title = "Form File Upload | Skote - React Admin & Dashboard Template"

  const CurrentStudyState = (state) => state.CurrentStudy
  const CurrentStudyProps = createSelector(CurrentStudyState, (state) => ({
    studyId: state.studyId,
    studyDetails: state.studyDetails,
    isFetching: state.isFetching,
  }))
  const { studyDetails, isFetching } = useSelector(CurrentStudyProps)

  const AuthState = (state) => state.AuthState
  const AuthStateProps = createSelector(AuthState, (data) => ({
    authUserData: data.authUserData,
  }))
  const { authUserData } = useSelector(AuthStateProps)

  const UploadFiles = (state) => state.UploadFiles
  const UploadFilesProps = createSelector(UploadFiles, (data) => ({
    progressCount: data.progressCount,
  }))
  const { progressCount } = useSelector(UploadFilesProps)

  const userId = authUserData.uid

  const { studyId, meetingId } = useParams()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploadedFile, setUploadedFile] = useState(null)
  const [isUploaded, setIsUploaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [videoUrl, setVideoUrl] = useState(null)
  const [uploadTask, setUploadTask] = useState(null) // Track the upload task
  const [isPaused, setIsPaused] = useState(false) // Track if the upload is paused
  const [isZoomAuthorized, setIsZoomAuthorized] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = ""
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  function handleAcceptedFiles(files) {
    checkZoomAuthorization()
    const videoFiles = files.filter((file) => file.type.startsWith("video/"))
    if (videoFiles.length === 0) {
      alert("Please upload an MP4 video file only.")
      return
    }

    videoFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    )
    setSelectedFiles(videoFiles)
  }

  const conicColors = {
    "0%": "#87d068",
    "50%": "#ffe58f",
    "100%": "#ffccc7",
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleSendFiles() {
    // Here you can upload the selected file to your backend or perform any desired action
    // For demonstration purposes, I'll just set the uploaded file to the first selected file
    if (selectedFiles.length > 0) {
      setUploading(true)
      setUploadedFile(selectedFiles[0])
      selectedFiles.forEach((file) => {
        uploadFileToFirebase(file) // Upload each selected file to Firebase
      })
    }
  }

  // Initialize Firebase
  const storage = getStorage() // Use the default Firebase storage
  const storageRef = ref(storage)

  function uploadFileToFirebase(file, userID) {
    // Construct the full path using the userID, studyID, meetingID, and file name
    const filePath = `${userId}/${studyId}/${meetingId}/${file.name}`

    // Create a reference to the file in Firebase Storage
    const fileRef = ref(storageRef, filePath)

    // Upload the file
    const uploadTask = uploadBytesResumable(fileRef, file)
    setUploadTask(uploadTask) // Save the upload task

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        dispatch(
          updateProgressCount({
            progressCount: progress,
          }),
        )
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error("Error uploading file:", error)
      },
      async () => {
        try {
          setUploading(false)
          setIsUploaded(true)
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
          // sendToCollection(downloadURL)
          // displayNotification("success","Video Uploaded Successfully ")
          setVideoUrl(downloadURL)
          updateFirebaseData(downloadURL)
        } catch (error) {
          console.error("Error getting download URL:", error)
        }
      },
    )
  }

  const handleCancelUpload = () => {
    if (uploadTask !== null) {
      uploadTask.cancel() // Cancel the upload task
      setIsUploaded(false) // Reset upload status
      setSelectedFiles([]) // Clear selected files
      setUploadedFile(null) // Reset uploaded file
      setUploadTask(null) // Reset upload task
      setIsPaused(false) // Reset pause status
      setUploading(false) // Reset uploading status
      displayNotification("error", "Upload canceled")
      dispatch(
        updateProgressCount({
          progressCount: 0,
        }),
      )
    }
  }

  function handlePauseUpload() {
    if (uploadTask !== null) {
      uploadTask.pause() // Pause the upload task
      setIsPaused(true)
    }
  }

  function handleResumeUpload() {
    if (uploadTask !== null && isPaused) {
      uploadTask.resume() // Resume the upload task
      setIsPaused(false)
    }
  }

  const checkZoomAuthorization = async () => {
    try {
      const functions = getFunctions()
      const isZoomAuthorized = httpsCallable(functions, "isZoomAuthorized")
      const response = await isZoomAuthorized()
      const { status } = response.data
      setIsZoomAuthorized(status)
      return status
    } catch (error) {
      console.log("checkZoomAuth error : ", error)
    }
  }
  const updateFirebaseData = async (downloadURL) => {
    const db = getFirestore()
    const meetingsCollectionRef = collection(db, "zoomMeetings")
    
    const isMeetingIdNumber = !isNaN(meetingId);

    const q = query(
      meetingsCollectionRef,
      where("userId", "==", `${userId}`),
      where("id", "==", isMeetingIdNumber ? Number(meetingId) : meetingId)
    );
    

    try {
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach(async (doc) => {
        try {
          // Update each document with the new downloadURL
          await updateDoc(doc.ref, { downloadUrl: downloadURL })
          console.log(`Document with ID ${doc.id} updated successfully.`)
          displayNotification("success", "Video uploaded successfully")
        
        } catch (error) {
          console.error(`Error updating document with ID ${doc.id}:`, error)
        }
      })
    } catch (error) {
      console.error("Error fetching documents:", error)
    }
  }

  useEffect(() => {
    const fetchStudyDetails = async () => {
      setIsLoading(true)
      try {
        const response = await FirebaseService.fetchStudyDetails(studyId)
        const { success, data } = response
        dispatch(saveStudyDetails({ studyId: studyId, studyDetails: data }))
        //   setStudyDetails(data)
        setIsLoading(false)
      } catch (error) {
        dispatch(saveStudyDetails({ studyId: studyId, data: null }))
        // displayNotification("error", error.message ?? "Failed to fetch study details")
        setIsLoading(false)
      }
    }

    fetchStudyDetails()
  }, [])

  const navigateToBackPage = () => {
    navigate(-1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="Forms" breadcrumbItem="Form File Upload" /> */}
          <Link
            onClick={navigateToBackPage}
            className="mb-1 d-sm-flex align-items-center justify-content-between"
          >
            {!isFetching && (
              <h4 className="mb-sm-0 text-black p-2 font-size-18">
                {studyDetails?.studyName ?? ""} :{" "}
                {studyDetails?.fieldValues?.studyType ?? ""}
              </h4>
            )}
            {isFetching && <Skeleton.Input active size="small" />}
          </Link>

          <Row
            className="d-flex justify-content-center  "
            style={{ alignItems: "center", minHeight: "70vh" }}
          >
            <Col className="col-8">
              <Card>
                <CardBody>
                  {/* <h6 className="card-title">  Study Description: lorem Ipsum is simply dummy text </h6> */}
                  {/* <CardSubtitle className="mb-3">
                
                  </CardSubtitle> */}
                  <Row>
                    <Col>
                      <Form style={{ cursor: "pointer" }}>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Form>
                    </Col>
                    <Col >
                      {/* <div className="text-center  mt-4">
                        <Progress type="line" percent={progressCount} size="small" />
                      </div> */}
                      {/* <div className="text-center mt-4">
                                      <Progress
                                        color="primary"
                                        value={progressCount}
                                        size="small"
                                      >
                                        {progressCount}
                                      </Progress>
                                    </div> */}
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          const truncatedName =
                            f.name.length > 20
                              ? f.name.slice(0, 20) + "..."
                              : f.name
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <div
                                      className="text-muted font-weight-bold"
                                      title={f.name}
                                      style={{
                                        maxHeight: "40px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {truncatedName}
                                    </div>
                                    <div>
                                      <p className="mb-2">
                                        <strong>
                                          {formatBytes(
                                            (progressCount / 100) * f.size,
                                          )}{" "}
                                          / {f.formattedSize}
                                        </strong>
                                      </p>
                                    </div>
                                  </Col>
                                  <div>
                                    <div className="text-center mt-2">
                                      <Progress
                                        color="primary"
                                        value={progressCount}
                                        size="medium"
                                      >
                                        {progressCount}
                                      </Progress>
                                    </div>
                                    <div className="text-center mt-4">
                        {selectedFiles.length === 1 &&
                          !uploading &&
                          !isUploaded && (
                            <button
                              type="button"
                              color="primary"
                              className="btn btn-primary"
                              onClick={handleSendFiles}
                            >
                              Upload
                            </button>
                          )}
                        {selectedFiles.length > 0 && uploading && isPaused ? (
                          <button
                            type="button"
                            color="primary"
                            className="btn btn-primary mx-2"
                            onClick={handleResumeUpload}
                          >
                            <i className="fas far fa-play-circle"></i>
                          </button>
                        ) : (
                          selectedFiles.length > 0 &&
                          uploading && (
                            <button
                              type="button"
                              color="warning"
                              className="btn btn-warning mx-2"
                              onClick={handlePauseUpload}
                            >
                              <i className="fas far fa-pause-circle"></i>
                            </button>
                          )
                        )}
                        {uploading && (
                          <button
                            type="button"
                            color="danger"
                            className="btn btn-danger mx-2"
                            onClick={handleCancelUpload}
                          >
                            <i className="fas far fa-times"></i>
                          </button>
                        )}
                        {uploading && (
                          <p className="my-2">Uploading please wait</p>
                        )}
                      </div>
                                  </div>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                    
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormUpload
