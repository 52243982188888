import React from "react"
import { Spinner } from "reactstrap"

const Spinners = ({ text }) => {
  return (
    <React.Fragment>
      <div className="d-flex flex-column align-items-center position-absolute top-50 start-50">
        <Spinner color="primary" type="grow" />
        <p>{text}</p>
      </div>
    </React.Fragment>
  )
}

export default Spinners
