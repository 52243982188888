// @flow
import { all, fork, takeEvery, put } from "redux-saga/effects"

import {
  ADD_NEW_QUESTION_BLOCK,
  CREATE_FORM,
  UPDATE_ANSWER,
  UPDATE_CURRENT_FORM,
} from "./actionTypes"
import {
  DEFAULT,
  DEFAULT_FORM_STRUCTURE,
  EMPTY_QUESTION_BLOCK,
  USER_INTERVIEW_FORM_STRUCTURE,
  UT_FEATURE_TESTING_FORM_STRUCTURE,
  UT_ONBOARDING_FORM_STRUCTURE,
} from "./../../constants/studyForm"
import {
  USER_INTERVIEW,
  UT_FEATURE_TESTING,
  UT_ONBOARDING,
} from "constants/dashboard"

function* updateForm({ payload }) {
  try {
    if (payload === DEFAULT) {
      yield put({ type: UPDATE_CURRENT_FORM, payload: DEFAULT_FORM_STRUCTURE })
    } else if (payload === UT_ONBOARDING) {
      yield put({
        type: UPDATE_CURRENT_FORM,
        payload: UT_ONBOARDING_FORM_STRUCTURE,
      })
    } else if (payload === UT_FEATURE_TESTING) {
      yield put({
        type: UPDATE_CURRENT_FORM,
        payload: UT_FEATURE_TESTING_FORM_STRUCTURE,
      })
    } else if (payload === USER_INTERVIEW) {
      yield put({
        type: UPDATE_CURRENT_FORM,
        payload: USER_INTERVIEW_FORM_STRUCTURE,
      })
    } else {
      yield put({ type: UPDATE_CURRENT_FORM, payload: DEFAULT_FORM_STRUCTURE })
    }
  } catch (error) {
    console.log("Inside saga function. error - ", error)
  }
}

function* addNewQuestionBlock({ payload }) {
  console.log("addNewQuestionBlock - ", payload)
  try {
    const { setionIndex } = payload

    yield put({ type: UPDATE_CURRENT_FORM, payload: EMPTY_QUESTION_BLOCK })
  } catch (error) {
    console.log("Inside saga function. error - ", error)
  }
}

function* handleUpdateAnswer({ payload }) {
  try {
    const { ansValue, queIndex, secIndex, currentForm } = payload
    let updatedForm = currentForm

    if (updatedForm.length > secIndex) {
      if (updatedForm[secIndex].questions) {
        updatedForm[secIndex].questions[queIndex].ansValue = ansValue
      }
    }

    yield put({ type: UPDATE_CURRENT_FORM, payload: updatedForm })
  } catch (error) {
    console.log("Inside saga function. error - ", error)
  }
}

/**
 * Watchers
 */
export function* watchUpdateForm() {
  yield takeEvery(CREATE_FORM, updateForm)
}
export function* watchNewQuestionBlock() {
  yield takeEvery(ADD_NEW_QUESTION_BLOCK, addNewQuestionBlock)
}
export function* watchUpdateAnswer() {
  yield takeEvery(UPDATE_ANSWER, handleUpdateAnswer)
}

function* studyFormSaga() {
  yield all([
    fork(watchUpdateForm),
    fork(watchNewQuestionBlock),
    fork(watchUpdateAnswer),
  ])
}

export default studyFormSaga

//
// /**
//  * Changes the layout type
//  * @param {*} param0
//  */
// function* changeLayout({ payload: layout }) {
//     try {
//       if (layout === "horizontal") {
//         // yield put(changeTopbarThemeAction("dark"))
//         document.body.removeAttribute("data-sidebar")
//         document.body.removeAttribute("data-sidebar-image")
//         document.body.removeAttribute("data-sidebar-size")
//       } else {
//         // yield put(changeTopbarThemeAction("light"))
//       }
//       yield call(changeBodyAttribute, "data-layout", layout)
//     } catch (error) {}
//   }

//   /**
//    * Changes the layout mode
//    * @param {*} param0
//    */
//   function* changeLayoutMode({ payload: mode }) {
//     try {
//       yield call(changeHTMLAttribute, "data-bs-theme", mode)
//     } catch (error) {
//       // console.log(error);
//     }
//   }
//   // હા, અમે હવે નીચેની લિંક આપી છે ઉદાહરણ તરીકે ગ્રીડ ડેટા ટેબલને સૉર્ટ કરવા.
//   /**
//    * Changes the layout width
//    * @param {*} param0
//    */
//   function* changeLayoutWidth({ payload: width }) {
//     try {
//       if (width === "boxed") {
//         yield put(changeSidebarTypeAction("icon"))
//         yield call(changeBodyAttribute, "data-layout-size", width)
//         yield call(changeBodyAttribute, "data-layout-scrollable", false)
//       } else if (width === "scrollable") {
//         yield put(changeSidebarTypeAction("default"))
//         yield call(changeBodyAttribute, "data-layout-scrollable", true)
//       } else {
//         yield put(changeSidebarTypeAction("default"))
//         yield call(changeBodyAttribute, "data-layout-size", width)
//         yield call(changeBodyAttribute, "data-layout-scrollable", false)
//       }
//     } catch (error) {}
//   }

//   /**
//    * Changes the left sidebar theme
//    * @param {*} param0
//    */
//   function* changeLeftSidebarTheme({ payload: theme }) {
//     try {
//       yield call(changeBodyAttribute, "data-sidebar", theme)
//     } catch (error) {}
//   }

//   /**
//    * Changes the left sidebar theme Image
//    * @param {*} param0
//    */
//   function* changeLeftSidebarThemeImage({ payload: theme }) {
//     try {
//       yield call(changeBodyAttribute, "data-sidebar-image", theme)
//     } catch (error) {}
//   }

//   /**
//    * Changes the topbar theme
//    * @param {*} param0
//    */
//   function* changeTopbarTheme({ payload: theme }) {
//     try {
//       yield call(changeBodyAttribute, "data-topbar", theme)
//     } catch (error) {}
//   }

//   /**
//    * Changes the left sidebar type
//    * @param {*} param0
//    */
//   function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
//     try {
//       switch (sidebarType) {
//         case "compact":
//           yield call(changeBodyAttribute, "data-sidebar-size", "small")
//           yield call(manageBodyClass, "sidebar-enable", "remove")
//           yield call(manageBodyClass, "vertical-collpsed", "remove")
//           break
//         case "icon":
//           yield call(changeBodyAttribute, "data-sidebar-size", "")
//           yield call(changeBodyAttribute, "data-keep-enlarged", "true")
//           yield call(manageBodyClass, "vertical-collpsed", "add")
//           break
//         case "condensed":
//           yield call(manageBodyClass, "sidebar-enable", "add")
//           if (window.screen.width >= 992) {
//             yield call(manageBodyClass, "vertical-collpsed", "remove")
//             yield call(manageBodyClass, "sidebar-enable", "remove")
//             yield call(manageBodyClass, "vertical-collpsed", "add")
//             yield call(manageBodyClass, "sidebar-enable", "add")
//           } else {
//             yield call(manageBodyClass, "sidebar-enable", "add")
//             yield call(manageBodyClass, "vertical-collpsed", "add")
//           }
//           break
//         default:
//           yield call(changeBodyAttribute, "data-sidebar-size", "")
//           yield call(manageBodyClass, "sidebar-enable", "remove")
//           if (!isMobile)
//             yield call(manageBodyClass, "vertical-collpsed", "remove")
//           break
//       }
//     } catch (error) {}
//   }
