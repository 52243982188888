import { Skeleton, Space } from "antd"
import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

const SkeletonComponent = () => {
  return (
    <>
      <Col xl="2" lg="3" md="4" sm="6" xs="12">
        <Card className="study-container1">
          <CardBody className="studies-card">
            <div className="favorite-icon-other">
              <div className="mt-1 hstack d-flex justify-content-center ">
                <span className="p-2  rounded-1">
                  <Space>
                  <Skeleton.Image active={true} />
                  </Space>
                </span>
              </div>
              <div className="img-content-"></div>
            </div>
            <div className="studies-card-content">
              <div className="d-flex justify-content-between">
                <h5 className="study-title mb-2">
                  <medium className="study-primary-text">
                    <Space className="w-100">
                      <Skeleton.Button active={true} />
                    </Space>
                  </medium>
                </h5>
              </div>
              <ul className="list-inline mb-0">
                <li className="list-inline-item text-muted study-body-text w-100"></li>{" "}
                <li className="list-inline-item">
                  <p className="text-muted study-body-text">
                    <Space>
                      <Skeleton.Button active={true} />
                    </Space>
                  </p>
                </li>{" "}
              </ul>
              <div className="mt-3 hstack gap-2"></div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}
const mappingarray = [1, 2, 3,]
const PastStudiesSkeleton = () => {
  return (
    <>
      <div>
        <Row>
          {mappingarray.map((num) => {
            return <SkeletonComponent key={num} />
          })}
        </Row>
      </div>
    </>
  )
}

export default PastStudiesSkeleton
