import { USER_INTERVIEW, UT_FEATURE_TESTING, UT_ONBOARDING } from "./dashboard"

export const DEFAULT = "DEFAULT"
export const USER_FEEDBACK = "USER_FEEDBACK"
export const TEST_UI_DESIGN = "TEST_UI_DESIGN"

// template variabl identifiers
export const VAR_USER_NAME = "$user_name"
export const VAR_USER_EMAIL = "$user_email"
export const VAR_USER_ROLE = "$user_role"
export const VAR_STUDY_NAME = "$study_name"
export const VAR_STUDY_TYPE = "$study_type"
export const VAR_STUDY_DURATION = "$study_duration"
export const VAR_PRODUCT_NAME = "$product_name"
export const VAR_PRODUCT_CATEGORY = "$product_category"
export const VAR_PRODUCT_LINK = "$product_link"
export const VAR_PRODUCT_TYPE = "$product_type"
export const VAR_PRODUCT_FEEDBACK = "$product_feedback"
export const VAR_METRICS = "$metrics"
export const VAR_FEATURE_1 = "$feature_1"
export const VAR_FEATURE_2 = "$feature_2"
export const VAR_FEATURE_3 = "$feature_3"
export const VAR_PARTICIPANT_COUNT = "$participantCount"
export const VAR_PARTICIPANT_AWARENESS = "$participantProductAwareness"

export const QUESTION_TYPE_OPTIONS = [
  { label: "Text", value: "text" },
  // { label: "MCQ", value: "mcq" },
  // { label: "Dropdown", value: "dropdown" },
  // { label: "Checkboxes", value: "checkboxes" },
]

export const STUDY_TYPE = [
  { label: "Select study type", value: "Other" },
  { label: "User Interview", value: "User Interview" },
  {
    label: "Usability Testing - Onboarding",
    value: "Onboarding Testing",
  },
  {
    label: "Usability Testing - Feature testing",
    value: "Feature Testing",
  },
]
export const STUDY_TYPE_ONBOARDING = [
  {
    label: "Usability Testing - Onboarding",
    value: "Onboarding Testing",
  },
]
export const STUDY_TYPE_FEATURE = [
  {
    label: "Usability Testing - Feature testing",
    value: "Feature Testing",
  },
]
export const STUDY_TYPE_INTERVIEW = [
  { label: "User Interview", value: "User Interview" },
]

export const USER_METRICS = [
  { label: "User Satisfaction", value: "User Satisfaction" },
  { label: "Discoverability ", value: "Discoverability " },
  { label: "Awareness ", value: "Awareness " },
  { label: "Comprehension ", value: "Comprehension " },
  { label: "Navigation ", value: "Navigation " },
  { label: "Task Success Rate", value: "Task Success Rate" },
  { label: "Effectiveness ", value: "Effectiveness " },
  {
    label: "User Interface Feedback",
    value: "User Interface Feedback",
  },
  { label: "Terminology", value: "Terminology" },
  { label: "Error Recovery", value: "Error Recovery" },
]

export const USER_METRICS_INTERVIEW = [
  { label: "User Satisfaction", value: "User Satisfaction" },
]

export const PRODUCT_TYPE = [
  { label: "B2B", value: "B2B" },
  { label: "B2C", value: "B2C" },
]

export const PRODUCT_KIND = [
  { label: "Website", value: "website" },
  { label: "Mobile app", value: "mobile app" },
  { label: "Web app", value: "web app" },
  { label: "Dashboard", value: "dashboard" },
  { label: "Advertisement", value: "Advertisement" },
  { label: "Other", value: "other" },
]

export const DURATION_OF_STUDY = [
  { label: "20 min", value: 20 },
  { label: "30 min", value: 30 },
  { label: "40 min", value: 40 },
  { label: "50 min", value: 50 },
  { label: "60 min", value: 60 },
]

export const PRODUCT_DOMAIN = [
  { label: "Food Tech", value: "Food Tech" },
  { label: "Retail", value: "Retail" },
  { label: "Travel", value: "Travel" },
  { label: "Social Media", value: "Social Media" },
  { label: "Music", value: "Music" },
  { label: "Online Streaming Platform", value: "Online Streaming Platform" },
  { label: "Gaming", value: "Gaming" },
  { label: "Other", value: "Other" },
]

export const AWARENESS_OPTIONS = [
  {
    label: "Yes, and using the product",
    value: "Yes, and using the product",
  },
  {
    label: "Yes but not using the product",
    value: "Yes but not using the product",
  },
  { label: "No, not aware", value: "No, not aware" },
]

export const PARTICIPANT_COUNT = [
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
  { label: "32", value: "32" },
  { label: "33", value: "33" },
  { label: "34", value: "34" },
  { label: "35", value: "35" },
  { label: "36", value: "36" },
  { label: "37", value: "18" },
  { label: "39", value: "39" },
  { label: "40", value: "40" },
  { label: "41", value: "41" },
  { label: "42", value: "42" },
  { label: "43", value: "43" },
  { label: "44", value: "44" },
  { label: "45", value: "45" },
  { label: "46", value: "46" },
  { label: "47", value: "47" },
  { label: "48", value: "48" },
  { label: "49", value: "49" },
  { label: "50", value: "50" },
  { label: "51", value: "51" },
  { label: "52", value: "52" },
  { label: "53", value: "53" },
  { label: "54", value: "54" },
  { label: "55", value: "55" },
  { label: "56", value: "56" },
  { label: "57", value: "57" },
  { label: "58", value: "58" },
  { label: "59", value: "59" },
  { label: "60", value: "60" },
]

export const DEFAULT_DROPDOWN_OPTIONS = [
  { id: "1", title: "Option 1", placeholder: "Option 1", value: "Option 1" },
]

export const EMPTY_QUESTION_BLOCK = {
  type: "text", // "mcq", "checkboxes", "dropdown", "linear scale"
  title: "",
  name: "",
  ansValue: "",
  options: [],
  placeholder: "Type your question...",
  isRequired: false,
  isEditable: true,
}

export const DEFAULT_FORM_STRUCTURE = [
  {
    type: "section",
    sectionId: "",
    title: "Study Information",
    desc: "Information about the study, product and feature or focus of the study",
    questions: [
      {
        queId: "",
        name: VAR_STUDY_TYPE,
        type: "dropdown",
        title: "Study type",
        ansValue: "",
        hide: true,
        options: [
          { label: "Select study type", value: null },
          { label: "User Interview", value: "User Interview" },
          {
            label: "Usability Testing - Onboarding",
            value: "Onboarding Testing",
          },
          {
            label: "Usability Testing - Feature testing",
            value: "Feature Testing",
          },
          { label: "Other", value: "Other" },
        ],
        placeholder: "Study type",
        defaultValue: null,
        isDisabled: false,
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "",
        type: "text",
        title: "Study name",
        name: VAR_STUDY_NAME,
        ansValue: "",
        options: [],
        placeholder: "Study name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "",
        type: "text",
        title: "Product name",
        name: VAR_PRODUCT_NAME,
        ansValue: "",
        options: [],
        placeholder: "Product name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "",
        type: "text",
        title: "Product link",
        name: VAR_PRODUCT_LINK,
        ansValue: "",
        options: [],
        placeholder: "Product link",
        isRequired: false,
        isEditable: false,
      },
      {
        queId: "",
        type: "checkboxes",
        name: VAR_METRICS,
        title: "User Metrics",
        ansValue: [],
        options: [
          { label: "User Satisfaction", value: "User Satisfaction" },
          { label: "Discoverability ", value: "Discoverability " },
          { label: "Awareness ", value: "Awareness " },
          { label: "Comprehension ", value: "Comprehension " },
          { label: "Navigation ", value: "Navigation " },
          { label: "Task Success Rate", value: "Task Success Rate" },
          { label: "Effectiveness ", value: "Effectiveness " },
          {
            label: "User Interface Feedback",
            value: "User Interface Feedback",
          },
          { label: "Terminology", value: "Terminology" },
          { label: "Error Recovery", value: "Error Recovery" },
        ],
        placeholder: "",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "",
        type: "dropdown",
        name: VAR_STUDY_DURATION,
        title: "Duration of study",
        ansValue: "",
        options: [
          { label: "20 min", value: 20 },
          { label: "30 min", value: 30 },
          { label: "40 min", value: 40 },
          { label: "50 min", value: 50 },
          { label: "60 min", value: 60 },
        ],
        placeholder: "Duration",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false,
    isRequired: true,
  },
  {
    type: "section",
    sectionId: "",
    title: "About product",
    desc: "",
    questions: [
      {
        queId: "",
        type: "mcq",
        name: VAR_PRODUCT_CATEGORY,
        title: "What kind of product it is?",
        ansValue: "",
        options: [
          { label: "Website", value: "website" },
          { label: "Mobile app", value: "mobile app" },
          { label: "Web app", value: "web app" },
          { label: "Dashboard", value: "dashboard" },
          { label: "Other", value: "other" },
        ],
        placeholder: "",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "",
        type: "mcq",
        name: VAR_PRODUCT_TYPE,
        title: "What is the product type?",
        ansValue: "",
        options: [
          { label: "B2B", value: "B2B" },
          { label: "B2C", value: "B2C" },
        ],
        placeholder: "",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: true,
    isReuired: false,
  },
  {
    type: "section",
    sectionId: "",
    title: "Participant Profile",
    desc: "Let’s define user criteria to screen them",
    questions: [
      {
        queId: "",
        type: "mcq",
        name: VAR_PARTICIPANT_AWARENESS,
        title: "Are participants aware of the product?",
        ansValue: "",
        options: [
          {
            label: "Yes, and using the product",
            value: "Yes, and using the product",
          },
          {
            label: "Yes but not using the product",
            value: "Yes but not using the product",
          },
          { label: "⁠No, not aware", value: "⁠No, not aware" },
        ],
        placeholder: "",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "KD9KE0DEW0ORWERW3",
        name: VAR_PARTICIPANT_COUNT,
        type: "dropdown",
        title: "How many participants are planned to participate in the study?",
        ansValue: "6",
        options: [
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
          { label: "13", value: "13" },
          { label: "14", value: "14" },
          { label: "15", value: "15" },
          { label: "16", value: "16" },
          { label: "17", value: "17" },
          { label: "18", value: "18" },
          { label: "19", value: "19" },
          { label: "20", value: "20" },
          { label: "21", value: "21" },
          { label: "22", value: "22" },
          { label: "23", value: "23" },
          { label: "24", value: "24" },
          { label: "25", value: "25" },
          { label: "26", value: "26" },
          { label: "27", value: "27" },
          { label: "28", value: "28" },
          { label: "29", value: "29" },
          { label: "30", value: "30" },
          { label: "31", value: "31" },
          { label: "32", value: "32" },
          { label: "33", value: "33" },
          { label: "34", value: "34" },
          { label: "35", value: "35" },
          { label: "36", value: "36" },
          { label: "37", value: "18" },
          { label: "39", value: "39" },
          { label: "40", value: "40" },
          { label: "41", value: "41" },
          { label: "42", value: "42" },
          { label: "43", value: "43" },
          { label: "44", value: "44" },
          { label: "45", value: "45" },
          { label: "46", value: "46" },
          { label: "47", value: "47" },
          { label: "48", value: "48" },
          { label: "49", value: "49" },
          { label: "50", value: "50" },
          { label: "51", value: "51" },
          { label: "52", value: "52" },
          { label: "53", value: "53" },
          { label: "54", value: "54" },
          { label: "55", value: "55" },
          { label: "56", value: "56" },
          { label: "57", value: "57" },
          { label: "58", value: "58" },
          { label: "59", value: "59" },
          { label: "60", value: "60" },
        ],
        placeholder: "Participant count",
        // defaultValue: { label: "6", value: "6" },
        isDisabled: false,
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false,
    isRequired: true,
  },
]

export const PARTICIPANT_SCREENER = [
  {
    type: "section",
    sectionId: "3J7dq3n98DirE94",
    title: "Participant profile",
    desc: "Let's define user criterion to screen them",
    questions: [
      {
        queId: "SJ9rh39wdjw0Ddh",
        type: "checkboxes", // "radio", "checkboxes", "dropdown", "linear scale"
        title: "Tech profile",
        options: [
          { label: "Mobile phone", value: "Mobile phone" },
          { label: "Laptop", value: "Laptop" },
          { label: "Social media", value: "Social media" },
          { label: "Online shopping", value: "Online shopping" },
          { label: "Digital payments", value: "Digital payments" },
          { label: "Others", value: "Others" },
        ],
        placeholder: "Tech profile",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "8hs739qhs98HD8S",
        type: "text",
        title: "Number of users",
        options: [],
        placeholder: "Number of users",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false, // if true, new questions can be added to the section/ question title can be modified
    isRequired: true,
  },
  {
    type: "section",
    sectionId: "3J7dq3n98DirE94",
    title: "Demographic",
    desc: "",
    questions: [
      {
        queId: "SJ9rh39wdjw0Ddh",
        type: "slider", // "radio", "checkboxes", "dropdown", "linear scale"
        title: "Age",
        options: [],
        placeholder: "Age",
        isRequired: true,
        isEditable: true,
      },
      {
        queId: "8hs739qhs98HD8S",
        type: "checkboxes",
        title: "Gender",
        options: [
          { label: "Male", value: "Male" },
          { label: "Female", value: "Female" },
          { label: "Other", value: "Other" },
        ],
        placeholder: "Gender",
        isRequired: false,
        isEditable: false,
      },
      {
        queId: "9ushr9ajsdo9ajd",
        type: "radio",
        title: "Education",
        options: [
          { label: "Bachelor's", value: "Bachelor's" },
          { label: "Master's", value: "Master's" },
          { label: "PhD", value: "PhD" },
          { label: "Other", value: "Other" },
        ],
        placeholder: "Education",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "BS8Aysh8hd9dfew",
        type: "slider",
        title: "Income",
        options: [],
        placeholder: "Income",
        isRequired: true,
        isEditable: true,
      },
    ],
    isDynamic: false, // if true, new questions can be added to the section/ question title can be modified
    isRequired: true,
  },
]

export const USER_FEEDBACK_FORM_EDITABLE_TEMPLATE = [
  {
    type: "section",
    title: "User feedback form",
    desc: "user feedback form",
    questions: [
      {
        type: "text", // "radio", "checkboxes", "dropdown", "linear scale"
        name: VAR_USER_NAME,
        title: "User name",
        options: [],
        placeholder: "User name",
        isRequired: true,
        isEditable: true,
      },
      {
        type: "text",
        title: "User email",
        name: VAR_USER_EMAIL,
        options: [],
        placeholder: "User email",
        isRequired: false,
        isEditable: false,
      },
      {
        type: "radio",
        name: VAR_PRODUCT_NAME,
        title: "Product name",
        options: [
          { label: "User Interview", value: "User Interview" },
          { label: "Usability Testing ", value: "Uusability Testing" },
        ],
        placeholder: "Product name",
        isRequired: true,
        isEditable: false,
      },
      {
        type: "dropdown",
        name: VAR_PRODUCT_FEEDBACK,
        title: "How satisfied are you with our product?",
        options: [
          { label: "Good", value: "Good" },
          { label: "Average", value: "Average" },
          { label: "Poor", value: "Poor" },
        ],
        placeholder: "Select your answer",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false, // if true, new questions can be added to the section/ question title can be modified
    isRequired: true,
  },
]

export const USER_INTERVIEW_FORM_STRUCTURE = [
  {
    type: "section",
    sectionId: "3J7dq3n98DirE94",
    title: "Study Information",
    desc: "Information about the study, product and feature or focus of the study",
    questions: [
      {
        queId: "29ek449dw0eopw",
        name: VAR_STUDY_TYPE,
        type: "dropdown",
        title: "Study type",
        ansValue: USER_INTERVIEW,
        options: [{ label: "User Interview", value: "User Interview" }],
        placeholder: "Study type",
        // defaultValue: { label: "User Interview", value: "User Interview" },
        isDisabled: true,
        isRequired: true,
        isEditable: true,
      },
      {
        queId: "SJ9rh39wdjw0Ddh",
        type: "text", // "radio", "checkboxes", "dropdown", "linear scale"
        name: VAR_STUDY_NAME,
        title: "Study name",
        ansValue: "",
        options: [],
        placeholder: "Study name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "8hs739qhs98HD8S",
        name: VAR_PRODUCT_NAME,
        type: "text",
        title: "Product name",
        ansValue: "",
        options: [],
        placeholder: "Product name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "8hs739qhs98HD8S",
        type: "text",
        name: VAR_PRODUCT_LINK,
        title: "Product link",
        ansValue: "",
        options: [],
        placeholder: "Product link",
        isRequired: false,
        isEditable: false,
      },
      {
        queId: "BS8Aysh8hd9dfew",
        name: VAR_METRICS,
        type: "checkboxes",
        title: "User Metrics",
        ansValue: "",
        options: [
          { label: "User Satisfaction", value: "User Satisfaction" },
          // { label: "Discoverability ", value: "Discoverability " },
          // { label: "Awareness ", value: "Awareness " },
          // { label: "Comprehension ", value: "Comprehension " },
          // { label: "Navigation ", value: "Navigation " },
          // { label: "Task Success Rate", value: "Task Success Rate" },
          // { label: "Effectiveness ", value: "Effectiveness " },
          // {
          //   label: "User Interface Feedback",
          //   value: "User Interface Feedback",
          // },
          // { label: "Terminology", value: "Terminology" },
          // { label: "Error Recovery", value: "Error Recovery" },
        ],
        placeholder: "User satisfaction metrics",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "J89sjei39ejak9S",
        name: VAR_STUDY_DURATION,
        type: "dropdown",
        title: "Duration of study",
        ansValue: "20",
        options: [
          { label: "20 min", value: 20 },
          { label: "30 min", value: 30 },
          { label: "40 min", value: 40 },
          { label: "50 min", value: 50 },
          { label: "60 min", value: 60 },
        ],
        placeholder: "Duration",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false, // if true, new questions can be added to the section/ question title can be modified
    isRequired: true,
  },
  {
    type: "section",
    sectionId: "h7898H9784jS0D9",
    title: "About product",
    desc: "",
    questions: [
      {
        queId: "9SJs9id0IDD90od",
        name: VAR_PRODUCT_CATEGORY,
        type: "mcq",
        title: "What kind of product it is?",
        ansValue: "",
        options: [
          { label: "Website", value: "website" },
          { label: "Mobile app", value: "mobile app" },
          { label: "Web app", value: "web app" },
          { label: "Dashboard", value: "dashboard" },
          { label: "Other", value: "other" },
        ],
        placeholder: "Study name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "I8hs9SIsj0SJosS",
        name: VAR_PRODUCT_TYPE,
        type: "mcq",
        title: "What is the product type?",

        options: [
          { label: "B2B", value: "B2B" },
          { label: "B2C", value: "B2C" },
        ],
        placeholder: "Product name",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: true, // if true, new questions can be added to the section/ question title can be modified
    isReuired: false,
  },
  {
    type: "section",
    sectionId: "",
    title: "Participant Profile",
    desc: "Let’s define user criteria to screen them",
    questions: [
      {
        queId: "",
        type: "mcq",
        name: VAR_PARTICIPANT_AWARENESS,
        title: "Are participants aware of the product?",
        ansValue: "",
        options: [
          {
            label: "Yes, and using the product",
            value: "Yes, and using the product",
          },
          {
            label: "Yes but not using the product",
            value: "Yes but not using the product",
          },
          { label: "⁠No, not aware", value: "⁠No, not aware" },
        ],
        placeholder: "",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "",
        name: VAR_PARTICIPANT_COUNT,
        type: "dropdown",
        title: "How many participants are planned to participate in the study?",
        ansValue: "6",
        options: [
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
          { label: "13", value: "13" },
          { label: "14", value: "14" },
          { label: "15", value: "15" },
          { label: "16", value: "16" },
          { label: "17", value: "17" },
          { label: "18", value: "18" },
          { label: "19", value: "19" },
          { label: "20", value: "20" },
          { label: "21", value: "21" },
          { label: "22", value: "22" },
          { label: "23", value: "23" },
          { label: "24", value: "24" },
          { label: "25", value: "25" },
          { label: "26", value: "26" },
          { label: "27", value: "27" },
          { label: "28", value: "28" },
          { label: "29", value: "29" },
          { label: "30", value: "30" },
          { label: "31", value: "31" },
          { label: "32", value: "32" },
          { label: "33", value: "33" },
          { label: "34", value: "34" },
          { label: "35", value: "35" },
          { label: "36", value: "36" },
          { label: "37", value: "18" },
          { label: "39", value: "39" },
          { label: "40", value: "40" },
          { label: "41", value: "41" },
          { label: "42", value: "42" },
          { label: "43", value: "43" },
          { label: "44", value: "44" },
          { label: "45", value: "45" },
          { label: "46", value: "46" },
          { label: "47", value: "47" },
          { label: "48", value: "48" },
          { label: "49", value: "49" },
          { label: "50", value: "50" },
          { label: "51", value: "51" },
          { label: "52", value: "52" },
          { label: "53", value: "53" },
          { label: "54", value: "54" },
          { label: "55", value: "55" },
          { label: "56", value: "56" },
          { label: "57", value: "57" },
          { label: "58", value: "58" },
          { label: "59", value: "59" },
          { label: "60", value: "60" },
        ],
        placeholder: "Study type",
        defaultValue: null,
        isDisabled: false,
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false,
    isRequired: true,
  },
]

export const UT_ONBOARDING_FORM_STRUCTURE = [
  {
    type: "section",
    sectionId: "3J7dq3n98DirE94",
    title: "Study Information",
    desc: "Information about the study, product and feature or focus of the study",
    questions: [
      {
        queId: "wd0932ie932ie09qo2w",
        name: VAR_STUDY_TYPE,
        type: "dropdown",
        title: "Study type",
        ansValue: UT_ONBOARDING,
        options: [
          {
            label: "Usability Testing - Onboarding",
            value: "Usability Testing - Onboarding",
          },
        ],
        placeholder: "Study type",
        // defaultValue: { label: "Usability Testing - Onboarding", value: "Usability Testing - Onboarding" },
        isDisabled: true,
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "SJ9rh39wdjw0Ddh",
        name: VAR_STUDY_NAME,
        type: "text", // "radio", "checkboxes", "dropdown", "linear scale"
        title: "Study name",
        ansValue: "",
        options: [],
        placeholder: "Study name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "8hs739qhs98HD8S",
        name: VAR_PRODUCT_NAME,
        type: "text",
        title: "Product name",
        ansValue: "",
        options: [],
        placeholder: "Product name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "8hs739qhs98HD8S",
        name: VAR_PRODUCT_LINK,
        type: "text",
        title: "Product link",
        ansValue: "",
        options: [],
        placeholder: "Product link",
        isRequired: false,
        isEditable: false,
      },
      {
        queId: "BS8Aysh8hd9dfew",
        name: VAR_METRICS,
        type: "checkboxes",
        title: "User Metrics",
        ansValue: "",
        options: [
          { label: "User Satisfaction", value: "User Satisfaction" },
          { label: "Discoverability ", value: "Discoverability " },
          { label: "Awareness ", value: "Awareness " },
          { label: "Comprehension ", value: "Comprehension " },
          { label: "Navigation ", value: "Navigation " },
          { label: "Task Success Rate", value: "Task Success Rate" },
          { label: "Effectiveness ", value: "Effectiveness " },
          {
            label: "User Interface Feedback",
            value: "User Interface Feedback",
          },
          { label: "Terminology", value: "Terminology" },
          { label: "Error Recovery", value: "Error Recovery" },
        ],
        placeholder: "User satisfaction metrics",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "J89sjei39ejak9S",
        name: VAR_STUDY_DURATION,
        type: "dropdown",
        title: "Duration of study",
        ansValue: "",
        options: [
          { label: "20 min", value: 20 },
          { label: "30 min", value: 30 },
          { label: "40 min", value: 40 },
          { label: "50 min", value: 50 },
          { label: "60 min", value: 60 },
        ],
        placeholder: "Duration",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false, // if true, new questions can be added to the section/ question title can be modified
    isRequired: true,
  },
  {
    type: "section",
    sectionId: "h7898H9784jS0D9",
    title: "About product",
    desc: "",
    questions: [
      {
        queId: "9SJs9id0IDD90od",
        name: VAR_PRODUCT_CATEGORY,
        type: "mcq",
        title: "What kind of product it is?",
        ansValue: "",
        options: [
          { label: "Website", value: "website" },
          { label: "Mobile app", value: "mobile app" },
          { label: "Web app", value: "web app" },
          { label: "Dashboard", value: "dashboard" },
          { label: "Other", value: "other" },
        ],
        placeholder: "Study name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "I8hs9SIsj0SJosS",
        name: VAR_PRODUCT_TYPE,
        type: "mcq",
        title: "What is the product type?",

        options: [
          { label: "B2B", value: "B2B" },
          { label: "B2C", value: "B2C" },
        ],
        placeholder: "Product name",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: true, // if true, new questions can be added to the section/ question title can be modified
    isReuired: false,
  },
  {
    type: "section",
    sectionId: "",
    title: "Participant Profile",
    desc: "Let’s define user criteria to screen them",
    questions: [
      {
        queId: "",
        type: "mcq",
        name: VAR_PARTICIPANT_AWARENESS,
        title: "Are participants aware of the product?",
        ansValue: "",
        options: [
          {
            label: "Yes, and using the product",
            value: "Yes, and using the product",
          },
          {
            label: "Yes but not using the product",
            value: "Yes but not using the product",
          },
          { label: "⁠No, not aware", value: "⁠No, not aware" },
        ],
        placeholder: "",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "",
        name: VAR_PARTICIPANT_COUNT,
        type: "dropdown",
        title: "How many participants are planned to participate in the study?",
        ansValue: "6",
        options: [
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
          { label: "13", value: "13" },
          { label: "14", value: "14" },
          { label: "15", value: "15" },
          { label: "16", value: "16" },
          { label: "17", value: "17" },
          { label: "18", value: "18" },
          { label: "19", value: "19" },
          { label: "20", value: "20" },
          { label: "21", value: "21" },
          { label: "22", value: "22" },
          { label: "23", value: "23" },
          { label: "24", value: "24" },
          { label: "25", value: "25" },
          { label: "26", value: "26" },
          { label: "27", value: "27" },
          { label: "28", value: "28" },
          { label: "29", value: "29" },
          { label: "30", value: "30" },
          { label: "31", value: "31" },
          { label: "32", value: "32" },
          { label: "33", value: "33" },
          { label: "34", value: "34" },
          { label: "35", value: "35" },
          { label: "36", value: "36" },
          { label: "37", value: "18" },
          { label: "39", value: "39" },
          { label: "40", value: "40" },
          { label: "41", value: "41" },
          { label: "42", value: "42" },
          { label: "43", value: "43" },
          { label: "44", value: "44" },
          { label: "45", value: "45" },
          { label: "46", value: "46" },
          { label: "47", value: "47" },
          { label: "48", value: "48" },
          { label: "49", value: "49" },
          { label: "50", value: "50" },
          { label: "51", value: "51" },
          { label: "52", value: "52" },
          { label: "53", value: "53" },
          { label: "54", value: "54" },
          { label: "55", value: "55" },
          { label: "56", value: "56" },
          { label: "57", value: "57" },
          { label: "58", value: "58" },
          { label: "59", value: "59" },
          { label: "60", value: "60" },
        ],
        placeholder: "Study type",
        defaultValue: null,
        isDisabled: false,
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false,
    isRequired: true,
  },
]
export const UT_FEATURE_TESTING_FORM_STRUCTURE = [
  {
    type: "section",
    sectionId: "3J7dq3n98DirE94",
    title: "Study Information",
    desc: "Information about the study, product and feature or focus of the study",
    questions: [
      {
        queId: "er09gire09fkef0e",
        name: VAR_STUDY_TYPE,
        type: "dropdown",
        title: "Study type",
        ansValue: UT_FEATURE_TESTING,
        options: [
          {
            label: "Usability Testing - Feature testing",
            value: "Usability Testing - Feature testing",
          },
        ],
        placeholder: "Study type",
        // defaultValue: { label: "Usability Testing - Feature testing", value: "Usability Testing - Feature testing" },
        isDisabled: true,
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "SJ9rh39wdjw0Ddh",
        name: VAR_STUDY_NAME,
        type: "text", // "radio", "checkboxes", "dropdown", "linear scale"
        title: "Study name",
        ansValue: "",
        options: [],
        placeholder: "Study name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "8hs739qhs98HD8S",
        name: VAR_PRODUCT_NAME,
        type: "text",
        title: "Product name",
        ansValue: "",
        options: [],
        placeholder: "Product name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "8hs739qhs98HD8S",
        name: VAR_PRODUCT_LINK,
        type: "text",
        title: "Product link",
        ansValue: "",
        options: [],
        placeholder: "Product link",
        isRequired: false,
        isEditable: false,
      },
      {
        queId: "BS8Aysh8hd9dfew",
        name: VAR_METRICS,
        type: "checkboxes",
        title: "Select user statisfaction metrics",
        ansValue: "",
        options: [
          { label: "User Satisfaction", value: "User Satisfaction" },
          { label: "Discoverability ", value: "Discoverability " },
          { label: "Awareness ", value: "Awareness " },
          { label: "Comprehension ", value: "Comprehension " },
          { label: "Navigation ", value: "Navigation " },
          { label: "Task Success Rate", value: "Task Success Rate" },
          { label: "Effectiveness ", value: "Effectiveness " },
          {
            label: "User Interface Feedback",
            value: "User Interface Feedback",
          },
          { label: "Terminology", value: "Terminology" },
          { label: "Error Recovery", value: "Error Recovery" },
        ],
        placeholder: "User satisfaction metrics",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "J89sjei39ejak9S",
        name: VAR_STUDY_DURATION,
        type: "dropdown",
        title: "Duration of study",
        ansValue: "",
        options: [
          { label: "20 min", value: 20 },
          { label: "30 min", value: 30 },
          { label: "40 min", value: 40 },
          { label: "50 min", value: 50 },
          { label: "60 min", value: 60 },
        ],
        placeholder: "Duration",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false, // if true, new questions can be added to the section/ question title can be modified
    isRequired: true,
  },
  {
    type: "section",
    sectionId: "h7898H9784jS0D9",
    title: "About product",
    desc: "",
    questions: [
      {
        queId: "9SJs9id0IDD90od",
        name: VAR_FEATURE_1,
        type: "text",
        title: "Feature 1",
        ansValue: "",
        options: [],
        placeholder: "Feature 1",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "9SJs9id0IDD90od",
        name: VAR_FEATURE_2,
        type: "text",
        title: "Feature 2",
        ansValue: "",
        options: [],
        placeholder: "Feature 2",
        isRequired: false,
        isEditable: false,
      },
      {
        queId: "9SJs9id0IDD90od",
        name: VAR_FEATURE_3,
        type: "text",
        title: "Feature 3",
        ansValue: "",
        options: [],
        placeholder: "Feature 3",
        isRequired: false,
        isEditable: false,
      },
      {
        queId: "9SJs9id0IDD90od",
        name: VAR_PRODUCT_CATEGORY,
        type: "mcq",
        title: "What kind of product it is?",
        ansValue: "",
        options: [
          { label: "Website", value: "website" },
          { label: "Mobile app", value: "mobile app" },
          { label: "Web app", value: "web app" },
          { label: "Dashboard", value: "dashboard" },
          { label: "Other", value: "other" },
        ],
        placeholder: "Study name",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "I8hs9SIsj0SJosS",
        name: VAR_PRODUCT_TYPE,
        type: "mcq",
        title: "What is the product type?",

        options: [
          { label: "B2B", value: "B2B" },
          { label: "B2C", value: "B2C" },
        ],
        placeholder: "Product name",
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: true, // if true, new questions can be added to the section/ question title can be modified
    isReuired: false,
  },
  {
    type: "section",
    sectionId: "",
    title: "Participant Profile",
    desc: "Let’s define user criteria to screen them",
    questions: [
      {
        queId: "",
        type: "mcq",
        name: VAR_PARTICIPANT_AWARENESS,
        title: "Are participants aware of the product?",
        ansValue: "",
        options: [
          {
            label: "Yes, and using the product",
            value: "Yes, and using the product",
          },
          {
            label: "Yes but not using the product",
            value: "Yes but not using the product",
          },
          { label: "⁠No, not aware", value: "⁠No, not aware" },
        ],
        placeholder: "",
        isRequired: true,
        isEditable: false,
      },
      {
        queId: "",
        name: VAR_PARTICIPANT_COUNT,
        type: "dropdown",
        title: "How many participants are planned to participate in the study?",
        ansValue: "6",
        options: [
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
          { label: "13", value: "13" },
          { label: "14", value: "14" },
          { label: "15", value: "15" },
          { label: "16", value: "16" },
          { label: "17", value: "17" },
          { label: "18", value: "18" },
          { label: "19", value: "19" },
          { label: "20", value: "20" },
          { label: "21", value: "21" },
          { label: "22", value: "22" },
          { label: "23", value: "23" },
          { label: "24", value: "24" },
          { label: "25", value: "25" },
          { label: "26", value: "26" },
          { label: "27", value: "27" },
          { label: "28", value: "28" },
          { label: "29", value: "29" },
          { label: "30", value: "30" },
          { label: "31", value: "31" },
          { label: "32", value: "32" },
          { label: "33", value: "33" },
          { label: "34", value: "34" },
          { label: "35", value: "35" },
          { label: "36", value: "36" },
          { label: "37", value: "18" },
          { label: "39", value: "39" },
          { label: "40", value: "40" },
          { label: "41", value: "41" },
          { label: "42", value: "42" },
          { label: "43", value: "43" },
          { label: "44", value: "44" },
          { label: "45", value: "45" },
          { label: "46", value: "46" },
          { label: "47", value: "47" },
          { label: "48", value: "48" },
          { label: "49", value: "49" },
          { label: "50", value: "50" },
          { label: "51", value: "51" },
          { label: "52", value: "52" },
          { label: "53", value: "53" },
          { label: "54", value: "54" },
          { label: "55", value: "55" },
          { label: "56", value: "56" },
          { label: "57", value: "57" },
          { label: "58", value: "58" },
          { label: "59", value: "59" },
          { label: "60", value: "60" },
        ],
        placeholder: "Study type",
        defaultValue: null,
        isDisabled: false,
        isRequired: true,
        isEditable: false,
      },
    ],
    isDynamic: false,
    isRequired: true,
  },
]
