import React, { useCallback, useRef } from "react"
import "../../assets/scss/custom/pages/notes.scss"
import { getFunctions, httpsCallable } from "firebase/functions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button, Spinner } from "reactstrap"
import { AgGridReact } from "ag-grid-react"
import { useState, useEffect } from "react"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { displayNotification } from "utils/utility"
import { Skeleton } from "antd"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

function DisplayNotes() {
  const gridRef = useRef(null)
  const navigate = useNavigate()
  const { studyId, templateFormId } = useParams()
  const [rowData, setRowData] = useState([])
  const [studyDetails, setStudyDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [columnDefs, setColumnDefs] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const CurrentStudyState = (state) => state.CurrentStudy
  const CurrentStudyProps = createSelector(CurrentStudyState, (data) => ({
    studyId: data.studyId,
    pastMeetings: data.pastMeetings,
    ongoingMeetings: data.ongoingMeetings,
    upcomingMeetings: data.upcomingMeetings,
    studyDetails: data.studyDetails,
  }))
  const { pastMeetings, ongoingMeetings, upcomingMeetings } =
    useSelector(CurrentStudyProps)

  const defaultColDef = {
    sortable: true,
    filter: true,
    editable: true,
    flex: 1,
    // resizable: true,
    // wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  }

  function headerHeightGetter() {
    var columnHeaderTexts = [
      ...document.querySelectorAll(".ag-header-cell-text"),
    ]
    var clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight,
    )
    var tallestHeaderTextHeight = Math.max(...clientHeights)

    return tallestHeaderTextHeight
  }

  function headerHeightSetter() {
    var padding = 20
    var height = headerHeightGetter() + padding
    // gridOptions.api.setHeaderHeight(height)
  }

  function parseColumns(apiResponses) {
    let columnList = []
    let nameLabelMapping = []
    const topicColumn = {
      field: "topic",
      headerName: "Topic",
      flex: 8,
      minWidth: 150,
    }
    columnList.push(topicColumn)

    if (Array.isArray(apiResponses)) {
      apiResponses.forEach((response) => {
        const topic = response.topic

        if (Array.isArray(response.formResponse)) {
          response.formResponse.forEach((section) => {
            const sectionTitle = section.title

            if (Array.isArray(section.root)) {
              section.root.forEach((obj) => {
                if (
                  obj.label &&
                  obj.hasOwnProperty("name") &&
                  !(obj.name === "textarea" && obj.label === "notes")
                ) {
                  if (!(obj.name in nameLabelMapping)) {
                    const cleanLabel = obj.label
                      .replace(/<\/?[^>]+(>|$)/g, "")
                      .replace(/&nbsp;/g, "")
                      .trim()
                    const colObj = {
                      field: obj.name,
                      headerName: cleanLabel,
                      flex: 1,
                      cellEditor: "agLargeTextCellEditor",
                      cellEditorPopup: true,
                      cellEditorParams: {
                        rows: 7,
                        cols: 50,
                      },
                      minWidth: 300,
                    }
                    columnList.push(colObj)
                    nameLabelMapping[obj.name] = cleanLabel
                  }
                }
              })
            } else {
              // columnList = []
              nameLabelMapping = {}
            }
          })
        } else {
          // columnList = []
          nameLabelMapping = {}
        }
      })
    } else {
      // columnList = []
      nameLabelMapping = {}
    }

    return { columnList: columnList, nameLabelMapping: nameLabelMapping }
  }

  function parseRows(apiResponses, nameLabelMapping) {
    let rowList = []

    if (Array.isArray(apiResponses)) {
      apiResponses.forEach((response, index) => {
        const topic = response.topic
        const rowObj = { topic: topic }

        if (Array.isArray(response.formResponse)) {
          response.formResponse.forEach((section, sectionIndex) => {
            if (Array.isArray(section.root)) {
              section.root.forEach((obj, objIndex) => {
                if (
                  obj.label &&
                  obj.hasOwnProperty("name") &&
                  obj.name in nameLabelMapping
                ) {
                  let value = "NA"
                  if (obj.userData && Array.isArray(obj.userData)) {
                    value = obj.userData
                      .map((str) => str.replace(/\n/g, ""))
                      .join(" ")
                    value = value.trim()
                  }
                  rowObj[obj.name] = value
                }
              })
            } else {
              rowList = []
            }
          })
        } else {
          rowList = []
        }
        rowList.push(rowObj)
      })
    } else {
      rowList.push({ topic: "NA" })
    }

    return rowList
  }

  var gridOptions = {
    columnDefs: columnDefs,
    rowData: rowData,
    defaultColDef: defaultColDef,
    onFirstDataRendered: headerHeightSetter,
    // onColumnResized: headerHeightSetter,
    // getRowHeight: function(params) {
    //   // Calculate the height based on the content of the row
    //   const lineHeight = 20; // Adjust as needed
    //   const padding = 20; // Adjust as needed
    //   const contentHeight = params.data ? (params.data.length || 1) * lineHeight : 0;
    //   return contentHeight + padding; // Add padding to the calculated height
    // }
    // rowHeight:"25"
  }

  const formResponse = async () => {
    try {
      setIsFetching(true)
      const functions = getFunctions()
      const getFormResponses = httpsCallable(functions, "getAllFormResponses")
      const response = await getFormResponses({
        formId: studyId,
        templateFormId: templateFormId,
      })
      console.log("response: ", response)

      if (
        response &&
        response.data &&
        response.data.data.isParsingRequired === false
      ) {
        const columnDefs = response.data.data.docData[0].columnDefs
        const rowData = response.data.data.docData[0].rowData
        setRowData(rowData)
        setColumnDefs(columnDefs)
      } else if (
        response &&
        response.data &&
        response.data.data.isParsingRequired === true
      ) {
        const result = parseColumns(response.data.data.docData)
        const rowsData = parseRows(
          response.data.data.docData,
          result.nameLabelMapping,
        )
        setIsFetching(false)
        setRowData(rowsData)
        setColumnDefs(result.columnList)
      }
      setIsFetching(false)
    } catch (error) {
      console.error("Error occurred while fetching form responses:", error)
      setIsFetching(false)
      displayNotification("error", "Failed to get meeting summary")
    }
  }

  const onGridReady = (params) => {
    gridRef.current = params.api
  }

  const saveDataToDatabase = async () => {
    if (gridRef.current) {
      const rowData = []
      const columnDefs = gridRef.current.getColumnDefs().map((column) => ({
        field: column.field,
        headerName: column.headerName,
        flex: column.flex,
        minWidth: column.minWidth,
        cellEditorPopup: true,
        cellEditor: "agLargeTextCellEditor",
        cellEditorParams: {
          rows: 7,
          cols: 50,
        },
      }))
      gridRef.current.forEachNode((node) => {
        rowData.push(node.data)
      })
      const functions = getFunctions()
      const notesResponse = httpsCallable(functions, "saveNotes")
      const data = {
        formId: studyId,
        templateFormId: templateFormId,
        rowData: rowData,
        columnDefs: columnDefs,
      }
      setIsLoading(true)
      setRowData(rowData)
      setColumnDefs(columnDefs)
      const result = await notesResponse(data)
      if (result.data.success) {
        displayNotification("success", result.data.message)
      }
      setIsLoading(false)
    } else {
      setIsLoading(false)
      displayNotification("error", error?.message ?? "Something went wrong!")
      console.log("error.meessage", error.message)
    }
  }

  const fetchStudyDetails = async () => {
    try {
      const functions = getFunctions()
      const getStudyDetails = httpsCallable(functions, "getStudyDetails")
      const { data } = await getStudyDetails({ formId: studyId })

      if (!data) {
        return
      }
      if (!data.success) {
        return
      }
      setStudyDetails(data.data)
    } catch (error) {
      console.error("Failed to fetch study details", error)
    }
  }
  const handleNavigate = () => {
    navigate(-1)
  }

  const onBtnExport = useCallback((params) => {
    gridRef.current.exportDataAsCsv(params)
    },
    [gridRef],
  )

  useEffect(() => {
    formResponse()
    fetchStudyDetails()
  }, [])

  return (
    <div className="page-content">
      <div className="d-flex bd-highlight">
        <div className="p-2 flex-grow-1 bd-highlight">
          <Link
            onClick={handleNavigate}
            className="mb-0p-2 d-sm-flex align-items-center justify-content-between"
          >
            <h4 className="mb-sm-0 text-black p-2 font-size-18">
              {studyDetails?.studyName ?? ""} : {studyDetails?.studyType ?? ""}
            </h4>
          </Link>
        </div>
        <div className="p-2 bd-highlight">
          <Button
            color="primary"
            onClick={onBtnExport}
            className="d-sm-flex align-items-center justify-content-between"
          >
          <i className="mdi mdi-microsoft-excel mx-1"> </i> Excel
          </Button>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 500 }}>
        {!isFetching && (
          <AgGridReact
            onGridReady={onGridReady}
            ref={gridRef}
            rowSelection="multiple"
            animateRows={true}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            gridOptions={gridOptions}
          />
        )}
        {isFetching && <Skeleton isLoading={isFetching} active={isFetching} />}
      </div>
      <Button
        color="primary"
        onClick={saveDataToDatabase}
        className="d-flex mt-2 mr-2 ml-auto"
      >
        {isLoading ? (
          <>
            <Spinner size="sm" color="light" className="mr-2" /> Saving...
          </>
        ) : (
          "Save"
        )}
      </Button>
    </div>
  )
}
export default DisplayNotes
