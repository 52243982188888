import React, { useState, useEffect, useContext } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Form,
  Spinner,
} from "reactstrap"
import { Input, Select } from "antd"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"
import avatar from "../../assets/images/circle.png"
import { resetProfileFlag } from "../../store/actions"
import { displayNotification } from "utils/utility"
import { getFunctions, httpsCallable } from "firebase/functions"
import {
  COMPANY_TYPE_OPTIONS,
  TIMEZONE_OPTIONS,
  JOB_TITLE,
} from "constants/profile"
import { UserDataContext } from "../../routes/route"
import "./user-profile.scss"


const UserProfile = () => {
  const usersData = useContext(UserDataContext)
  document.title = "Profile | Hureo.Ai"
  const { Option } = Select
  const dispatch = useDispatch()
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [companyName, setcompanyName] = useState("")
  const [companyLocation, setcompanyLocation] = useState("")
  const [productUsageReason, setProductUsageReason] = useState("")
  const [jobTitle, setJobTitle] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    error: profile.error,
    success: profile.success,
  }))

  const { error, success } = useSelector(ProfileProperties)

  useEffect(() => {
    if (usersData) {
      const obj = usersData
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setcompanyName(obj.companyName)
        setcompanyLocation(obj.companyLocation)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setcompanyName(obj.companyName)
        setcompanyLocation(obj.companyLocation)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const handleSubmit = async (values) => {
    try {
      const functions = getFunctions()
      const updateUser = httpsCallable(functions, "updateUser")
      const data = {
        name: values.name,
        username: values.username,
        email: values.email ?? "",
        companyName: values.companyName ?? "",
        companyLocation: values.companyLocation ?? "",
        productUsageReason: values.productUsageReason ?? "",
        jobTitle: values.jobTitle ?? "",
        timezone: values.timezone ?? "",
        whyUs: values.whyUs ?? "",
        companyType: values.companyType ?? "",
      }
      const response = await updateUser(data)
      if (response && response.data && response.data.success) {
        displayNotification("success", response.data.message)
      }
    } catch (error) {
      console.error("Error updating user profile:", error)
      displayNotification(
        "error",
        error?.message ?? "Failed to update user profile",
      )
    }
  }

  const formikobj = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name || "",
      username: name || "",
      email: email || "",
      companyName: companyName || "",
      companyLocation: companyLocation || "",
      productUsageReason: productUsageReason || "",
      jobTitle: jobTitle || "",
      timezone: null,
      companyType: "",
      whyUs: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      username: Yup.string().required("Please Enter Your UserName"),
      email: Yup.string()
        .email("Invalid email")
        .required("Please Enter Your Email"),
      // companyName: Yup.string().required("Please Enter Your Company Name"),
      // companyLocation: Yup.string().required(
      //   "Please Enter Your Company Location",
      // ),
      // productUsageReason: Yup.string().required("Please Enter Your Reason"),
      // jobTitle: Yup.string().required("Please Select Your Job Title"),
      // timezone: Yup.object().required("Please Select Your Timezone"),
      // companyType: Yup.string().required("Please select company type"),
      // whyUs: Yup.string().required("Please enter your reason for choosing us"),
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleDeletion = async () => {
    setIsLoading(true)
    try {
      const functions = getFunctions()
      const deauthorize = httpsCallable(functions, "deauthorizeZoom")
      const response = await deauthorize()
      if (response) {
        displayNotification("success", response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error updating user profile:", error)
      displayNotification(
        "error",
        error?.message ?? "Failed to update user profile",
        setIsLoading(false)
      )
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Row>
            <Col className="col-12">
              <h4 className="mb-sm-0 p-2 mb-2 font-size-18">PROFILE</h4>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        {/* <p className="mb-0">Company Name: {companyName}</p>
                        <p className="mb-0">
                          Company Location: {companyLocation}
                        </p> */}
                      </div>
                    </div>
                    <Button
                      style={{ margin: "25px" }}
                      color="primary"
                      onClick={handleDeletion}
                    >
                      {isLoading ? (
                        <>
                          <Spinner size="sm" color="light" className="mr-2" />{" "}
                          Deauthorizing...
                        </>
                      ) : (
                        "Deauthorize Zoom"
                      )}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update Profile</h4>

          <Card className="pt-4 pb-4">
            <CardBody className="d-flex justify-content-around  ">
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault()
                  formikobj.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col>
                    <h4 className="card-title mb-4">About You</h4>
                    <div className="form-group">
                      <Label className="form-label">Name</Label>
                      <div className=" ant-input1">
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Your Name"
                          type="text"
                          onChange={formikobj.handleChange}
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.name || ""}
                          invalid={
                            formikobj.touched.name && formikobj.errors.name
                              ? true
                              : false
                          }
                        />
                      </div>
                      {formikobj.touched.name && formikobj.errors.name ? (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {formikobj.errors.name}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <Label className="form-label">User Name</Label>
                      <div className=" ant-input1">
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter User Name"
                          type="text"
                          onChange={formikobj.handleChange}
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.username || ""}
                          invalid={
                            formikobj.touched.username &&
                            formikobj.errors.username
                              ? true
                              : false
                          }
                        />
                      </div>
                      {formikobj.touched.username &&
                      formikobj.errors.username ? (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {formikobj.errors.username}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <Label className="form-label">Email</Label>
                      <div className=" ant-input1">
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          onChange={formikobj.handleChange}
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.email || ""}
                          invalid={
                            formikobj.touched.email && formikobj.errors.email
                              ? true
                              : false
                          }
                        />
                      </div>
                      {formikobj.touched.email && formikobj.errors.email ? (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {formikobj.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Label className="form-label">
                        Why do you use this product?
                      </Label>
                      <div className=" ant-input1">
                        <Input
                          name="productUsageReason"
                          className="form-control"
                          placeholder="Enter your reason"
                          type="text"
                          onChange={formikobj.handleChange}
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.productUsageReason || ""}
                        />
                      </div>
                      {formikobj.touched.productUsageReason &&
                      formikobj.errors.productUsageReason ? (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {formikobj.errors.productUsageReason}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Why Us?</Label>
                      <div className="ant-input1">
                        <Input
                          name="whyUs"
                          className="form-control"
                          placeholder="Enter your reason for choosing us"
                          type="text"
                          onChange={formikobj.handleChange}
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.whyUs || ""}
                          invalid={
                            formikobj.touched.whyUs && formikobj.errors.whyUs
                              ? true
                              : false
                          }
                        />
                      </div>
                      {formikobj.touched.whyUs && formikobj.errors.whyUs ? (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {formikobj.errors.whyUs}
                        </div>
                      ) : null}
                    </div>
                  </Col>

                  <Col className="pt-1">
                    <h4 className="card-title mb-4">About Your Company</h4>
                    <div className="form-group">
                      <Label className="form-label">Company Type</Label>
                      <div>
                        <Select
                          placeholder="Select Company Type"
                          name="companyType"
                          className="ant-select1"
                          onChange={(value) =>
                            formikobj.setFieldValue("companyType", value)
                          }
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.companyType || ""}
                        >
                          {COMPANY_TYPE_OPTIONS.map((option) => (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      {formikobj.touched.companyType &&
                      formikobj.errors.companyType ? (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {formikobj.errors.companyType}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Job Title</Label>
                      <div>
                        <Select
                          name="jobTitle"
                          className="ant-select1"
                          onChange={(value) =>
                            formikobj.setFieldValue("jobTitle", value)
                          }
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.jobTitle || ""}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {JOB_TITLE.map((title) => (
                            <Option key={title.value} value={title.value}>
                              {title.label}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      {formikobj?.touched?.jobTitle &&
                        formikobj?.errors?.jobTitle && (
                          <div style={{ color: "red", marginTop: "5px" }}>
                            {formikobj?.errors?.jobTitle}
                          </div>
                        )}
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Company Name</Label>
                      <div className=" ant-input1 ">
                        <Input
                          name="companyName"
                          className="form-control bg-transparent  "
                          placeholder="Enter Company Name"
                          type="text"
                          onChange={formikobj.handleChange}
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.companyName || ""}
                          invalid={
                            formikobj.touched.companyName &&
                            formikobj.errors.companyName
                              ? true
                              : false
                          }
                        />
                        {formikobj.touched.companyName &&
                        formikobj.errors.companyName ? (
                          <div style={{ color: "red", marginTop: "5px" }}>
                            {formikobj.errors.companyName}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group">
                      <Label className="form-label">Company Location</Label>
                      <div className=" ant-input1">
                        <Input
                          name="companyLocation"
                          className="form-control"
                          placeholder="Enter Company Location"
                          type="text"
                          onChange={formikobj.handleChange}
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.companyLocation || ""}
                          invalid={
                            formikobj.touched.companyLocation &&
                            formikobj.errors.companyLocation
                              ? true
                              : false
                          }
                        />
                      </div>
                      {formikobj.touched.companyLocation &&
                      formikobj.errors.companyLocation ? (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {formikobj.errors.companyName}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <Label className="form-label">Timezone</Label>
                      <div>
                        <Select
                          name="timezone"
                          type="select"
                          className="ant-select1"
                          onChange={(value, option) => {
                            const selectedValue = option?.data.value
                            formikobj.setFieldValue("timezone", {
                              label: value,
                              value: selectedValue,
                            })
                          }}
                          onBlur={formikobj.handleBlur}
                          value={formikobj.values.timezone?.label || ""}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {TIMEZONE_OPTIONS.map((timezone) => (
                            <Option
                              key={timezone.value}
                              value={timezone.label}
                              data={{ value: timezone.value }}
                            >
                              {timezone.label}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      {formikobj.touched.timezone &&
                      formikobj.errors.timezone ? (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {formikobj.errors.timezone}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <div className="text-center mt-4">
                  <Button
                    type="submit"
                    color="primary"
                    // disabled={formikobj.isSubmitting}
                  >
                    Update Profile
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
