import axios from "axios"
import { auth } from "config/firebase"
import { MEETINGS_BASE_URL, STUDY_BASE_URL, TEMPLATE_BASE_URL,TRANSCRIPTS_BASE_URL } from "constants/ApiConstant"
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth"
import { collection, getDocs, limit, query, where } from "firebase/firestore/lite"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err)
}

FirebaseService.signOutRequest = async () =>
  await signOut(auth)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signInGoogleRequest = async () =>
  await signInWithPopup(auth, googleAuthProvider)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signInFacebookRequest = async () =>
  await signInWithPopup(auth, facebookAuthProvider)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signUpEmailRequest = async (email, password) =>
  await createUserWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.createStudy = async (data) => {
  if(!data){
    return {success: false, message: "Failed to create study. Invalid input parameters", data: data}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.post(`${STUDY_BASE_URL}/`, data, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.fetchAllStudies = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${STUDY_BASE_URL}/`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.fetchStudyDetails = async (studyId) => {
  if(!studyId){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${STUDY_BASE_URL}/${studyId}`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.deleteStudy = async (studyId) => {
  if(!studyId){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.delete(`${STUDY_BASE_URL}/${studyId}`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}
FirebaseService.duplicateStudy = async (studyId,studyName) => {
  if(!studyId || !studyName){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${STUDY_BASE_URL}/duplicate/${studyId}/${studyName}`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}
FirebaseService.renameStudy = async (updatedData,studyId) => {
  if(!studyId ){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.post(`${STUDY_BASE_URL}/update/${studyId}`,updatedData, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.updateTemplate = async (studyId, templateId, data) => {
  if(!studyId || !templateId || !data){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.post(`${TEMPLATE_BASE_URL}/${studyId}/${templateId}`, data, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.updateTemplateForm = async (studyId, templateFormId, data) => {
  if(!studyId || !templateFormId || !data){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.post(`${TEMPLATE_BASE_URL}/templateForm/${studyId}/${templateFormId}`, data, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.checkZoomAuthorizationStatus = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/authorize/status`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.authorizeZoom = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/authorize`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.redirectZoom = async (code) => {
  if(!code){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/redirect/${code}`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.deauthorizeZoom = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/deauthorize`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.getClientID = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/join/clientid`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.getMeetingSignature = async (meetId) => {
  if(!studyId){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/join/${meetId}/sign`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.getMeetings = async (studyId) => {
  if(!studyId){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/${studyId}`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.createMeeting = async (studyId, data) => {
  if(!studyId || !data){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.post(`${MEETINGS_BASE_URL}/${studyId}`, data, {
      headers: {
        authorization: `Bearer ${idtoken}`,
        "Content-Type": "application/json",
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.editMeeting = async (studyId, meetId, data) => {
  if(!studyId || !meetId ||  !data){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.post(`${MEETINGS_BASE_URL}/${studyId}/${meetId}`, data, {
      headers: {
        authorization: `Bearer ${idtoken}`,
        "Content-Type": "application/json",
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.deleteMeeting = async (studyId, meetId) => {
  if(!studyId || !meetId){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.delete(`${MEETINGS_BASE_URL}/${studyId}/${meetId}`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}
FirebaseService.generateTranscript = async (studyId, meetId, data) => {
  if (!studyId || !meetId || !data) {
    return { success: false, message: "Invalid input parameters", data: null };
  }
  try {
    const idtoken = await auth?.currentUser?.getIdToken(true);
    const response = await axios.post(`${TRANSCRIPTS_BASE_URL}/${studyId}/${meetId}`, data, {
      headers: {
        authorization: `Bearer ${idtoken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error('generateTranscript ERROR:', error); // Log the error for debugging

    // Check if error.response is defined before accessing error.response.data
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return { success: false, message: "An unexpected error occurred", data: null };
    }
  }
}

FirebaseService.fetchContactUsDocs = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/contact`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

FirebaseService.fetchSingleMeetingNotes = async (studyId, meetId) => {
  if(!studyId || !meetId){
    return {success: false, message: "Invalid input parameters", data: null}
  }
  try {
    const idtoken = await auth?.currentUser.getIdToken(true)
    const response = await axios.get(`${MEETINGS_BASE_URL}/form-response/${studyId}/${meetId}`, {
      headers: {
        authorization: `Bearer ${idtoken}`,
      },
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export default FirebaseService
