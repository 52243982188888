import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// studyForm
import studyForm from "./studyform/reducer"

// Template
import Template from "./template/reducer"

// TemplateForm
import TemplateForm from "./dynamicForm/reducer"

// ChatWindow
import ChatWindow from "./chatWindow/reducer"

// Chat
import Chat from "./chat/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import AuthState from "./auth/authState/reducer"
import CurrentStudy from "./study/reducer"
import UploadFiles from "./UploadFiles/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  studyForm,
  Template,
  TemplateForm,
  Chat,
  ChatWindow,
  AuthState,
  CurrentStudy,
  UploadFiles
})

export default rootReducer
