import {
  SET_INITIAL_TEMPLATE_FORM,
  UPDATE_FORM_IDS,
  UPDATE_TEMPLATE_FORM,
} from "./actionTypes"

export const setInitialTemplateForm = (data) => {
  return {
    type: SET_INITIAL_TEMPLATE_FORM,
    payload: data,
  }
}

export const updateTemplateForm = (data) => {
  return {
    type: UPDATE_TEMPLATE_FORM,
    payload: data,
  }
}

export const updateTemplateFormId = (data) => {
  return {
    type: UPDATE_FORM_IDS,
    payload: data,
  }
}
