import React from 'react'
import RedoOutlinedIcon from "@ant-design/icons/RedoOutlined";
import UndoOutlinedIcon from "@ant-design/icons/UndoOutlined";
import BoldOutlined  from "@ant-design/icons/BoldOutlined";
import ItalicOutlined from "@ant-design/icons/ItalicOutlined";
import UnderlineOutlined from "@ant-design/icons/UnderlineOutlined";
import StrikethroughOutlined from "@ant-design/icons/StrikethroughOutlined";
import LinkOutlined  from "@ant-design/icons/LinkOutlined";
import AlignLeftOutlined from "@ant-design/icons/AlignLeftOutlined";
import AlignRightOutlined from "@ant-design/icons/AlignRightOutlined";
import AlignCenterOutlined  from "@ant-design/icons/AlignCenterOutlined";
// import FormatQuoteIcon from "@ant-design/icons/FormatQuote";
// import FormatHeader1 from "mdi-material-ui/FormatHeader1";
// import FormatHeader2 from "mdi-material-ui/FormatHeader2";
import UnorderedListOutlined from "@ant-design/icons/UnorderedListOutlined";
import OrderedListOutlined from "@ant-design/icons/OrderedListOutlined";
// import FormatText from "mdi-material-ui/FormatText";
// import CodeIcon from "@ant-design/icons/Code";
import FileImageOutlined from "@ant-design/icons/FileImageOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined"
import Breadcrumbs from "../../../Common/Breadcrumb"
import TextIcon from '../ui/icons/TextIcon';
import Heading1Icon from '../ui/icons/HeadingIcon';
import HeadingsIcon from '../ui/icons/HeadingsIcon';
import DoubleQuote from '../ui/icons/DoubleQuote';
import CodeIcon from '../ui/icons/CodeIcon';
import HeadingIcon from '../ui/icons/HeadingIcon';
import BulletedList from '../ui/icons/BulletedList';
import NumberList from '../ui/icons/NumberList';
import UndoIcon from '../ui/icons/UndoIcon';
import RedoIcon from '../ui/icons/RedoIcon';
import BoldIcon from '../ui/icons/BoldIcon';
import ItalicIcon from '../ui/icons/ItalicIcon';
import UnderLineIcon from '../ui/icons/UnderLineIcon';
import StrikethroughIcon from '../ui/icons/StrikethroughIcon';
import AlignLeftIcon from '../ui/icons/AlignLeftIcon';
import AlignRIghtIcon from '../ui/icons/AlignRIghtIcon';
import AlignCenterIcon from '../ui/icons/AlignCenterIcon';

export const eventTypes = {
  paragraph: "paragraph",
  h1: "h1",
  h2: "h2",
  ul: "ul",
  ol: "ol",
  quote: "quote",
  formatCode: "formatCode",
  formatUndo: "formatUndo",
  formatRedo: "formatRedo",
  formatBold: "formatBold",
  formatItalic: "formatItalic",
  formatUnderline: "formatUnderline",
  formatStrike: "formatStrike",
  formatInsertLink: "formatInsertLink",
  formatAlignLeft: "formatAlignLeft",
  formatAlignCenter: "formatAlignCenter",
  formatAlignRight: "formatAlignRight",
  insertImage: "insertImage",
  readOnlyMode: "readOnlyMode",
  dropDown : "dropDown"
};
{/* <Breadcrumbs title="Icons" breadcrumbItem="Boxicons" /> */}
const pluginsList = [   
  {
    id: 6,
    // Icon: <i className="bx bxs-quote-alt-right"></i> ,
    Icon: DoubleQuote,
    event: eventTypes.quote,
  },
  {
    id: 1,
    Icon: TextIcon,
    // Icon: LinkOutlined,
    event: eventTypes.paragraph,
  },
  {
    id: 2,
    // Icon: <i className="mdi-format-header-1"></i>,
    Icon: HeadingIcon,
    event: eventTypes.h1,
  },
  {
    id: 3,
    // Icon: <i className="bx bx-heading"></i>,
    Icon: HeadingsIcon,
    event: eventTypes.h2,
  },
  {
    id: 4,
    Icon: BulletedList,
    event: eventTypes.ul,
  },

  {
    id: 5,
    Icon: NumberList,
    event: eventTypes.ol,
  },
  

  // {
  //   id: 7,
  //   // Icon: <i className="bx bx-code-alt"></i>,
  //   Icon: CodeIcon,
  //   event: eventTypes.dropDown,
  // },
  {
    id: 8,
    Icon: UndoIcon,
    event: eventTypes.formatUndo,
  },
  {
    id: 9,
    Icon: RedoIcon,
    event: eventTypes.formatRedo,
  },
  {
    id: 10,
    Icon: BoldIcon,
    event: eventTypes.formatBold,
  },
  {
    id: 11,
    Icon:ItalicIcon,
    event: eventTypes.formatItalic,
  },
  {
    id: 12,
    Icon: UnderLineIcon,
    event: eventTypes.formatUnderline,
  },
  // { // reactive it if you need it
  //   id: 13,
  //   Icon: StrikethroughIcon,
  //   event: eventTypes.formatStrike,
  // },
  // {
  //   id: 18,
  //   Icon: FileImageOutlined,
  //   event: eventTypes.insertImage,
  // },
  // {
  //   id: 14,
  //   Icon: LinkOutlined,
  //   event: eventTypes.formatInsertLink,
  // },
  {
    id: 15,
    Icon:AlignLeftIcon,
    event: eventTypes.formatAlignLeft,
  },

  {
    id: 16,
    Icon: AlignCenterIcon,
    event: eventTypes.formatAlignCenter,
  },
  {
    id: 17,
    Icon: AlignRIghtIcon,
    event: eventTypes.formatAlignRight,
  },
  // {
  //   id: 18,
  //   Icon: LockOutlined,
  //   event: eventTypes.readOnlyModet,
  // },

];

export default pluginsList;
