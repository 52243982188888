import { SET_INITIAL_TEMPLATE, UPDATE_TEMPLATE } from "./actionTypes"



export const setInitialTemplate = (data) => {
    return {
        type: SET_INITIAL_TEMPLATE,
        payload: data
    }
}

export const updateTemplate = (data) => {
    return {
        type: UPDATE_TEMPLATE,
        payload: data
    }
}
