import React, { useEffect, useState } from "react"
import {
  Row,
  Container,
  Col,
  Card,
  Button,
  TabPane,
  TabContent,
  NavLink,
  NavItem,
  CardBody,
  Spinner,
} from "reactstrap"
import FormSection from "./FormSection"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { updateFormData } from "../../store/studyform/actions"
import "../StudyForm"
import { updateButtonState } from "store/studyform/actions"
import {
  displayNotification,
  generateFormData,
  generateVariables,
  validateFormFields,
} from "../../utils/utility"
import { getFunctions, httpsCallable } from "firebase/functions"
import Redirector from "helpers/Redirector"
import classnames from "classnames"

const StudyForm = () => {
  document.title = "Create Study | Hureo.Ai"
  const dispatch = useDispatch()
  const selectFormState = (state) => state.studyForm
  const selectStudyFormProperties = createSelector(
    selectFormState,
    (formData) => ({
      currentForm: formData.form,
      currentQue: formData.currentQuestion,
      currentSec: formData.currentSection,
      isSubmittable: formData.isSubmittable,
      studyType: formData.studyType,
    }),
  )
  const { currentForm, studyType } = useSelector(
    selectStudyFormProperties,
  )
  const [currentFormData, setCurrentFormData] = useState(currentForm)
  const [apiCalled, setApiCalled] = useState(false)
  const [params, setParams] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [loader, setLoader] = useState(false)
  const [loadings, setLoadings] = useState([])
  const [studyId, setStudyId] = useState()
  const [redirectPath, setRedirectPath] = useState()

  const handleFormData = (sectionData, sectionIndex) => {
    const newFormData = currentForm
    newFormData[sectionIndex] = sectionData
    dispatch(updateFormData(newFormData))
  }

  const handleSubmission = async () => {
    try {
      const functions = getFunctions()
      const saveStudyForm = httpsCallable(functions, "saveStudyForm")
      let fieldValues = generateFormData(currentForm)
      if (!fieldValues.studyType) {
        fieldValues = { ...fieldValues, studyType: studyType }
      }
      setLoader(true)
      const user = JSON.parse(localStorage.getItem("authUser"))
      const data = {
        userId: user.uid,
        form: currentForm,
        userName: user.displayName ?? "",
        companyName: user.companyName ?? "",
        templateVariables: generateVariables(currentForm),
        fieldValues: fieldValues,
      }
      saveStudyForm(data).then((result) => {
        // if (result.data.success) {
        //   displayNotification("success", result.data.message)
        // }
        setLoader(false)
        setStudyId(result.data.docId)
        setRedirectPath(`/study/${result.data.docId}/script`)
        setParams({
          ...params,
          state: {
            docId: result.data.docId,
            formTitle: result.data.studyName,
            studyType: result.data.studyType,
            templateId: result.data.templateId,
          },
        })
        setApiCalled(true)
      })
    } catch (error) {
      displayNotification("error", error.message)
      setRedirectPath(`/dashboard`)
      setApiCalled(true)
    }
  }

  useEffect(() => {
    const isSubmittable = validateFormFields(currentForm)
    dispatch(updateButtonState(isSubmittable))
  }, [])

  const generateContent = (sectionData, index) => {
    return (
      <FormSection
        key={index}
        sectionData={sectionData}
        sectionIndex={index}
        updateFormData={handleFormData}
        currentFormData={currentFormData}
      />
    )
  }
  const stepsData = currentForm.map((obj, index) => ({
    title: obj.title,
    content: generateContent(obj, index),
  }))

  const enterLoading = (index) => {
    handleSubmission()
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = false
        return newLoadings
      })
    }, 10000)
  }

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card className="form-wizard-wrapper">
                  <CardBody>
                    <div className="vertical-wizard wizard clearfix vertical">
                      <div className="steps clearfix">
                        <ul>
                          {stepsData.map((step, index) => (
                            <NavItem
                              key={index}
                              className={classnames({
                                current: currentStep === index,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: currentStep === index,
                                  done: currentStep > index,
                                })}
                                onClick={() => setCurrentStep(index)}
                                disabled={currentStep < index}
                              >
                                <span className="number">{index + 1}.</span>{" "}
                                {step.title}
                              </NavLink>
                            </NavItem>
                          ))}
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent
                          activeTab={currentStep + 1}
                          className="body"
                        >
                          {stepsData.map((step, index) => (
                            <TabPane key={index} tabId={index + 1}>
                              {step.content}
                            </TabPane>
                          ))}
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              currentStep === 0
                                ? "previous disabled"
                                : "previous "
                            }
                          >
                            {currentStep > 0 && (
                              <div className="d-flex flex-wrap gap-2">
                                <Button
                                  color="primary"
                                  outline
                                  onClick={() =>
                                    setCurrentStep(currentStep - 1)
                                  }
                                >
                                  Previous
                                </Button>
                              </div>
                            )}
                          </li>
                          <li>
                            {currentStep === stepsData.length - 1 ? (
                              <Button
                                className={loader ? "disabled" : ""}
                                loading={loadings[0]}
                                onClick={() => enterLoading(0)}
                                disabled={!loadings}
                                color="primary"
                                style={{
                                  cursor: loader ? "not-allowed" : "pointer",
                                }}
                              >
                                {loader ? (
                                  <>
                                    <Spinner
                                      size="sm"
                                      color="light"
                                      className="mr-2"
                                    />
                                    Loading...
                                  </>
                                ) : (
                                  "Create Study"
                                )}
                              </Button>
                            ) : (
                              <div className="d-flex flex-wrap gap-2">
                                <Button
                                  color="primary"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={() =>
                                    setCurrentStep(currentStep + 1)
                                  }
                                >
                                  Next
                                </Button>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      {apiCalled && redirectPath && (
        <Redirector path={redirectPath} params={params} replace={true} />
      )}
    </>
  )
}

export default StudyForm
