const {
  DEFAULT_FORM_STRUCTURE,
  USER_FEEDBACK,
  TEST_UI_DESIGN,
  USER_FEEDBACK_FORM_EDITABLE_TEMPLATE,
  TEST_UI_DESIGN_FORM_EDITABLE_TEMPLATE,
  DEFAULT,
} = require("../../constants/studyForm")
const {
  CREATE_FORM,
  UPDATE_CURRENT_FORM,
  SAVE_STUDY_FORM,
  CHANGE_CURRENT_EDITABLE_FORM,
  ADD_NEW_SECTION,
  REMOVE_SECTION,
  CHANGE_CURRENT_SECTION,
  UPDATE_CURRENT_SECTION_DATA,
  ADD_QUESTION,
  REMOVE_QUESTION,
  CHANGE_CURRENT_QUESTION,
  UPDATE_FIELDS_IN_CURRENT_QUESTION,
  ADD_NEW_QUESTION_BLOCK,
  SEND_EMAIL,
  UPDATE_ANSWER,
  UPDATE_BUTTON_STATE,
} = require("./actionTypes")

const getFormType = (type) => {
  switch (type) {
    case USER_FEEDBACK:
      return USER_FEEDBACK_FORM_EDITABLE_TEMPLATE
    case TEST_UI_DESIGN:
      return TEST_UI_DESIGN_FORM_EDITABLE_TEMPLATE
    default:
      return DEFAULT_FORM_STRUCTURE
  }
}

const INIT_STATE = {
  form: DEFAULT_FORM_STRUCTURE,
  currentQuestion: null,
  currentSection: null,
  lastSaveTime: Date.now(),
  name: DEFAULT,
  isEditingForm: false,
  isSubmittable: false,
  studyType: "NA"
}

const studyForm = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_FORM:
      return {
        ...state,
        studyType: action.payload,
      }
    case UPDATE_CURRENT_FORM:
      return {
        ...state,
        form: action.payload,
      }
    case SAVE_STUDY_FORM:
      return {
        state,
      }
    case CHANGE_CURRENT_EDITABLE_FORM:
      return {
        state,
      }
    case ADD_NEW_SECTION:
      return {
        state,
      }
    case ADD_NEW_QUESTION_BLOCK:
      return {
        ...state,
      }
    case REMOVE_SECTION:
      return {
        state,
      }
    case CHANGE_CURRENT_SECTION:
      return {
        ...state,
        currentSection: action.payload,
      }
    case UPDATE_CURRENT_SECTION_DATA:
      return {
        state,
      }
    case ADD_QUESTION:
      return {
        state,
      }
    case REMOVE_QUESTION:
      return {
        state,
      }
    case CHANGE_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
      }
    case UPDATE_FIELDS_IN_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
      }
    case SEND_EMAIL:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_ANSWER:
      return {
        ...state
      }
    case UPDATE_BUTTON_STATE:
      return {
        ...state,
        isSubmittable: action.payload
      }
    default:
      return state
  }
}

export default studyForm
