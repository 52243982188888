import { Progress, Skeleton, Tabs } from "antd"
import { getFunctions, httpsCallable } from "firebase/functions"
import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import "./template.scss"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap"
import {
  displayNotification,
  formatTime,
  getColor,
  getImage,
  getWeekDifference,
} from "utils/utility"
import "moment-timezone"
import moment from "moment-timezone"
import "flatpickr/dist/themes/material_blue.css"
import MeetTable from "./MeetTable"
import Breadcrumb from "components/Common/Breadcrumb"
import { trim } from "lodash"
import SchedulerForm from "./SchedulerForm"
import { Doughnut } from "react-chartjs-2"
import axios from "axios"
import { UserDataContext } from "../../routes/route"

const ReadOnlyTemplateView = () => {
  const usersData = useContext(UserDataContext)
  const navigate = useNavigate()
  const [ongoingMeetings, setOngoingMeetings] = useState([])
  const [upcomingMeetings, setUpcomingMeetings] = useState([])
  const [numOfUsers, setNumOfUsers] = useState(1)
  const [pastMeetings, setPastMeetings] = useState([])
  const [studyDetails, setStudyDetails] = useState({})
  const [meetings, setMeetings] = useState([])
  const [recordings, setRecordings] = useState([])
  const [transcripts, setTranscripts] = useState([])
  const [isApiInProgress, setIsApiInProgress] = useState(false)
  const { studyId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isZoomAuthInProgress, setIsZoomAuthInProgress] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [isZoomAuthorized, setIsZoomAuthorized] = useState(false)
  function convertTo24HourFormat(timestr, period) {
    const hours = timestr.split(":")[0]
    const minutes = timestr.split(":")[1]
    let hoursInt = parseInt(hours, 10)
    const minutesInt = parseInt(minutes, 10)

    if (period === "PM" && hoursInt < 12) {
      hoursInt += 12
    } else if (period === "AM" && hoursInt === 12) {
      hoursInt = 0
    }

    const formattedHours = hoursInt.toString().padStart(2, "0")
    const formattedMinutes = minutesInt.toString().padStart(2, "0")
    const formattedTime = `${formattedHours}:${formattedMinutes}`
    return formattedTime
  }

  const handleScheduleMeet = async (values) => {
    const datestring = values.date.format("YYYY-MM-DD")
    const returnedtime = convertTo24HourFormat(values.time, values.period)
    const updatedDateString = datestring + " " + returnedtime
    let revisedValue = moment.tz(updatedDateString, values.timezone)
    const timeString = revisedValue.utc().format()
    const start_time = timeString
    try {
      setIsApiInProgress(true)
      // const formattedTime = values.time.format("HH:mm:ss")
      const formattedDate = values.date.format("YYYY-MM-DD")
      delete values.date
      delete values.time
      delete values.period
      const result = await fbCreateZoomMeet({
        ...values,
        start_time,
      })
      if (!result) {
        displayNotification("Failed to create meeting")
        return
      }
      let hostName = "Test user"
      if (usersData) {
        const obj = usersData
        hostName = obj.displayName
      }
      let utcDate = new Date(result.start_time)
      let localTime = utcDate.toLocaleString("en-US", {
        timeZone: result.timezone,
      })

      let dateObject = new Date(localTime)
      let formatTime = dateObject.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      let timeAndZone = formatTime + " " + `(${result.timezone})`
      const emailInvite = {
        emailList: values.invite,
        joinUrl: result.join_url,
        hostName: hostName,
        agenda: result.agenda,
        topic: result.topic,
        date: formattedDate,
        time: timeAndZone,
      }
      setSubscribemodal(false)
      setIsApiInProgress(false)
      await fbGetMeetings()
      await sendEmailInvite(emailInvite)
    } catch (error) {
      setSubscribemodal(false)
      setIsApiInProgress(false)
      displayNotification("error", error.message)
      console.log("ERROR : ", error)
    }
  }

  const displayTemplate = () => {
    const currentURL = window.location.pathname
    navigate(currentURL + "/view")
  }
  const handleNavigate = () => {
    const currentURL = window.location.pathname
    navigate(currentURL + "/report")
  }
  const handleNotes = () => {
    const currentURL = window.location.pathname
    navigate(currentURL + "/notes")
  }

  const fbGetUserId = async () => {
    try {
      const functions = getFunctions()
      const getUsers = httpsCallable(functions, "getUsers")
      let userResponse = await getUsers()
      const userId = userResponse.data.users[0].id
      return userId
    } catch (error) {
      console.log("fbGetUserId ERROR : ", error)
      return
    }
  }
  const fbGetMeetings = async () => {
    try {
      const functions = getFunctions()
      const testgetMeetings = httpsCallable(functions, "testgetMeetings")
      const meetResponse = await testgetMeetings({ formId: studyId })
      const currentTime = Date.now()
      const allMeetings = meetResponse.data
      allMeetings.sort(
        (a, b) => new Date(a.start_time) - new Date(b.start_time),
      )
      if (allMeetings) {
        const upcomingMeets = allMeetings.filter(
          (meet) =>
            new Date(meet.start_time) > new Date(currentTime - 60 * 60 * 1000),
        )
        const pastMeets = allMeetings.filter(
          (meet) =>
            new Date(meet.start_time) < new Date(currentTime - 60 * 60 * 1000),
        )
        setMeetings(allMeetings)
        setUpcomingMeetings(upcomingMeets)
        setPastMeetings(pastMeets)
        const response = await getRecordings()
        setRecordings(response.recordings)
        setTranscripts(response.transcripts)
      }
    } catch (error) {
      // displayNotification("error", error.message)
      console.log("fbGetMeetings ERROR msg: ", error.message)
      console.log("fbGetMeetings ERROR cd: ", error.code)
      console.log("fbGetMeetings ERROR dtls: ", error.details)
      setMeetings([])
      setUpcomingMeetings([])
      setPastMeetings([])
      setRecordings([])
      setTranscripts([])
    }
  }

  const fbDeleteMeeting = async (meetingId) => {
    try {
      const functions = getFunctions()
      const deleteMeeting = httpsCallable(functions, "testdeleteMeeting")
      const meetResponse = await deleteMeeting({ meetingId: meetingId })
      if (meetResponse) {
        await fbGetMeetings()
        displayNotification("success", "Meeting deleted successfully")
      } else {
        console.error("Failed to delete meeting", meetResponse)
      }
    } catch (error) {
      console.log("fbGetMeetings ERROR : ", error.message)
      await fbGetMeetings()
    }
  }

  const fbOngoingMeetings = async () => {
    try {
      const functions = getFunctions()
      const testgetLiveOngoingMeetings = httpsCallable(
        functions,
        "testgetLiveOngoingMeetings",
      )
      const response = await testgetLiveOngoingMeetings()
      setOngoingMeetings(response.data.meetings)
    } catch (error) {
      console.log("fbOngoingMeetings ERROR : ", error)
      setOngoingMeetings([])
    }
  }

  const fbCreateZoomMeet = async (meetingData, userId) => {
    const functions = getFunctions()
    const testcreateZoomMeet = httpsCallable(functions, "testcreateZoomMeet")
    const data = {
      agenda: meetingData.agenda,
      topic: meetingData.topic,
      type: 2, // 2 for scheduled meeting
      start_time: meetingData.start_time, // Use current time or specify the desired start time
      duration: 40, // in minutes
      password: "123456",
      // schedule_for: "yash_bhavi@apprely.com",
      join_before_host: "true",
      settings: {
        auto_recording: "cloud",
      },
    }
    const response = await testcreateZoomMeet({ meetData: data })
    if (response.data) {
      const functions = getFunctions()
      const storeMeet = httpsCallable(functions, "storeMeet")
      const meeting = response.data
      const storeMeetResponse = await storeMeet({
        userId: userId,
        meetData: { ...meeting, formId: studyId },
      })
      displayNotification("success", "Meeting scheduled successfully")
      return response.data
    } else {
      return
    }
  }
  const getRecordings = async () => {
    try {
      const functions = getFunctions()
      const testgetAllRecordings = httpsCallable(
        functions,
        "testgetAllRecordings",
      )
      const response = await testgetAllRecordings()
      const data = response.data.meetings
      const recordings = data.map((meet) => {
        return {
          meetingId: meet.id,
          recording_url:
            data.length > 0
              ? `${meet.share_url}?pwd=${meet.recording_play_passcode}`
              : "",
        }
      })
      const transcripts = []
      data.map((meeting) => {
        let obj = { meetingId: meeting.id, transcript_url: "" }
        let transcript_url = ""
        if (Object.hasOwn(meeting, "recording_files")) {
          meeting.recording_files.map((record) => {
            if (record.file_type === "TRANSCRIPT") {
              transcript_url = record?.play_url ?? ""
            }
          })
        }
        obj.transcript_url =
          transcript_url === ""
            ? ""
            : transcript_url + `?pwd=${meeting.recording_play_passcode}`
        transcripts.push(obj)
      })

      return {
        recordings: recordings,
        transcripts: transcripts,
      }
    } catch (error) {
      console.log("getRecording ERROR : ", error)
      return {
        recordings: [],
        transcripts: [],
      }
    }
  }

  const fbEnableTranscription = async () => {
    try {
      const userId = await fbGetUserId()
      const functions = getFunctions()
      const updateUserSettings = httpsCallable(functions, "updateUserSettings")
      const body = {
        recording: {
          recording_audio_transcript: true,
        },
      }
      const response = await updateUserSettings({ userId: userId, body: body })
    } catch (error) {
      console.log("ERROR - ", error)
    }
  }
  const fetchStudyDetails = async () => {
    setIsLoading(true)
    try {
      const functions = getFunctions()
      const getStudyDetails = httpsCallable(functions, "getStudyDetails")
      const { data } = await getStudyDetails({ formId: studyId })

      if (!data) {
        return
      }
      if (!data.success) {
        return
      }
      setStudyDetails(data.data)
      if (studyDetails?.participantCount) {
        setNumOfUsers(studyDetails?.participantCount)
      }
      setIsLoading(false)
    } catch (error) {
      // displayNotification("error", error.message ?? "Failed to fetch study details")
      setIsLoading(false)
    }
  }

  const checkZoomAuthorization = async () => {
    try {
      const functions = getFunctions()
      const isZoomAuthorized = httpsCallable(functions, "isZoomAuthorized")
      const response = await isZoomAuthorized()
      const { status } = response.data
      setIsZoomAuthorized(status)
      return status
    } catch (error) {
      console.log("checkZoomAuth error : ", error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    checkZoomAuthorization()
    fbGetMeetings()
    fbOngoingMeetings()
    fetchStudyDetails()
    // fbEnableTranscription()
    getRecordings()
  }, [])

  const handleDelete = async (meetingId) => {
    await fbDeleteMeeting(meetingId)
  }
  const items = [
    {
      key: "1",
      label: <span className="font-poppins">Upcoming meetings</span>,
      children: (
        <MeetTable
          handleScheduleMeet={handleScheduleMeet}
          list={upcomingMeetings}
          studyDetails={studyDetails}
          recordingList={recordings}
          transcriptList={transcripts}
          fbGetMeetings={fbGetMeetings}
          isApiInProgress={isApiInProgress}
          id={1}
          handleDelete={handleDelete}
        />
      ),
    },
    {
      key: "2",
      label: <span className="font-poppins">Ongoing meetings</span>,
      children: (
        <MeetTable
          list={ongoingMeetings}
          recordingList={recordings}
          transcriptList={transcripts}
          id={2}
          handleDelete={handleDelete}
        />
      ),
    },
    {
      key: "3",
      label: <span className="font-poppins">Past meetings</span>,
      children: (
        <MeetTable
          list={pastMeetings}
          recordingList={recordings}
          transcriptList={transcripts}
          id={3}
          handleDelete={handleDelete}
        />
      ),
    },
  ]
  const handleModal = () => {
    setSubscribemodal(true)
  }
  const sendEmailInvite = async (data) => {
    const functions = getFunctions()
    const sendInvite = httpsCallable(functions, "sendInvite")
    const response = await sendInvite(data)
  }

  const getChartColorsArray = (colors) => {
    colors = JSON.parse(colors)
    return colors.map(function (value) {
      var newValue = value.replace(" ", "")
      if (newValue.indexOf(",") === -1) {
        var color = getComputedStyle(document.documentElement).getPropertyValue(
          newValue,
        )

        if (color.indexOf("#") !== -1) color = color.replace(" ", "")
        if (color) return color
        else return newValue
      } else {
        var val = value.split(",")
        if (val.length === 2) {
          var rgbaColor = getComputedStyle(
            document.documentElement,
          ).getPropertyValue(val[0])
          rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")"
          return rgbaColor
        } else {
          return newValue
        }
      }
    })
  }

  const handleZoomAuthorization = async () => {
    setIsZoomAuthInProgress(true)
    try {
      const functions = getFunctions()
      const authorizeZoom = httpsCallable(functions, "authorizeZoom")
      const response = await authorizeZoom()
      const { status, redirect, url } = response.data
      setIsZoomAuthInProgress(false)
      if (status && redirect) {
        window.location.replace(url)
        // setTimeout(() => {
        //   window.location.reload()
        // }, 2000)
        // displayNotification("success", "Successfully authorized")
      }
    } catch (error) {
      setIsZoomAuthInProgress(false)
      displayNotification("error", error)
      console.log("ERROR : ", error)
    }
  }

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {isLoading && (
              <h2 className="text-truncate font-size-15">
                <Skeleton.Input active={true} size={"small"} />
              </h2>
            )}
            {!isLoading && (
              <Breadcrumb
                handleModal={handleModal}
                breadcrumbItem={
                  (studyDetails?.studyName ?? "") +
                  " : " +
                  (studyDetails?.studyType ?? "")
                }
              />
            )}
            <Row>
              <Col xs="12" sm="9">
                <Card className="script-display">
                  <div>
                    <CardBody>
                      <Row>
                        <Col sm="4" xs="6">
                          <div className="d-flex">
                            {/* <img src={img1} alt="" className="avatar-sm me-4" /> */}
                            <div className="flex-grow-1 overflow-hidden">
                              {isLoading && (
                                <h2 className="text-truncate font-size-15">
                                  <Skeleton.Input
                                    active={true}
                                    size={"small"}
                                  />
                                </h2>
                              )}
                              {!isLoading && (
                                <>
                                  <h5 className=" font-size-15  mt-0 mb-0">
                                    <span className="text-truncate mb-0">
                                      Project Overview
                                    </span>
                                  </h5>
                                  {/* <h5 className="font-size-13 mt-2 mb-0">
                                    <span className="text-muted mb-0">
                                      {studyDetails.studyType}
                                    </span>
                                  </h5> */}
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {isLoading && <Skeleton active paragraph={{ rows: 4 }} />}
                      {!isLoading && (
                        <>
                          <h5 className="font-size-15  mt-2">Goals :</h5>
                          <span className="text-muted font-size-13">
                            {studyDetails?.goal ?? "NA"}
                          </span>

                          <div className="ml-4">
                            <Row className="mb-1">
                              <Col md="6">
                                {studyDetails?.metrics &&
                                  studyDetails.metrics.map((point, index) => {
                                    return (
                                      <>
                                        <p className="mb-0">
                                          <span
                                            key={index}
                                            className="text-muted font-size-13 mb-0"
                                          >
                                            <i className="mdi mdi-circle-medium me-1 " />{" "}
                                            {point}
                                          </span>
                                        </p>
                                      </>
                                    )
                                  })}
                                <h5 className="font-size-14 mt-1 mb-2">
                                  <i className="mdi text-muted mdi-circle-medium me-1 " />{" "}
                                  Product :{" "}
                                  <span className="text-muted font-size-13 ">
                                    {studyDetails?.productName ?? "NA"}
                                  </span>
                                </h5>
                                <h5 className="font-size-14 mt-0">
                                  <i className="mdi text-muted mdi-circle-medium me-1 " />{" "}
                                  Type :{" "}
                                  <span className="text-muted font-size-13">
                                    {studyDetails.productType}
                                  </span>
                                </h5>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                      {!isLoading && (
                        <>
                          <h5 className="font-size-15   mt-2 mb-3">
                            About the Users :
                          </h5>
                          <h5 className="font-size-14 mt-0 ml-4">
                            <i className="mdi mdi-circle-medium  me-1 text-muted " />
                            Number of Users:{" "}
                            <span className="text-muted font-size-13">
                              {studyDetails?.participantCount ?? 0}
                            </span>
                          </h5>
                          <h5 className="font-size-14 mt-0 ml-4">
                            <i className="mdi mdi-circle-medium  me-1 text-muted " />
                            Product Awareness:{" "}
                            <span className="text-muted font-size-13">
                              {studyDetails?.participantProductAwareness ??
                                "NA"}
                            </span>
                          </h5>
                        </>
                      )}

                      <Row className="task-dates">
                        {isLoading && (
                          <Col sm="4" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14">
                                <i className="bx bx-calendar me-1 " />{" "}
                                <Skeleton.Input active size="small" />
                              </h5>
                              <p className="text-muted mb-0">
                                <Skeleton.Input active size="small" />
                              </p>
                            </div>
                          </Col>
                        )}
                        {!isLoading && (
                          <Col sm="4" xs="6">
                            <div className="mt-4">
                              <h5 className="font-size-14  ">
                                <i className="bx bx-calendar me-1 " /> Start
                                Date
                              </h5>
                              <p className="text-muted mb-0 font-size-13">
                                {formatTime(studyDetails?.createdAt)}
                              </p>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </div>
                </Card>
              </Col>
              <Col xs="12" sm="3">
                <Card style={{ height: "95.5%" }}>
                  <CardBody>
                    <CardTitle className="mb-4">Script</CardTitle>
                    <Col>
                      <Card className="study-container">
                        <div className="hover-text" onClick={displayTemplate}>
                          Edit
                        </div>
                        <CardBody className="studies-card">
                          <div
                            className={
                              "favorite-icon-" +
                              getColor(studyDetails?.studyType)
                            }
                            onClick={displayTemplate}
                          >
                            <div className="mt-1 hstack gap-2">
                              <span className="badge p-2 badge-text ms-auto rounded-1 badge-soft-success"></span>
                            </div>
                            <div
                              className={
                                "img-content-" +
                                getColor(studyDetails?.studyType)
                              }
                            >
                              <img
                                src={getImage(studyDetails?.studyType)}
                                alt="1"
                              />
                            </div>
                          </div>
                          <div className="studies-card-content">
                            <div className="d-flex justify-content-between">
                              <h5 className="study-title mb-2">
                                <medium
                                  className="study-primary-text"
                                  // onClick={() => handleClick(data)}
                                >
                                  {studyDetails.studyName ?? ""}
                                </medium>
                              </h5>
                              <div className="delete-button">
                                <UncontrolledDropdown className="mb-2">
                                  <DropdownToggle
                                    className="font-size-16 text-muted"
                                    tag="a"
                                  >
                                    <i className="mdi mdi-dots-horizontal"></i>
                                  </DropdownToggle>

                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem>Delete</DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item">
                                <p className="text-muted study-secondary-text">
                                  {studyDetails.studyType ?? ""}
                                </p>
                              </li>{" "}
                            </ul>
                            <div className="mt-3 hstack gap-2"></div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="5">
                <Card className="p-4">
                  <CardTitle>Progress</CardTitle>
                  <CardBody className="d-flex ">
                    <div id="doughnut-chart" className="e-chart">
                      <div className="echarts-for-react ">
                        <Progress
                          type="circle"
                          success={{
                            percent:
                              parseInt(
                                (pastMeetings.length /
                                  studyDetails?.participantCount ?? 1) * 100,
                              ) ?? 0,
                          }}
                          format={(percent) =>
                            `${pastMeetings.length}/${
                              studyDetails?.participantCount ?? 1
                            }`
                          }
                          strokeLinecap="butt"
                          size={"default"}
                          strokeWidth={20}
                        />
                      </div>
                    </div>
                    <div className="mx-4 mt-4">
                      <h5 className="font-size-14 mt-0 ml-4">
                        <i className="mdi mdi-circle-medium  me-1 text-muted " />
                        Study Progress:{" "}
                        <span className="text-muted font-size-13">
                          {pastMeetings.length ?? 0}/{" "}
                          {studyDetails?.participantCount ?? 0}
                        </span>
                      </h5>
                      <h5 className="font-size-14 mt-0 ml-4">
                        <i className="mdi mdi-circle-medium  me-1 text-muted " />
                        Duration:{" "}
                        <span className="text-muted font-size-13">
                          {getWeekDifference(studyDetails?.createdAt)} weeks
                        </span>
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="7">
                <Card style={{ height: "94%" }}>
                  {!isLoading && (
                    <CardBody>
                      {!isZoomAuthorized && (
                        <>
                          {/* <div className="d-flex justify-content-center align-items-center ">
                            <p
                              className="alert alert-warning"
                              style={{
                                maxWidth: "1100px",
                                margin: "25px 0 25px 0",
                              }}
                            >
                              Visit dasbhboard to connect your Zoom account
                            </p>
                            <Button
                              color="primary"
                              onClick={navigate("/dashboard")}
                            >
                              Go to dashboard
                            </Button>
                          </div> */}
                          {isLoading && <Skeleton className="p-3" />}
                          <Button
                            type="primary"
                            color="primary"
                            disabled={isZoomAuthInProgress}
                            onClick={handleZoomAuthorization}
                          >
                            {isZoomAuthInProgress ? (
                              <>
                                <Spinner
                                  size="sm"
                                  color="light"
                                  className="mr-2"
                                />{" "}
                                Authorization in progress...
                              </>
                            ) : (
                              "Connect to Zoom account"
                            )}
                          </Button>
                        </>
                      )}
                      {isZoomAuthorized && (
                        <>
                          <div className="d-flex justify-content-between   ">
                            <CardTitle className="mb-4">Meetings</CardTitle>

                            <div>
                              <Button
                                className="mr-2"
                                type="primary"
                                color="primary"
                                onClick={handleNotes}
                              >
                                <i className="mdi mdi-table-large-plus"></i> View Summary
                              </Button>
                              <Button
                                className="ml-2"
                                type="primary"
                                color="primary"
                                onClick={handleNavigate}
                              >
                                <i className="mdi mdi-chart-bar"></i> Generate Report
                              </Button>
                            </div>
                          </div>
                          <Tabs defaultActiveKey="1" items={items} />
                        </>
                      )}
                    </CardBody>
                  )}
                  {isLoading && <Skeleton className="p-3" />}
                </Card>
              </Col>
            </Row>
          </Container>

          <Modal
            isOpen={subscribemodal}
            role="dialog"
            autoFocus={true}
            centered
            className="meet-modal"
            data-toggle="modal"
          >
            <div>
              <ModalHeader
                className="border-bottom-0"
                toggle={() => {
                  setSubscribemodal(!subscribemodal)
                }}
              ></ModalHeader>
            </div>
            <Row>
              <Col>
                <CardTitle className="h4">
                  {" "}
                  <h4 className="text-primary text-center mb-4">
                    Schedule your meet!
                  </h4>
                </CardTitle>

                <SchedulerForm
                  handleScheduleMeet={handleScheduleMeet}
                  studyDetails={studyDetails}
                  isApiInProgress={isApiInProgress}
                />
              </Col>
            </Row>
          </Modal>
        </div>
      </React.Fragment>
    </>
  )
}

export default ReadOnlyTemplateView
