import $ from "jquery" //Load jquery
import React, { createRef, useContext, useEffect, useState } from "react" //For react component
import { MyContext } from "./context"
// import ReactJson from 'react-json-view'
import { Button, Spinner } from "reactstrap"
import { useParams } from "react-router-dom"
import { updateTemplateForm } from "store/dynamicForm/actions"
import { updateChat } from "store/chatWindow/actions"
import { useDispatch } from "react-redux"
import { getFunctions, httpsCallable } from "firebase/functions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { displayNotification } from "utils/utility"
import useAutoSaveChatBot from "./useAutoSaveChatBot"

window.jQuery = $ //JQuery alias
window.$ = $ //JQuery alias

require("jquery-ui-sortable") //For FormBuilder Element Drag and Drop
require("formBuilder/dist/form-render.min.js")

const FormRender = ({
  data,
  sectionIndex,
  list,
  name,
  title,
  showButton,
  handleUpdateTemplate,
}) => {
  const { studyId, templateId, meetingId, templateFormId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { form, setForm } = useContext(MyContext)
  const [output, setOutput] = useState({})
  const [updatedData, setUpdatedData] = useState([])
  const [previousData, setPreviousData] = useState()
  const fb = createRef()
  const selectChatWindowState = (state) => state.ChatWindow
  const selectChatWindowProperties = createSelector(
    selectChatWindowState,
    (chatWindow) => ({
      formResponse: chatWindow.formResponse,
    }),
  )
  const { formResponse } = useSelector(selectChatWindowProperties)
  let formRender

  useEffect(() => {

    let formData = data

    formRender = $(fb.current).formRender({ formData })
  }, [data])

  useEffect(() => {
    console.log(fb)
  }, [])

  const saveData = async (dataToSave) => {
    try {
      const userData = $(fb.current)?.formRender("userData")

      const newList = [...list]
      if (Object.keys(userData).length > 0) {
        const data = dataToSave
        setUpdatedData(userData)
        newList[sectionIndex] = { name: name, title: title, root: userData }
      }

      const functions = getFunctions()
      const saveFormResponses = httpsCallable(functions, "saveFormResponses")
      setIsLoading(true)
      const apiData = {
        formResponse: newList,
        formId: studyId,
        meetingId: meetingId,
        studyName: "",
        templateFormId: templateFormId,
      }
      if (JSON.stringify(previousData) !== JSON.stringify(newList)) {

  const result = await saveFormResponses(apiData)
  setPreviousData(result.data.data)
  
  dispatch(
    updateChat({
      data: result.data.data,
    }),
    )
    if (result && result.data && result.data.success) {
      // displayNotification("success", result.data.message)
      // handleUpdateTemplate(result.data.data)
    } else {
      displayNotification("error", "Something went wrong!")
    }
  }

      setIsLoading(false)
    } catch (error) {
      console.error("Error saving data:", error)
      displayNotification("error", error?.message ?? "Something went wrong!")
      setIsLoading(false)
    }
  }

  useAutoSaveChatBot($(fb.current)?.formRender("userData"),list,name,title,sectionIndex, saveData)
  // useEffect(() => {
  //   saveData()
  // }, [updatedData])

  return (
    <div
      className="form-wrap form-builder p-3"
      style={{ overflowY: "scroll", height: "67vh" }}
    >
      <form ref={fb}></form>
      {Object.keys(data).length > 0 && (
        <>
          {/* {showButton && (
            <Button
              // onClick={saveData}
              type="button"
              color="primary"
              className="mr-auto "
            >
              {isLoading ? (
                <>
                  <Spinner size="sm" color="light" className="mr-2" /> Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          )} */}
        </>
      )}
    </div>
  )
}

export default FormRender
